.section-slider {
	position: relative;
	z-index: 0;

	[data-srcset] {
		display: none;
	}
}

.main-slider {
	.visual {
		min-height: 1px;
		@include media('≥tablet') {
			display: none;
		}
	}

	.text {
		text-align: center;
		padding: 1.5rem 0;
		color: $black;

		@include media('≥tablet') {
			text-align: left;
		}
	}
}

.content-slide {
	text-align: center;
	background-color: $pale-gray;
	display: flex !important;
	flex-direction: column;

	@include media('≥tablet') {
		background-color: transparent;
		@include v-center(25rem);
	}

	@include media('≥widescreen') {
		@include v-center(30rem);
		font-size: 18px;
		line-height: 28px;
	}

	h2 {
		margin-bottom: 0.75rem;
	}

	.wrap-btn {
		@include media('≥widescreen') {
			padding-top: 0.5rem;
		}
	}
}

//.slider-controls {
//
//	position: absolute;
//	bottom: 0;
//	left: 0;
//	right: 0;
//
//	@include media('≥tablet') {
//		bottom: 2rem;
//	}
//
//	.container {
//		display: flex;
//		flex-direction: row-reverse;
//		align-items: flex-end;
//	}
//
//	.slick-arrow {
//		@include size(2rem);
//		display: inline-flex;
//		justify-content: center;
//		align-items: center;
//		background-color: $black;
//		border: 0;
//		color: $white;
//		font-size: 0;
//
//		&:before {
//			@extend %icomoon;
//			font-size: 1rem;
//		}
//
//		&:hover {
//			cursor: pointer;
//		}
//
//		&:focus {
//			outline: none;
//		}
//
//		&:active {
//			border-style: outset;
//			background-image: none;
//		}
//
//		&.slick-prev:before {
//			content: "\e90c";
//		}
//
//		&.slick-next {
//			margin-left: 0.5rem;
//
//			&:before {
//				content: "\e90b";
//			}
//		}
//	}
//}
//
//.wrap-arrows {
//	display: none;
//
//	@include media('≥tablet') {
//		display: block;
//	}
//}

.slick-dots {
	@extend %listreset;
	display: flex;
	font-size: 0;
	position:absolute;
	bottom: 0.75rem;
	left: 50%;
	transform: translateX(-50%);

	@include media('≥phone') {
		bottom: 0.75rem;
	}

	@include media('≥tablet') {
		bottom: 2rem;		
	}

	.slider-controls & {
		
		@include media('≥tablet') {
			position: static;
			transform: none;
			margin-right: auto;
		}

		button {
			pointer-events: none;
			
			@include media('≥tablet') {
				pointer-events: auto;
				border-color: rgba($black, 0.8);
			}

			&:hover {
				background-color: rgba($gray-500, 0.4);

				@include media('≥tablet') {
					background-color: rgba($black, 0.4);					
				}
			}
		}

		.slick-active {
			button {
				background-color: rgba($gray-500, 0.8);	
				
				@include media('≥tablet') {
					background-color: rgba($black, 0.8);
				}
			}	
		}
	}

	li {
		outline: none;

		+ li {
			margin-left: 0.375rem;

			@include media('≥phone') {
				margin-left: 0.5625rem;
			}

			@include media('≥tablet') {
				margin-left: 0.625rem;
			}
		}
	}

	.slick-active {
		button {
			background-color: $gray-500;	
		}	
	}

	button {
		@include size(0.5rem);
		background-color: transparent;
		border: 1px solid $gray-500;
		border-radius: 100%;
		padding: 0;
		cursor: pointer;

		@include media('≥phone') {
			@include size(0.75rem);			
		}

		&:hover {
			background-color: rgba($gray-500, 0.8);
		}

		&:focus {
			outline: none;
		}
	}
}