// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

// font weight
// Fonts
// Body
// Paragraphs
// Grid columns
// Buttons
// submit button


//
// Color system
//
// stylelint-disable
$gray: #666;
$gray-light: #777;
$gray-dark: #323232;
$pale-gray: #f1f4f6;
$white: #fff;
$dark-red: #7b7b7b;
$gray-50: #f0f4f7; // Custom color
$gray-100: #f7f9f9; // Custom color
$gray-200: #f1f4f6; // Custom color
$gray-300: #f1f4f6; // Custom color
$gray-400: #e2e4e6; // Custom color
$gray-500: #c2cacf; // Custom color
$gray-550: #cdcdcd; // Custom color
$gray-600: #57606A; // Custom color
$gray-700: #EEF1F3; // Custom color
$gray-800: #494949; // Custom color
$gray-900: #231F20;
$black: #000;

$grays: ();
$grays: map-merge(("100": $gray-100,
    "200": $gray-200,
    "300": $gray-300, 
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900), $grays);

$blue: #0076C0; // Custom color
$blue-light: #00bcd4; // Custom color
$indigo: #6610f2;
$purple: #9c27b0; // Custom color
$pink: #e91e63; // Custom color
$red: #dc3545;
$orange: #ff9900; // Custom color
$yellow: #e9a006;
$green: #4caf50; // Custom color
$teal: #20c997;
$cyan: #17a2b8;

$colors: ();
$colors: map-merge(("blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray-light": $gray-550,
    "gray-100": #D6D6D6,
    "gray": $gray-600,
    "gray-dark": $gray-800), $colors);

$primary: $blue;
$secondary: $gray-550;
$success: #17c8d2;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-400;
$dark: $gray-800;

$theme-colors: ();
$theme-colors: map-merge(("primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "gray-light": $gray-light,
    "pale-gray": $pale-gray,
    "gray": $gray,
    "black": $black,
    "blue": $blue), $theme-colors);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px) !default;



// font weight
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-black: 800;

$widget-margin: 20px;
$vertical-rhythm: 20px;

$wp-caption-border: 1px solid $black;

// Fonts
$font-family-sans-serif: 'Arial',
'Helvetica',
sans-serif;
// $base-font-serif:             'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;
// $font-family-base:             "HelveticaNeue", $font-family-sans-serif;
$font-family-base: 'Roboto',
$font-family-sans-serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base !default;
$font-size-sm: $font-size-base !default;
$line-height-base: 1.5;
// $line-height-base:            1.42857;

$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;

$component-active-color: $blue !default;
$component-active-bg: theme-color("primary") !default;

// $base-min-width:              320px;
// $max-width-center-container:  1200px;

$border-radius-base: 0;

// Body
$body-bg: $white;
$body-color: $gray-900;


$box-shadow-sm: 0 2px 2px 0 rgba($black, 0.14),
0 3px 1px -2px rgba($black, 0.12),
0 1px 5px 0 rgba($black, 0.2) !default;
$box-shadow: 0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;


$border-radius: .125rem !default;
$border-radius-lg: .125rem !default;
$border-radius-sm: .125rem !default;

// Style anchor elements.

$link-color: theme-color("primary") !default;
$link-decoration: none !default;
$link-hover-color: $link-color !default;
$link-hover-decoration: none !default;

// Paragraphs
$paragraph-margin-bottom: 1rem;

// Grid columns
$grid-columns: 12;
$grid-gutter-width: 1.875rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
// $padding-y:                   0.75rem;
// $padding-x:                   0.75rem;
$input-btn-padding-y: .375rem !default;
$input-btn-padding-x: .75rem !default;
$input-btn-line-height: 1.2 !default;

$input-btn-focus-width: .2rem !default;
$input-btn-focus-color: rgba($blue, .25) !default;
$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-sm: .25rem !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-line-height-sm: 1.2 !default;

$input-btn-padding-y-lg: .875rem !default;
$input-btn-padding-x-lg: 2.25rem !default;
$input-btn-line-height-lg: 1 !default;
$input-color: $body-color !default;
// $input-focus-bg:                        $input-bg !default;
$input-focus-border-color: lighten($blue, 25%) !default;
// $input-focus-color:                     $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$custom-control-indicator-active-bg: lighten($gray, 35%) !default;

// $input-btn-border-width:      $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 0.5rem !default;
$btn-padding-x: 1.75rem !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

// Navs
$navbar-nav-link-padding-x: 0rem !default;
$nav-link-padding-y: .5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $pale-gray !default;

// Buttons
$btn-font-weight: normal;
$btn-border-width: 0;
$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;
$btn-border-radius: 0;
$btn-padding: 0.75rem;
// $btn-padding:                 13px 10px;
$btn-font-size-base: 16px;
$btn-line-height: $btn-font-size-base+8;

$border-radius: .125rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;


$link-hover-decoration: none !default;

// submit button
$submit-btn-padding: $btn-padding;
$submit-btn-default-color: $btn-default-color;
$submit-btn-font-size-base: $btn-font-size-base;
$submit-btn-font-weight: $btn-font-weight;
$submit-btn-line-height: $btn-line-height;
$submit-btn-border-radius: $btn-border-radius;
$submit-btn-border-width: $btn-border-width;
$submit-btn-default-border: $btn-default-border;
$submit-btn-default-bg: $btn-default-bg;

// heading
$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.5 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.75 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// $headings-margin-bottom:      ($spacer / 2) !default;
$headings-margin-bottom: 22px;
$headings-font-family: inherit !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;


// inputs
$input-padding-x: 2.4375rem;
$input-color: $body-color;

// Jumbotron

$jumbotron-padding: 1rem !default;
$jumbotron-bg: $blue !default;

// Cards

$card-spacer-y: .5625rem !default;
$card-spacer-x: 2.3875rem 4rem 2.5rem !default;
$card-border-width: 0 !default;
$card-border-radius: 0 !default;
$card-border-color: rgba($black, .125) !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: rgba($black, .03) !default;
$card-bg: $gray-100 !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: ($grid-gutter-width / 2) !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Breadcrumbs

$breadcrumb-padding-y: .5rem !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding: .625rem !default;

$breadcrumb-margin-bottom: 0 !default;

$breadcrumb-bg: $white !default;
$breadcrumb-divider-color: $blue !default;
$breadcrumb-active-color: $black !default;
$breadcrumb-divider: "\e907" !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;

$modal-backdrop-bg: $gray !default;
$modal-backdrop-opacity: 1 !default;
$modal-header-padding: 1rem !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .3s ease-out !default;