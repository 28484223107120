
.skew-style p {
    color: #2B2F2F;
}

.new-style #accordion .card .accordion-arrow {
    right: 0;
}

.reset .mb-48 {
    margin-bottom: 48px !important;

    @media (max-width:768px) {
        margin-bottom: 40px !important;
    }
}

.section-resources-new.second .border-list .row .col-md-4:nth-child(1) .section-resources_item.border-none, 
.section-resources-new.second .border-list .row .col-md-4:nth-child(2) .section-resources_item.border-none {
    border-bottom: none !important;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career {
    background-image: url(./images/career/career-bg.png);
    background-repeat: no-repeat;
    background-size: cover;   
}

.section-about-career {
    .indicators-card {
        .indicators-card_number {
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            color: #171717;
            margin-bottom: 4px;
        }

        .indicators-card_characteristics {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 172%;
            font-variant: small-caps;
            color: #7D7D7D;
            margin-bottom: 16px;
        }

        .indicators-card_description {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #2B2F2F;
        }
    }

    @media (max-width:992px) {
        .col-md-9 {
            flex: 0 0 90%;
            max-width: 90%;
        }

        .d-desctop {
            display: none !important;
        }

        .d-992 {
            display: block !important;
        }
    }

    @media (max-width:768px) {

        .d-992 {
            display: none !important;
        }

        .d-768 {
            display: block !important;
        }
    }

    @media (max-width:576px) {
        .col-md-9 {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
        }

        .d-768 {
            display: none !important;
        }

        .d-576 {
            display: block !important;
        }

        .col-md-4 {
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            .indicators-card_number {
                font-size: 24px;
                line-height: 28px;

                @media (max-width:320px) {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }
    }

    @media (max-width:320px) {

        .d-576 {
            display: none !important;
        }

        .d-320 {
            display: block !important;
        }
    }
}
 
.section-culture {
    .slick-track {
        margin: 0 -16px;
    }

    .culture-slider-item {
        background: #FFFFFF;
        border: 1px solid #D7D7D7;
        padding: 40px 32px;
        margin: 0 16px;
    }

    .slick-list {
        overflow: inherit;
    }

    .culture-slider-item_icon {
        margin-bottom: 48px;
    }

    .culture-slider-item_title {

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #171717;
        }
    }

    .culture-slider-item_description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3D4448;
    }

    .slick-arrow {
        position: absolute;
        top: -70px;
        right: 50px;
        cursor: pointer;

        &.prev {
            right: 110px;
        }

        &:hover path {
            fill: #0076C0;
        }
    }

    @media (max-width:992px) {
        .col-md-5 {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .slick-list {
            overflow: hidden;
        }

        .culture-slider-item_title h4 {
            font-size: 19px;

            @media (max-width:320px) {
                font-size: 16px;
            }
        }

        .culture-slider-item {
            min-height: 330px;

            @media (max-width:576px) {
                min-height: 300px;

                @media (max-width:320px) {
                    min-height: 0;
                }
            }
        }
    }

    @media (max-width:768px) {
        .culture-slider-item {
            padding: 32px 24px;
        }

        .culture-slider-item_icon {
            margin-bottom: 34px;
        }

        .col-md-5 {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }

    @media (max-width:576px) {
        .col-md-5 {
            flex: 0 0 90%;
            max-width: 90%;
        }

        .slick-arrow {
            display: none;
        }

        .slick-track {
            transform: none !important;
            margin: 0 -16px;
            display: flex;
            width: auto !important;
            flex-wrap: wrap;

            .culture-slider-item {
                min-width: calc(50% - 32px) !important;
                width: calc(50% - 32px) !important;

                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: 32px;
                }

                @media (max-width:320px) {
                    min-width: calc(100% - 32px) !important;
                    width: calc(100% - 32px) !important;
                    min-height: 0;

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }
}

.section-benefits {
    #accordion .card.pt-0 .accordion-arrow {
        top: 17px;
    }

    &.section-faq .container > .row {
        margin-right: -0.9375rem !important; 
        margin-left: -0.9375rem !important; 
    }

    @media (max-width:992px) {
        br {
            display: none;
        }
    }

    @media (max-width:768px) {
        .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .big-arrow {
            margin-top: 32px;
        }
    }

    @media (max-width:576px) {
        #accordion .card.pt-0 .accordion-arrow {
            top: 0px;
        }

        #accordion {
            margin-top: 32px;
        }

        .accordion-arrow  svg{
            transform: scale(0.7) !important;
        }
    }  
    
    @media (max-width:320px) {
        .accordion-arrow  svg{
            width: 14px;
            height: 14px;
            transform: scale(1.1) !important;
        }

        #accordion .card.pt-0 .accordion-arrow {
            top: 0px !important;
        }
    }  
}

@media screen and (max-width: 376px) {
    body.reset.skew-style main, 
    body.reset.skew-style p {
        font-size: 14px;
    }
}

.section-college-graduates {
    position: relative;


    .section-college-left {
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #2B2F2F;
        }

        li {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: none;
            }
        }
    }

    .section-college-right {
        position: absolute;
        right: 0;
        height: 100%;
        max-width: 50%;

        img {
            height: 100%;
            max-width: 120%;
        }
    }

    .col-md-6 {
        position: static;
    }

    @media (max-width:992px) {

        .section-college-left {
            padding-top: 0 !important;
            text-align: center;

            p {
                max-width: 90%;
                margin: 0 auto;
            }

            ul {
                max-width: 90%;
                margin: 0 auto;

                li {
                    display: inline-block;
                    width: 33%;
                    text-align: left;
                }
            }

            h4 {
                font-size: 20px;

                @media (max-width:320px) {
                    font-size: 16px;    
                }
            }
        }

        .col-md-6  {
            min-width: 100%;

            .section-college-right {
                display: none;
            }
        }

        .section-college-left_img {
            display: block !important;
            margin: 0 -16px 40px -16px !important;
        }
    }

    @media (max-width:768px) {
        .section-college-left {
            ul {
                max-width: 100%;

                li {
                    font-size: 14px;
                    width: 32%;

                    @media (max-width:576px) {
                        width: calc(50% - 8px);
                        font-size: 14px;
                    }
                }

                @media (max-width:576px) {
                    text-align: left;
                }
            }
        }
    }

    @media (max-width:320px) {
        .section-college-left p {
            max-width: 100%;
        }

        .section-college-button {
            a {
                width: 225px;

                &.mr-3 {
                    margin: 0 0 16px 0 !important;
                }
            }
        }

        .section-college-left {
            ul {

                li {
                    font-size: 14px;
                    width: 100%;
                }
            }
        }   
        
        .section-college-left_img img {
            height: 200px;
            object-fit: cover;
        }
    }
}

.section-positions {

    .positions-content {
        margin: 0 -16px;
    }

    .positions-content-slide {
        margin: 0 16px;
    }

    .slick-dots {
        bottom: -35px;
    }

    .positions-catd {
        padding: 40px;
        display: flex;
        align-items: center;
        border: 1px solid #D7D7D7;
        margin-bottom: 32px;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }

        .positions-catd-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #171717;
            margin-bottom: 16px;
        }

        .positions-catd-description {
            margin-bottom: 24px;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #3D4448;
            }
        }

        .positions-catd-bottom ul {
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #3D4448;
                padding: 0 24px 0 0;
                margin: 0 24px 0 0;
                border-right: solid 1px #D7D7D7;

                &:last-child {
                    padding: 0;
                    margin: 0;
                    border-right: none;
                }
            }
        }
    }

    .positions-catd-right {
        min-width: 200px;
        text-align: right;
    }

    .search .icon {
        position: absolute;
        right: 25px;
        bottom: 7px;
    }

    @media (max-width:768px) {
        padding-bottom: 68px !important;

        .col-md-3 {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 16px; 
        }
    }

    @media (max-width:576px) {
        .positions-catd {
            display: block;
        }

        .positions-catd-right {
            margin-top: 32px;
            text-align: left;
        }
    }

    @media (max-width:320px) {
        .positions-catd {
            padding: 32px;
        }    
    }
}


.reset .section-recruitment {
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3D4448;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0x;
        }
    }
}

.new-style .pci-career .container-bg { 
    @media (max-width: 992px) {
        background-image: url(images/product/banner-bg-small-career2.png) !important;

        @media (max-width: 768px) {
            background-image: url(images/product/banner-bg-small-career3.png) !important; 

            @media (max-width: 576px) {
                background-image: url(images/product/banner-bg-small-career4.png) !important;

                @media (max-width: 320px) {
                    background-image: url(images/product/banner-bg-small-career5.png) !important;
                }    
            }    
        }    
    }    
}



#main .section-black-top.no-shadow.section-top-update2 .container .section-black-top_wrap {
    justify-content: space-between;
    width: 100%;
}

.reset main ul.list-dots.dark li {
    color: #171717;

    &::before{
        background: #000;
    }
}

.new-style section.section-black-halfBlue .black-half-list.more {
    .black-half-item {
        margin-right: 60px;

        &:last-child {
            margin-right: 0;
        }
    }

    h4, small {
        font-size: 28px;
        line-height: 40px;
    }

    .description {
        color: #7D7D7D;
        font-variant: small-caps;
    }
}

.new-style section.section-default-page.section-black-link.black-version {
    padding: 40px 0 !important;
    border-top: solid 1px #D7D7D7;
    border-bottom: solid 1px #D7D7D7;

    li i {
        text-align: center;
        display: block;
        margin-bottom: 10px;
    }

    span {
        display: block;
        color: #000;
        font-size: 14px;
        line-height: 170%;

        svg {
            margin-left: 10px;
        }
    }

    .new-scroll:hover {
        path {
            fill: #0076C0 !important;
        }
    } 
}

.new-style .section-default-page.section-every-detail.black-version {
    .svg-bg {
        right: 0;

        &:after {
            left: auto;
            right: 100%;
        }
    }

    .section-every-detail_card {
        padding: 0 32px 40px 32px;
        box-shadow: none;
        border: solid 1px #D7D7D7;

        .icon {
            margin: 0 -32px 32px -32px;

            svg {
                width: 100%;
            }
        }    

        .title {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 16px !important;
        } 

        .text {
            margin-bottom: 16px;
        }

        ul li {
            padding-left: 18px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .linl-more {
            position: static;
        }
    }
}

.section-experience {
    .container {
        max-width: 1060px;
    }

    .tabs-pci {
        justify-content: space-between;
    }

    .linl-more span {
        font-size: 14px;
        line-height: 170%;
    }

    .block-view .lc-item.active a:before {
        transition: .2s !important;
    }
}

.experience-slider,
.glance-slider {
    .experience-slider-item_img img,
    .glance-slider-item_img img {
        margin: 0 auto;
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 9;
        cursor: pointer;

        path {
            fill: rgb(125, 125, 125);
        }

        &.next {
            right: 0;
        }

        &.slick-disabled {
            display: none !important;
        }

        &:hover path {
            fill: #0076C0;
        }
    }
}

.glance-slider  {
    .slick-arrow {
        top: 22%;
    }

    .slick-dots {
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 170px;

        li:first-child:before {
            display: block;
            width: 1px;
            height: 24px;
            content: "";
            position: absolute;
            left: 80px;
            top: 0;
            background-color: #D7D7D7;
            
        }

        li {
            position: relative;
            
            button {
                display: none;
            }

            &::after {
                display: block;
                width: 100px;
                height: 30px;
                content: "Front view";
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9999;
                font-weight: 500;
                font-size: 14px;
                line-height: 170%;
                color: #171717;
                cursor: pointer;
            }

            &.slick-active:after {
                color: #0076C0;
            }

            &:last-child:after {
                content: "Side view";
                left: 100px;
            }
        }
    }
}

.section-glance {
    .glance-slider-item_top {
        text-align: center;

        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            color: #171717;

            padding-right: 16px;
            margin-right: 16px;
            border-right: solid 1px #D7D7D7;

            &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: none;
            }

            &:hover {
                color: #0076C0;
            }
        }
    }

    .glance-slider-item_img img {
        margin: 0 auto;
        display: block;
    }

    .glance-slider-item_content {
        li {
            display: flex;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #3D4448;
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                min-width: 30px;
                min-height: 30px;
                margin-right: 10px;
            }
        }
    }
}

#wrapper {
    overflow-x: initial;  
}

#wrapper #main .section-best-choice-wrap {
    display: flex;
    position: relative;

    .first-table {
        min-width: 200px;
    }

    .section-best-choice-slider-item.statick {
        position: relative;
        z-index: 9;

        tr {
            border: none;
            background-color: #F6F8F8 !important;
            position: relative;

            &:after {
                display: block;
                content: "";
                background-color: rgb(215, 215, 215) !important;
                width: 100%;
                height: 1px;
                position: absolute; 
                top: 100%;
                left: 0;
                z-index: 9;
            }
        }

        tr td {
            border-right: solid 1px #4CAF50;
            border-left: solid 1px #4CAF50;
            // border-bottom: 1px solid rgb(215, 215, 215);
        }

        thead td {
            border-top: solid 1px #4CAF50;
            background-color: #F6F8F8;

            .secnd-table-top:after {
                display: none;
            }

            .title {
                color: #4CAF50;
            }
        }

        tbody tr:last-child td {
            border-bottom: solid 1px #4CAF50;
            background-color: #F6F8F8;
        }

        tbody tr:last-child:after {
            display: none;
        }

    }


    .secnd-table-top { 
        position: relative;

        &::after {
            display: block;
            width: 1px;
            height: 100%;
            background-color: #D7D7D7;
            content: "";
            right: -15px;
            top: 0;
            position: absolute;
        }
    }


    .slick-slider {
        position: static;
        width: 800px !important;
    }

    .arrow {
        position: absolute;
        z-index: 99;
        top: 80px;
        cursor: pointer;
        left: 0;

        &.next {
            right: -120px;
            left: auto;
        }

        &.slick-disabled {
            display: none !important;
        }

        &:hover path {
            fill: #0076C0;
        }
    }


    .first-table thead {
        position: relative;

        &::before {
            display: block;
            width: 999999px;
            height: 268px;
            content: "";
            left: -9999px;
            top: -8px;
            position: absolute;
            background-repeat: repeat-x;
            z-index: 0;

            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMCIgaGVpZ2h0PSIyMzQiIHZpZXdCb3g9IjAgMCAxOTIwIDIzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF82OTQ2XzExMzkwOSkiPgo8bWFzayBpZD0icGF0aC0xLWluc2lkZS0xXzY5NDZfMTEzOTA5IiBmaWxsPSJ3aGl0ZSI+CjxwYXRoIGQ9Ik0wIDhIMTkyMFYyMThIMFY4WiIvPgo8L21hc2s+CjxwYXRoIGQ9Ik0wIDhIMTkyMFYyMThIMFY4WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTAgOUgxOTIwVjdIMFY5Wk0xOTIwIDIxN0gwVjIxOUgxOTIwVjIxN1oiIGZpbGw9IiNEN0Q3RDciIG1hc2s9InVybCgjcGF0aC0xLWluc2lkZS0xXzY5NDZfMTEzOTA5KSIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2RfNjk0Nl8xMTM5MDkiIHg9Ii0xMiIgeT0iMCIgd2lkdGg9IjE5NDQiIGhlaWdodD0iMjM0IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQgZHk9IjQiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNiIvPgo8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJvdXQiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMDYgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd182OTQ2XzExMzkwOSIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvd182OTQ2XzExMzkwOSIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K");
        }
    }


   

    .first-table {
        width: 200px;
        max-width: 200px;

        tr td {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #3D4448;
        }

        thead td {
            min-width: 0 !important;
        }
    }

    .secnd-table tbody {
        tr td {
            text-align: center;
            min-width: 350px;
            max-width: 350px;
            font-weight: normal; 

            span {
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }

            a {
                display: block;
                font-size: 14px;
                line-height: 170%;
                color: #0076C0;
                margin-top:10px;
            }
        }

        .span-wrap {
            display: flex;
            justify-content: center;

            span {
                width: 50%;

                &:first-child {
                    border-right: solid 1px #D9D9D9;
                }

                b {
                    display: block;
                    font-weight: normal;
                    padding-top: 5px;
                }
            }
        }
    }

    thead {
        tr {
            min-height: 210px;

            td {
                text-align: center;
                min-width: 350px;

                &.active {
                    border-top: solid 2px #4CAF50;

                    .title {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        color: #4CAF50;
                    }
                }
            }

            svg.icon {
        
                height: 74px;
                margin-bottom: 12px;
            }

            .title {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #171717;
                margin-bottom: 12px;
            }

            .link-arrow svg {
                margin-left: 8px;
            }
        }
    }

    tr {
        height: 90px;
        display: inline-block;
        width: 100%;

        &:nth-child(2),
        &:nth-child(12) {
            height: 160px;

            td {
                height: 160px;
            }
        }

        &:nth-child(3)  {
            height: 150px;

            td {
                height: 150px;
            }
        }

        &:nth-child(4),
        &:nth-child(7) {
            height: 140px;

            td {
                height: 140px;
            }
        }

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(13) {
            height: 120px;

            td {
                height: 120px;
            }
        }
    }
}

.video-content-product {
    .video-content-product_title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #171717;
        margin-bottom: 8px;
    }

    .video-content-product_details {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #7D7D7D;
    
        span:first-child {
            position: relative;
            padding-right:12px;
            margin-right: 12px;

            &:after {
                position: absolute;
                width: 4px;
                height: 4px;
                display: block;
                content: "";
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                background-color: #D7D7D7;
                border-radius: 5px;
            }
        }
    }
}



.event-content-product {

    .event-content-product_wrap {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: solid 1px #D7D7D7;
        
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        h3 {
            margin-bottom: 32px;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #171717; 
        }
    }

    .event-item {
        border: solid 1px #D7D7D7;
        padding: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }

        .event-item-type {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #7D7D7D;
            margin-bottom: 24px;
            position: relative;
            padding-left: 30px;

            &::after {
                position: absolute;
                width: 16px;
                height: 16px;
                display: block;
                content: "";
                left: 0;
                top: 0;
                background-color: #51BAD1;
            }
        }

        .event-item-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #171717;
            margin-bottom: 16px;
        }

        .event-item-content {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #3D4448;
            margin-bottom: 24px;
        }

        .event-item-bottom {
            display: flex;

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #3D4448;

                svg {
                    margin-right: 8px;
                }

                &:first-child {
                    padding-right: 24px;
                    margin-right: 24px;
                    border-right: solid 1px #D7D7D7;;
                }
            }
        }
    }
}

.posters-content-product {
    .posters-content-product-item {
        background-color: #F6F8F8;
        padding: 50px 40px;
        text-align: center;

        &:hover {
            background-color: #F2F4F5;;
        }

        .posters-content-product_img {
            margin-bottom: 24px;
        }

        .posters-content-product_title {
            margin-bottom: 24px;
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            text-align: center;
            color: #231F20;
        }

        .posters-content-product_description {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #3D4448;
        }
    }
}

a.link {
    color: #0076C0 !important;

    &:hover {
        color: #0699F5 !important;
    }
}

#wrapper .table-wrap {
    h3 {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 32px;
    }

    .link svg {
        margin-left: 10px;
    }
}

.table-wrap-list {
    margin-bottom: 32px;

    ul {
        display: flex;
        padding: 0;
        margin: 0;

        li {
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            color: #171717;
            cursor: pointer;
            padding: 0;
            margin: 0;

            &.active {
                color: #0076C0;
            }
        }
    }
}

.nav-pills-slider {
    

    li {
        width: auto !important;
    }

    .arrow {
        position: absolute;
        top: 6px;
        cursor: pointer;
        z-index: 9;
        background: #fff;
        padding: 0 40px;
        left: -40px;

        &.next {
            right: -40px;
            left: auto;
        }

        &:hover {
            path {
                fill: #0076C0;
            }
        }

        &.slick-disabled {
            display: none !important;
        }
    }
}

.table-models-slider {
    .arrow {
        position: absolute;
        top: 25px;
        z-index: 99;
        cursor: pointer;
        left: -75px;

        &.next {
            right: -70px;
            left: auto;
        }

        &.slick-disabled {
            display: none !important;
        }

        path {
            fill: rgb(125, 125, 125);
        }

        &:hover path {
            fill: #0076C0;
        }
        
    }

    p {
        font-weight: normal;
    }

    &:not(.slick-slider) {
        display: flex;

    }   


}

.checkbox-label input[disabled] + .checkbox-icon{
    background: #D7D7D7;
    cursor: default;
}

.statick-models-table-wrap {
    display: flex;

    .table-models thead {
        height: 100px;
    }

    .table-models tbody tr {
        display: inline-block;
        height: 120px;
        width: 100%;

        &.big2 {
            height: 210px;
        }

        &.big3 {
            height: 500px;
        }

        &.big4 {
            height: 280px;
        }

        &.big5 {
            height: 800px;
        }

        &.big6 {
            height: 350px;
        }

        &.big7 {
            height: 260px;
        }

        &.big8 {
            height: 305px;
        }

        &.big9 {
            height: 330px;
        }

        &.big10 {
            height: 550px;
        }

        &.big11 {
            height: 230px;
        }

        &.no-border {
            height: 70px;
        }    
    }

    .statick-models-table {
        width: 300px;
    }

    .table-models-slider {
        width: calc(100% - 300px);

        p {
            font-weight: normal !important;
        }

        .table-models-slider-item {
            width: 306px !important;
        }
    }
}




#wrapper #main .table-models {
    .form-row-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        label {
            margin: 0;
        }
    }

    p {
        width: 270px;
    }

    .form-row {
        border-right: solid 1px #ccc;
    }

    thead {
        position: relative;

        &::before,
        &::after {
            display: block;
            width: 10000%;
            height: 1px;
            background-color: #ccc;
            content: "";
            left: -1000%;
            top: 0;
            position: absolute;
        }

        &::after {
            top: 100%;
        }

        th {
            background-color: #fff !important;
            text-transform: none !important;
            font-variant: normal !important;
        }
    }

    tbody {
        td:first-child p {
            font-weight: 500;
        }

        tr.no-border {
            border: none !important;

            td {
                padding-bottom: 0;

                h4 {
                    margin: 0;
                }
            }
        }
    }
}

.show-filters {
    padding: 32px 0;
    display: flex;

    label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #171717;
        margin: 0 24px 0 0;
    }

    .span-wrap  {
        display: flex;

        span {
            font-size: 14px;
            line-height: 24px;
            color: #171717;
            font-weight: 500;
            cursor: pointer;

            &:first-child {
                padding-right: 24px;
                margin-right: 24px;
                border-right: solid 1px #ccc;
            }

            &.filter-go {
                color: #D7D7D7;
                cursor: default;
                pointer-events: none;

                &.active {
                    cursor: pointer;
                    pointer-events: auto;
                    color: #000;
                }
            }

            &.active {
                color: #0076C0;
            }

            &.color {
                color: #0076C0 !important;
            }
        }

    }
}




.reset .block-view.tabs-scroll div.tabs-pci {
    border: none;
    position: relative;

    &::after {
        display: block;
        width: 9999%;
        height: 2px;
        background-color: #ccc;
        content: "";
        left: -999%;
        top: calc(100% - 2px);
        position: absolute;
    }
}

.reset .block-view.tabs-scroll .lc-item a, 
.reset .block-view.tabs-scroll .lc-item span {
    margin-right: 56px;
    padding: 20px 0px 10px 0 !important;
}

.reset .block-view.tabs-scroll .lc-item a .title, 
.reset .block-view.tabs-scroll .lc-item span .title {
    white-space: nowrap;
}

.reset .block-view.tabs-scroll .lc-item:last-child a, 
.reset .block-view.tabs-scroll .lc-item:last-child span {
    margin-right: 0px;
}

.reset .block-view.tabs-scroll .lc-item a.active .title:before {
    width: calc(100% - 0px) !important;
    bottom: 0 !important;
}


.new-style .section-categories {
    transition: none;
}


::-webkit-scrollbar {
    width: 8px; 
    height: 4px; 
    background-color: #F6F8F8;
}

::-webkit-scrollbar-thumb {
    background-color: #0076C0;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #0699F5;
}



.section-breadcrumbs.section-breadcrumbs-bg {
    &.section-breadcrumbs-bg-22 {
        background-image: url(./images/product/Case-Study-bg.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
}    

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci.top-40 {
    top: 40px;
}

.caption {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7D7D7D;
}


.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career.frontline {
    background-image: url(./images/frontline/front-line-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.culture-slider-item_link a {
    font-size: 14px;
}

.section-faq-button {
    display: flex;
    align-items: center;

    a {
        font-size: 14px;
    }
}


@media (max-width: 768px) {
    .career-slider .slick-slide {
      display: none;
    }
  
    .career-slider .slick-slide.slick-active {
      display: block;
    }
  
    .career-slider .slick-slide {
      display: none;
    }
  
    .section-culture .career-slider .slick-arrow {
      top: -134px;
    }
}
  
@media (max-width: 576px) {
    .section-culture .career-slider .slick-track .culture-slider-item {
      min-width: 0 !important;
      width: 100% !important;
    }
  
    .section-culture .career-slider .slick-arrow {
      top: -160px;
    }
}
  
@media (max-width: 992px) and (max-width: 576px)  {
    .section-culture .career-slider .slick-track .culture-slider-item {
      min-height: 200px;
    }
}

.reset .section-text-list-img {
    text-align: center;

    h3 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 50px;
    }

    img {
        margin: 0 auto;
    }

    h4 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    svg {
        margin-bottom: 14px;
    }
}

.section-ssd-solutions {
    .section-ssd-solutions_col {
        width: 392px;
        margin: 0 16px;

        .card-content {
            padding: 32px 32px 40px 32px;

            h4 {
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 16px;
            }

            p {
                margin-bottom: 16px;
            }

            a {
                font-size: 14px;
                line-height: 170%;
            }
        }
    }

    .section-ssd-solutions_row {
        display: flex;
        justify-content: center;
    }

    .card {
        background-color: #fff;
        border: solid 1px #D7D7D7;
    }
}

.section-about-oakgate {
    .title {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: #171717;
        margin-bottom: 4px;
    }

    .description {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 172%;
        font-variant: small-caps;
        color: #7D7D7D;
    }
}

.section-breadcrumbs.section-breadcrumbs-bg {
    &.section-breadcrumbs-bg-30 {
        background-image: url(./images/product/breadcrumbs-bg-30.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-23 {
    background-image: url(./images/product/Case-Study-bg-2.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-24 {
    background-image: url(./images/product/Case-Study-bg-3.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.reset.new-skew-style #main .section-equipment {
    h4 {
        font-size: 20px;
    } 

    table th {
        background-color: #fff !important;
    }

    a {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        color: #0076C0;
        white-space: nowrap;
    }
}

.reset.new-skew-style #main .section-applications {
    h3 {
        font-size: 20px;
    }

    #accordion .card {
        padding: 8px 0;
    }

    #accordion .card .accordion-arrow {
        top: 25px;
    }
}

.section-ssd-solutions_col.h-100 {
    img {
        height: 220px;
        width: 100%;
        object-fit: contain;
    }
} 

.font-24 {
    font-size: 24px !important;
}

@media (min-width: 1200px) {
    .section-banner, .bg-video-holder {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 677px;
        display: flex;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .section-banner .banner p, .bg-video-holder .banner p {
        font-size: 16px;
    }
}

.max-w-100 {
    max-width: 100% !important;
}


.section-resources-new.second .video-tab .row {
    border: none; 
    margin: 0 -1rem;

    [class^="col"] {
        padding: 0 1rem;
        margin-bottom: 32px;
    }  
    
    iframe {
        width: 100%;
        height: 222px;
    }
}

.reset #main .language-block-blue .form-row .jcf-select {
    border: none;
    position: relative;
    bottom: 5px;
}

.reset #main .language-block-blue .form-row .jcf-select span {
    color: #0076C0 !important;
}

.reset #main .language-block-blue .form-row  .jcf-select .jcf-select-opener:after {
    border-right: 2px solid #0076C0 !important;
    border-top: 2px solid #0076C0 !important;
}

.reset #main .language-block-blue .form-row .selected + .jcf-select .jcf-select-text span {
    color: #0076C0 !important;
}

 
.section-breadcrumbs.section-breadcrumbs-bg h1.title-h3 {
    color: #fff !important;
    margin-bottom: 32px !important;
    font-size: 32px !important;
}

.new-style .tabs-pci .lc-item {

    a {
        color: #8F8F8F !important;
    }   

    &.active a {
        color: #231F20 !important;
    }    
}

p.comments {
    font-weight: 500 !important;
}

table.height-auto {
    tbody tr {
        height: auto !important;

        td {
            height: auto !important;
            white-space: nowrap;
        }
    }
}

.list-dots li:before {
    width: 8px;
    height: 8px;
}

.video-tab .row iframe {
    width: 100%;
    height: 222px;
}

.col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reset .section-frontline-bg {
    background-image: url(./images/frontline/Frontline-bg-app.jpg);
    background-repeat: no-repeat;
    background-size: cover;   

    h3, 
    .col-md-5 p {
        color: #F3F3F3;
    }

    path {
        fill: #fff;
    }

    .culture-slider-item path {
        fill: #0076C0;
    }
}

.section-about-indicators.small {
    .indicators-wrap {
        display: flex;
        flex-wrap: wrap;

        .indicators-card  {
            margin-right: 64px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

section a:hover {
    color: #0699F5 !important;

    path {
        fill: #0699F5 !important;
    }    
}

.col-apsolute {
    position: sticky;
    top: 100px;
}

.secnd-table-top img {
    height: 100px;
    margin-bottom: 10px;
}

#wrapper #main .section-best-choice-wrap .height-auto tr:nth-child(6) td {
    height: 112px !important;
}

main a.link-video {
    color: #0076C0;
    font-size: 14px;
    font-weight: 500;

    svg {
        margin-left: 5px;
        position: relative;
        bottom: 2px;
    }
}

.section-no-border {
    #accordion .card {
        border-bottom: none;
        padding: 0 !important;
    }
}


.posters-wrap {
    .posters-card {
        background: #F6F8F8;
        padding: 26px 40px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .posters-card-img {
            text-align: center;
            margin-bottom: 24px;
        }

        .posters-card-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            text-align: center;
            color: #231F20;
            margin-bottom: 20px;
        }

        .posters-card-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #3D4448;
        }

        .posters-card-btn {
            margin-top: auto;

            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: #0076C0;

                &:hover {
                    color: #0699F5 !important;
                }
            }
        }
    }
}



.section-breadcrumbs {
    padding: 0 !important;
    height: 272px;
    display: flex;

    .container {
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

#main li {
    color: #2B2F2F;
}

.section-ssd-solutions .section-ssd-solutions_col .card-content a.mr-3 {
    padding-right: 16px;
    border-right: solid 1px #D7D7D7;
}

.section-faq .big-arrow-2 {
    max-width: 800px;
    margin: 0 auto;
}



.section-ssd-solutions .section-ssd-solutions_col.big-link-text .card-content a.mr-3 {
    padding: 0 0 16px 0;
    border-bottom: solid 1px #D7D7D7;
    border-right: none;
    margin: 0 0 16px 0 !important;
}

.section-small-padding {
    #accordion .row {
        padding: 8px 0;
    }
}

// NEW

.section-breadcrumbs .container {
    align-items: inherit;
    flex-direction: column;
}

.section-breadcrumbs.section-breadcrumbs-bg {
    position: relative;
}

.section-breadcrumbs .breadcrumbs-product {
    position: absolute;
    top: 24px;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-mil {
    background-image: url(./images/mil/bd-mil.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    h1 {
        text-transform: capitalize;

        & + p {
            color: #F3F3F3 !important;
        }
    }
}

.text-flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.section-content.small-content {
    max-width: 816px;
    margin: 0 auto;
}

section.mil-slider {
    background-image: url(./images/mil/mil-big-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

section.mil-slider {
    h3 {
        color: #fff !important;
    }

    .row p {
        color: #F3F3F3  !important;
    }

    .culture-slider-item {
        min-height: 280px;
        min-width: 392px;
    }

    .culture-slider-item_icon {
        margin-bottom: 32px;
    }

    .slick-arrow path {
        fill: #fff;
    }
}



#wrapper #main .section-best-choice-mil .section-best-choice-wrap thead tr td {
    text-align: center;
    min-width: 200px;
    max-width: 200px;
}

#wrapper #main .section-best-choice-mil .section-best-choice-wrap .secnd-table tbody tr td {
    text-align: center;
    min-width: 200px;
    max-width: 200px;
}

#wrapper #main .section-best-choice-mil .section-best-choice-wrap .secnd-table-top::after {
    right: -16px;
} 

#wrapper #main .section-best-choice-mil .section-best-choice-wrap thead tr {
    display: flex;
    align-items: center;
}

.reset.new-skew-style #main .section-best-choice-mil a:not(.btn).link-arrow-new {
    justify-content: center;
}

#wrapper #main .section-best-choice-mil .section-best-choice-wrap .height-auto tr:nth-child(6) td {
    height: auto !important; 
}

#main .section-best-choice-mil .section-active {
    line-height: 32px;
}

.section-best-choice-mil .secnd-table-top {
    .name {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    .sub-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #7D7D7D;
        margin-bottom: 32px;
    }

    a {
        color: #0076C0;
    }
}

#main .small-fonts.pci-mil .container .pci-small-row .pci-small-title {
    flex-direction: column;
    align-items: normal;

    h3 {
        margin-bottom: 8px;
        color: #fff;
        font-size: 28px;
    }

    p {
        color: #fff;
    }
}



.posters-wrap {
    .posters-card {
        padding: 40px;
    }
}

// SEO
.new-style .new-font section.section-black-halfBlue.second h1 {
    font-size: 32px !important;
    line-height: 40px;
    white-space: nowrap;
}

.new-style section.section-black-halfBlue .black-half-list.more h2 {
    font-size: 28px;
    line-height: 40px;
    color: #171717;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4px;
    text-transform: inherit;

    small {
        color: #171717;
        font-size: 28px;
        line-height: 40px;
        font-weight: 500;
    }
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card h3.title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px !important;
    color: #171717;
    font-style: normal;
    font-weight: 500;
}

.reset .block-view .lc-item a h3 {
    font-size: 20px;
    margin: 0;
    color: #8F8F8F !important;
    letter-spacing: normal;
}

.new-style .tabs-pci .lc-item.active a h3 {
    color: #231F20 !important;
}

#wrapper #main .section-best-choice-wrap .first-table tr td h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3D4448;
    margin: 0;
}

.new-style .small-fonts.pci-small .container .pci-small-row .pci-small-title h2 {
    font-size: 28px;
    color: #fff;
    margin: 0;
}

.second-tabs .lc-item a .title {
    font-size: 20px;
}

.reset h3.strong {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #171717;
    margin: 0;
}

#wrapper #main .table-models tbody tr.no-border td h3 {
    margin: 0;
    font-size: 24px;
    line-height: 1.333334;
    font-weight: 500;
}

.table-wrap-list ul li h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    cursor: pointer;
    padding: 0;
    margin: 0;
}



.reset h2.new-scroll {
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    color: #171717 !important;
    font-size: 16px;
}

.reset .anchor-active h2.new-scroll {
    color: #7D7D7D !important;
}


#wrapper #main .section-best-choice-wrap.rapid .first-table {
    width: 300px;
    max-width: 300px;
}

#wrapper #main .section-best-choice-wrap.rapid tr,
#wrapper #main .section-best-choice-wrap.rapid tr td {
    height: auto !important;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li a {
    color: #0076C0;
}


.reset .block-view .tabs-pci.scroll .lc-item a {
    padding: 20px 18px;

    h3 {
        font-size: 18px;
    }
}

.experience-slider .slick-arrow {
    left: -60px;

    &.next {
        right: -60px;
        left: auto;
    }
}

#wrapper {
    overflow: initial;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card .icon img {
    width: 100%;
}

.link-blue {
    color: rgb(0, 118, 192) !important;
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 500;
}

body.reset section.section-services-bg {
    background-image: url(images/austin-labs/services-bg.png);

    h3, 
    h3 + div p {
        color: #fff;
    }

    .slick-arrow path {
        fill: #fff;
    }
}

.secnd-table-top img {
    height: 70px;
    margin-bottom: 10px !important;
}

.statick-models-table-wrap.rapid .table-models thead {
    height: 215px;
}

.statick-models-table-wrap.rapid .table-models thead .form-row {
    min-height: 160px;
}


.statick-models-table-wrap.rapid .table-models-slider td {
    text-align: center;
}

.statick-models-table-wrap.rapid .table-models-slider thead div,
.statick-models-table-wrap.rapid .table-models-slider thead li {
    text-align: center;
    display: block !important;
    padding: 0;
}

.rapid .section-every-detail_card .icon {
    margin-bottom: 24px;
    height: 200px;

    img {
        height: 100%;
        object-fit: contain;
    }
}

a.link-arrow {
    font-size: 14px;
}

// H style

.reset .h3 {
    font-size: 28px !important;
    margin: 0 0 0.75rem 0 !important;
    letter-spacing: 0.2px !important;
    line-height: 1.5 !important;
    font-weight: 500 !important;
}

@media (min-width: 992px) {
    .column-content .h6 {
        color: #0076C0;
        padding: 0 0 0 0.5rem;
        margin: 0 0 0.75rem -0.5rem;
        position: relative;
        border: 0;
        display: inline-block;

        &::before {
            width: 3px;
            min-height: calc(100% - 0.5rem);
            position: absolute;
            top: 0.25rem;
            right: 100%;
            background-color: #0076C0;

            @media (min-width: 992px) {
                content: '';
            }
        }
    }
}

.column-content ul {
    margin-bottom: 16px !important;
}

.reset .section-black-top {
    z-index: 2;
}

header {
    overflow: inherit;
}

.new-style .section-black-top_wrap .tabs-wrap .tabs-pci_second .lc-item {
    a {
        color: #7D7D7D !important;
    }

    &.active a {
        color: #171717 !important;
    }
}

.reset #main .section-best-choice table tr td {
    padding: 16px;
}  

.statick-models-table-wrap .table-models tbody tr {
    height: 56px;

    td {
        padding: 16px !important;
    }
}

.link-blue {
    font-size: 14px;
}

.d-space-b {
    justify-content: space-between !important;
}

.interoperability-block {
    margin-bottom: 48px;

    &:last-child {
        margin-bottom: 0;
    }

    .interoperability-block-header {
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-bottom: solid 1px #D7D7D7;

        span {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            background: linear-gradient(180deg, #4EBCFF 0%, #0076BF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }

    .interoperability-block-body {
        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #171717;
            margin-bottom: 16px;
        }

        a {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #0076C0;
        }
    }

    li::before {
        background: #3D4448 !important;
        width: 6px !important;
        height: 6px !important;
        display: block;
        position: absolute;
        top: 10px !important;
        left: 0;
        content: "";
    }
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-01 {
    background-image: url(./images/product/breadcrumbs-bg-01.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.list-check.black {
    .icon-chek:before {
        color: #7D7D7D;
    }
} 

.list-dots.black {
    li:before {
        background: #3D4448 !important;
        top: 6px !important;
    }
} 

.section-block-img-absolute {
    position: relative;

    .container:after {
        display: none !important;
    }

    .block-img-absolute {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 45%;
        text-align: right;

        img {
            height: 100%;
            // object-fit: cover;
            object-position: left;
        }

        @media (max-width: 768px) {
            position: static;
            height: 100%; 
            width: auto;
            margin-bottom: 24px;
        }
    }
}


.new-style section.section-black-halfBlue.section-black-halfBlue-small-f {
    h2 {
        font-size: 32px !important;
        line-height: 40px;
    }

    h4 {
        font-size: 28px;
        line-height: 40px;
    }

    .black-half-list .description {
        font-variant: all-small-caps;
    }
}

.section-breadcrumbs.section-breadcrumbs-bg.automation-services-bg {
    background-image: url(./images/product/automation-services-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

body.reset section.section-services-bg.section-services-bg-2 {
    background-image: url(images/product/Rectangle-1247.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3.section-breadcrumbs-training-bg {
    background-image: url(./images/product/section-breadcrumbs-training-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.section-upcoming-training {
    .section-upcoming-training-filter {
        display: flex;
        margin-bottom: 40px;
        justify-content: center;

        .filter-item {
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            color: #171717;
            padding: 8px 16px;
            border: solid 1px #D7D7D7;
            border-radius: 20px;
            margin-right: 16px;
            cursor: pointer;
            transition: 0.5s;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                background-color: #0076C0;
                color: #fff;
            }

            &:hover {
                background-color: #0076C0;
                color: #fff;
                transition: 0.5s;
            }
        }
    }

    .section-upcoming-training-card {
        .card {
            background: none;
            border: 1px solid #D7D7D7;
            padding-bottom: 32px;
            height: 100%;
            justify-content: space-around;

            .card-content {
                padding: 32px 32px 0 32px;
            }

            .card-img {
                margin-bottom: 32px;
            }

            .card-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #171717;
                margin-bottom: 16px;
            }

            .card-text {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 24px;
            }

            .card-bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 32px;
                margin-top: auto;
            }
        }
    }
}

.new-style section.section-black-halfBlue.section-black-halfBlue-small-f .black-half-list .black-half-item {
    margin-right: 60px;
}

.new-style #accordion .card .accordion-arrow {
    z-index: 1;
}

.instructors_block {
    .instructors_item {
        margin-bottom: 40px;
        display: flex;

        &:last-child {
            margin-bottom: 0;
        }

        .instructors_item-left {
            margin-right: 32px;

            img {
                min-width: 180px;
                min-height: 180px;
            }
        }

        .instructors_item-name {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #171717;
            margin-bottom: 8px;
        }

        .instructors_item-position {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #7D7D7D;
            margin-bottom: 24px;
        }

        .instructors_item-description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #171717;
        }
    }
}


// walter

#main {


    .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter {
        background-image: url(./images/walter/walter-main-img.png);
        height: 800px;
        text-align: center;

        .date {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #F3F3F3;
            margin-bottom: 24px;
        }

        h1 {
            font-weight: 500;
            font-size: 56px !important;
            line-height: 40px !important;
            color: #FFFFFF;
        }

        ul.technologies {
            display: flex;
            justify-content: center;
            margin-bottom: 54px;

            li {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #FFFFFF !important;
                position: relative;    
                margin-right: 32px;
                padding: 0;
                
                &:last-child {
                    margin-right: 0; 

                    &::after {
                        display: none;
                    }
                }

                &::after {
                    position: absolute;
                    background-color: #fff;
                    width: 4px;
                    height: 4px;
                    display: block;
                    content: "";
                    border-radius: 50%;
                    right: -16px;
                    top: 10px;
                }
            }   
        }

        svg {
            margin: 0 auto 22px;
        }

        .quote { 
            margin-bottom: 22px; 

            p {
                font-weight: 400;
                font-size: 24px;
                line-height: 40px;
                text-align: center;
                color: #FFFFFF;
            }    
        }
    }

    .walter-section-top {
        .walter-section-top_text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }
    }

    .small-container {
        max-width: 816px;
        margin: 0 auto;
    }

    .text-more {

        p + p {
            display: none;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            letter-spacing: 0.5px;
            color: #0076C0;
            cursor: pointer;
            margin-left: 4px;

            &:hover {
                color: #0699F5 !important;

                & + svg path {
                    fill: #0699F5 !important;
                }
            }
        } 
        
        &.active {
            p + p {
                display: block;
            }

            p:first-child span,
            p:first-child span + svg {
                display: none;
            }
        }
    }

    .walter-section {
        img {
            margin: 0 auto;
            display: block;
        }

        .caption {
            font-weight: 400;
            font-style: italic;
            font-size: 14px;
            line-height: 170%;
            text-align: center;
            letter-spacing: 0.5px;
            color: #7D7D7D;
            margin-top: 8px;
        }

        .italick {
            font-style: italic;
            
        }

        h2 {
            margin-bottom: 32px !important;
        }

        ul {
            margin-bottom: 32px !important;
        }

        .walter-datasheet ul {
            display: flex;
            flex-wrap: wrap;

            li {
                color: #0076C0;
                margin: 0 10px 10px 0;
                padding: 0 10px 0 0;
                border-right: 1px solid #ccc;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    .walter-slider {
        .slick-arrow {
            top: 50%;
            position: absolute;
            z-index: 9;
            cursor: pointer;

            &.next {
                right: 0;
            }
        }

        img {
            width: auto;
            height: 540px;
        }

        .slick-track {
            align-items: center;
        }

        .slick-dots { 
            bottom: -70px;

            li {
                margin: 0 2px 0 0;
                padding: 0;

                button {
                    width: 20px;
                    height: 2px;
                    background: #D7D7D7;
                    border: none;
                    border-radius: 0;
                }

                &.slick-active {
                    button {
                        background: #0076C0;
                    }
        
                }
            }    
        }
    }
}


.section-lifecycle-new .lc-item {
    i:before,
    span {
        color: #000;
    }

    &.active {
        i:before,
        span {
            color: #fff;
        }    
    }
}

.new-style .card-line.hover {
    box-shadow: 0px 0px 32px rgba(191, 191, 191, 0.36);
}

.white .lc-item a {
    background-color: #fff;
}

a.btn.btn-default-outline:hover {
    color: #fff !important;
}

.table-description {
    color: rgb(61, 68, 72);
        strong {
            font-weight: bold;
    }    
}