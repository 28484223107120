// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

// transition
%transition {
  transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
  text-align: justify;
  font-size: 1px;
  line-height: 0;

  >* {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }

  &:after {
    content: '';
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
}

%active-tab {
  background-color: $blue;
  color: $white;
  font-weight: 500;
}

%icomoon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

%body {
  font-family: $font-family-base;
  font-size: $font-size-sm;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left; // 3
  background-color: $body-bg; // 2
  letter-spacing: 0.004em;

  @include media('≥phone') {
    font-size: $font-size-base;
    line-height: 1.5;
  }

  @include media('≥widescreen') {
    font-size: $font-size-lg;
    line-height: 1.555555;
  }
}


%common {
  p {
    line-height: inherit;
    font-size: inherit;
    margin-top: 0;
  }

  a {
    &:hover {
      color: inherit;
    }

    &:link,
    &:active,
    &:visited {
      color: inherit;
    }
  }

  //*::selection {
  //  background: rgba($blue, 0.99);
  //  color: $white;
  //}

  *::-moz-selection {
    background: rgba($blue, 0.99);
    color: $white;
  }

  *::-webkit-selection {
    background: rgba($blue, 0.99);
    color: $white;
  }

  

  a[href*="mailto:"] {
    word-wrap: break-word;
  }

  a {
    color: inherit;

    i[class^="icon"]:before {
      color: inherit;
    }
  }

  img {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    height: auto;
  }

  input::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }

  .tab-hidden {
    @include media('≥phone') {
      position: absolute !important;
      left: -9999px !important;
      top: -9999px !important;
      display: block !important;
      width: 100% !important;
    }
  }

  .disable-link {
    pointer-events: none;
    color: $body-color !important;

    *,
    &:before {
      color: inherit !important;
    }

    &:before {
      background-color: $body-color !important;
    }
  }
}

@include media("<desktop") {
  .container {
    max-width: 100%;
  }
}

%skew-shape {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: inherit;
}