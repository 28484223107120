.section-ts {
    background-color: $white;
    position: relative;
    z-index: 1;
    padding: 1.25rem 0;

    @include media('≥phone') {
        padding: 2rem 0;
    }

    @include media('≥tablet') {
        padding: 4rem 0 2.5rem;
    }

    @include media('≥widescreen') {
        padding: 1.5rem 0 0;
    }

    &:before {
        @extend %skew-shape;
        bottom: 0;
        transform-origin: 0;
        transform: skewY(-4.8deg);
    }

    &:after {
        //@extend %skew-shape;
        //bottom: 0;
        //transform-origin: 100%;
        //transform: skewY(-4.8deg);
        @extend %skew-shape;
        bottom: 0;
        transform-origin: 0;
        transform: skewY(4.8deg);
    }

    .title {
        @include media('≥widescreen') {
            font-size: 2.5rem;
        }
    }

    .section-heading {
        p {
            @include media('≥desktop') {
                font-size: 1.25rem;
            }
        }
    }

    p {
        @include media('≥desktop') {
            font-size: 1.5rem;
        }
    }
}

.section-what {
    color: rgba($white, .6);
    position: relative;
    padding: 1.25rem 0;

    @include media('≥phone') {
        padding: 2rem 0;
    }

    @include media('≥tablet') {
        padding: 4rem 0 2.5rem;
    }

    @include media('≥widescreen') {
        padding-top: 8.6%;
        padding-bottom: 4.3%;
    }


    p {
        @include media('≥widescreen') {
            font-size: 1.25rem;
        }
    }
}

.section-gray-dark {
    .title {
        color: $white;
    }
}

.section-external-validation {
    background-color: $white;
    padding: 1.25rem 0;

    @include media('≥phone') {
        padding: 2rem 0;
    }

    @include media('≥tablet') {
        padding: 4rem 0 2.5rem;
    }

    @include media('≥widescreen') {
        padding: 4.3% 0;
        font-size: 1.25rem;
    }
}

.section-valid {
    //position: relative;
    //z-index: 1;
    padding: 1.25rem 0;
    color: rgba($white, .6);

    @include media('≥phone') {
        padding: 2rem 0;
    }

    @include media('≥tablet') {
        padding: 4rem 0 2.5rem;
    }

    @include media('≥widescreen') {
        padding: 4.3% 0 8.6%;
        //font-size: 1.25rem;
    }

    .section-heading {
        p {
            @include media('≥widescreen') {
                font-size: 1.25rem;
            }
        }
    }
}

.section-provide {
    @extend .section-valid;

    @include media('≥widescreen') {
        padding: 5rem 0;
    }

    p {
        @include media('≥widescreen') {
            font-size: 1.25rem;
            margin-bottom: 2rem;
        }
    }
}



.section-different {
    position: relative;
    z-index: 1;
    padding: 1.25rem 0;
    background-color: $white;

    @include media('≥phone') {
        padding: 2rem 0;
    }

    @include media('≥tablet') {
        padding: 4rem 0 2.5rem;
    }

    @include media('≥widescreen') {
        padding: 1.5rem 0 4.3%;
        //font-size: 1.25rem;
    }

    &:before {
        @extend %skew-shape;
        bottom: 0;
        transform-origin: 0;
        transform: skewY(-4.8deg);
    }

    .title {
        font-weight: 500;

        @include media('≥widescreen') {
            margin-bottom: .75rem;
        }
    }
}


.section-different .inner-img {
    height: 180px;
  }
  
  .section-different .inner-content {
    padding: 40px;
  }
  
  .section-different .list-check {
    list-style: none;
    padding: 0;
  }
  
  .section-different .list-check li {
    margin-bottom: 16px;
    padding-left: 28px;
    position: relative;
    color: #596369;
  }
  
  .section-different .list-check li:last-child {
    margin-bottom: 0;
  }
  
  .section-different .list-check li .icon-chek {
    position: absolute;
    left: 0;
    top: 5px;
  }


  

.section-training-slider {
    padding: 64px 0 150px 0;

    .slick-track,
    .slick-slide {
        width: 100% !important;
    }

    .container {
        overflow: hidden;
    }

    .slick-slide>div {
        padding: 0
    }

    .slide-event {        
        @include media('<widescreen') {
            margin-bottom: 20px          
        }
    }
}

.section-izotope {
    padding-bottom: 48px !important;

    @media screen and (max-width: 376px) {
        padding-bottom: 16px !important;
    }

    .green {
        color: #4CAF50 !important;

        p {
            color: #4CAF50 !important;
        }
    }

    .tab-hidden {
        position: absolute !important;
        left: -9999px !important;
        top: -9999px !important;
        display: block !important;
        width: 100% !important;
    }

    .izotope-sub-filters {
        .izotope-filters {
            margin: 0;

            .lc-item {
                padding: 0 3px;
                width: auto;

                @media screen and (max-width: 940px) {
                    width: 33%;    
                    text-align: center;
                }

                @media screen and (max-width: 820px) {
                    width: 50%;    
                }

                @media screen and (max-width: 480px) {
                    width: 100%;    
                }

                &.hidden {
                    display: none;
                }

                .opener {
                    padding: 12px 32px;
                    font-size: 20px;

                    &::after {
                        display: none;
                    }

                    &.small {
                        padding: 12px 16px;
                    }

                    @media screen and (max-width: 570px) {
                        padding: 12px;
                        font-size: 16px;    
                    }
                }  
                
                &.active {
                    .opener {
                        color: #fff;
                        background-color: #0076C0; 
                    }
                }
            }
        }

        .sub-filters {
            label {
                color: #323232;
                font-size: 16px;
                font-weight: 500;
            }

            button,
            .button {
                color: #0076BF; 
                font-size: 16px;
                background: none;
                border: none;
                padding: 8px 16px;
                text-decoration: underline;
                outline: none;
                cursor: pointer;

                &:hover,
                &.is-checked {
                    text-decoration: none;
                    color: #323232; 
                }
            }
        }
    }

    // .card-line .inner-img {
    //     margin: 15px auto 0;
    //     max-width: 90%;
    // }
        

    .grid {
        margin: 0 -16px;

        &.height {
            .card-line {
                position: relative;

                .inner-content {
                    padding: 15px 24px 75px 24px !important;
                    min-height: 236px;
                }

                .card-bottom {
                    width: calc(100% - 48px);
                    position: absolute;
                    bottom: 40px;
                    left: 24px;  

                    .form-row {
                        display: flex; 
                        align-items: flex-end;

                        label {
                            margin: 0;
                        }
                    }
                }
            }    
        }

        &.small {
            .element-item {
                padding: 0 8px;

                .form-row a.link {
                    display: none;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .form-row span.text {
                    color: #000;
                }

                .card-line.add-link {
                    .form-row span.text {
                        display: none;
                    }

                    .form-row a.link {
                        display: inline-block;
                    }
                }
                  

                .inner-content {
                    padding: 15px 24px 40px 24px;
                    
                    .inner-info {
                        min-height: 0;
                        margin: 0;
                    }

                    .card-bottom label {
                        color: #0076BF;
                    }

                    .title {
                        font-size: 18px; 
                        line-height: 30px;
                        margin-bottom: 4px;
                    }

                    .card-text p {
                        font-size: 16px;
                        line-height: 24px;  
                        margin-bottom: 8px;  
                    }

                    .list-characteristics {
                        margin: 0;

                        li {
                            line-height: 1;
                            margin: 0 0 8px 0;

                            &:last-child {
                                margin: 0;
                            }
                        }    
                    }

                    .list-items {
                        li:first-child {
                            padding-left: 0;
                        }

                        li:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }

    .element-item {
        margin: 0 0 16px 0;
    }
    
    // izotope-select
    .container {
        overflow: hidden;
    }

    .izotope-select {
        margin: 0 0 40px 0;

        .jcf-select {
            width: 366px;
            margin: 0 auto;
            position: relative;

            &::before,
            &::after { 
                position: absolute;
                left: calc(100% + 21px);
                top: 50%;
                width: 999%;
                height: 1px;
                background: #F6F8F8;
                display: block;
                content: "";
            }

            &::after {
                left: auto;
                right: calc(100% + 21px);
            }
        }
    }

    // izotope-filters
    .izotope-filters {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;

        .button {
            margin-right: 2px;
            width: 170px;
            height: 48px;
            border: none;
            background: #F6F8F8;
            font-size: 20px;
            font-weight: 500;
            transition: .5s;
            outline: none;

            @media screen and (max-width: 1200px) {
                width: auto;  
                padding: 0 16px;
            }

            &:hover {
                background: #146194;
                transition: .5s;
                color: #fff;
            }

            &:last-child {
                margin-right: 0;
            }

            &.is-checked {
                background: #0076BF;
                color: #fff;
                transition: .5s;

                &:hover {
                    background: #146194;
                }
            }
        }
    }

    .inner-img {
        height: 115px;
    }

    .card-line.p-0 {
        position: relative;

        &.register {
            .register-show {
                display: inline-block;
            }

            .card-button {
                .register-show + a {
                    display: none;
                }
            }

            .hold-date {
                .register-show.local + .date {
                    display: none;
                }
            }
        }

        .register-show {
            display: none;
        }

        .inner-content {
            padding: 32px 40px 40px 40px;

            @media screen and (max-width: 376px) {
                padding: 24px;
            }
        }

        .title {
            font-size: 24px;
            line-height: 36px;
        }

        .inner-info {
            min-height: 165px;
            margin-bottom: 24px;

            @media screen and (max-width: 769px) {
                min-height: 0;
            }

            p {
                margin: 0;
                font-size: 14px;
                line-height: 21px;
            }
        }
        
        .status {
            position: absolute;
            left: 0;
            top: 20px;
            text-transform: uppercase;
            font-size: 14px;
            background: #4CAF50;
            color: $white;
            padding: 8px 12px;
            line-height: 1;
        }

        .card-bottom {
            width: 100%;

            .card-button {
                display: flex;
                align-items: center;

                .btn-default {
                    margin-right: 46px;
                    min-width: 148px;
                }

            }
        }

        .hold-date {
            margin-bottom: 16px;
            color: #231F20;

            .date {
                margin-right: 32px;
            }
            
            i {
                margin-right: 8px;
            }
        }
    } 

    &.oscilloscopes {
        .card-footer-img img {
            width: 50px;
            transition: .5s;

            &:hover {
                transform: scale(1.1);
                transition: .5s;
            }
        }
    }
}

.section-explore {
    padding: 64px 0 80px 0;
}


.section-explore {
    .section-heading {
        margin-bottom: 40px;
    }

    h2 {
        margin-bottom: 12px;
    }

    .sub-title {
        color: #596369;
        font-size: 20px;
        line-height: 30px;
        margin-top: 12px;

        @media screen and (max-width: 769px) {
            font-size: 16px;    

            @media screen and (max-width: 376px) {
                font-size: 14px;    
            }
        }

        b {
            font-size: 20px;
            font-weight: 500;
            display: block;
        }
    }
}

.container.small {
    max-width: 856px;

    .explore-content {
        padding: 40px;
        box-shadow: 0 0.5rem 0.75rem #ececec;
        border: 1px solid #EAEDEE;
        background: #fff;

        @media screen and (max-width: 376px) {
            padding: 24px;
        }
    }

    .agreement {
        align-items: center;

        p {
            font-size: 12px;
            line-height: 18px;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

    }
}

// form
#pardot-form {
    .form-field {
        span.value + span {
            display: flex;

            .inline {
                margin-left: 10px;
            }
        }

        input[type='checkbox'] {

            & + label {
                display: inline-block;
            }   
        }

        span.description {
            display: block;
        }
    }

    .description {
        font-size: 12px;
        line-height: 18px;

        a:hover {
            text-decoration: underline;
        }
    }

    .submit input {
        background: #0076C0;
        color: #f7f9f9;
        border: none;
        padding: 14px 1.5rem;
        line-height: 1rem;

        &:hover {
            background: #0067a7;
            color: #f7f9f9;
        }    
    }
}


.form-row,
#pardot-form .form-field {
    margin-bottom: 16px !important;

    &:last-child {
        margin-bottom: 0 !important;
    }

    label {
        color: $gray-900;
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        display: block;
        margin-bottom: 8px;

        span {
            color: #BDBDBD;
            font-weight: normal;
        }
    }

    input[type=text],
    input[type=email],
    input[type=phone],
    input[type=password],
    textarea,
    select {
        width: 100%;
        height: 44px;
        border: 2px solid #F2F2F2;
        border-radius: 2px;
        outline: none;
        padding: 8px;
        font-size: 16px;
        line-height: 24px;
        color: #231F20;
        transition: .5s;

        &:focus {
            border-color: #0076C0;
            transition: .5s;
        }

        &.error {
            border-color: #ff0000;
        }
    }

    label.error {
        margin: 8px 0 0 0;
        display: inline-block;
        font-size: 12px;
        line-height: 18px;
        color: #ff0000;
    }

    textarea {
        height: 132px;
        min-height: 44px;
    }

    input::placeholder,
    textarea::placeholder,
    select {
        color: #828282;
        font-size: 16px;
        line-height: 24px;
    }

    .checkbox-label {
        cursor: pointer;
        // margin-top: 8px;

        input {
            margin-right: 8px;
            width: 18px;
            height: 18px;
            opacity: 0;
            position: absolute;
            display: inline-block;

            & + label.error {
                display: none !important;

                & + .checkbox-icon {
                    border-color: red !important;
                }
            }

            &:checked  + .checkbox-icon,
            &:checked  + label.error + .checkbox-icon {                       
                background: url(https://assets.lcry.net/images/checkbox-bg.svg);
                border: none;
                background-repeat: no-repeat;
               
            }
        }

        .checkbox-icon {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            border: solid 2px #F2F2F2;
            border-radius: 3px;
            display: inline-block;
        }
    }
}

.form-row .checkbox-label .checkbox-icon,
#pardot-form .form-field .checkbox-label .checkbox-icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border: solid 2px #F2F2F2;
    border-radius: 3px;
    display: inline-block;
}

.oscilloscopes span.seriesCheckBox {
    display: flex;
}

.oscilloscopes input:checked + .checkbox-icon + span {
    display: none;
}

.section-external {

    .external-content {
        position: relative;

        // &::before {
        //     display: block;
        //     content: "";
        //     position: absolute;
        //     right: 0;
        //     top: -10px;
        //     width: 825px;
        //     height: 366px;
        //     background: url(images/external-bg.png);
        // }
    }

    .col {
        margin-bottom: 32px;
    }

    div.card-line .inner-content {
        padding-top: 26px;
    }

    p {
        margin: 0;
    }

    .number {
        color: #231F20;
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 8px;
        font-weight: 500;
    }
}

.section-validation { 
    padding: 64px 0 150px 0;

    .col {
        margin-bottom: 32px;
    }

    .icons {
        margin-right: 10px;

        svg {
            width: 27px;
            height: 27px;
        }
    }
}

.section-different {

    .inner-img {
        height: 180px;
    }

    .inner-content {
        padding: 40px;
    }

    .list-check {
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 16px;
            padding-left: 28px;
            position: relative;
            color: #596369;

            &:last-child {
                margin-bottom: 0;
            }

            .icon-chek {
                position: absolute;
                left: 0;
                top: 5px;
            }
        }
    }
}







.section-overview {
    .card-line { 

        a {
            color: #0076C0;

            &:hover {
                text-decoration: underline;
            }
        }

        &.gray {
            background: #f7f9f9;
        }
    }
    
    .card-blue {
        h3 {
            font-size: 16px;
            margin-bottom: 17px;
            text-transform: uppercase;
        }

        .list-check {
            margin-bottom: 20px;
        }
    }

    .col-lg-6  .card-blue h3 {
        text-transform: none;
    }

    .col-right {
        p {
            font-size: 20px;
            color: #231F20;
            font-weight: 600;

        }
    }
}

.section-summary {
    .card-line {
        margin-bottom: 32px;
        
        &:last-child {
            margin-bottom: 0;    
        }

        h3 {
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 17px;
        }
    }
}

.reset .section-speakers {
    .card-line {

        .inner-img {
            min-width: 254px;    
            height: 100% !important;
        }

        .card-speakers-name h3 {
            font-size: 20px !important;
            line-height: 30px !important;
            margin-bottom: 4px;
        }

        .card-speakers-position h3 {
            font-size: 16px !important;
            margin-bottom: 12px;
        }
    }

    .card-line.horizontale { 

        display: flex !important;  

        @media screen and (max-width: 540px) {
            display: block !important;  
            height: auto;
            
            .inner-img {
                min-width: 254px;
                min-height: 250px;
            }
        }
    }
}

.reset .m-0 {
    margin: 0 !important;
}

.advantages {
    display: flex;
    justify-content: space-between;

    .advantages-left, 
    .advantages-right {
        display: flex;
        flex-direction: column;

        .card-line {

            &:before {
                content: '';
                display: block;
                height: 8px;
                position: absolute;
                left: 100%;
                right: 0;
                background-color: inherit;
                bottom: -8px;
                transform-origin: 0;
                transform: skewY(-35deg);
                width: 200px;
                background: #0076BF;

                @media screen and (max-width: 1224px) {
                    display: none;
                }
            }

            @media screen and (max-width: 864px) { 
                margin: 0 auto; 
            }    
    
            //     &:before {
            //         content: '';
            //         display: block;
            //         height: 100%;
            //         position: absolute;
            //         left: 50% !important;
            //         top:100%;
            //         transform: translate(-50%, 0);
            //         width: 8px;
            //         background: #0076BF;
            //     }   
            // } 
        }
    }   


    .advantages-right {
        margin-top: 155px;

        @media screen and (max-width: 1224px) {
            margin-top: 0;
        }

        .card-line:before {
            left: auto;
            right: 100%;
            transform: skewY(35deg);
            bottom: auto;
            top: 100%;
            margin-top: -140px;
        }

        .advantages-icon {
            left: auto !important;
            right: calc(180px + 100%);
        }
    }

    .advantages-center {
        background: #0076BF;
        width: 8px;  
        
        &.secondary {
            position: relative; 
            top: 100px;
            height: 1000px;    
        }
    }

    @media screen and (max-width: 864px) {
        display: block;

        .advantages-center.secondory {
            height: auto;
        }
    }
}

section.section-advantages {

    .mobile {
        display: none;
        text-align: center;
    }
        
    @media screen and (max-width: 864px) {
        .mobile {
            display: block;
        }

        .no-mobile {
            display: none;
        }
    }

    .card-line {
        border-bottom: solid 8px #0076BF;
        position: relative;
        max-width: 392px;
        width: 100%;
        height: auto;

        @media screen and (max-width: 1224px) {
            margin-bottom: 90px !important; 
        }

        .inner-content {
            padding-top: 55px !important;
            background: #fff;
            z-index: 99999;
            position: relative;
        }

        .card-sub-title {
            margin-bottom: 10px;
            color: #0076C0;
            font-size: 16px;
        }

        .card-text p {
            color: #231F20;
        }

        .advantages-icon {
            width: 98px;
            height: 98px;
            background: #fff;
            border: solid 8px #0076BF;
            border-radius: 50%;
            position: absolute;
            left: calc(180px + 100%);
            top: 50%;
            transform: translate(0, -50%); 
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 1224px) {
                top: auto;
                bottom: 100%;
                left: 50% !important;
                transform: translate(-50%, 50%);
            }
        }
    } 

    .advantages-center-top,
    .advantages-center-bottom {
        .card-line {
            margin: 0 auto;
        }   
        
        .advantages-icon {
            top: auto;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 50%); 
        }
    }

    .advantages-center-top {
        @media screen and (max-width: 864px) {
            margin-top: 90px !important;
        }
    }
    

    .advantages-center-bottom {
        .card-line {
            margin-bottom: 90px !important;

            &:last-child {
                margin-bottom: 0 !important;

                &:before {
                    display: none;
                }
            }

            &:before {
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                left: 50%;
                top:100%;
                transform: translate(-50%, 0);
                width: 8px;
                background: #0076BF; 

                @media screen and (max-width: 864px) {
                    display: none;
                }
            }    
        }
    }
}



