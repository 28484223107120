.section-contact {
	font-size: 1rem;
	line-height: 1.5;
	color: $white;
	padding: 1.25rem 2rem;
	background-size: cover;
	background-repeat: no-repeat;
	font-weight: 500;
	background-color: $blue;
	background-image: linear-gradient(to left, #056eb0 0%,#056dac 15%,#046aa8 21%,#0969a6 31%,#07649d 54%,#0c629b 63%,#0b6298 65%,#0c5f95 80%,#0c5f91 82%,#0e5c8d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	overflow: hidden;
	position: relative;

	@include media('≥phone') {
		font-size: 1.5rem;
		line-height: 1.6;
		padding: 2.5rem;
	}

	@include media('≥tablet') {
		padding: 3.5rem 2rem;
		font-size: 1.75rem;
	}

	@include media('≥widescreen') {
		font-size: 1.75rem;
		line-height: 1.57143;
		padding: 3.5rem 2.5rem;
	}
	
	&:before {
		display: block;
		position: absolute;
		bottom: 0;
		left: -70px;
		width: 475px;
		height: 100%;
		background-image: linear-gradient(to right, rgba($white, 0.05) 0%, rgba($white, 0.05) 20%, #0000 20%,#0000 27%, rgba($white, 0.05) 27%, rgba($white, 0.05) 100%);
		transform: skew(42deg);
		
		@include media('≥tablet') {
			content: '';
		}
	}

	p {
		margin-bottom: 0.75rem;

		@include media('≥phone') {
			margin-bottom: 0;
		}
	}
}