// vertical align el inside parent with fixed height/min-height
// usage 
// html - .parent>.child
// scss - @include v-align;
//     or @include v-align(250px);
//     or @include v-align(250px, bottom, before);
//
@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
  white-space: nowrap;
  text-align: center;

  &:#{$va-pseudo} {
    content: '';
    display: inline-block;
    vertical-align: $va-direction;
    width: 0;
    min-height: $va-height;
  }

  > * {
    white-space: normal;
    display: inline-block;
    vertical-align: $va-direction;
    max-width: 99%;
  }
}

// vertical align a pair of child el inside parent
// usage 
// html - .post>.image+.text
// scss - @include v-align-pair(image, text);
//     or @include v-align-pair(image, text, bottom);
//     or @include v-align-pair(image, text, middle, 200px);
//
@mixin v-align-pair($child-name1, $child-name2, $valign: middle, $width1: auto, $width2: auto) {
  display: table;

  .#{$child-name1} {
    display: table-cell;
    vertical-align: $valign;
    width: $width1; 
  }

  .#{$child-name2} {
    display: table-cell;
    vertical-align: $valign;
    width: $width2;
  }
}

// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/// Mixin helping defining both `width` and `height` simultaneously.
///
/// @author Hugo Giraudel
///
/// @access public
///
/// @param {Length} $width - Element’s `width`
/// @param {Length} $height ($width) - Element’s `height`
///
/// @example scss - Usage
///   .foo {
///     @include size(10em);
///   }
///
///   .bar {
///     @include size(100%, 10em);
///   }
///
/// @example css - CSS output
///   .foo {
///     width: 10em;
///     height: 10em;
///   }
///
///   .bar {
///     width: 100%;
///     height: 10em;
///   }
///
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/// font-smothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }@else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}


/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}


/// Creates a visual triangle. 
/// Mixin takes ($size, $color, $direction)
/// The $size argument can take one or two values—width height.
/// The $color argument can take one or two 
/// values—foreground-color background-color.
/// 
/// @author http://bourbon.io/docs/#triangle
///
/// $direction: 
/// up, down, left, right, up-right, up-left, down-right, down-left
/// @example scss - Usage
///   @include triangle(12px, gray, down);
///   @include triangle(12px 6px, gray blue, up-left);
///
@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);
  height: 0;
  width: 0;

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction == inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}


/// Animate css properties
// usage 
// scss - @include animate(color);
//     or @include animate(color width);
//     or @include animate(color width, 1s, linear);
//
$animation-speed: 0.3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list: ();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }
  transition: $list;
}


/// Mixin for styling form-placeholders
/// @example scss - Usage
///   @include placeholder {
///    color: #333;
///   }
///
///   input[type='text'],
///   input[type='tel'],
///   input[type='email'] {
///     @include placeholder {
///      color: red;
///     }
///   }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &.placeholder {
    @content;
  }
}

/// Font-size px to rem calc
/// @example scss - Usage
/// selector {
///    @include rem-font-size(16px);
/// }

@mixin rem-font-size($px-size) {
  font-size: $px-size;
  font-size: ($px-size / 10px) + rem;
}

//font-face
@mixin font-face($font-name, $font-path, $weight, $style) {
  @font-face {
    font-family: '#{$font-name}';
    src: url('fonts/#{$font-path}.eot');
    src: url('fonts/#{$font-path}.eot?#iefix') format('embedded-opentype'),
         url('fonts/#{$font-path}.woff2') format('woff'),
         url('fonts/#{$font-path}.woff') format('woff'),
         url('fonts/#{$font-path}.ttf') format('truetype'),
         url('fonts/#{$font-path}.svg##{$font-name}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin v-center($min-height: 400px) {
  display: flex;

  > * {
    min-height: $min-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

// Button sizes
@mixin button-color($btn-background, $btn-color, $btn-border-color, $btn-hover-background, $btn-hover-color) {
  background: $btn-background;
  color: $btn-color;
  border-color: $btn-border-color;

  &:hover {
    background: $btn-hover-background;
    color: $btn-hover-color;
  }
}


// @include rem(padding, 20px 10px);

@function parseInt($n) {
  /* 2 */
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px: (); /* 3 */
  $rem: (); /* 3 */

  @each $value in $values {
    /* 4 */

    @if $value == 0 or $value == auto {
      /* 5 */
      $px: append($px, $value);
      $rem: append($rem, $value);
    } @else {
      $unit: unit($value); /* 6 */
      $val: parseInt($value); /* 6 */

      @if $unit == 'px' {
        /* 7 */
        $px: append($px, $value);
        $rem: append($rem, ($val / 10 + rem));
      }

      @if $unit == 'rem' {
        /* 7 */
        $px: append($px, ($val * 10 + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    /* 8 */
    #{$property}: $px; /* 9 */
  } @else {
    #{$property}: $px; /* 9 */
    #{$property}: $rem; /* 9 */
  }
}


@mixin skew-angle($side, $vert, $hor, $angle){
    position: relative;
    z-index: 1;
    background-color: #f00;

    @if $vert == top {
        @if $side == outside {
            &:before {
                @if $hor == left {  //outside
                    bottom: 100%;
                    transform-origin: 100%;
                    transform: skewY(-#{$angle}deg);
                } @else if $hor == right {  //outside
                    bottom: 100%;
                    transform-origin: 0;
                    transform: skewY(#{$angle}deg);
                }
            }
        } @else if $side == inside {
            &:before {
                @extend %skew-shape;

                @if $hor == left {
                    transform-origin: 100%;
                    transform: skewY(#{$angle}deg);
                } @else if $hor == right {
                    transform-origin: 0;
                    transform: skewY(-#{$angle}deg);
                }
            }
        }


    } @else if $vert == bottom {
        background-color: blue;
        overflow: hidden;

        @if $side == outside {
            &:after {
                @extend %skew-shape;

                @if $hor == left {  //outside
                    transform: skewY(-#{$angle}deg);
                    transform-origin: 100%;
                    bottom: 0;
                } @else if $hor == right {  //outside
                    transform: skewY(-#{$angle}deg);
                    transform-origin: 0;
                    bottom: -100%;
                }
            }
        } @else if $side == inside {
            &:after {
                @extend %skew-shape;

                @if $hor == left {
                    transform: skewY(-#{$angle}deg);
                    transform-origin: 100%;
                } @else if $hor == right {
                    transform: skewY(#{$angle}deg);
                    transform-origin: 0;
                }
            }
        }

    }

}
