@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
@font-face {
  font-family: "HelveticaNeue";
  src: url("fonts/helveticaneuemedium-webfont.eot");
  src: url("fonts/helveticaneuemedium-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/helveticaneuemedium-webfont.woff2") format("woff"), url("fonts/helveticaneuemedium-webfont.woff") format("woff"), url("fonts/helveticaneuemedium-webfont.ttf") format("truetype"), url("fonts/helveticaneuemedium-webfont.svg#HelveticaNeue") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?pjx8xk");
  src: url("../fonts/icomoon.eot?pjx8xk#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?pjx8xk") format("truetype"), url("../fonts/icomoon.woff?pjx8xk") format("woff"), url("../fonts/icomoon.svg?pjx8xk#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chek:before {
  content: "\e92c";
  color: #0076c0;
}

.icon-scroll:before {
  content: "\e92b";
  color: #fff;
}

.icon-calendar-1:before {
  content: "\e928";
}

.icon-check-2:before {
  content: "\e929";
  color: #fff;
}

.icon-local-1:before {
  content: "\e92a";
}

.icon-arr-right:before {
  content: "\e926";
}

.icon-arr-left:before {
  content: "\e927";
}

.icon-clock:before {
  content: "\e924";
}

.icon-calendar:before {
  content: "\e925";
}

.icon-target-link:before {
  content: "\e923";
  color: #0076bf;
}

.icon-btn-right-01:before {
  content: "\e921";
  color: #5e686e;
}

.icon-btn-left-01:before {
  content: "\e922";
  color: #5e686e;
}

.icon-check-1:before {
  content: "\e920";
  color: #fff;
}

.icon-menu2:before {
  content: "\e91b";
}

.icon-menu3:before {
  content: "\e91c";
}

.icon-menu1:before {
  content: "\e91d";
}

.icon-menu .path1:before {
  content: "\e91e";
  color: white;
}

.icon-menu .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: #222222;
}

.icon-check:before {
  content: "\e91a";
  color: #fff;
}

.icon-world:before {
  content: "\e918";
}

.icon-profile:before {
  content: "\e919";
}

.icon-prototype:before {
  content: "\e90f";
}

.icon-resolution:before {
  content: "\e910";
}

.icon-testing:before {
  content: "\e911";
}

.icon-training:before {
  content: "\e912";
  color: #fff;
}

.icon-validation:before {
  content: "\e913";
}

.icon-bring-up:before {
  content: "\e914";
}

.icon-compliance:before {
  content: "\e915";
}

.icon-debug:before {
  content: "\e916";
}

.icon-power-on:before {
  content: "\e917";
}

.icon-icon-check:before {
  content: "\e90e";
  color: #0076bf;
}

.icon-location:before {
  content: "\e90d";
}

.icon-btn-right:before {
  content: "\e90b";
}

.icon-btn-left:before {
  content: "\e90c";
}

.icon-arrow-right:before {
  content: "\e90a";
  color: #0076bf;
}

.icon-close:before {
  content: "\e900";
}

.icon-fb:before {
  content: "\e901";
  color: #0076bf;
}

.icon-in:before {
  content: "\e902";
  color: #0076bf;
}

.icon-search:before {
  content: "\e903";
}

.icon-tw:before {
  content: "\e904";
  color: #0076bf;
}

.icon-yt:before {
  content: "\e905";
  color: #0076bf;
}

.icon-arrow:before {
  content: "\e906";
  color: #fff;
}

.icon-arrow-down:before {
  content: "\e907";
  color: #fff;
}

.icon-arrow-left:before {
  content: "\e908";
  color: #0076bf;
}

.icon-blogger:before {
  content: "\e909";
  color: #0076bf;
}

/* Slick slider styles */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  outline: none;
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots, .list-dots, .section-anchors ul, .list-gen, .product-card ul, .list-product-cards, .list-banner, .list-psg, aside ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.lc-item.show .opener, .lc-item .opener:hover, .gen-item.active .opener-gen, .gen-item > a:hover {
  background-color: #0076C0;
  color: #fff;
  font-weight: 500;
}

.lc-item .opener:after, .breadcrumb-item + .breadcrumb-item:before, .gen-item > a:after, .list-banner-item:before, .item-bullet:before, .slider-controls .slick-arrow:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

main {
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #231F20;
  text-align: left;
  background-color: #fff;
  letter-spacing: 0.004em;
}

@media (min-width: 576px) {
  main {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media (min-width: 1200px) {
  main {
    font-size: 1rem;
    line-height: 1.555555;
  }
}

main p {
  line-height: inherit;
  font-size: inherit;
  margin-top: 0;
}

main a:hover {
  color: inherit;
}

main a:link, main a:active, main a:visited {
  color: inherit;
}

main *::-moz-selection {
  background: rgba(0, 118, 192, 0.99);
  color: #fff;
}

main *::-webkit-selection {
  background: rgba(0, 118, 192, 0.99);
  color: #fff;
}

main a[href*="mailto:"] {
  word-wrap: break-word;
}

main a {
  color: inherit;
}

main a i[class^="icon"]:before {
  color: inherit;
}

main img {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

main input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

@media (min-width: 576px) {
  main .tab-hidden {
    position: absolute !important;
    left: -9999px !important;
    top: -9999px !important;
    display: block !important;
    width: 100% !important;
  }
}

main .disable-link {
  pointer-events: none;
  color: #231F20 !important;
}

main .disable-link *, main .disable-link:before {
  color: inherit !important;
}

main .disable-link:before {
  background-color: #231F20 !important;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }
}

.section-technologies:before,
.section-angle:before, .section-events-slider:before, .section-ts:before, .section-ts:after, .section-different:before, .reset section.section-angle:before,
.reset .section-default-page.section-angle:before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: inherit;
}

.btn {
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  border: 2px solid;
  transition: none;
}

@media (min-width: 576px) {
  .btn {
    padding: 0.5rem 2.25rem;
  }
}

.btn .focus, .btn:focus {
  box-shadow: none !important;
}

.btn:link, .btn:active, .btn:visited {
  color: #231F20;
}

.btn-default {
  background: #0076C0;
  color: #f7f9f9;
  border-color: #0076C0;
}

.btn-default:hover {
  background: #0067a7;
  color: #f7f9f9;
  border-color: #0067a7;
}

.btn-default:focus {
  box-shadow: none;
}

.btn-default:link, .btn-default:active, .btn-default:visited {
  color: #f7f9f9;
}

.btn-white {
  background: #fff;
  color: #0076C0;
  border-color: #fff;
}

.btn-white:hover {
  background: #f1f4f6;
  color: #0076C0;
  border-color: #f1f4f6;
}

.btn-white:focus {
  box-shadow: none;
}

.btn-white:link, .btn-white:active, .btn-white:visited {
  color: #0076C0;
}

.btn-green {
  background-color: #4CAF50 !important;
  border-color: #4CAF50 !important;
  color: #fff !important;
}

.btn-green:hover {
  background-color: #fff !important;
  border-color: #4CAF50 !important;
  color: #4CAF50 !important;
}

.btn-green-outline {
  background-color: transparent !important;
  border-color: #4CAF50 !important;
  color: #4CAF50 !important;
}

.btn-green-outline:hover {
  background-color: #4CAF50 !important;
  color: #fff !important;
  border-color: #4CAF50 !important;
}

.btn-blue-outline {
  background-color: transparent !important;
  border-color: #0076C0 !important;
  color: #0076C0 !important;
}

.btn-blue-outline:hover {
  background-color: #0076C0 !important;
  color: #fff !important;
  border-color: #0076C0 !important;
}

.btn-default-outline {
  background: transparent;
  color: #0076C0;
  border-color: #0076C0;
  border: 2px solid;
}

.btn-default-outline:hover {
  background: #0076C0;
  color: #fff;
  border-color: #0076C0;
}

.btn-default-outline:focus {
  box-shadow: none;
}

.btn-default-outline:link, .btn-default-outline:active, .btn-default-outline:visited {
  color: #0076C0;
}

.section-slider {
  position: relative;
  z-index: 0;
}

.section-slider [data-srcset] {
  display: none;
}

.main-slider .visual {
  min-height: 1px;
}

@media (min-width: 768px) {
  .main-slider .visual {
    display: none;
  }
}

.main-slider .text {
  text-align: center;
  padding: 1.5rem 0;
  color: #000;
}

@media (min-width: 768px) {
  .main-slider .text {
    text-align: left;
  }
}

.content-slide {
  text-align: center;
  background-color: #f1f4f6;
  display: flex !important;
  flex-direction: column;
}

@media (min-width: 768px) {
  .content-slide {
    background-color: transparent;
    display: flex;
  }
  .content-slide > * {
    min-height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .content-slide {
    display: flex;
    font-size: 18px;
    line-height: 28px;
  }
  .content-slide > * {
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

.content-slide h2 {
  margin-bottom: 0.75rem;
}

@media (min-width: 1200px) {
  .content-slide .wrap-btn {
    padding-top: 0.5rem;
  }
}

.slick-dots {
  display: flex;
  font-size: 0;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 576px) {
  .slick-dots {
    bottom: 0.75rem;
  }
}

@media (min-width: 768px) {
  .slick-dots {
    bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .slider-controls .slick-dots {
    position: static;
    transform: none;
    margin-right: auto;
  }
}

.slider-controls .slick-dots button {
  pointer-events: none;
}

@media (min-width: 768px) {
  .slider-controls .slick-dots button {
    pointer-events: auto;
    border-color: rgba(0, 0, 0, 0.8);
  }
}

.slider-controls .slick-dots button:hover {
  background-color: rgba(194, 202, 207, 0.4);
}

@media (min-width: 768px) {
  .slider-controls .slick-dots button:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.slider-controls .slick-dots .slick-active button {
  background-color: rgba(194, 202, 207, 0.8);
}

@media (min-width: 768px) {
  .slider-controls .slick-dots .slick-active button {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.slick-dots li {
  outline: none;
}

.slick-dots li + li {
  margin-left: 0.375rem;
}

@media (min-width: 576px) {
  .slick-dots li + li {
    margin-left: 0.5625rem;
  }
}

@media (min-width: 768px) {
  .slick-dots li + li {
    margin-left: 0.625rem;
  }
}

.slick-dots .slick-active button {
  background-color: #c2cacf;
}

.slick-dots button {
  width: 0.5rem;
  height: 0.5rem;
  background-color: transparent;
  border: 1px solid #c2cacf;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
}

@media (min-width: 576px) {
  .slick-dots button {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.slick-dots button:hover {
  background-color: rgba(194, 202, 207, 0.8);
}

.slick-dots button:focus {
  outline: none;
}

.section-contact {
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  padding: 1.25rem 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  font-weight: 500;
  background-color: #0076C0;
  background-image: linear-gradient(to left, #056eb0 0%, #056dac 15%, #046aa8 21%, #0969a6 31%, #07649d 54%, #0c629b 63%, #0b6298 65%, #0c5f95 80%, #0c5f91 82%, #0e5c8d 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  overflow: hidden;
  position: relative;
}

@media (min-width: 576px) {
  .section-contact {
    font-size: 1.5rem;
    line-height: 1.6;
    padding: 2.5rem;
  }
}

@media (min-width: 768px) {
  .section-contact {
    padding: 3.5rem 2rem;
    font-size: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .section-contact {
    font-size: 1.75rem;
    line-height: 1.57143;
    padding: 3.5rem 2.5rem;
  }
}

.section-contact:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: -70px;
  width: 475px;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 20%, #0000 20%, #0000 27%, rgba(255, 255, 255, 0.05) 27%, rgba(255, 255, 255, 0.05) 100%);
  transform: skew(42deg);
}

@media (min-width: 768px) {
  .section-contact:before {
    content: '';
  }
}

.section-contact p {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .section-contact p {
    margin-bottom: 0;
  }
}

.section-lifecycle .tabs-area > * {
  flex: 1 1 auto;
}

@media (min-width: 1200px) {
  .section-lifecycle .tabs-pci {
    display: flex;
    margin-bottom: 2.5rem;
    padding: 0 5px;
  }
}

.section-lifecycle .tab-holder {
  display: none;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .section-lifecycle .tab-holder {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .section-lifecycle .tab-holder {
    flex: 1 1 auto;
  }
}

.section-lifecycle .tab-holder [id^="tab"] {
  padding: 0;
}

.section-lifecycle .tab-holder [id^="tab"]:before {
  content: none;
}

.section-lifecycle .tab-holder .slide-content {
  transition: opacity 0.6s ease-in-out;
  opacity: 1;
}

@media (min-width: 1200px) {
  .section-lifecycle .tab-holder .slide-content {
    display: flex;
    min-height: 0;
  }
}

.section-lifecycle .tab-holder .tab-hidden {
  opacity: 0;
}

@media (min-width: 768px) {
  .section-lifecycle .tab-holder p {
    margin-bottom: 1.5rem;
  }
}

.section-lifecycle .visual {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .section-lifecycle .visual {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .section-lifecycle .visual {
    flex-basis: calc(50% - 15px);
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .section-lifecycle .text {
    flex-basis: calc(50% - 15px);
  }
}

.section-lifecycle .title {
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .section-lifecycle .title {
    font-size: 1.5rem;
    line-height: 1.333333;
  }
}

@media (min-width: 992px) {
  .section-lifecycle .title {
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .section-lifecycle .wrap-btn {
    padding-top: 8px;
    display: flex;
    align-items: flex-start;
  }
}

.section-lifecycle .wrap-btn .btn {
  margin-bottom: 0;
}

.section-lifecycle .wrap-btn .btn + .btn {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .section-lifecycle .wrap-btn .btn + .btn {
    margin-left: 1rem;
    margin-bottom: 0;
  }
}

.section-lifecycle .wrap-btn p {
  margin-bottom: 0.75rem;
}

.section-lifecycle .wrap-btn p:last-child {
  margin-bottom: 0;
}

.section-lifecycle .wrap-links p {
  margin-bottom: 0;
}

.section-lifecycle .wrap-links p:not(:last-child) {
  margin-bottom: 0.75rem;
}

.lc-item {
  margin-bottom: 1rem;
}

@media (min-width: 1200px) {
  .lc-item {
    display: flex;
    margin-bottom: 0;
    padding: 0 10px;
    width: 100%;
    flex: 1 1 auto;
  }
}

.lc-item:last-child {
  margin-bottom: 0;
}

.lc-item.show .opener:after {
  transform: translateY(-50%) rotate(180deg);
}

@media (min-width: 768px) {
  .lc-item.active .opener {
    background-color: #0076C0;
    color: #fff;
    font-weight: 500;
  }
}

.lc-item .opener {
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  display: block;
  background-color: #F6F8F8;
  color: #000;
  padding: 0.625rem 2rem 0.625rem 1rem;
  position: relative;
}

@media (min-width: 768px) {
  .lc-item .opener {
    padding-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .lc-item .opener {
    width: 100%;
    height: 100%;
    padding: 1.5rem 0.5rem 0.75rem;
    text-align: center;
  }
}

.lc-item .opener > * {
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 1200px) {
  .lc-item .opener > * {
    display: block;
  }
}

.lc-item .opener:after {
  content: "\e907";
  font-size: 7px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

@media (min-width: 768px) {
  .lc-item .opener:after {
    content: none;
  }
}

.lc-item .slide-content {
  padding: 1rem 0 0.5rem;
}

@media (min-width: 576px) {
  .lc-item .slide-content {
    padding: 1.5rem 0 0.5rem;
  }
}

@media (min-width: 768px) {
  .lc-item .slide-content {
    display: none;
  }
}

@media (min-width: 576px) {
  .lc-item .slide-content p {
    margin-bottom: 1.5rem;
  }
}

.lc-item i {
  margin-right: 0.75rem;
  font-size: 1.5rem;
}

@media (min-width: 1200px) {
  .lc-item i {
    display: block;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

.lc-item i:before {
  color: inherit;
}

.slick-track {
  display: flex;
}

.slick-track:before, .slick-track:after {
  content: none;
}

.slick-slide {
  vertical-align: top;
  float: none;
  outline: none;
}

.slick-slider {
  user-select: text;
}

.slick-slider * {
  user-select: text;
}

.slick-list.draggable {
  user-select: none;
}

/* Tabs styles */
@media (min-width: 768px) {
  .tab-hidden-md {
    position: absolute !important;
    left: -9999px !important;
    top: -9999px !important;
    display: block !important;
    width: 100% !important;
  }
}

.bg-retina {
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
}

.large-link {
  display: inline-block;
  vertical-align: top;
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.section-heading {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  color: #222;
}

@media (min-width: 576px) {
  .section-heading {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media (min-width: 1200px) {
  .section-heading {
    font-size: 1rem;
    line-height: 1.555555;
    margin-bottom: 2.5rem;
  }
}

.section-heading img {
  width: auto;
}

.section-heading p {
  margin-bottom: 0;
}

.section-heading span.blue {
  margin-right: 10px;
  font-weight: inherit;
  font-size: inherit;
}

.card-body {
  padding: 1.5rem 1rem 2rem;
}

@media (min-width: 576px) {
  .card-body {
    padding: 2rem 2.5rem 2.5rem 2rem;
  }
}

@media (min-width: 768px) {
  .card-body {
    padding: 2rem 1.5rem 2.5rem;
  }
}

@media (min-width: 992px) {
  .card-body {
    padding: 2rem 3rem 3rem;
  }
}

@media (min-width: 1200px) {
  .card-body {
    padding: 2rem 4rem 3.5rem;
  }
}

.card-body > a + a {
  padding-top: 0;
}

.breadcrumb-item {
  color: #0076C0;
  font-weight: 500;
}

@media (min-width: 768px) {
  .breadcrumb-item {
    font-size: 1rem;
  }
}

.breadcrumb-item + .breadcrumb-item:before {
  transform: rotate(-90deg);
  font-size: 0.25rem;
  display: inline-block;
  vertical-align: middle;
}

.jumbotron {
  padding: 2rem 0;
  margin-bottom: 0;
  background-color: #0076C0;
  border-radius: 0;
  color: #fff;
  background-image: linear-gradient(to bottom, #056eb0 0%, #056dac 15%, #046aa8 21%, #0969a6 31%, #07649d 54%, #0c629b 63%, #0b6298 65%, #0c5f95 80%, #0c5f91 82%, #0e5c8d 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .jumbotron {
    background-image: linear-gradient(to left, #056eb0 0%, #056dac 15%, #046aa8 21%, #0969a6 31%, #07649d 54%, #0c629b 63%, #0b6298 65%, #0c5f95 80%, #0c5f91 82%, #0e5c8d 100%);
  }
}

@media (min-width: 992px) {
  .jumbotron {
    padding: 2.5rem 0;
    background-image: linear-gradient(to right, #0c5f94 0%, #0076C0 76%, #0076C0 100%);
  }
}

@media (min-width: 576px) {
  .jumbotron .title {
    line-height: 2.75rem;
  }
}

@media (min-width: 768px) {
  .jumbotron .title {
    margin-bottom: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .jumbotron .title {
    line-height: 3.375rem;
    margin-bottom: 1.75rem;
  }
}

.box-item {
  display: block;
  padding-top: 44.14%;
  position: relative;
  overflow: hidden;
}

.box-item .text {
  padding: 1.5rem 1rem;
  background-image: linear-gradient(to top, #f000 75%, #0064a233 100%, #007096);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s ease-in-out;
}

@media (min-width: 576px) {
  .box-item .text {
    padding: 1.25rem 1rem;
  }
}

@media (min-width: 768px) {
  .box-item .text {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 992px) {
  .box-item .text .title {
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .box-item .text .title + span {
    display: block;
    font-weight: 500;
  }
}

.box-item .title {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  line-height: 1.5;
  text-shadow: 2px 2px 4px #000;
  margin-bottom: 0;
  font-weight: 500;
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .box-item .title {
    font-size: 1.5rem;
    line-height: 1.3333333;
  }
}

@media (min-width: 992px) {
  .box-item .title {
    margin-bottom: 0.75rem;
  }
}

.box-item .title + span {
  display: none;
}

@media (min-width: 992px) {
  .box-item .title + span {
    display: block;
  }
}

.justify-space {
  width: 100%;
  justify-content: space-between;
}

.section-analysis .section-heading {
  text-align: center;
}

@media (min-width: 1200px) {
  .section-analysis .section-heading {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
  }
}

.section-analysis [class^="col"]:not(:first-child):not(:last-child) {
  padding-bottom: 1rem;
}

@media (min-width: 576px) {
  .section-analysis [class^="col"]:not(:first-child):not(:last-child) {
    padding-bottom: 30px;
  }
}

@media (min-width: 576px) {
  .section-analysis [class^="col"]:not(:first-child):not(:last-child):nth-last-child(2) {
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .section-analysis [class^="col"]:not(:first-child):not(:last-child):nth-last-child(3) {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .section-analysis [class^="col"]:not(:first-child):not(:last-child):nth-last-child(4) {
    padding-bottom: 0;
  }
}

.section-analysis .box-item {
  min-height: 128px;
  color: #fff;
}

@media (min-width: 576px) {
  .section-analysis .box-item {
    min-height: 160px;
  }
}

@media (min-width: 768px) {
  .section-analysis .box-item {
    min-height: 220px;
  }
}

@media (min-width: 992px) {
  .section-analysis .box-item {
    min-height: 340px;
  }
}

@media (min-width: 992px) {
  .section-analysis .box-item:hover .text {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 992px) {
  .section-analysis .box-item:hover .block-hover {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-analysis .box-item .block-hover {
  display: none;
}

@media (min-width: 992px) {
  .section-analysis .box-item .block-hover {
    display: block;
    padding: 2rem 1.5rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    top: 0;
    z-index: 1;
    opacity: 0;
    transform: translateY(30%);
    line-height: 1.375;
    transition: opacity 0.12s ease-in-out 0.08s, transform 0.12s ease-in-out 0.08s;
  }
}

.section-analysis .box-item .block-hover .title {
  visibility: hidden;
}

.section-events .link-all {
  display: inline-block;
  vertical-align: top;
}

.section-events .event-item {
  display: block;
  padding: 1.5rem 1rem 1rem;
  line-height: 1.5;
  position: static;
  background-color: #fff;
  border: 1px solid #d6d6d6;
}

@media (min-width: 576px) {
  .section-events .event-item {
    padding: 1.5rem 1rem;
  }
}

@media (min-width: 768px) {
  .section-events .event-item {
    padding: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .section-events .event-item {
    padding: 1.5rem 2rem;
  }
}

.section-events .event-item .title {
  color: #0076C0;
  font-weight: 500;
  line-height: inherit;
}

@media (min-width: 576px) {
  .section-events .event-item .title {
    display: block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .section-events .event-item .title {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.section-events .event-item:hover h5 {
  text-decoration: underline;
}

.section-events .type,
.section-events time {
  display: inline-block;
  vertical-align: top;
}

.section-events .type,
.section-events .title {
  margin-bottom: 0.5rem;
}

.section-events .type {
  position: relative;
  padding-left: 1.5rem;
}

@media (min-width: 576px) {
  .section-events .type {
    margin-right: auto;
    margin-bottom: 0.75rem;
  }
}

@media (min-width: 768px) {
  .section-events .type {
    margin-bottom: 0.5rem;
  }
}

.section-events .type-trade-show:before {
  background-color: #e91e63;
}

.section-events .type-webinar:before {
  background-color: #00bcd4;
}

.section-events .type-training:before {
  background-color: #4caf50;
}

.section-events .type-seminar:before {
  background-color: #ff9900;
}

.section-events .type-presentation:before {
  background-color: #9c27b0;
}

.section-events .type:before {
  width: 1rem;
  height: 1rem;
  content: '';
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .section-events .about {
    width: 200px;
    text-align: right;
  }
}

.section-events .location {
  margin-bottom: 0.5rem;
  margin-left: auto;
}

.content-events h5 {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .content-events h5 {
    display: block;
    width: 100%;
  }
}

.content-events > .row > [class^="col"] + [class^="col"] {
  padding-top: 1rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-0 {
  flex-grow: 0;
}

.block-card:last-child {
  position: relative;
}

.block-card .btn-arrow {
  text-transform: uppercase;
  background-color: #0076C0;
  color: #fff;
}

.block-card .hold-form {
  background-color: #546e7a;
  color: #fff;
  position: relative;
}

.block-card .hold-form p {
  margin-bottom: 0.5rem;
}

.block-card .hold-form p.required label:after {
  content: '*';
}

.block-card iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
}

.box-form input:not([type="submit"]) {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 0.25rem;
}

.section-padding {
  padding: 2rem 0;
}

@media (min-width: 576px) {
  .section-padding {
    padding: 3rem 0;
  }
}

@media (min-width: 1200px) {
  .section-padding {
    padding: 5rem 0;
  }
}

.section-margin-bottom {
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .section-margin-bottom {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1200px) {
  .section-margin-bottom {
    margin-bottom: 5rem;
  }
}

#testing .col-content-item .visual {
  overflow: hidden;
  height: auto;
}

@media (min-width: 768px) {
  #testing .col-content-item .visual {
    height: 135px;
  }
}

@media (min-width: 992px) {
  #testing .col-content-item .visual {
    height: 119px;
  }
}

@media (min-width: 1200px) {
  #testing .col-content-item .visual {
    height: 143px;
  }
}

.col-content-item {
  position: relative;
  padding-bottom: 1rem;
}

@media (min-width: 992px) {
  .col-content-item {
    padding-bottom: 0;
  }
}

.col-content-item .visual {
  overflow: hidden;
}

@media (min-width: 768px) {
  .col-content-item .visual {
    height: 207px;
  }
}

@media (min-width: 992px) {
  .col-content-item .visual {
    height: 182px;
  }
}

@media (min-width: 1200px) {
  .col-content-item .visual {
    height: 219px;
  }
}

.col-content-item img {
  max-height: 100%;
}

.col-content-item .title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.col-content-item p {
  margin-bottom: 0.5rem;
}

.col-content-item strong {
  margin-bottom: 0.75rem;
}

.section-gray {
  background-color: #f7f9f9;
}

@media (min-width: 768px) {
  .list-dots {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .list-dots {
    margin-bottom: 0.5rem;
  }
}

.list-dots li {
  position: relative;
  line-height: 1.5;
  padding-left: 1rem;
  margin-bottom: 0.25rem;
}

.list-dots li:before {
  width: 0.375rem;
  height: 0.375rem;
  content: '';
  display: block;
  color: inherit;
  position: absolute;
  left: 0;
  top: 0.5rem;
  background-color: #0076C0;
}

.list-dots a {
  color: #0076C0;
}

.list-dots.green li:before {
  background-color: #4CAF50 !important;
}

#videos .content-wrap {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  #videos .content-wrap {
    margin-bottom: 2.5rem;
  }
}

#videos .content-wrap .title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  #videos .content-wrap .title {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  #videos .content-wrap .title {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media (min-width: 1200px) {
  #videos .content-wrap .title {
    font-size: 1.25rem;
  }
}

#videos .visual {
  background-color: #323232;
  padding-top: 0;
  margin-bottom: 0.625rem;
  position: relative;
}

@media (min-width: 768px) {
  #videos .visual {
    margin-bottom: 0.875rem;
  }
}

@media (min-width: 992px) {
  #videos .visual {
    margin-bottom: 0.75rem;
  }
}

#videos .visual:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  mix-blend-mode: multiply;
  transition: all 80ms ease-out 0s;
  background-color: rgba(0, 118, 192, 0.5);
}

#videos .visual > span {
  height: 100% !important;
  padding: 0 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

#videos a {
  display: block;
}

#videos a:hover .visual {
  position: relative;
}

#videos a:hover .visual:before {
  width: 100%;
  height: 100%;
}

#videos a:hover .title {
  text-decoration: underline;
}

#videos .count {
  font-size: 1rem;
}

@media (min-width: 992px) {
  #videos .count {
    font-size: 1rem;
  }
}

[id^='content'] .inner-top {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  [id^='content'] .inner-top {
    margin-bottom: 2.75rem;
  }
}

@media (min-width: 992px) {
  [id^='content'] .inner-top {
    margin-bottom: 4.5rem;
  }
}

@media (min-width: 1200px) {
  [id^='content'] .inner-top {
    margin-bottom: 5.5rem;
  }
}

[id^='content'] .image-holder {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  [id^='content'] .image-holder {
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  [id^='content'] .image-holder {
    margin-bottom: 0;
  }
}

[id^='content'] .title {
  font-size: 1.5rem;
  line-height: 1.38;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  [id^='content'] .title {
    font-size: 2rem;
    margin-bottom: 18px;
  }
}

@media (min-width: 992px) {
  [id^='content'] .title {
    margin-bottom: 1.25rem;
  }
}

@media (min-width: 1200px) {
  [id^='content'] .title {
    font-size: 3rem;
    line-height: 1.25;
    margin-bottom: 1.5rem;
  }
}

[id^='content'] .text-link {
  font-weight: 500;
  cursor: pointer;
}

[id^='content'] .card-body {
  border-top: 1px solid #e2e4e6;
}

@media (min-width: 992px) {
  [id^='content'] .card-body {
    border-top: 0;
  }
}

[id^='content'] .title-card {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.33;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  [id^='content'] .title-card {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  [id^='content'] .title-card {
    font-size: 2rem;
  }
}

.section-anchors {
  transition: background-color 0.3s ease-in-out;
  background-color: #fff;
  z-index: 1;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  position: relative;
  color: #0076C0;
}

@media (min-width: 992px) {
  .section-anchors {
    border-top: 0;
  }
}

@media (min-width: 992px) {
  .fixed-position .section-anchors {
    background-color: #fff;
    border-bottom: 1px solid #d6d6d6;
    color: #0076C0;
  }
}

@media (min-width: 992px) {
  .fixed-position .section-anchors .slide {
    border-bottom: 0;
  }
}

.fixed-position .section-anchors .anchor-active {
  color: #323232;
  opacity: 1;
}

.fixed-position .section-anchors a:hover {
  color: #323232;
}

.section-anchors.open .opener i {
  transform: rotate3d(1, 0, 0, 180deg);
}

.section-anchors.open .slide {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
}

@media (min-width: 992px) {
  .section-anchors.open .slide {
    background-color: transparent;
  }
}

.section-anchors a.opener {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  background-color: #fff;
  color: #323232;
}

.section-anchors a.opener:hover {
  color: #323232;
}

@media (min-width: 576px) {
  .section-anchors a.opener {
    font-size: 24px;
  }
}

.section-anchors a.opener i {
  display: inline-block;
  vertical-align: top;
  margin-left: auto;
  font-size: 0.5rem;
}

.section-anchors .slide {
  transform: translateY(1px);
  opacity: 1;
  visibility: visible;
  transition: opacity 250ms ease 250ms, visibility 0s linear 500ms, transform 0s linear 500ms;
}

.section-anchors .slide.js-slide-hidden {
  display: block !important;
  left: auto !important;
  right: auto !important;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
}

.section-anchors ul {
  color: inherit;
  font-size: 1rem;
  line-height: 1.5;
  padding: 8px 0;
}

@media (min-width: 992px) {
  .section-anchors ul {
    display: flex;
    font-size: 1rem;
    line-height: 1;
    margin: 0 -2rem;
  }
}

@media (min-width: 992px) {
  .section-anchors li {
    padding: 0 1vw;
  }
}

.section-anchors li:not(:last-child) {
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .section-anchors li:not(:last-child) {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .section-anchors li:first-child {
    padding-left: 1rem;
  }
}

.section-anchors .anchor-active {
  color: #323232;
}

.section-anchors a {
  transition: color 0.3s ease-in-out;
  display: inline-block;
  vertical-align: top;
  padding: 8px 0;
}

@media (min-width: 992px) {
  .section-anchors a:hover {
    color: #323232;
  }
}

.section-overview {
  padding-top: 32px;
  padding-bottom: 64px;
}

@media (min-width: 576px) {
  .section-overview {
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) {
  .section-overview {
    padding-top: 56px;
  }
}

@media (min-width: 1200px) {
  .section-overview {
    padding-bottom: 104px;
  }
}

.section-streams .content-wrap h4 {
  font-weight: 500;
}

@media (min-width: 768px) {
  .section-streams .item-content {
    display: flex;
    margin: 0 -15px;
  }
}

@media (min-width: 992px) {
  .section-streams .item-content {
    display: block;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .section-streams .item-content .visual,
  .section-streams .item-content .text {
    width: 50%;
    padding: 0 15px;
  }
}

@media (min-width: 992px) {
  .section-streams .item-content .visual,
  .section-streams .item-content .text {
    width: auto;
    padding: 0;
  }
}

h2 .visual {
  display: flex;
  width: 60px;
  align-items: center;
  margin-left: 0.5rem;
}

@media (min-width: 992px) {
  h2 .visual {
    margin-left: 1rem;
  }
}

@media (min-width: 1200px) {
  h2 .visual {
    width: 100px;
  }
}

h2 .visual * {
  display: flex;
  align-items: center;
}

.slide-gen {
  padding: 1.5rem 0 0.5rem;
}

@media (min-width: 576px) {
  .slide-gen {
    padding: 1rem 0;
  }
}

@media (min-width: 768px) {
  .slide-gen {
    display: none;
  }
}

.list-gen {
  padding-top: 16px;
}

@media (min-width: 768px) {
  .list-gen {
    display: flex;
    margin: 0 -9px 40px;
  }
}

@media (min-width: 992px) {
  .list-gen {
    margin: 0 -15px 44px;
  }
}

.gen-item {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .gen-item {
    padding: 0 9px;
    width: 25%;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .gen-item {
    padding: 0 15px;
  }
}

.gen-item:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .gen-item.active .opener-gen {
    background-color: #e3eaee;
    color: #0076C0;
    font-weight: 400;
  }
}

@media (min-width: 768px) {
  .gen-item.active .opener-gen:hover {
    background-color: #0076C0;
    color: #fff;
    font-weight: 500;
  }
}

.gen-item.active .opener-gen:after {
  transform: translateY(-50%) rotate(180deg);
}

@media (min-width: 768px) {
  .gen-item.active .opener-gen:after {
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) {
  .gen-item.active-tab .opener-gen {
    background-color: #0076C0;
    color: #fff;
    font-weight: 500;
  }
  .gen-item.active-tab .opener-gen:after {
    transform: translateY(-50%) rotate(180deg);
  }
}

.gen-item > a {
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  display: block;
  padding: 10px;
  color: #0076C0;
  background-color: #e3eaee;
  position: relative;
  text-align: center;
}

@media (min-width: 768px) {
  .gen-item > a {
    padding: 0.5rem;
  }
}

.gen-item > a:after {
  content: "\e907";
  font-size: 7px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

@media (min-width: 768px) {
  .gen-item > a:after {
    content: none;
  }
}

.gen-item > a .speed {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.box-pci-generations .tab-holder {
  display: none;
}

@media (min-width: 768px) {
  .box-pci-generations .tab-holder {
    display: block;
  }
}

.box-pci-generations .tab-holder > * {
  transition: opacity 0.6s ease-in-out;
  opacity: 1;
}

@media (min-width: 1200px) {
  .box-pci-generations .tab-holder .product-card {
    flex-direction: row;
  }
}

.box-pci-generations .tab-hidden-md {
  opacity: 0;
}

.product-card {
  border: solid 1px #d6d6d6;
  background-color: #fff;
  min-height: 124px;
}

@media (min-width: 1200px) {
  .product-card {
    display: flex;
    min-height: 144px;
  }
}

.product-card:hover .title {
  text-decoration: underline;
}

.product-card .main {
  width: 100%;
}

.product-card .main p {
  margin-bottom: 0;
}

.product-card ul li {
  position: relative;
  padding-left: 8px;
}

.product-card ul li:before {
  width: 4px;
  height: 4px;
  content: '';
  display: block;
  border-radius: 100%;
  background-color: #231F20;
  position: absolute;
  left: 0;
  top: 9px;
}

.product-card .content {
  padding: 1.5rem 1rem;
  background-color: #fff;
  line-height: 1.25;
}

@media (min-width: 576px) {
  .product-card .content {
    padding: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .product-card .content {
    display: flex;
    align-items: center;
    width: 61.94%;
    padding: 1.5rem 2rem;
    line-height: 1.5;
  }
}

@media (min-width: 1200px) {
  .product-card .visual {
    flex-basis: 144px;
  }
}

.list-product-cards {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .list-product-cards {
    padding-top: 0;
  }
}

.list-product-cards > li:not(:last-child) {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .list-product-cards > li:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 992px) {
  .list-product-cards > li:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.header-product {
  background-color: #f1f4f6;
  display: flex;
  align-items: center;
  color: #666;
}

@media (min-width: 1200px) {
  .header-product {
    width: 38.06%;
  }
}

.header-product .visual {
  width: 90px;
  flex-shrink: 0;
  margin: 0;
  padding-top: 90px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (min-width: 576px) {
  .header-product .visual {
    width: 106px;
    padding-left: 1rem;
  }
}

@media (min-width: 1200px) {
  .header-product .visual {
    width: 144px;
    padding-top: 30%;
    padding-left: 0;
  }
}

.header-product .visual img {
  max-height: 100%;
}

.header-product .text {
  color: #666;
  padding: 19px 0.5rem;
  flex-grow: 1;
}

@media (min-width: 576px) {
  .header-product .text {
    padding: 0.9375rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .header-product .text {
    padding: 0.9375rem 0 0.9375rem 1.5rem;
  }
}

@media (min-width: 1200px) {
  .header-product .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    height: 100%;
    flex-grow: 1;
    padding: 0.9375rem 0 0.9375rem 2rem;
  }
}

.header-product .title {
  color: #0076C0;
  font-size: 18px;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

@media (min-width: 576px) {
  .header-product .title {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

#overview .jumbotron {
  padding-top: 5rem;
}

@media (min-width: 1200px) {
  #overview .jumbotron {
    padding-top: 6.5rem;
  }
}

.section-overview-top {
  padding: 2rem 0;
}

@media (min-width: 576px) {
  .section-overview-top {
    background-position: 80% 50%;
  }
}

@media (min-width: 768px) {
  .section-overview-top {
    padding: 3rem 0;
  }
}

@media (min-width: 992px) {
  .section-overview-top {
    background-position: 70% 50%;
  }
}

@media (min-width: 1200px) {
  .section-overview-top {
    background-position: 50% 50%;
  }
}

.section-overview-top .section-heading {
  font-size: 18px;
  line-height: 1.3333;
}

@media (min-width: 576px) {
  .section-overview-top .section-heading {
    font-size: 1.25rem;
    line-height: 1.6;
    padding: 0 0 3.5rem;
  }
}

@media (min-width: 768px) {
  .section-overview-top .section-heading {
    padding: 0 0 44px;
  }
}

.section-overview-top .title {
  font-weight: 500;
}

.section-overview-top .add-text {
  opacity: 0.7;
}

.section-overview-top .visual {
  height: 43px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 576px) {
  .section-overview-top .visual {
    height: 50px;
  }
}

@media (min-width: 768px) {
  .section-overview-top .visual {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 992px) {
  .section-overview-top .visual {
    margin-bottom: 1rem;
  }
}

.section-overview-top .visual img {
  max-height: 100%;
  max-width: 80%;
}

@media (min-width: 576px) {
  .section-overview-top .visual img {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
}

.js-tab-hidden {
  display: block !important;
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important;
}

.list-banner {
  text-align: center;
}

@media (min-width: 1200px) {
  .list-banner {
    display: flex;
  }
}

.list-banner [class^="col"]:not(:last-child) {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .list-banner [class^="col"]:not(:last-child) {
    margin-bottom: 0;
  }
}

.list-banner strong {
  font-weight: 500;
}

@media (min-width: 1200px) {
  .list-banner strong + span {
    opacity: 0.8;
  }
}

.list-banner-item {
  position: relative;
  padding: 1.25rem;
  height: 100%;
}

@media (min-width: 768px) {
  .list-banner-item {
    padding: 1.25rem 0.5rem;
  }
}

@media (min-width: 992px) {
  .list-banner-item {
    padding: 1.25rem 1rem;
  }
}

@media (min-width: 1200px) {
  .list-banner-item {
    padding: 0 0.75rem 3rem;
  }
}

.list-banner-item:before {
  content: '\e91a';
  font-size: 36px;
  color: #0076C0;
}

.banner-top {
  color: #fff;
  background-image: linear-gradient(to left, #056eb0 0%, #056dac 15%, #046aa8 21%, #0969a6 31%, #07649d 54%, #0c629b 63%, #0b6298 65%, #0c5f95 80%, #0c5f91 82%, #0e5c8d 100%);
  /*Andrii Changed 8/11/20*/
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  overflow: hidden;
  position: relative;
}

@media (min-width: 576px) {
  .banner-top {
    font-size: 1.5rem;
    line-height: 1.3333333;
  }
}

.banner-top:before {
  display: block;
  position: absolute;
  bottom: 0;
  right: -10rem;
  width: 420px;
  height: 100%;
  background-image: linear-gradient(to right, #0000 27%, rgba(255, 255, 255, 0.05) 27%, rgba(255, 255, 255, 0.05) 100%);
  transform: skew(42deg);
}

@media (min-width: 768px) {
  .banner-top:before {
    content: '';
  }
}

@media (min-width: 992px) {
  .banner-top:before {
    right: 0;
  }
}

@media (min-width: 1200px) {
  .banner-top:before {
    right: 13%;
  }
}

.banner-top .jumbotron {
  background: none;
}

.banner-top .inner-footer {
  background-color: transparent;
  padding: 0;
  margin-top: 1.25rem;
}

@media (min-width: 992px) {
  .banner-top .inner-footer {
    margin-top: 2rem;
  }
}

.inner-footer {
  font-size: 1rem;
  background-color: #024877;
  color: #fff;
  padding: 1.5rem 0;
}

.inner-footer p {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .inner-footer span {
    display: block;
  }
}

@media (min-width: 992px) {
  .list-bullets {
    display: flex;
    margin: 0 -15px;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .list-bullets {
    margin: 0 -2rem;
  }
}

.item-bullet {
  position: relative;
  padding-left: 45px;
}

@media (min-width: 992px) {
  .item-bullet {
    padding: 0 15px;
    padding-left: 3.75rem;
  }
}

@media (min-width: 1200px) {
  .item-bullet {
    padding: 0 2rem;
    padding-left: 4.5rem;
  }
}

.item-bullet:before {
  content: "\e920";
  position: absolute;
  left: 0;
  font-size: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  .item-bullet:before {
    left: 15px;
  }
}

@media (min-width: 1200px) {
  .item-bullet:before {
    left: 2rem;
  }
}

.item-bullet + .item-bullet {
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .item-bullet + .item-bullet {
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  .item-bullet + .item-bullet {
    margin-top: 0;
  }
}

.section-services .row + .row {
  padding-top: 2rem;
}

.section-services .text {
  background-color: #f7f9f9;
}

.skew-style p .link-arrow {
  line-height: 1.5;
}

.skew-style p .link-arrow i {
  top: 8px;
}

.banner-home {
  padding: 2rem 0;
  font-size: 16px;
  color: #375d7d;
}

@media (min-width: 992px) {
  .banner-home {
    padding: 2.5rem 0;
  }
}

@media (min-width: 1200px) {
  .banner-home {
    padding: 5rem 0;
    font-size: 24px;
  }
}

.banner-home .title {
  margin-bottom: 0.5rem;
}

.section-documents .title {
  margin-bottom: 0.5rem;
  /*Andrii Added 8/11/20*/
}

@media (min-width: 992px) {
  .section-documents .title {
    margin-bottom: 0.75rem;
    /*Andrii Added 8/11/20*/
  }
}

.header-transparented header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: transparent;
  border-color: rgba(216, 216, 216, 0.12);
  border: 0;
}

.header-transparented header.popup-active, .header-transparented header:hover {
  transition: background-color 0.3s ease-in-out;
  background-color: #fff;
  border-bottom: 1px solid #e2e4e6;
}

.header-transparented header.popup-active svg #Combined-Shape-1,
.header-transparented header.popup-active svg #Combined-Shape-2, .header-transparented header:hover svg #Combined-Shape-1,
.header-transparented header:hover svg #Combined-Shape-2 {
  transition: fill 0.3s ease-in-out;
  fill: #231F20;
}

.header-transparented header.popup-active svg #Combined-Shape-3, .header-transparented header:hover svg #Combined-Shape-3 {
  transition: fill 0.3s ease-in-out;
  fill: #0076C0;
}

.header-transparented header.popup-active .nav-item > .opener, .header-transparented header:hover .nav-item > .opener {
  color: inherit;
  font-weight: 400;
}

.header-transparented header.popup-active .holder-icons i, .header-transparented header:hover .holder-icons i {
  color: inherit;
}

.header-transparented header.popup-active .drop, .header-transparented header:hover .drop {
  border-top: 1px solid #e2e4e6;
}

.header-transparented header.popup-active .nav-opener i,
.header-transparented header.popup-active .nav-opener:before,
.header-transparented header.popup-active .nav-opener:after, .header-transparented header:hover .nav-opener i,
.header-transparented header:hover .nav-opener:before,
.header-transparented header:hover .nav-opener:after {
  background-color: #222;
}

.header-transparented header.popup-active .header-nav .nav-opener i,
.header-transparented header.popup-active .header-nav .nav-opener:before,
.header-transparented header.popup-active .header-nav .nav-opener:after, .header-transparented header:hover .header-nav .nav-opener i,
.header-transparented header:hover .header-nav .nav-opener:before,
.header-transparented header:hover .header-nav .nav-opener:after {
  background-color: inherit;
}

.header-transparented header.popup-active .close i.icon-close, .header-transparented header:hover .close i.icon-close {
  color: #999;
}

.header-transparented .navbar-brand {
  display: flex;
  align-items: center;
}

.header-transparented svg {
  max-width: 100%;
}

.header-transparented svg #Combined-Shape-1,
.header-transparented svg #Combined-Shape-2,
.header-transparented svg #Combined-Shape-3 {
  fill: #fff;
}

.header-transparented .holder-icons i {
  color: #fff;
}

.header-transparented .holder-icons .nav-opener i,
.header-transparented .holder-icons .nav-opener:before,
.header-transparented .holder-icons .nav-opener:after {
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}

.header-transparented .nav-item > .opener {
  color: #fff;
  font-weight: 400;
}

.header-transparented .drop {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.header-transparented .close i.icon-close {
  color: #999;
}

body:not(.header-transparented) svg #Combined-Shape-1,
body:not(.header-transparented) svg #Combined-Shape-2 {
  fill: #000;
}

.skew-style main,
.skew-style p {
  font-size: 0.75rem;
  color: #596369;
}

@media (min-width: 576px) {
  .skew-style main,
  .skew-style p {
    font-size: 0.875rem;
  }
}

@media (min-width: 768px) {
  .skew-style main,
  .skew-style p {
    font-size: 1rem;
  }
}

.skew-style .container {
  padding: 0 16px;
}

@media (min-width: 992px) {
  .skew-style .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .skew-style .container {
    max-width: 1280px;
  }
}

@media (max-width: 540px) {
  .skew-style .container {
    padding: 0 8px;
  }
}

.skew-style [class^="row"] {
  margin: 0 -1rem;
}

.skew-style [class^="col"] {
  padding: 0 1rem;
}

.skew-style .add-text {
  padding-top: 1.25rem;
  color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 576px) {
  .skew-style .add-text {
    padding-top: 1.5rem;
  }
}

@media (min-width: 768px) {
  .skew-style .add-text {
    padding-top: 2.5rem;
  }
}

.skew-style .section-heading {
  font-size: 0.75rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .skew-style .section-heading {
    margin-bottom: 2.5rem;
    font-size: 1rem;
  }
}

.skew-style .section-heading h2 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 500;
}

@media (min-width: 576px) {
  .skew-style .section-heading h2 {
    margin-bottom: 0.75rem;
    font-size: 1.75rem;
  }
}

@media (min-width: 992px) {
  .skew-style .section-heading h2 {
    line-height: 1.3;
    font-size: 2.75rem;
  }
}

@media (min-width: 576px) {
  .skew-style .section-heading p {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .skew-style .section-heading p {
    font-size: 1.25rem;
  }
}

.skew-style .btn-default {
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 14px 1.5rem;
}

@media (min-width: 576px) {
  .skew-style .btn-default {
    font-size: 1rem;
  }
}

.skew-style .btn-default:disabled {
  background-color: #EAEDEE;
  opacity: 1;
  color: #fff;
  border: solid 1px #EAEDEE;
}

.skew-style footer .drop {
  border-top: 0;
}

.skew-style .sub-footer {
  margin-top: 1.5rem;
}

@media (min-width: 576px) {
  .skew-style .sub-footer {
    margin-top: 0.5rem;
  }
}

.skew-style .section-gray {
  background-color: #F6F8F8 !important;
}

.skew-style h2 {
  color: #231f20;
  font-weight: 500;
}

@media (min-width: 992px) {
  .skew-style h2 {
    font-size: 52px;
    font-weight: 500;
  }
}

@media (min-width: 992px) {
  .skew-style h3 {
    font-size: 32px;
    font-weight: 500;
  }
}

@media (min-width: 992px) {
  .skew-style h4 {
    font-size: 24px;
    font-weight: 500;
  }
}

.skew-style .add-info {
  color: #1a1818;
}

.skew-style .bg-overlay {
  background-color: rgba(11, 27, 37, 0.5);
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.skew-style .bg-overlay.blue {
  background: rgba(20, 97, 148, 0.5);
}

.skew-style .icon-target-link {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  margin-left: 0.5rem;
  font-size: 0.875rem;
}

.section-banner,
.bg-video-holder {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 7.5rem;
  padding-bottom: 11.25rem;
}

.section-banner.small,
.bg-video-holder.small {
  height: 520px;
  display: flex;
  align-items: center;
}

.section-banner.small .banner,
.bg-video-holder.small .banner {
  bottom: 0;
}

.section-banner.small .banner span.page,
.bg-video-holder.small .banner span.page {
  text-transform: uppercase;
  margin-bottom: 8px;
}

.section-banner.small h1 + div,
.bg-video-holder.small h1 + div {
  margin: 0 -1rem  !important;
}

.section-banner.midle,
.bg-video-holder.midle {
  height: 520px;
}

.section-banner.small-2,
.bg-video-holder.small-2 {
  height: 320px;
}

.section-banner.small-2 h1,
.bg-video-holder.small-2 h1 {
  margin-top: 70px;
  margin-bottom: 8px !important;
}

.section-banner.small.not-angle,
.bg-video-holder.small.not-angle {
  height: 320px;
}

.section-banner.small.not-angle .banner,
.bg-video-holder.small.not-angle .banner {
  bottom: 0;
}

.section-banner .banner,
.bg-video-holder .banner {
  width: 100%;
  color: #fff;
}

.section-banner .status,
.bg-video-holder .status {
  font-size: 14px;
  padding: 8px 12px;
  background: #4CAF50;
  border-radius: 2px;
}

@media (min-width: 576px) {
  .section-banner,
  .bg-video-holder {
    padding-top: 16% !important;
    padding-bottom: 20.9% !important;
  }
}

@media (min-width: 768px) {
  .section-banner,
  .bg-video-holder {
    padding-top: 15.7% !important;
    padding-bottom: 18.5% !important;
  }
}

@media (min-width: 992px) {
  .section-banner,
  .bg-video-holder {
    padding-top: 20.4% !important;
    padding-bottom: 27.7% !important;
  }
}

@media (min-width: 1200px) {
  .section-banner,
  .bg-video-holder {
    padding-top: 10.5% !important;
    padding-bottom: 17.7% !important;
  }
}

.section-banner h1 + div,
.bg-video-holder h1 + div {
  max-width: 780px;
  margin: 0 auto !important;
}

.section-banner h1 + div p,
.bg-video-holder h1 + div p {
  color: #fff;
}

.section-banner h1 + div.big,
.bg-video-holder h1 + div.big {
  max-width: 880px;
}

.section-banner h1 + div.big p,
.bg-video-holder h1 + div.big p {
  font-size: 20px;
}

.section-banner .banner,
.bg-video-holder .banner {
  background-color: transparent;
  line-height: 1.5;
  color: #fff;
  position: relative;
}

@media (min-width: 768px) {
  .section-banner .banner,
  .bg-video-holder .banner {
    font-size: 1.25rem;
  }
}

.section-banner .banner h1,
.bg-video-holder .banner h1 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.32;
  color: #fff;
  margin-bottom: 16px;
}

.section-banner .banner h1 br,
.bg-video-holder .banner h1 br {
  display: none;
}

@media (min-width: 576px) {
  .section-banner .banner h1,
  .bg-video-holder .banner h1 {
    font-size: 2rem;
    line-height: 1.3;
  }
  .section-banner .banner h1 br,
  .bg-video-holder .banner h1 br {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .section-banner .banner h1,
  .bg-video-holder .banner h1 {
    font-size: 3.25rem;
  }
}

.section-banner .banner h1 + p,
.bg-video-holder .banner h1 + p {
  font-weight: 400;
  font-size: 20px;
}

.section-banner .banner span,
.bg-video-holder .banner span {
  display: inline-block;
  vertical-align: top;
  font-size: 0.625rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  color: #fff;
}

@media (min-width: 576px) {
  .section-banner .banner span,
  .bg-video-holder .banner span {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
  }
}

@media (min-width: 768px) {
  .section-banner .banner span,
  .bg-video-holder .banner span {
    font-size: 1rem;
    line-height: 1.1875;
  }
}

.section-banner .banner p,
.bg-video-holder .banner p {
  font-size: 0.875rem;
  letter-spacing: 0.03em;
  color: #fff;
  font-weight: 100;
}

.section-banner .banner p.bold,
.bg-video-holder .banner p.bold {
  font-weight: 400;
}

@media (min-width: 576px) {
  .section-banner .banner p,
  .bg-video-holder .banner p {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .section-banner .banner p,
  .bg-video-holder .banner p {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .section-banner .banner p,
  .bg-video-holder .banner p {
    font-size: 1.25rem;
  }
}

.bg-video-holder .bg-video {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.video-active .bg-video {
  opacity: 1;
}

.banner {
  background-color: rgba(0, 118, 192, 0.15);
}

.block-list {
  padding: 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}

@media (min-width: 992px) {
  .block-list .inner-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .list-psg {
    display: flex;
    margin: 0 -0.75rem;
  }
}

@media (min-width: 1200px) {
  .list-psg {
    margin-left: 220px;
  }
}

@media (min-width: 992px) {
  .list-psg li {
    padding: 0 0.75rem;
  }
}

.section-technologies,
.section-angle {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 2.5rem 0 2rem;
}

@media (min-width: 576px) {
  .section-technologies,
  .section-angle {
    padding: 2rem 0 2.5rem;
  }
}

@media (min-width: 768px) {
  .section-technologies,
  .section-angle {
    padding: 1.5rem 0 5rem;
  }
}

@media (min-width: 992px) {
  .section-technologies,
  .section-angle {
    padding: 2.75rem 0 5rem;
  }
}

@media (min-width: 1200px) {
  .section-technologies,
  .section-angle {
    padding: 0 0 5rem;
  }
}

.section-technologies:before,
.section-angle:before {
  bottom: 0;
  transform-origin: 0;
  transform: skewY(-5deg);
}

.section-technologies .slick-slide > div + div .col-tech,
.section-angle .slick-slide > div + div .col-tech {
  margin-bottom: 0;
}

.tech-list-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

@media (min-width: 768px) {
  .tech-list-cards {
    margin: 0 -1rem;
  }
}

@media (min-width: 992px) {
  .tech-list-cards {
    margin: 0 -2.5rem;
  }
}

@media (min-width: 1200px) {
  .tech-list-cards {
    margin: 0 -1rem;
  }
}

.tech-list-cards .col-tech {
  width: 50%;
  padding: 0 0.375rem;
  margin-bottom: 1.875rem;
}

@media (min-width: 576px) {
  .tech-list-cards .col-tech {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 768px) {
  .tech-list-cards .col-tech {
    width: 33.33%;
    padding: 0 1rem;
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 992px) {
  .tech-list-cards .col-tech {
    padding: 0 2.5rem;
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .tech-list-cards .col-tech {
    width: 25%;
    padding: 0 1rem;
    margin-bottom: 3.75rem;
  }
}

.tech-list-cards .card-tech {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media (min-width: 768px) {
  .tech-list-cards .card-tech {
    display: block;
  }
}

.tech-list-cards .card-tech:focus {
  outline: none;
}

.tech-list-cards .card-tech .visual {
  align-items: center;
  margin: 0 0 0.5rem;
}

.tech-list-cards .card-tech .visual a {
  width: 100%;
}

@media (min-width: 576px) {
  .tech-list-cards .card-tech .visual {
    height: 3.75rem;
  }
}

@media (min-width: 768px) {
  .tech-list-cards .card-tech .visual {
    display: flex;
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

.tech-list-cards .icon-target-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1px;
}

.tech-list-cards .text {
  color: rgba(0, 0, 0, 0.6);
}

.tech-list-cards h6.title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
  transition: color 0.3s ease-in-out;
  color: #0076C0;
}

@media (min-width: 576px) {
  .tech-list-cards h6.title {
    font-size: 1rem;
  }
}

.tech-list-cards .slick-dots {
  position: relative;
  bottom: 0;
  padding-top: 1.25rem;
}

.tech-list-cards .slick-dots .slick-active button {
  background: #0076C0;
}

.tech-list-cards .slick-dots li + li {
  margin-left: 0.25rem;
}

.tech-list-cards .slick-dots button {
  width: 1.25rem;
  height: 0.125rem;
  background: #c4c4c4;
  border-radius: 0;
  border: 0;
}

.card-tech .visual {
  margin-bottom: 1.5rem;
}

.card-tech .text {
  font-size: 0.875rem;
  line-height: 1.5;
}

.card-tech .title {
  margin-bottom: 0.25rem;
  position: relative;
}

.card-tech .title:hover {
  color: #003f66;
}

.card-tech .title:hover .icon-target-link {
  opacity: 1;
}

@media (min-width: 992px) {
  .title-section {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .title-group {
    font-size: 2rem;
    line-height: 1.15625;
    font-weight: 500;
  }
}

.section-skew:after {
  content: "";
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  transform: skew(-83deg);
  transform-origin: bottom;
}

.section-skew-white:after {
  content: "";
  background-color: #fff !important;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: skew(-83deg);
  transform-origin: bottom;
}

.v1 .bg-video-holder:after {
  background-color: #f6f8f8 !important;
}

.section-gray-dark {
  background-color: #404040;
}

.section-gray-dark .section-heading {
  color: rgba(255, 255, 255, 0.6);
}

.section-gray-light {
  background-color: #f6f8f8;
}

.section-white {
  background-color: #fff;
  color: #000;
}

.section-white .title {
  color: #222;
}

.card-line {
  padding: 1.5rem 1.5rem 2rem;
  box-shadow: 0 0.5rem 0.75rem #ececec;
  background-color: #fff;
  font-size: 0.75rem;
  line-height: 1.5;
  height: 100%;
  border: 1px solid #EAEDEE;
}

@media (min-width: 576px) {
  .card-line {
    padding: 2.5rem;
  }
}

@media (min-width: 768px) {
  .card-line {
    font-size: 1rem;
  }
}

.card-line .inner-content {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  height: 170px;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.card-line span.sub-title {
  font-size: 16px;
  color: #596369;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.card-line .title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 8px;
  color: #231F20;
}

.card-line .title.d-flex {
  align-items: center;
}

@media (min-width: 576px) {
  .card-line .title {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

@media (min-width: 992px) {
  .card-line .title {
    font-size: 1.75rem;
  }
}

.card-line p {
  flex-grow: 1;
  margin-bottom: 0.75rem;
}

.link-arrow {
  transition: color 0.3s ease-in-out;
  display: inline-block;
  vertical-align: top;
  color: #0076C0;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 500;
  position: relative;
  outline: none;
}

.link-arrow:link, .link-arrow:visited, .link-arrow:active {
  color: #0076C0;
}

.link-arrow:focus, .link-arrow:hover {
  color: #0699F5;
}

.link-arrow:focus i, .link-arrow:hover i {
  margin-left: 0.75rem;
}

.link-arrow i {
  transition: margin-left 0.3s ease-in-out, right 0.3s ease-in-out;
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 0.375rem;
  font-size: 0.375rem;
  font-weight: 700;
  color: inherit;
  margin-left: 0.5rem;
}

@media (min-width: 576px) {
  .link-arrow i {
    top: 6px;
    font-size: 0.5rem;
  }
}

.link-arrow.bottom svg {
  margin-left: 8px;
}

.section-testing {
  padding: 2.5rem 0 90px;
}

@media (min-width: 576px) {
  .section-testing {
    padding-bottom: 6rem;
  }
}

@media (min-width: 992px) {
  .section-testing {
    padding: 64px 0 8.5rem;
  }
}

@media (min-width: 1200px) {
  .section-testing {
    padding: 64px 0 calc(8.6% + 2rem);
  }
}

.section-testing [class^='col']:not(:last-child) {
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .section-testing [class^='col']:not(:last-child) {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .section-testing [class^='col']:not(:last-child) {
    margin-bottom: 0;
  }
}

.section-testing .card-line {
  color: #000;
  border: 0;
}

.section-testing .card-line .title {
  color: #231F20;
}

.section-testing .card-line .title span {
  text-transform: uppercase;
}

.section-testing .card-line p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .section-testing .link-arrow {
    margin-right: 2rem;
  }
}

.section-testing .inner-content {
  height: auto;
}

@media (min-width: 992px) {
  .section-testing .inner-content {
    min-height: 180px;
  }
}

.section-testing .inner-content p {
  flex-grow: 0;
  margin-bottom: 16px;
  color: #596369;
}

.section-testing .list-items {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.25rem 32px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.333333;
  letter-spacing: -0.0178em;
  min-height: 96px;
}

@media (min-width: 992px) {
  .section-testing .list-items {
    font-size: 0.875rem;
  }
}

@media (max-width: 1199px) {
  .section-testing .list-items {
    min-height: 0;
  }
}

.section-testing .list-items li {
  padding: 0 0.25rem 0.5rem;
}

.section-testing .list-items li span {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  background-color: #F6F8F8;
  padding: 8px 12px;
  height: 40px;
}

.section-testing .title {
  color: #222;
}

aside {
  color: #000;
}

@media (min-width: 768px) {
  aside {
    min-width: 190px;
  }
}

aside h5 {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

@media (min-width: 576px) {
  aside h5 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  aside h5 {
    margin-bottom: 1rem;
  }
}

aside ul {
  padding: 0;
  font-size: 0.75rem;
  line-height: 1rem;
  columns: 2;
  column-gap: 20px;
  column-fill: balance;
  display: inline-block;
}

@media (max-width: 1199px) {
  aside ul {
    columns: 1;
  }
}

@media (max-width: 991px) {
  aside ul {
    columns: 2;
  }
}

@media (min-width: 576px) {
  aside ul {
    font-size: 1rem;
    column-gap: 2.75rem;
  }
}

@media (min-width: 768px) {
  aside ul {
    font-size: 1rem;
    line-height: 1.1875;
  }
}

@media (max-width: 575px) {
  aside ul {
    column-gap: 10px;
    font-size: 11px;
  }
}

aside ul li {
  padding-left: 1.375rem;
  position: relative;
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  aside ul li {
    padding-left: 1.75rem;
  }
}

@media (max-width: 767px) {
  aside ul li {
    white-space: nowrap;
  }
}

aside ul span {
  display: inline-block;
  vertical-align: top;
}

aside ul p {
  color: rgba(0, 0, 0, 0.6);
}

aside .list-check {
  line-height: 1.5;
  padding-bottom: 2rem;
  color: #596369;
}

aside .list-check i {
  color: #0076C0;
  position: absolute;
  left: 0;
  top: 2px;
}

@media (min-width: 768px) {
  aside .list-check i {
    top: 1px;
  }
}

@media (min-width: 992px) {
  aside .list-check i {
    top: 3px;
  }
}

aside .list-check i:before {
  color: inherit;
  font-size: 0.875rem;
  padding-left: 1px;
}

@media (min-width: 576px) {
  aside .list-check i:before {
    font-size: 1rem;
  }
}

aside .list-check p {
  font-size: 0.75rem;
}

@media (min-width: 992px) {
  aside .list-check p {
    font-size: 0.875rem;
  }
}

.section-industries {
  font-size: 1.25rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 992px) {
  .section-industries [class^="col"] {
    margin-bottom: 44px;
  }
}

.section-industries .title-section {
  color: #fff;
  line-height: 28px;
  margin-bottom: 80px;
  font-weight: 400;
}

.section-industries .title {
  line-height: 28px;
  margin-bottom: 12px;
  font-weight: 500;
  color: #fff;
}

.section-industries p {
  padding-right: 28px;
}

.holder-slider {
  position: relative;
  overflow: visible;
}

@media (min-width: 992px) {
  .holder-slider {
    padding: 0;
  }
}

.holder-slider .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.holder-slider .progress {
  width: 100%;
}

.section-events-slider {
  line-height: 1.5;
  position: relative;
  z-index: 1;
  padding-top: 8px;
  padding-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .section-events-slider {
    padding-bottom: 5rem;
    padding-top: 16px;
  }
}

@media (max-width: 1920px) {
  .section-events-slider.events-fix .container {
    overflow: hidden;
  }
  .section-events-slider.events-fix .holder-slider:hover .slider-controls {
    display: block;
  }
  .section-events-slider.events-fix .slider-controls {
    position: static;
    display: none;
  }
  .section-events-slider.events-fix .slider-controls .slick-arrow {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translate(0, -50%);
    background-image: url(images/new-arrow.svg);
    display: inline-block;
    width: 20px;
    height: 33px;
    background-repeat: no-repeat;
  }
  .section-events-slider.events-fix .slider-controls .slick-arrow:before {
    content: none;
  }
  .section-events-slider.events-fix .slider-controls .slick-arrow.slick-prev {
    right: auto;
    left: -10px;
    transform: rotate(-180deg);
    margin-top: -25px;
  }
  .section-events-slider.events-fix .slider-controls .slick-arrow.disable {
    display: none;
  }
}

.section-events-slider:before {
  bottom: 0;
  transform-origin: 0;
  transform: skewY(-4.8deg);
}

.section-events-slider .text-sub-title {
  color: rgba(0, 0, 0, 0.6);
}

.section-events-slider p {
  color: rgba(0, 0, 0, 0.6);
}

.section-events-slider .visual {
  opacity: 0.4;
}

/**
 *  Progress Bar
 */
.progress,
.progress2 {
  display: block;
  width: 100%;
  height: 0.25rem;
  border-radius: 0.125rem;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to right, #0076C0, #0076C0);
  background-repeat: no-repeat;
  transition: background-size 0.4s ease-in-out;
  margin-top: 40px;
}

@media (max-width: 577px) {
  .progress,
  .progress2 {
    margin-top: 32px;
  }
}

.slider-events {
  width: 73.2%;
}

@media (min-width: 576px) {
  .slider-events {
    width: 82%;
  }
}

@media (min-width: 768px) {
  .slider-events {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .slider-events {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .slider-events {
    width: 100%;
  }
}

.slider-events .slick-list {
  overflow: visible;
  margin: 0 -0.5rem;
}

@media (min-width: 576px) {
  .slider-events .slick-list {
    margin: 0 -1rem;
  }
}

.slider-events .slick-slide > div {
  padding: 0 0.5rem;
  outline: none;
}

@media (min-width: 576px) {
  .slider-events .slick-slide > div {
    padding: 0 1rem;
  }
}

.slider-controls {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 992px) {
  .slider-controls {
    display: block;
  }
}

@media (min-width: 1200px) {
  .slider-controls {
    bottom: -0.3125rem;
  }
}

.slider-controls .container {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.slider-controls .slick-arrow {
  transition: opacity 0.3s ease-in-out;
  background-color: transparent;
  border: 0;
  color: #0076C0;
  font-size: 0;
  padding: 0;
}

.slider-controls .slick-arrow:before {
  content: "\e926";
  font-size: 0.875rem;
}

.slider-controls .slick-arrow:hover {
  cursor: pointer;
  color: #003f66;
}

.slider-controls .slick-arrow:focus {
  outline: none;
}

.slider-controls .slick-arrow:active {
  border-style: outset;
  background-image: none;
}

.slider-controls .slick-arrow.slick-prev:before {
  content: "\e927";
}

.slider-controls .slick-arrow.slick-next {
  margin-left: 2rem;
}

@media (min-width: 992px) {
  .slider-controls .slick-arrow.slick-next {
    margin-left: 1.875rem;
  }
}

.slider-controls .slick-prev.disable,
.slider-controls .slick-next.disable {
  color: #EAEDEE;
  pointer-events: none;
}

.slide-event {
  transition: transform 0.3s ease-in-out;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem 1rem 1.5rem 1.5rem;
  min-height: 16.125rem;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0.5rem 16px #ececec;
  border: 1px solid #EAEDEE;
}

@media (min-width: 576px) {
  .slide-event {
    min-height: 24.5rem;
    padding: 2.5rem 2rem 2.5rem 2.5rem;
  }
}

@media (min-width: 992px) {
  .slide-event {
    padding: 2.5rem 5rem 2.5rem 2.5rem;
  }
}

@media (min-width: 1200px) {
  .slide-event {
    padding: 2.5rem 3rem 2.5rem 2.5rem;
  }
}

.slide-event:focus {
  outline: none;
}

.slide-event .title-slide {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  flex-grow: 1;
  margin-bottom: 0.75rem;
  color: #231F20;
}

@media (min-width: 576px) {
  .slide-event .title-slide {
    font-size: 1.75rem;
  }
}

@media (min-width: 992px) {
  .slide-event .title-slide {
    font-size: 1.75rem;
    margin-bottom: 0;
    min-height: 7.875rem;
  }
}

.slide-event .type {
  font-size: 0.75rem;
  line-height: 0.875rem;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1.25rem;
  color: #231F20;
}

@media (min-width: 576px) {
  .slide-event .type {
    margin-right: auto;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  .slide-event .type {
    font-size: 0.875rem;
    padding-left: 1.25rem;
  }
}

.slide-event .type.Trade.Shows:before {
  background-color: #e91e63;
}

.slide-event .type.Webinars:before {
  background-color: #00bcd4;
}

.slide-event .type.Training:before {
  background-color: #4caf50;
}

.slide-event .type.Seminar:before {
  background-color: #ff9900;
}

.slide-event .type.Presentation:before {
  background-color: #9c27b0;
}

.slide-event .type:before {
  width: 0.75rem;
  height: 0.75rem;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  .slide-event .type:before {
    top: calc(50% - 1px);
  }
}

.slide-event .footer-slide {
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.slide-event .footer-slide a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-event .footer-slide a i:before {
  margin-left: 0.75rem;
  color: #fff;
}

.slide-event .footer-slide i {
  font-size: 0.75rem;
}

.slide-event .footer-slide i:before {
  font-size: inherit;
  font-weight: 700;
}

.slide-event .footer-slide .date {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

@media (min-width: 576px) {
  .slide-event .footer-slide .date {
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .slide-event .footer-slide .date {
    font-size: 0.875rem;
  }
}

.slide-event .footer-slide .time {
  display: flex;
  align-items: center;
}

.slide-event .footer-slide .local {
  display: block;
  line-height: 1.5;
  font-size: 0.75rem;
  margin-right: 1rem;
  color: #596369;
}

.slide-event .footer-slide .local i {
  color: #596369;
}

@media (min-width: 576px) {
  .slide-event .footer-slide .local {
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .slide-event .footer-slide .local {
    font-size: 0.875rem;
  }
}

.slide-event .footer-slide i {
  color: #596369;
  margin: 0 0.5rem 0 0;
}

.slide-event .hold-date {
  width: 100%;
  justify-content: flex-start;
  color: #596369;
}

.slide-event .btn {
  display: inline-block;
  vertical-align: top;
  padding: 0.5625rem 1.5rem;
}

@media (min-width: 576px) {
  .slide-event .btn {
    padding: 0.875rem 1.5rem;
  }
}

.holder-btn-more {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

@media (min-width: 768px) {
  .holder-btn-more {
    padding-top: 2.75rem;
  }
}

@media (min-width: 992px) {
  .holder-btn-more {
    padding-top: 2.5rem;
  }
}

.holder-btn-more a {
  transition: right 0.3s ease-in-out;
  padding-right: 0.875rem;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .holder-btn-more a {
    font-size: 1rem;
  }
}

.holder-btn-more a.link-arrow:hover i {
  right: -0.25rem;
}

.holder-btn-more i {
  position: absolute;
  right: 0;
  font-size: 0.6em;
  font-weight: 400;
  margin-left: 0.75rem;
}

.section-resources {
  padding: 2.5rem 0;
}

@media (min-width: 992px) {
  .section-resources {
    padding: 64px 0 5rem 0;
  }
}

.section-resources [class^="col"]:not(:last-child) {
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .section-resources [class^="col"]:not(:last-child) {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .section-resources [class^="col"]:not(:last-child) {
    margin-bottom: 0;
  }
}

.section-resources .card-line {
  border: 1px solid #EAEDEE;
  padding-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .section-resources .card-line {
    padding-bottom: 2.5rem;
  }
}

@media (min-width: 992px) {
  .section-resources .card-line {
    padding-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .section-resources .card-line {
    padding-right: 2.5rem;
  }
}

@media (min-width: 576px) {
  .section-resources .card-line .title {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 992px) {
  .section-resources .card-line .title {
    margin-bottom: 0.75rem;
  }
}

@media (min-width: 576px) {
  .section-resources .card-line p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
}

.section-resources .link-arrow {
  font-size: 0.875rem;
}

@media (min-width: 576px) {
  .section-resources .link-arrow {
    font-size: 1rem;
  }
}

.section-resources .link-arrow:hover i {
  margin-left: 0.75rem;
}

.section-resources .link-arrow i {
  font-size: 0.6em;
  font-weight: 400;
  margin-left: 0.5rem;
}

#videos iframe {
  width: 100%;
  height: 200px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.section-an-first .block-img,
.section-an-first .block-link {
  opacity: 0;
  position: relative;
  top: 100px;
  transition: 2.4s;
}

.section-an-first.section_animation .block-img,
.section-an-first.section_animation .block-link {
  opacity: 1;
  top: 0;
  transition: 2.4s;
}

.section-an-second {
  background-position: -100px 80px !important;
  transition: 2.4s !important;
  opacity: 0;
  padding-top: 80px !important;
  min-height: 655px;
}

.section-an-second.section_animation {
  background-position: -100px 80px !important;
  transition: 2.4s !important;
  opacity: 1;
}

@media (max-width: 1200px) {
  .section-an-second {
    background-position: -350px 80px !important;
  }
  .section-an-second.section_animation {
    background-position: -350px 80px !important;
  }
}

@media (max-width: 840px) {
  .section-an-second {
    min-height: 0;
    background-position: -450px 80px !important;
  }
  .section-an-second.section_animation {
    background-position: -450px 80px !important;
  }
}

@media (max-width: 620px) {
  .section-an-second {
    background: none !important;
  }
  .section-an-second.section_animation {
    background: none !important;
  }
  .section-an-second h2 br {
    display: none;
  }
}

.section-zoom img {
  transition: 0.2s;
}

.section-zoom img:hover {
  transition: 0.2s;
  transform: scale(1.02);
  z-index: 999;
  position: relative;
}

.section-an-third .row .demonstration-view {
  position: relative;
  right: 100px;
  transition: 2.4s;
  opacity: 0;
}

.section-an-third .row-2 .demonstration-view {
  position: relative;
  right: auto;
  left: 100px;
  transition: 2.4s;
}

.section-an-third.section_animation .row .demonstration-view {
  right: 0;
  transition: 2.4s;
  opacity: 1;
}

.section-an-third.section_animation .row-2 .demonstration-view {
  right: auto;
  left: 0;
  transition: 2.4s;
}

.section-an-four {
  background-position: 120% 146px !important;
  transition: 2.4s !important;
  opacity: 0;
}

.section-an-four.section_animation {
  background-position: 120%  46px !important;
  transition: 2.4s !important;
  opacity: 1;
}

.w-90 {
  width: 90% !important;
}

#webinars .time {
  font-size: 14px;
}

.inner-img-full.min-h img {
  height: 220px;
  width: 100%;
}

.section-solutions .row {
  justify-content: center;
}

.section-solutions .section-content h5.title {
  color: #fff !important;
}

.section-solutions .section-content p {
  font-size: 13px;
  color: #fff !important;
}

.home-slide {
  position: relative;
}

.home-slide .slider-item_span {
  text-transform: uppercase;
  color: #231F20;
}

.home-slide .slider-item_text p {
  color: #231F20;
}

.home-slide .slick-arrow {
  background: none !important;
}

.home-slide .slider-block-action {
  margin-top: -50px;
}

.home-slide .container {
  position: static;
}

.home-slide .icon {
  height: 397px;
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  background-size: cover;
  right: 0;
}

.home-logo {
  min-height: 480px;
}

.home-logo .container {
  position: relative;
}

.home-logo .icon {
  height: 340px;
  width: 750px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: -40px;
  transform: translate(40%, 10px);
}

.home-logo .icon path {
  fill: #0076C0;
}

.home-logo p {
  font-size: 28px !important;
  line-height: 42px;
  color: #fff;
  position: relative;
}

.section-angle-2:before {
  top: -160px;
  transform: skewY(4.5deg) !important;
}

.home-events .container {
  margin-top: -70px;
}

b a:hover {
  color: #008ecd;
  text-decoration: underline;
}

.section-pro .card-line {
  box-shadow: 0 0.5rem 0.75rem #D4D4D4;
}

.section-event-solutions .lc-item.active {
  pointer-events: none;
  border-bottom: solid 2px #0076C0;
}

.section-event-solutions .lc-item.active span {
  color: #0076C0 !important;
}

.section-header p {
  color: #231F20;
}

@media (min-width: 1200px) {
  .home-video.bg-video-holder {
    padding-top: 7.5% !important;
    max-height: 520px;
  }
}

.home-video.bg-video-holder .bg-video {
  margin-top: 0 !important;
}

.h-full {
  height: 100vh;
  max-height: 100% !important;
}

.h-full.home-video.bg-video-holder {
  padding-top: 11.5rem !important;
}

@media (max-height: 600px) {
  .h-full.home-video.bg-video-holder {
    padding-top: 5rem !important;
  }
}

.scroll-bottom {
  display: block;
  width: 25px;
  height: 50px;
  background: url(images/home/scroll-bottom.svg);
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, 0);
}

.scroll-bottom a {
  display: inline-block;
  width: 100%;
  height: 100%;
}

@media (max-height: 600px) {
  .scroll-bottom {
    display: none;
  }
}

.cart-height.section-analysis .box-item {
  height: 260px;
  min-height: 0;
}

.reset main footer ul li {
  padding-left: 0;
}

.reset section.home-slide {
  padding: 0;
}

.reset section.home-slide .col-md-6 {
  position: static;
}

.reset section.home-slide .slick-slide {
  height: 400px;
}

.reset section.home-slide .slider-block .slider-block-action {
  justify-content: start;
  margin-top: 0;
  position: absolute;
  top: 60px;
  z-index: 9;
}

.reset section.home-slide .slider-block .slider-block-action .slick-arrow {
  border: none;
  transition: none;
  width: 24px;
  height: 24px;
}

.reset section.home-slide .slider-block .slider-block-action .slick-arrow:hover {
  background: #0076C0 !important;
  padding: 6px 9px;
  color: #fff;
  transition: none;
}

.reset section.home-slide .slider-block .slider-block-action .slick-arrow:hover path {
  stroke: #fff;
}

.reset section.home-slide .slider-item {
  padding: 96px 0 40px 0;
}

.reset section.home-slide .slider-item .slider-item_img-bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.reset section.home-slide .slider-item .slider-item_img-bg img {
  height: 100%;
  width: 100%;
}

.reset section.home-slide .slider-item .slider-item_img {
  margin-top: 30px;
  position: static;
}

@media (min-width: 768px) {
  .reset section.home-slide .slider-item .slider-item_img {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.reset section.home-slide .slider-item .slider-item-content {
  position: relative;
}

.reset section.home-slide .slider-item.white .slider-block-info span,
.reset section.home-slide .slider-item.white .slider-item_title h2,
.reset section.home-slide .slider-item.white .slider-item_text p {
  color: #fff;
}

.reset section.home-slide.white-section-slider .slider-block-info {
  color: #fff;
}

.reset section.home-slide.white-section-slider .slider-block-info span {
  color: #fff;
}

.min-h-0 h3, .min-h-0 h4 {
  min-height: 0 !important;
}

.img-cover img {
  object-fit: cover;
  width: 100%;
}

.img-contain img {
  object-fit: contain;
}

.section-frontline-bg {
  background-position: 100% 0 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden;
}

.content-frontline-img {
  min-height: 700px;
  position: relative;
}

.content-frontline-img .content-img {
  position: absolute;
}

.content-frontline-img .content-img:nth-child(2n) {
  top: 66px;
  left: 236px;
  z-index: 9;
}

.content-frontline-img .content-img:nth-child(3n) {
  top: 130px;
  left: 514px;
  z-index: 99;
}

@media (max-width: 1140px) {
  .content-frontline-img {
    min-height: 0;
  }
  .content-frontline-img .content-img {
    position: static;
    margin-bottom: 24px;
  }
}

.front-line-list {
  font-size: 16px;
}

.front-line-list .card-line .inner-content {
  padding: 24px !important;
}

.front-line-list .icons {
  min-width: 21px;
  max-width: 21px;
  height: 21px;
}

.front-line-list .icons svg {
  height: 21px;
}

.front-line-list h4 {
  font-size: 16px !important;
}

span.blue {
  font-size: 16px;
  line-height: 24px;
  color: #0699F5;
  font-weight: 400;
}

.reset table.gray {
  border: none;
}

.reset table.gray tr {
  border-bottom: solid 1px rgba(11, 27, 37, 0.44);
}

.reset table.gray tr:nth-child(2n+ 1) {
  background: none !important;
}

.align-item-center {
  align-items: center !important;
}

.inner-content.small li {
  font-size: 16px;
}

.h-240 {
  min-height: 240px;
}

i.circle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
}

i.circle.blue {
  background-color: #0076C0;
}

i.circle.orange {
  background-color: #f26631;
}

.tabs-pci.no-wrap {
  flex-wrap: nowrap !important;
}

.tabs-pci.no-wrap .lc-item {
  width: 100% !important;
}

.tabs-pci.no-wrap .lc-item:not(.active) {
  border-bottom: solid 2px #ccc;
}

.tabs-pci.no-wrap .lc-item:not(.active) a:before {
  background: #ccc;
  height: 2px;
  width: 100%;
}

.number.second li {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.frontline-benefits .card-line .inner-content {
  min-height: 165px;
  display: block;
}

.frontline-benefits .card-line .inner-content p {
  font-size: 14px;
}

.section-pci-bg {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-color: #f7f7f7;
}

.section-pci-bg p {
  font-size: 23px !important;
  color: #000;
  margin-bottom: 20px;
}

.section-pci-list .row > div {
  display: flex;
  align-items: center;
}

.section-pci-list .row > div p {
  font-size: 20px;
}

.pci-izotop .lc-item .opener {
  padding: 12px !important;
}

.pci-banner {
  height: 420px !important;
  padding: 0 !important;
}

.pci-izotop.section-izotope .grid.height .card-line .inner-content {
  min-height: 195px;
}

.express-list .icon {
  margin-bottom: 27px;
}

.express-list .icon path {
  fill: #000;
}

.express-list .title h4 {
  margin: 0;
  font-size: 20px;
}

.express-list p {
  font-weight: lighter;
}

.express-list .link-arrow:hover i {
  margin-left: 0.5rem;
}

.express-item h3 {
  font-size: 36px !important;
}

.express-item h4 {
  font-size: 24px !important;
}

.section-pci-bg-2 p {
  font-size: 16px !important;
}

@media (max-width: 1640px) {
  .section-pci-bg-2 {
    background-position: 125% 0;
  }
  .section-pci-bg-2 .col-md-7 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (max-width: 1520px) {
  .section-pci-bg-2 .col-md-7 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 1320px) {
  .section-pci-bg-2 {
    background-position: 155% 0;
  }
}

@media (max-width: 1320px) and (max-width: 1220px) {
  .section-pci-bg-2 {
    background-position: 200% 0;
  }
}

@media (max-width: 1320px) and (max-width: 1140px) {
  .section-pci-bg-2 {
    background-position: 300% 0;
  }
}

@media (max-width: 1320px) and (max-width: 1080px) {
  .section-pci-bg-2 {
    background-position: 400% 0;
  }
}

@media (max-width: 1320px) and (max-width: 1050px) {
  .section-pci-bg-2 {
    background-image: none !important;
  }
  .section-pci-bg-2 .col-md-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.express-list {
  position: relative;
}

.row.rotate .link-arrow.close-item {
  display: none;
}

.row.rotate .link-arrow i {
  transform: rotate(90deg);
}

.row.collapse.show + .rotate .link-arrow {
  display: none;
}

.row.collapse.show + .rotate .link-arrow.close-item {
  display: inline-block;
}

.row.collapse.show + .rotate .link-arrow i {
  transform: rotate(-90deg);
}

@media (max-width: 1280px) {
  .reset .justify-space.info-wrap {
    margin-bottom: 20px !important;
  }
}

.reset .animate-responsive {
  display: none;
}

@media (max-width: 1280px) {
  .reset .animate-responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px !important;
  }
}

@media (max-width: 1280px) and (max-width: 720px) {
  .reset .animate-responsive {
    margin-top: 60px !important;
    font-size: 16px !important;
  }
}

@media (max-width: 1280px) {
  .reset .animate-responsive .icon {
    display: flex;
    height: auto;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 0;
  }
  .reset .animate-responsive .icon .animate {
    animation: animate_grow 2s infinite;
    transition: 1s;
  }
  @keyframes animate_grow {
    0% {
      transform: translate(0, 0);
      transition: 1s;
    }
    50% {
      transform: translate(-5px, 0px);
      transition: 1s;
    }
    100% {
      transform: translate(0px, 0px);
      transition: 1s;
    }
  }
}

@media (max-width: 720px) {
  .express-list .card-line h3 {
    font-size: 20px !important;
  }
}

.express-list .info-left {
  align-items: center;
}

.express-list .info-left h5 {
  margin: 0;
  font-size: 16px;
  line-height: 14px;
}

.express-list .info-left .icon {
  display: flex;
}

@media (max-width: 1280px) {
  .express-list .info-left {
    display: none !important;
  }
}

@media (max-width: 1280px) {
  .express-list .info-right {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 1280px) and (max-width: 530px) {
  .express-list .info-right .btn-default {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .tools-table {
    overflow: auto;
  }
}

.tools-table .tools-table-scroll {
  width: 1240px;
  position: relative;
}

.tools-table .tools-img {
  position: absolute;
  top: 95px;
  left: 325px;
}

.tools-table .row {
  margin: 0;
}

.tools-table .color {
  font-size: 10px;
  color: #fff;
  text-align: center;
  justify-content: space-between;
}

.tools-table .color > div {
  margin-right: 8px;
  padding: 4px 0;
}

.tools-table .color > div:last-child {
  margin-right: 0;
}

.tools-table .color .capture {
  background-color: #DF5E36;
}

.tools-table .color .view {
  background-color: #9F2857;
}

.tools-table .color .measure {
  background-color: #431E87;
}

.tools-table .color .math {
  background-color: #4153AF;
}

.tools-table .color .analyze {
  background-color: #93BF8B;
}

.tools-table .color .document {
  background-color: #7C1E4E;
}

.tools-table .capture {
  min-width: 128px;
  max-width: 128px;
}

.tools-table .view {
  min-width: 200px;
  max-width: 200px;
}

.tools-table .measure {
  min-width: 128px;
  max-width: 128px;
}

.tools-table .math {
  min-width: 128px;
  max-width: 128px;
}

.tools-table .analyze {
  min-width: 545px;
  max-width: 545px;
}

.tools-table .document {
  min-width: 60px;
  max-width: 60px;
}

.tools-table .sub-color {
  font-size: 8px;
  justify-content: space-between;
  padding: 4px 0;
}

.tools-table .sub-color > div {
  display: flex;
  justify-content: space-between;
  margin-right: 8px;
  padding: 0;
}

.tools-table .sub-color > div span {
  color: #000;
  font-weight: 500;
}

.tools-table .sub-color > div:last-child {
  margin: 0;
}

.tools-table .sub-color > div:last-child span {
  min-width: 60px;
  text-align: center;
}

.tools-table .content-color .row {
  justify-content: space-between;
}

.tools-table .content-color .item-left,
.tools-table .content-color .item-right {
  display: flex;
}

.tools-table .content-color .item-tools {
  background-color: #595A5C;
  width: 60px;
  margin-right: 8px;
  margin-bottom: 8px;
  transition: .5s;
  cursor: pointer;
  min-height: 75px;
  position: relative;
}

.tools-table .content-color .item-tools:hover {
  transition: .5s;
  transform: scale(1.2);
}

@media (max-width: 640px) {
  .tools-table .content-color .item-tools:hover {
    transform: none;
  }
}

.tools-table .content-color .item-tools:last-child {
  margin-right: 0;
}

.tools-table .content-color .item-tools.black {
  background-color: #000;
}

.tools-table .content-color .item-tools.black .item-tools_top {
  background-color: #000;
}

.tools-table .content-color .item-tools.grean {
  background-color: #245d38;
}

.tools-table .content-color .item-tools.grean .item-tools_top {
  background-color: #245d38;
}

.tools-table .content-color .item-tools.grean-2 {
  background-color: #6cc04a;
}

.tools-table .content-color .item-tools.grean-2 .item-tools_top {
  background-color: #6cc04a;
}

.tools-table .content-color .item-tools.ping {
  background-color: #b6008d;
}

.tools-table .content-color .item-tools.ping .item-tools_top {
  background-color: #b6008d;
}

.tools-table .content-color .item-tools.blue {
  background-color: #003b71;
}

.tools-table .content-color .item-tools.blue .item-tools_top {
  background-color: #003b71;
}

.tools-table .content-color .item-tools .item-tools_top {
  background: #DF5E36;
  height: 10px;
  display: flex;
  padding: 2px;
  justify-content: space-between;
  font-size: 8px;
  color: #fff;
  align-items: center;
}

.tools-table .content-color .item-tools .item-tools_top.document-color {
  background-color: #7C1E4E;
}

.tools-table .content-color .item-tools .item-tools_top.analyze-color {
  background-color: #93BF8B;
}

.tools-table .content-color .item-tools .item-tools_top.math-color {
  background-color: #4153AF;
}

.tools-table .content-color .item-tools .item-tools_top.measure-color {
  background-color: #431E87;
}

.tools-table .content-color .item-tools .item-tools_top.view-color {
  background-color: #9F2857;
}

.tools-table .content-color .item-tools .item-tools_top.black-color {
  background-color: #000;
}

.tools-table .content-color .item-tools .item-tools_top.black-color {
  background-color: #000;
}

.tools-table .content-color .item-tools .item-tools_top.ping-color {
  background-color: #b6008d;
}

.tools-table .content-color .item-tools .item-tools_img {
  padding: 8px 8px 0;
  display: flex;
}

.tools-table .content-color .item-tools .item-tools_img img {
  height: 24px !important;
  margin: 0 auto;
}

.tools-table .content-color .item-tools .item-tools_bottom {
  font-size: 8px;
  text-align: center;
  color: #fff;
  padding-bottom: 8px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.popup-color_item {
  width: 390px;
  padding: 24px;
  box-shadow: 0px 8px 17px 10px rgba(71, 71, 71, 0.08);
  position: fixed;
  right: 1px;
  bottom: 0;
  height: 100%;
  z-index: 9;
  background-color: #fff;
  transform: translate(490px, 0px);
  transition: 1s;
}

.popup-color_item.show {
  transform: translate(0, 0px);
  transition: 1s;
}

.popup-color_item .popup-color_wrap {
  overflow: auto;
  height: calc(100% - 50px);
}

@media (max-width: 520px) {
  .popup-color_item {
    width: 100%;
    border-radius: 24px 24px 0 0;
    right: 0;
    height: 75%;
    transform: translate(0, 150%);
  }
}

.popup-color_item .popup-color_close {
  text-align: right;
  margin-bottom: 24px;
}

.popup-color_item .popup-color_close i {
  cursor: pointer;
}

.popup-color_item .item-tools_top {
  height: 16px;
  background-color: #DF5E36;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  color: #fff;
}

.popup-color_item .item-tools_top .number {
  font-size: 10px;
}

.popup-color_item .item-tools_top.document-color {
  background-color: #7C1E4E;
}

.popup-color_item .item-tools_top.analyze-color {
  background-color: #93BF8B;
}

.popup-color_item .item-tools_top.math-color {
  background-color: #4153AF;
}

.popup-color_item .item-tools_top.measure-color {
  background-color: #431E87;
}

.popup-color_item .item-tools_top.view-color {
  background-color: #9F2857;
}

.popup-color_item .item-tools_top.black-color {
  background-color: #000;
}

.popup-color_item .item-tools_top.black-color {
  background-color: #000;
}

.popup-color_item .item-tools_top.ping-color, .popup-color_item .item-tools_top.disk-color {
  background-color: #b6008d;
}

.popup-color_item .item-tools_top.black-color {
  background-color: #000;
}

.popup-color_item .item-tools_top.measure-color {
  background-color: #431E87;
}

.popup-color_item .popup-color_img {
  margin-bottom: 16px;
  background-color: #595A5C;
  width: 100%;
  height: 200px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.popup-color_item .popup-color_img img {
  width: 250px;
  height: 150px;
  object-fit: contain;
}

.popup-color_item .popup-color_title {
  margin-bottom: 4px;
}

.popup-color_item .popup-color_category {
  margin-bottom: 16px;
  color: #596369;
  font-size: 12px;
  line-height: 16px;
}

.popup-color_item .popup-color_category a {
  text-decoration: underline;
}

.popup-color_item .popup-color_category a:hover {
  text-decoration: none;
}

.popup-color_item .popup-color_text p {
  font-size: 13px !important;
  color: #000;
}

.justify-center {
  display: flex;
  justify-content: center !important;
}

.h-auto {
  height: auto !important;
}

.section-training-filters.demand .line-none select {
  pointer-events: none;
  background: rgba(242, 242, 242, 0.5);
}

.section-training-filters.demand .calendar {
  display: none;
}

.breadcrumbs-product {
  margin: 32px 0;
}

.breadcrumbs-product ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.breadcrumbs-product ul li {
  padding: 0 8px !important;
}

.breadcrumbs-product ul li:first-child {
  padding-left: 0 !important;
}

.breadcrumbs-product ul li, .breadcrumbs-product ul a {
  color: #596369 !important;
  font-size: 14px;
}

.breadcrumbs-product + h3,
.breadcrumbs-product + h1 {
  margin-bottom: 24px !important;
  font-size: 32px !important;
  line-height: 40px !important;
}

@media (max-width: 768px) {
  .breadcrumbs-product + h3,
  .breadcrumbs-product + h1 {
    font-size: 28px !important;
  }
}

@media (max-width: 480px) {
  .breadcrumbs-product ul, .breadcrumbs-product li, .breadcrumbs-product a {
    font-size: 9px !important;
  }
}

section.section-product-slider .slider-for {
  margin-bottom: 64px;
}

section.section-product-slider .slider-for img {
  margin: 0 auto;
  width: 100%;
  object-fit: contain;
  height: 290px;
}

section.section-product-slider .slider-nav {
  margin-bottom: 40px;
}

section.section-product-slider .slider-nav .slick-list {
  padding: 0 !important;
}

section.section-product-slider .slider-nav .slick-slide {
  width: 78px !important;
  margin-right: 28px;
}

section.section-product-slider .slider-nav .slick-slide.slick-current {
  text-align: center;
  border-bottom: 2px solid #0076C0;
}

section.section-product-slider .slider-nav img {
  width: 58px;
  cursor: pointer;
  margin: 0 auto;
  height: 60px;
  object-fit: contain;
}

section.section-product-slider h5 {
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: 500;
}

@media (max-width: 480px) {
  section.section-product-slider h5 {
    font-size: 18px;
  }
}

section.section-product-slider .key-list span {
  color: #0076C0;
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  display: inline-block;
}

section.section-product-slider .key-list ul {
  margin-bottom: 32px;
}

section.section-product-slider .key-list ul li {
  margin-bottom: 4px;
}

section.section-product-slider .key-list .btn {
  width: 100%;
  max-width: 392px;
}

section.section-applications h5 {
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: 500;
}

section.section-applications .applications-list ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

section.section-applications .applications-list ul li {
  padding: 0;
  text-align: center;
  margin: 0 8px 16px 8px;
  min-width: 138px;
}

section.section-applications .applications-list ul li svg {
  height: 40px;
}

section.section-applications .applications-list ul li span {
  display: block;
  color: #0076C0;
  font-size: 16px;
  margin-top: 8px;
  font-weight: 500;
}

section.section-applications .applications-list ul li span:hover {
  color: #0067a7;
}

@media (max-width: 540px) {
  section.section-applications .applications-list ul {
    justify-content: left;
    margin: 0 -16px;
  }
  section.section-applications .applications-list ul li {
    margin: 0 0 15px 0 !important;
    width: 33.3%;
    padding: 0 12px;
  }
  section.section-applications .applications-list ul li span {
    font-size: 12px;
  }
}

section.section-applications .tab-hidden {
  position: absolute !important;
  left: -9999px !important;
  top: -9999px !important;
  display: block !important;
  width: 100% !important;
}

section.section-applications .block-view {
  width: 100%;
  border-bottom: solid 1px #F6F8F8;
  margin-bottom: 40px;
}

section.section-applications .block-view .lc-item a {
  font-size: 16px;
}

@media (max-width: 480px) {
  section.section-applications .block-view .lc-item {
    width: 100%;
    text-align: center;
  }
}

section.section-applications .tabs-content .row {
  margin-bottom: 64px;
}

section.section-applications .tabs-content h3 {
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  section.section-applications #tab-pci-1:not(.no-order) img {
    margin: 0 auto;
    margin-bottom: -16px;
  }
  section.section-applications #tab-pci-1:not(.no-order) .row {
    margin-bottom: 0px;
  }
  section.section-applications #tab-pci-1:not(.no-order) .row .col-md-6 {
    margin-bottom: 30px;
  }
  section.section-applications #tab-pci-1:not(.no-order) .row:nth-child(2n + 1) .col-md-6 {
    order: 1;
  }
  section.section-applications #tab-pci-1:not(.no-order) .row:nth-child(2n + 1) .col-md-6 + .col-md-6 {
    order: 0;
  }
}

section.section-applications .overflow-auto {
  overflow: visible !important;
}

section.section-applications .overflow-auto h5 {
  margin: 0 !important;
}

@media (max-width: 1000px) {
  section.section-applications .overflow-auto {
    overflow: auto !important;
  }
  section.section-applications .overflow-auto .specifications-list.head-left .row.head .gray span {
    padding: 0 2rem;
  }
  section.section-applications .overflow-auto .specifications-list h5,
  section.section-applications .overflow-auto .specifications-list .col-md-8 p {
    padding: 0 1rem;
  }
}

section.section-applications .overflow-auto .specifications-list .row {
  padding: 16px 0;
  margin-bottom: 0;
}

@media (max-width: 1000px) {
  section.section-applications .competitive-table {
    min-width: 1300px;
  }
}

section.section-applications .competitive-table .col-md-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

section.section-applications .competitive-table .col-md-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

section.section-applications .competitive-table .col-md-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

section.section-applications .competitive-table .row:not(.head) div:not(:first-child) span {
  padding-left: 20px;
}

section.section-applications .analyzer-content,
section.section-applications .option-content {
  max-width: 500px;
}

section.section-applications .analyzer-content h3,
section.section-applications .option-content h3 {
  color: red;
}

section.section-applications .analyzer-content h3 a,
section.section-applications .option-content h3 a {
  text-decoration: underline;
}

section.section-applications .analyzer-content h3 a:hover,
section.section-applications .option-content h3 a:hover {
  text-decoration: none;
}

section.section-applications .analyzer-content ul,
section.section-applications .option-content ul {
  list-style: unset;
  margin-left: 20px !important;
}

section.section-applications .analyzer-content img,
section.section-applications .option-content img {
  margin: 0 auto;
}

section.section-applications .option-content {
  max-width: 100%;
}

section.section-applications .software-content .options {
  margin: 16px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

section.section-applications .software-content .options .options-item {
  margin: 0 40px 24px 0;
  font-size: 14px;
}

section.section-applications .software-content .options .options-item span {
  font-weight: 500;
  color: #231F20;
}

section.section-applications .software-content .product-alert {
  margin-bottom: 24px;
  color: #231F20;
  font-weight: 400;
  display: flex;
  font-size: 14px;
}

@media (max-width: 740px) {
  section.section-applications .software-content .product-alert {
    margin-bottom: 24px;
  }
}

section.section-applications .software-content .btn-blue-outline:hover path {
  fill: #fff;
}

@media (max-width: 540px) {
  section.section-applications .software-content .btn-blue-outline {
    padding: 5px;
    font-size: 13px;
    width: 100%;
  }
}

section.section-applications .software-content .link {
  margin-top: 8px;
}

section.section-applications .software-content .link a {
  display: block;
  color: #0076C0;
  margin-bottom: 16px;
}

.opacity-none {
  opacity: 0;
}

@media (max-width: 768px) {
  .opacity-none {
    display: none;
  }
}

@media (max-width: 768px) {
  #tab-pci-1.first .row {
    margin-bottom: 0;
  }
  #tab-pci-1.first .row .col-md-6 {
    margin-bottom: 30px;
  }
}

.specifications-list ul li {
  color: #3D4448;
}

.specifications-list p {
  color: #3D4448;
}

.specifications-list .row {
  border-bottom: solid 1px #EAEDEE;
  padding: 24px 0;
  color: #3D4448;
}

.specifications-list .row:last-child {
  border-bottom: none;
}

.specifications-list .row.green-bg-row {
  padding: 0 !important;
}

.specifications-list .row div {
  display: flex;
  padding: 0 1rem;
  align-items: center;
}

.specifications-list .row div.text-center {
  justify-content: center;
}

.specifications-list .row div.green-color span {
  color: #4CAF50 !important;
}

.specifications-list .row div.green-bg {
  background: rgba(33, 150, 83, 0.08);
  padding: 24px 0;
}

.specifications-list .row.head {
  padding: 0 !important;
}

.specifications-list .row.head .gray {
  padding: 0;
  background: #F6F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
}

.specifications-list .row.head .gray span {
  color: #231F20;
  font-size: 16px;
  line-height: 128%;
  text-transform: uppercase;
  font-weight: 500;
}

.specifications-list h5 {
  font-size: 18px;
  margin: 0 0 8px 0;
}

.specifications-list.row-m-0 .row {
  margin-bottom: 0 !important;
}

.specifications-list.head-left .row.head .gray {
  justify-content: left;
}

.specifications-list.head-left .row.head .gray span {
  padding: 0 1rem;
}

.specifications-list.text-top .row div {
  align-items: normal;
}

.specifications-list.text-top .row div h4 {
  line-height: 1;
  margin: 0;
}

@media (max-width: 768px) {
  .specifications-list.text-top .row div h4 {
    margin: 0 0 16px 0;
  }
}

.mw-100 {
  max-width: 100% !important;
}

.buner-block {
  margin-top: 80px;
}

.buner-block .banner-slide-item {
  background-size: contain;
  background-repeat: no-repeat;
}

.buner-block .row {
  padding: 40px 106px 35px 106px;
}

.buner-block .sub-title {
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.buner-block h3 {
  font-size: 24px;
  margin-bottom: 8px;
  color: #231F20;
  font-weight: 400;
}

.buner-block h3 span {
  font-weight: 600;
}

.buner-block p {
  margin-bottom: 24px;
  color: #596369;
}

@media (max-width: 540px) {
  .buner-block p {
    display: none;
  }
}

.buner-block .link-arrow {
  font-size: 16px;
}

@media (max-width: 768px) {
  .buner-block .row {
    padding: 40px 24px;
  }
  .buner-block .row .text-center {
    margin-top: 20px;
  }
  .buner-block .row h3 span {
    display: block;
  }
}

@media (max-width: 768px) {
  .no-br-mob br {
    display: none;
  }
}

@media (width: 992px) {
  .big-width {
    max-width: 80%;
  }
}

#accordion .card {
  padding: 24px 0;
  border-bottom: solid 1px #EAEDEE;
  background: none;
  position: relative;
}

@media (max-width: 321px) {
  #accordion .card {
    padding: 4px 0;
  }
  #accordion .card .accordion-arrow {
    top: 16px !important;
  }
}

#accordion .card:last-child {
  border-bottom: none;
}

#accordion .card:last-child .row {
  padding-bottom: 0;
}

#accordion .card:last-child .row .card-body {
  margin-top: -70px;
}

#accordion .card:first-child .show .card-body {
  margin-top: -104px;
}

#accordion .card:first-child .show .card-body.top {
  margin-top: -75px;
}

#accordion .card.accordion-top {
  padding: 0;
  border-bottom: solid 2px #EAEDEE;
}

#accordion .card .collapse {
  padding: 0;
}

#accordion .card .collapse.show .row {
  margin: 0;
}

#accordion .open {
  display: none;
}

#accordion .open.collapsed {
  display: block;
}

#accordion .open.collapsed + .accordion-close {
  display: none;
}

#accordion .specifications-list .row div {
  align-items: flex-start;
}

#accordion .card-header {
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

#accordion .card-header h4 {
  font-size: 20px;
}

#accordion .card-header h4 + svg {
  width: 14px;
  height: 14px;
}

#accordion .row {
  border-bottom: none;
  padding: 12px 0;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  #accordion .row {
    padding: 0;
  }
}

@media (width: 768px) {
  #accordion .row [class^="col"] {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

#accordion .card .accordion-arrow {
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
}

@media (max-width: 768px) {
  #accordion .card .accordion-arrow {
    right: 0;
  }
}

@media (max-width: 320px) {
  #accordion .card .card-header h4 {
    font-size: 16px;
    padding-right: 20px;
  }
  #accordion .card .specifications-list h5 {
    font-size: 15px;
  }
}

#accordion .show {
  padding: 0;
}

#accordion .card-body {
  padding: 32px 0 0 0;
}

@media (max-width: 768px) {
  #accordion .card-body {
    padding: 12px 0 0 0;
  }
}

.min-w-100 {
  min-width: 100% !important;
}

.reset .section-sticky__container1 .banner-slide-item {
  background-position: center;
}

.reset .section-black .banner-slide-item {
  padding: 80px 0 96px 0;
  color: #171717;
  background-repeat: no-repeat;
  background-color: #F6F8F8;
}

.reset .section-black .banner-slide-item .sub-title {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  color: #C2C2C2;
}

.reset .section-black .banner-slide-item h2 {
  color: #171717;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 32px;
}

.reset .section-black .banner-slide-item .black-list {
  display: flex;
  flex-wrap: wrap;
}

.reset .section-black .banner-slide-item .black-list h4 {
  color: #171717;
  font-size: 20px;
  margin-bottom: 8px;
}

.reset .section-black .banner-slide-item .black-list span {
  color: #3D4448;
  font-size: 16px;
}

.reset .section-black .banner-slide-item .black-list .black-list-item {
  margin-right: 60px;
}

.reset .section-black .banner-slide-item .black-list .black-list-item:last-child {
  margin-right: 0;
}

.reset .section-black .banner-slide-item .black-img {
  text-align: center;
}

.reset .section-black {
  position: sticky;
  top: 80px;
  transition: 3s;
}

.reset .section-black + .section-default-page {
  background: #fff;
  z-index: 9999;
  position: relative;
}

.reset .section-black img {
  transition: 1s;
}

.reset .section-black.small-img img {
  transform: scale(0.98);
  transition: 1s;
}

.reset .section-black.small-img2 img {
  transform: scale(0.99);
  transition: 1s;
}

.reset .section-black.small-img3 img {
  transform: scale(0.98);
  transition: 1s;
}

.reset .section-black.small-img32 img {
  transform: scale(0.97);
  transition: 1s;
}

.reset .section-black.small-img4 img {
  transform: scale(0.96);
  transition: 1s;
}

.reset .section-black.small-img5 img {
  transform: scale(0.95);
  transition: 1s;
}

.reset .section-black.small-img6 .section-content {
  opacity: 0.5;
}

.reset .section-black.small-img6 .section-content img {
  transform: scale(0.94);
  transition: 1s;
}

.reset .section-black.small-img7 .section-content {
  opacity: 0;
}

.reset .section-black.small-img7 .section-content img {
  transform: scale(0.93);
  transition: 1s;
}

.reset .section-black.small-img8 .section-content {
  opacity: 0;
}

.reset .section-black.small-img8 .section-content img {
  transform: scale(0.92);
  transition: 1s;
}

.reset .title-h3 {
  font-size: 24px;
  font-weight: 500;
}

.reset .section-black-link {
  padding-top: 80px !important;
}

.reset .black-link {
  margin-bottom: 80px;
}

.reset .black-link ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.reset .black-link ul li {
  margin: 0;
  padding: 0 75px;
  color: #0076C0;
  border-right: solid 1px #D6D6D6;
  text-align: center;
  line-height: 48px;
  font-weight: 500;
  font-size: 16px;
}

@media (width: 992px) {
  .reset .black-link ul li {
    padding: 0 68px;
  }
}

@media (max-width: 992px) {
  .reset .black-link ul li {
    padding: 0 32px;
  }
}

.reset .black-link ul li:first-child {
  padding-left: 0 !important;
}

.reset .black-link ul li:last-child {
  padding-right: 0 !important;
}

.reset .black-link ul li i {
  margin-right: 8px;
}

.reset .black-link ul li a:hover {
  color: #0699F5;
}

.reset .black-link ul li a:hover path {
  fill: #0699F5;
}

.reset .black-link ul li:first-child {
  text-align: left;
}

.reset .black-link ul li:last-child {
  text-align: right;
  border: none;
}

.reset .black-text h4 {
  font-size: 24px;
  color: #171717;
}

.reset .black-text p {
  font-size: 16px;
  color: #3D4448;
  line-height: 32px;
}

.reset .list-items {
  justify-content: center;
}

.reset .list-items li {
  text-transform: uppercase;
  margin-right: 24px;
}

.reset .black-title-bg {
  color: #171717;
  font-size: 40px;
  font-weight: 500;
  position: relative;
  width: 286px;
}

.reset .black-title-bg span {
  position: relative;
  z-index: 99;
  left: 110px;
}

.reset .black-title-bg i {
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 768px) {
  .reset .black-title-bg {
    margin-bottom: 50px;
  }
}

.reset .section-black-half {
  background-color: #F6F8F8;
  position: relative;
  overflow: hidden;
}

.reset .section-black-half .bg {
  position: absolute;
  left: 50%;
  top: 0;
  width: 1920px;
  height: 403px;
  transform: translate(-50%, 0);
}

.reset .section-black-half .title {
  color: #171717;
  font-size: 28px;
  margin-bottom: 16px;
  font-weight: bold;
}

.reset .section-black-half p {
  color: #3D4448;
  font-size: 16px;
  line-height: 32px;
  font-style: normal;
  font-weight: normal;
}

.reset .section-black-half .section-black-bg-white {
  padding-left: 100px;
}

.reset .section-black-half .section-black-bg-white a {
  color: #171717;
}

.reset .section-black-half .section-black-bg-black {
  padding-right: 100px;
}

.reset .section-black-half .section-black-bg-black .title,
.reset .section-black-half .section-black-bg-black p {
  color: #FFFFFF;
  position: relative;
}

.reset .section-black-half .section-black-bg-black a {
  color: #fff;
}

.reset .section-black-half .section-black-bg-black p {
  color: #E5E5E5;
}

@media (max-width: 1024px) {
  .reset .section-black-half {
    height: auto;
  }
  .reset .section-black-half .section-black-bg-white,
  .reset .section-black-half .section-black-bg-black {
    padding: 0;
  }
  .reset .section-black-half .bg {
    display: none;
  }
  .reset .section-black-half .section-black-bg-black {
    background-color: #2B2F2F;
  }
}

@media (max-width: 768px) {
  .reset .section-black-half {
    padding-bottom: 0 !important;
  }
  .reset .section-black-half::before {
    display: none;
  }
  .reset .section-black-half .section-black-bg-black {
    position: relative;
    padding: 20px 0;
  }
  .reset .section-black-half .section-black-bg-black::before {
    position: absolute;
    left: -100px;
    width: 200%;
    background-color: #2B2F2F;
    content: "";
    display: block;
    height: 100%;
    top: 0;
    z-index: 0;
  }
}

.reset #tab-pci-12 .card-header,
.reset #tab-pci-13 .card-header,
.reset #tab-pci-14 .card-header,
.reset #tab-pci-15 .card-header,
.reset #tab-pci-52 .card-header {
  padding: 0;
}

.reset .section-black-top {
  position: sticky;
  top: 0;
  padding: 24px 0 !important;
  z-index: 999;
  background: #fff;
}

.reset .section-black-top.site-header_animation {
  box-shadow: inset 0px -1px 0px #E2E4E6;
}

.reset .section-black-top.border-bottom {
  box-shadow: inset 0px -1px 0px #E2E4E6;
}

.reset .section-black-top .section-black-top_right {
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .reset .section-black-top .section-black-top_right {
    justify-content: center;
  }
}

.reset .section-black-top .tabs-wrap {
  margin-right: 16px;
}

.reset .section-black-top .tabs-wrap .tabs-pci {
  border: none;
}

.reset .section-black-top .tabs-wrap ul {
  margin: 0;
  display: flex;
}

.reset .section-black-top .tabs-wrap ul li {
  margin: 0;
  color: #62717A;
  font-size: 14px;
}

.reset .section-black-top .tabs-wrap ul li a {
  color: #171717 !important;
}

.reset #text-animate .black-text {
  margin: 2rem 0;
  font-size: 3rem;
}

.reset #text-animate .black-text h4 {
  opacity: 0;
  transform: translateY(12px);
}

.reset #text-animate .black-text p {
  opacity: 0;
  transform: translateY(12px);
}

.reset #text-animate .black-text .black-text-img {
  opacity: 0;
  transform: translateY(12px);
}

.reset #text-animate .black-text h4.black-text_visible6 {
  opacity: 0.5;
  transition: all 0.1s;
  transform: translateY(10px);
}

.reset #text-animate .black-text p.black-text_visible6 {
  opacity: 0.5;
  transition: all 0.1s;
  transform: translateY(10px);
}

.reset #text-animate .black-text p.black-text_visible6 + .black-text-img {
  opacity: 0.5;
  transition: all 0.1s;
  transform: translateY(10px);
}

.reset #text-animate .black-text h4.black-text_visible5 {
  opacity: 0.5;
  transform: translateY(8px);
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible5 {
  opacity: 0.5;
  transform: translateY(8px);
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible5 + .black-text-img {
  opacity: 0.5;
  transform: translateY(8px);
  transition: all 0.1s;
}

.reset #text-animate .black-text h4.black-text_visible4 {
  opacity: 1;
  transform: translateY(6px);
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible4 {
  opacity: 1;
  transform: translateY(6px);
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible4 + .black-text-img {
  opacity: 1;
  transform: translateY(6px);
  transition: all 0.1s;
}

.reset #text-animate .black-text h4.black-text_visible3 {
  opacity: 1;
  transform: translateY(4px);
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible3 {
  opacity: 1;
  transform: translateY(4px);
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible3 + .black-text-img {
  opacity: 1;
  transform: translateY(4px);
  transition: all 0.1s;
}

.reset #text-animate .black-text h4.black-text_visible2 {
  opacity: 1;
  transform: translateY(2px);
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible2 {
  opacity: 1;
  transform: translateY(2px);
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible2 + .black-text-img {
  opacity: 1;
  transform: translateY(2px);
  transition: all 0.1s;
}

.reset #text-animate .black-text h4.black-text_visible {
  opacity: 1;
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible {
  opacity: 1;
  transition: all 0.1s;
}

.reset #text-animate .black-text p.black-text_visible + .black-text-img {
  opacity: 1;
  transition: all 0.1s;
}

.new-style section.section-default-page {
  padding: 80px 0;
}

.new-style .breadcrumbs-product {
  margin: 24px 0 0 0;
}

@media (max-width: 768px) {
  .new-style .breadcrumbs-product {
    margin: 8px 0;
  }
}

.new-style .breadcrumbs-product ul li {
  padding: 0 !important;
  margin: 0 4px 0 0;
  display: flex;
  align-items: center;
}

.new-style .breadcrumbs-product ul li a {
  margin-right: 4px;
}

.new-style .section-heading h2 {
  font-weight: 500;
}

.new-style .btn.btn-default {
  padding: 8px 24px;
  line-height: 170%;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.new-style .btn.btn-default.small {
  padding: 6px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
}

.new-style .breadcrumbs-product ul a {
  margin-right: 17px;
  color: #7D7D7D;
}

.new-style .block-view.big-tab .acc-pci {
  border-bottom: none;
}

.new-style .block-view.big-tab .acc-pci .lc-item {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 100%;
}

.new-style .block-view.big-tab .acc-pci .lc-item a {
  padding: 0 0 0 32px;
  height: 144px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F6F8F8 !important;
  border-radius: 2px;
  color: #171717 !important;
  font-weight: bold;
  font-size: 20px;
  border: 2px solid #F6F8F8;
  transition: .5s;
}

.new-style .block-view.big-tab .acc-pci .lc-item a::before {
  display: none;
}

.new-style .block-view.big-tab .acc-pci .lc-item.active a {
  background: #fff !important;
  border: 2px solid #0076C0;
  color: #171717;
  transition: .5s;
}

.new-style .card-line {
  border: none;
  box-shadow: none;
}

.new-style .card-line .card-img-bg {
  background: #F6F8F8;
  border-radius: 2px;
}

.new-style .card-line .sub-title {
  text-transform: uppercase;
  color: #858585;
  font-weight: 500;
  font-size: 16px;
}

.new-style .card-line .title {
  font-weight: bold;
  font-size: 24px;
}

.new-style .card-line div.inner-content.small {
  padding: 32px 24px;
}

.new-style .card-line .inner-img.big {
  height: 180px !important;
  margin-bottom: 90px;
}

.new-style .card-line .list-characteristics {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 24px;
}

.new-style .card-line .list-characteristics li {
  margin-bottom: 24px;
}

.new-style .card-line .list-characteristics li span:first-child {
  text-transform: uppercase;
  width: 190px;
  display: inline-block;
}

.new-style .card-line .list-characteristics li:last-child {
  margin-bottom: 0;
}

.new-style .card-line .card-bottom {
  opacity: 0;
}

.new-style .card-line:hover {
  box-shadow: 0px 0px 32px rgba(191, 191, 191, 0.36);
}

.new-style .card-line:hover .card-bottom {
  opacity: 1;
}

.new-style .card-gray .row {
  margin: 0 -12px;
}

.new-style .card-gray .row > div {
  padding: 0 12px;
  margin-bottom: 40px;
}

.new-style .card-gray .card-line:hover {
  box-shadow: none;
}

.new-style .card-gray .card-line:hover .inner-img {
  -webkit-transform: scale(0.99);
  transform: scale(0.99);
  transition: 1s;
}

.new-style .card-gray .card-gray_top {
  padding: 40px 24px 0 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}

.new-style .card-gray .card-gray_top .sub-title {
  color: #7D7D7D;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  font-variant: small-caps;
  margin-bottom: 4px;
  text-transform: none;
}

.new-style .card-gray .card-gray_top .title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  margin: 0 !important;
}

@media (max-width: 320px) {
  .new-style .card-gray .card-gray_top .title a span {
    display: none;
  }
}

.new-style .card-gray .card-gray_top .comparison-icon {
  opacity: 0;
}

.new-style .card-gray .inner-img {
  margin-bottom: 0 !important;
  transform: scale(0.9);
  transition: 1s;
}

@media (max-width: 540px) {
  .new-style .card-gray .inner-img {
    transform: scale(0.8) !important;
  }
}

.new-style .card-gray .inner-content {
  padding: 24px 24px 40px 24px !important;
  background: #F6F8F8;
}

.new-style .card-gray .card-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  text-align: center;
  color: #000;
}

.new-style .card-gray .card-options .card-options_item {
  margin-right: 24px;
}

.new-style .card-gray .card-options .card-options_item:last-child {
  margin-right: 0 !important;
}

.new-style .card-gray .card-options.small-line .card-options_item {
  margin-right: 48px !important;
}

.new-style .card-gray .card-options.small-line .card-options_item:last-child {
  margin-right: 0 !important;
}

.new-style .card-gray .card-options-icon {
  margin-bottom: 4px;
}

.new-style .card-gray .card-options-icon svg {
  min-width: 40px;
  min-height: 40px;
}

.new-style .card-gray .card-options-number {
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.new-style .card-gray .card-options-type {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-variant: small-caps;
}

.new-style .section-categories {
  background: #F6F8F8;
  max-height: 0px;
  overflow: hidden;
  transition: 1s;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #E2E4E6;
}

.new-style .section-categories.open {
  max-height: 999px;
  transition: 1s;
  border-color: #E2E4E6;
  margin-bottom: 32px;
}

.new-style .block-filters-result {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  height: 0;
  overflow: hidden;
}

.new-style .block-filters-result.active {
  padding: 0 0 34px 0;
  height: auto;
}

.new-style .block-filters-result .result__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-right: 24px;
}

.new-style .block-filters-result .list {
  display: flex;
  flex-wrap: wrap;
  align-items: self-end;
}

.new-style .block-filters-result .list .list__item {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  margin-right: 24px;
  display: flex;
  align-items: center;
}

.new-style .block-filters-result .list .list__item .list__text {
  margin-right: 6px;
}

.new-style .block-filters-result .list .list__item svg {
  cursor: pointer;
}

.new-style .block-filters-result .result__clear .clear__text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #0076C0;
  cursor: pointer;
}

.new-style .block-filters-2 .block-filters-top {
  align-items: center;
  padding: 32px 0;
}

.new-style .block-filters-2 .block-filters-top .btn-blue-outline:hover svg path {
  fill: #fff;
}

.new-style .block-filters-2 .block-filters-top .close-text {
  display: none;
}

.new-style .block-filters-2 .block-filters-top .open .close-text {
  display: inline-block;
}

.new-style .block-filters-2 .block-filters-top .open .show-text {
  display: none;
}

.new-style .block-filters-2 .block-filters-top svg {
  margin-right: 4px;
}

.new-style .block-filters-2 .link-comparison {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #008ecd;
  opacity: 0;
}

.new-style .block-filters-2 .link-comparison svg {
  margin-right: 8px;
}

.new-style .block-filters-2 .block-filters-content {
  display: flex;
}

.new-style .block-filters-2 .block-filters-content .block-filters-column {
  width: 25%;
  padding: 40px 0;
}

.new-style .block-filters-2 .block-filters-content h4 {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

@media (max-width: 576px) {
  .new-style .block-filters-2 .block-filters-content h4 {
    margin-top: 16px;
  }
}

.new-style .block-filters-2 .block-filters-content li {
  padding: 0;
  margin: 0 0 16px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.new-style .block-filters-2 .block-filters-content label {
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #171717;
}

.new-style .block-filters-2 .block-filters-content label span.all {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  font-variant: small-caps;
  color: #7D7D7D;
  margin-left: 4px;
}

.new-style .block-filters-2 .block-filters-content input {
  margin-right: 8px;
}

.new-style .block-filters-2 .block-filters-content input:disabled + span {
  color: #D7D7D7;
  cursor: context-menu;
}

.new-style .small-fonts {
  margin: 120px 0;
}

@media (max-width: 768px) {
  .new-style .small-fonts {
    margin: 48px 0;
  }
}

.new-style .small-fonts .container-bg {
  padding: 0 40px 0 100px !important;
}

.new-style .small-fonts.pci-small .container .pci-small-row .pci-small-title {
  margin-right: 40px;
}

.new-style .small-fonts.pci-small .container .pci-small-row .pci-small-title h3 {
  font-size: 28px;
}

.new-style .small-fonts.pci-small .container .pci-small-row .pci-small-text p {
  font-size: 14px;
}

.new-style .small-fonts .btn-white {
  padding: 8px 38px;
}

.new-style .small-express {
  padding-bottom: 120px;
}

.new-style .small-express .section-heading {
  margin-bottom: 64px;
}

.new-style .small-express .row {
  margin: -12px;
}

.new-style .small-express .row > div {
  padding: 0 12px;
}

.new-style .small-express.express-list .col-bg .title h4 {
  font-size: 20px;
}

.new-style .small-express p, .new-style .small-express .link-arrow {
  font-size: 13px !important;
}

.new-style .express-list .icon {
  margin-bottom: 27px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 320px) {
  .new-style .express-list .icon svg {
    width: 32px;
    height: 32px;
  }
}

.new-style .express-list h2 {
  font-size: 32px;
  margin-bottom: 40px;
}

@media (max-width: 320px) {
  .new-style .express-list h2 {
    margin-bottom: 16px;
  }
}

.new-style .express-list .col-bg {
  background: #F6F8F8;
  border-radius: 2px;
  padding: 64px 24px 40px 24px;
  height: 100%;
}

.new-style .express-list .col-bg .title h4 {
  font-size: 24px;
}

.new-style .pci-express {
  background-position: center;
  padding: 120px 0;
}

@media (max-width: 768px) {
  .new-style .pci-express {
    padding: 40px 0;
  }
}

.new-style .pci-express .col-md-9 {
  max-width: 712px;
}

.new-style .pci-express h2 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 8px;
  font-weight: 500;
}

.new-style .pci-express p {
  font-size: 16px;
  color: #E1E1E1;
  font-weight: lighter;
}

@media (max-width: 320px) {
  .new-style .pci-express p {
    font-size: 12px !important;
  }
}

.new-style .pci-small .container-bg {
  height: 150px;
  padding: 0 106px;
  display: flex;
  align-items: center;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
}

.new-style .pci-small .container-bg .pci-small-row {
  display: flex;
  align-items: center;
}

.new-style .pci-small .container-bg .pci-small-row.list {
  justify-content: space-between;
  width: 100%;
  padding: 0 50px 0 110px;
}

.new-style .pci-small .container-bg .pci-small-row.list br {
  display: block !important;
}

.new-style .pci-small .container-bg .pci-small-row .pci-small-title {
  margin-right: 43px;
  display: flex;
  align-items: center;
}

.new-style .pci-small .container-bg .pci-small-row .pci-small-title h3 {
  color: #fff;
  margin: 0;
  font-size: 32px;
}

.new-style .pci-small .container-bg .pci-small-row .pci-small-text {
  margin-right: 85px;
  max-width: 495px;
}

.new-style .pci-small .container-bg .pci-small-row .pci-small-text p {
  color: #F3F3F3;
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
}

.new-style .section-text-animate {
  position: relative;
  z-index: 999;
  background-color: #fff;
}

.new-style .section-black-top_wrap {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-style .section-black-top_wrap .section-black-top_left {
  display: flex;
  align-items: center;
}

@media (width: 992px) {
  .new-style .section-black-top_wrap .section-black-top_left {
    max-width: 50%;
  }
}

.new-style .section-black-top_wrap .section-black-top_left h3, .new-style .section-black-top_wrap .section-black-top_left h1 {
  margin: 0;
  font-weight: 500;
  font-style: normal;
}

@media (width: 768px) {
  .new-style .section-black-top_wrap .section-black-top_left h3, .new-style .section-black-top_wrap .section-black-top_left h1 {
    max-width: 75%;
  }
}

@media (width: 576px) {
  .new-style .section-black-top_wrap .section-black-top_left h3, .new-style .section-black-top_wrap .section-black-top_left h1 {
    max-width: 50%;
  }
}

.new-style .section-black-top_wrap .section-black-top_left h3 br, .new-style .section-black-top_wrap .section-black-top_left h1 br {
  display: none;
}

.new-style .section-black-top_wrap .tabs-wrap .tabs-pci {
  display: flex;
  justify-content: space-around;
}

.new-style .section-black-top_wrap .tabs-wrap .lc-item {
  padding: 0;
}

.new-style .section-black-top_wrap .tabs-wrap .lc-item.active a {
  color: #7D7D7D !important;
  font-weight: 500;
}

.new-style .section-black-top_wrap .tabs-wrap .lc-item a:hover {
  color: #7D7D7D !important;
  font-weight: 500;
}

.new-style .section-black-top_wrap .tabs-wrap a.opener,
.new-style .section-black-top_wrap .tabs-wrap span.opener {
  padding: 8px 16px;
  background: none;
  color: #171717 !important;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .new-style .section-black-top_wrap .tabs-wrap a.opener,
  .new-style .section-black-top_wrap .tabs-wrap span.opener {
    padding: 8px 12px;
  }
}

@media (max-width: 320px) {
  .new-style .section-black-top_wrap .tabs-wrap a.opener,
  .new-style .section-black-top_wrap .tabs-wrap span.opener {
    padding: 8px;
    font-size: 12px;
  }
}

.new-style .section-black-top_wrap .tabs-wrap .anchor-active a,
.new-style .section-black-top_wrap .tabs-wrap .anchor-active span {
  color: #7D7D7D !important;
}

.new-style .section-black-top_wrap .tabs-wrap + .btn-default:hover {
  background-color: #0699F5;
  border-color: #0699F5;
}

.new-style .section-black-top_wrap .tabs-wrap + .btn-default.next {
  background: none;
  position: relative;
  border-color: #0076C0;
  border-right: solid 2px #0076C0;
}

.new-style .section-black-top_wrap .tabs-wrap + .btn-default.next:before {
  position: absolute;
  content: '';
  display: block;
  left: 0;
  top: 0;
  right: -2px;
  bottom: 0;
  width: calc(100% + 1px);
  height: 100%;
  transform: scale(1, 1);
  transform-origin: right center;
  z-index: -1;
  background-color: #0076C0 !important;
  transition: .3s;
}

.new-style .section-black-top_wrap .tabs-wrap + .btn-default.next:hover {
  color: #0167A7;
}

.new-style .section-black-top_wrap .tabs-wrap + .btn-default.next:hover:before {
  transform: scale(0, 1);
  transition: .3s;
}

.new-style .list-items-link {
  background-repeat: no-repeat;
  background-position: center;
}

.new-style .list-items-link li {
  text-transform: uppercase;
  margin-right: 31px;
  position: relative;
  top: 10px;
}

.new-style .list-items-link li:last-child {
  margin-right: 0;
}

.new-style .list-items-link li span {
  font-size: 18px;
  background: none !important;
  height: auto !important;
  padding: 0 !important;
}

.new-style .banner-small {
  padding: 80px 0 !important;
  z-index: 99;
  position: relative;
  background-position: center;
  background-size: cover;
}

.new-style .banner-small h2 {
  color: #fff;
  font-size: 28px;
}

.new-style .banner-small p {
  color: #D7D7D7;
  font-size: 16px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: lighter;
}

.new-style .banner-small img {
  height: 315px;
}

.new-style .banner-small .banner-small-link {
  color: #fff;
  font-size: 14px;
  margin-bottom: 16px;
}

.new-style .banner-small .banner-small-link a {
  margin-right: 4px;
  letter-spacing: 0.5px;
}

.new-style .banner-small .banner-small-link svg {
  width: 18px;
  height: 18px;
}

.new-style #accordion .card-header {
  display: block;
}

.new-style #accordion .card-body {
  transition: .5s;
  opacity: 0;
}

.new-style #accordion .show .card-body {
  transition: .5s;
  margin-top: -75px;
  opacity: 1;
  padding-right: 120px;
}

@media (max-width: 768px) {
  .new-style #accordion .show .card-body {
    margin-top: 0 !important;
    padding-right: 0;
  }
  .new-style #accordion .show .card-body .specifications-list li {
    padding-left: 0;
  }
}

.new-style #accordion .card .accordion-arrow {
  top: 40px;
  z-index: 99;
}

.new-style #accordion .specifications-list_block {
  margin-bottom: 24px;
}

.new-style #accordion .specifications-list_block h4 {
  font-size: 16px;
  margin-bottom: 8px;
}

.new-style #accordion .specifications-list_block p {
  color: #3D4448;
  font-size: 16px;
}

@media (max-width: 320px) {
  .new-style #accordion .specifications-list_block p {
    font-size: 14px;
  }
}

.new-style #accordion .specifications-list_block.link a {
  display: block;
  color: #0076C0;
}

.new-style #accordion .specifications-list_block.link a:hover {
  color: #0699F5;
}

.new-style #accordion.options .card {
  padding: 32px 0;
}

.new-style #accordion.options .card h4 {
  margin-bottom: 8px;
}

.new-style #accordion.options .card h5 {
  color: #3D4448;
  font-weight: 500;
  font-size: 16px;
  line-height: 152%;
  margin-bottom: 32px;
  font-variant: small-caps;
}

@media (max-width: 575px) {
  .new-style #accordion.options .card h5 {
    display: none;
  }
}

.new-style #accordion.options .card a {
  color: #0076C0;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
}

.new-style #accordion.options .card a:hover {
  color: #0699F5;
}

.new-style #accordion.options .card a:hover path {
  fill: #0699F5;
}

.new-style #accordion.options .card a svg {
  margin-left: 6px;
}

.new-style #accordion.options .card a i {
  top: 8px;
}

.new-style #accordion.options .card p {
  color: #3D4448;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
}

@media (max-width: 575px) {
  .new-style #accordion.options .card p {
    margin-top: 16px !important;
  }
}

.new-style #accordion.comparison .comparison-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 2px #D7D7D7;
  margin-bottom: 24px;
}

.new-style #accordion.comparison .comparison-options h3 {
  font-size: 22px;
}

.new-style #accordion.comparison .comparison-top {
  margin-bottom: 40px;
}

.new-style #accordion.comparison .card-title h4 {
  font-size: 18px;
}

.new-style #accordion.comparison .comparison-body {
  margin-bottom: 40px;
}

.new-style #accordion.comparison .comparison-body > div {
  text-align: center;
}

.new-style #accordion.comparison .comparison-body > div span {
  font-size: 18px;
  color: #3D4448;
}

.new-style #tab-pci-3 #accordion .card-header h4 {
  font-size: 20px;
  margin-bottom: 4px;
}

@media (max-width: 768px) {
  .new-style #tab-pci-3 #accordion .card-header h4 {
    font-size: 16px;
  }
}

.new-style #tab-pci-3 #accordion .row {
  padding: 0;
}

.new-style #tab-pci-3 .jcf-select {
  border: none;
}

.new-style .banner-small-black {
  background: #2B2F2F;
  padding: 80px 0;
  background-position: center;
  position: relative;
  z-index: 99;
}

.new-style .banner-small-black p {
  font-size: 24px !important;
  line-height: 184%;
  text-align: center;
  color: #fff;
  font-weight: 400;
}

.new-style .banner-small-black b {
  font-size: 28px;
  font-weight: bold;
}

.new-style .tabs-pci {
  border-bottom: solid 2px #D7D7D7;
}

.new-style .tabs-pci.no-border {
  border: none;
}

.new-style .tabs-pci .lc-item a {
  color: #171717 !important;
  font-weight: 500 !important;
}

.new-style .tabs-pci .lc-item.active a {
  color: #8F8F8F !important;
  font-weight: 500 !important;
}

.new-style .pt-80 {
  padding-top: 80px !important;
}

.new-style .pt-120 {
  padding-top: 120px !important;
}

@media (max-width: 768px) {
  .new-style .pt-120 {
    padding-top: 48px !important;
  }
}

.new-style .section-filters-section_top .block-filters-top .close-filter-button + .block-filters-result.no-open {
  display: block !important;
}

.new-style .section-filters-section_top .block-filters-top .close-filter-button + .block-filters-result.no-open.open {
  display: none !important;
}

.new-style .block-filters .form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-style .block-filters .form .clear-filters {
  color: #0076BF !important;
  margin-bottom: 16px !important;
}

.new-style .block-filters .filter-item {
  margin-right: 25px;
  min-width: 200px;
  background: #F6F8F8;
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 2px;
}

.new-style .block-filters .filter-item:hover {
  border: 1px solid #0076BF;
}

.new-style .block-filters .filter-item:last-child {
  margin-right: 0;
}

.new-style .jcf-select-text {
  line-height: 48px !important;
  padding-left: 20px !important;
}

.new-style .jcf-select-text span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #171717;
}

.new-style .jcf-select {
  background: none;
  border: none;
  width: 100%;
}

.new-style .jcf-select.jcf-drop-active {
  border: 2px solid #0076BF;
}

.new-style .jcf-select .jcf-focus {
  border: 1px solid #0076BF;
}

.new-style .jcf-select .jcf-select-opener {
  height: 48px !important;
}

.new-style .jcf-select .jcf-select-opener:after {
  position: relative;
  bottom: 3px;
  border-right: 2px solid #171717 !important;
  border-top: 2px solid #171717 !important;
  position: relative;
  bottom: 2px;
}

.new-style .jcf-select .jcf-optgroup-caption {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #7D7D7D;
  cursor: auto;
}

.new-style .jcf-select-drop {
  top: calc(100% + 5px) !important;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: 0px 0px 32px rgba(191, 191, 191, 0.36);
  border-radius: 2px;
  border: none;
  max-height: 9999% !important;
  padding: 0;
}

.new-style .jcf-select-drop .jcf-list-content {
  max-height: 9999% !important;
  width: calc(100% - 16px) !important;
  margin: 0 auto;
  display: block !important;
}

.new-style .jcf-select-drop .jcf-list-content .jcf-disabled {
  display: none;
}

.new-style select.active + .jcf-disabled {
  border: 2px solid #F2F2F2;
  background: #fff;
  cursor: pointer;
}

.new-style select.active + .jcf-disabled .jcf-select-text span {
  color: #171717;
}

.new-style select.active + .jcf-disabled .jcf-select-opener:after {
  border-right: 1px solid #231F20;
  border-top: 1px solid #231F20;
}

.new-style .jcf-select-drop-content {
  background: none;
  box-shadow: none;
  padding: 8px 0;
}

.new-style .jcf-select-drop-content .jcf-option {
  font-weight: 500;
}

.new-style .jcf-select-drop-content .jcf-option.jcf-selected {
  background: #F6F8F8;
  border-radius: 2px;
}

.new-style .block-filters.full-filter {
  margin-top: 32px;
  width: 100%;
}

@media (max-width: 575px) {
  .new-style .block-filters.full-filter {
    margin-bottom: 16px;
  }
  .new-style .block-filters.full-filter .jcf-select-text span,
  .new-style .block-filters.full-filter .jcf-select-drop-content .jcf-option {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

.new-style .block-filters.full-filter .filter-item {
  width: 100%;
}

.new-style .block-filters.full-filter .filter-item .jcf-select {
  height: 53px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.new-style .block-filters.full-filter .filter-item .jcf-select .jcf-select-text {
  width: auto;
}

.new-style .block-filters.full-filter .filter-item .jcf-select .jcf-select-text span {
  text-transform: none !important;
}

.new-style .block-filters.full-filter .filter-item .jcf-select.jcf-drop-active {
  background: #fff;
}

.new-style .block-filters.full-filter .filter-item .jcf-select-opener {
  width: 10px !important;
}

.new-style .block-filters.full-filter .filter-item .jcf-select-drop-content {
  text-align: left;
}

.new-style .block-filters.full-filter .filter-item select:not(.selected) + .jcf-select .jcf-select-text span {
  color: #3D4448;
  text-transform: none;
  font-weight: 500;
}

.new-style .block-filters.full-filter .jcf-select-drop {
  overflow: auto;
  max-height: 300px;
}

.new-style .black-text-img {
  margin-top: 16px;
}

.new-style .accordion-top {
  padding-top: 0;
}

.new-style .accordion-top .row {
  padding-bottom: 8px;
}

.new-style .accordion-top .card-header_left-top {
  width: 24px;
  margin-right: 24px;
}

.new-style .accordion-top .card-header_left-bottom h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 16px;
  text-transform: uppercase;
  color: #0076BF;
  margin-bottom: 18px;
}

.new-style .accordion-top .card-header_left-bottom p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #171717;
}

.new-style .accordion-top .card-header_left-bottom.show h4 {
  color: #171717;
}

.new-style .accordion-top .card-header_left-bottom.show p {
  color: #0076BF;
}

.new-style .accordion-top .accordion-top_right h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 132%;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 9px;
}

.new-style .small-tabs .block-view .lc-item {
  margin-right: 56px;
}

@media (max-width: 768px) {
  .new-style .small-tabs .block-view .lc-item {
    margin-right: 48px;
  }
}

.new-style .small-tabs .block-view .lc-item a {
  padding: 0 0 16px 0;
  font-size: 20px;
}

@media (max-width: 768px) {
  .new-style .small-tabs .block-view .lc-item a {
    font-size: 16px;
  }
}

@media (max-width: 768px) and (max-width: 321px) {
  .new-style .small-tabs .block-view .lc-item a {
    font-size: 14px;
  }
}

.new-style .black-icon h4 {
  margin: 0 8px 0 0 !important;
}

.new-style .black-icon svg path {
  fill: #000;
}

.pointer-events {
  pointer-events: none;
}

.pb-70 {
  padding-bottom: 65px !important;
}

@media (max-width: 768px) {
  .acc-pci-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: inherit !important;
    border: none !important;
  }
  .acc-pci-scroll:after {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #D7D7D7;
    display: block;
    content: "";
    left: 0;
    bottom: 1px;
  }
  .acc-pci-scroll a {
    white-space: nowrap;
  }
  .acc-pci-scroll::-webkit-scrollbar {
    display: none;
  }
  .acc-pci-scroll::-webkit-scrollbar-thumb {
    display: none;
  }
}

.category-tabs-content {
  padding: 32px 0;
  display: flex;
  overflow: auto;
}

@media (max-width: 321px) {
  .category-tabs-content {
    padding: 16px 0;
  }
}

.category-tabs-content li {
  white-space: nowrap;
}

.category-tabs-content.space {
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.category-tabs-content.space .link-arrow {
  font-size: 14px;
}

.category-tabs-content.space h5 {
  min-width: 100%;
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .category-tabs-content.space h5 {
    font-size: 16px;
  }
}

@media (max-width: 768px) and (max-width: 321px) {
  .category-tabs-content.space h5 {
    font-size: 14px;
  }
}

.category-tabs-content.space h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
}

.category-tabs-content.space .right {
  display: flex;
}

.category-tabs-content ul {
  display: flex;
  margin: 0;
}

.category-tabs-content ul li {
  margin: 0 0 0 24px !important;
  padding: 0 !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #0076C0;
}

@media (max-width: 321px) {
  .category-tabs-content ul li {
    font-size: 14px;
  }
}

.category-tabs-content ul li.active {
  color: #171717;
}

.category-tabs-content .label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #7D7D7D;
}

@media (max-width: 321px) {
  .category-tabs-content .label {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .category-tabs-content .right {
    margin-top: 16px;
    min-width: 100%;
  }
}

.options-header {
  background: #F6F8F8;
  padding: 16px;
}

.options-header .row h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-variant: small-caps;
  color: #3D4448;
  margin: 0;
}

.options-header + .options .card {
  padding: 32px 16px !important;
}

@media (max-width: 575px) {
  .options-header {
    padding: 8px;
  }
  .options-header .col-sm-8 {
    display: none;
  }
  .options-header .col-sm-8 .row h5 {
    font-size: 14px;
  }
}

.pt-20 {
  padding-top: 20px !important;
}

.cookie-switch .cookie-switch__toggle .switch-toggle__label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 24px;
  height: 48px;
  padding: 0;
  margin: 0;
}

.cookie-switch .cookie-switch__toggle .switch-toggle__input {
  position: absolute;
  left: -9999px;
}

.cookie-switch .cookie-switch__toggle .switch-toggle__input:checked ~ .switch-toggle__text:after {
  background-color: #0076BF;
}

.cookie-switch .cookie-switch__toggle .switch-toggle__input:checked ~ .switch-toggle__text:before {
  right: 4px;
  top: auto;
  bottom: 4px;
}

.cookie-switch .cookie-switch__toggle .switch-toggle__text {
  padding-right: 96px;
  font-size: 16px;
  line-height: 40px;
}

.cookie-switch .cookie-switch__toggle .switch-toggle__text:before, .cookie-switch .cookie-switch__toggle .switch-toggle__text:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
}

.cookie-switch .cookie-switch__toggle .switch-toggle__text:after {
  width: 24px;
  height: 48px;
  border-radius: 32px;
  background-color: #0076BF;
  box-shadow: none;
}

.cookie-switch .cookie-switch__toggle .switch-toggle__text:before {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  top: 4px;
  right: 4px;
  z-index: 2;
  opacity: 1;
}

.reset .block-view .lc-item.active a::before,
.reset .block-view .lc-item.active span::before {
  height: 4px !important;
  bottom: -2px !important;
  z-index: 99;
}

@media (max-width: 768px) {
  .reset .block-view .lc-item.active a::before,
  .reset .block-view .lc-item.active span::before {
    bottom: -2px !important;
  }
}

.reset .view-cards-list:not(.small-tabs) .block-view .lc-item.active a::before,
.reset .view-cards-list:not(.small-tabs) .block-view .lc-item.active span::before {
  width: calc(100% - 48px) !important;
  left: 50%;
  transform: translate(-50%, 0);
}

.reset .view-cards-list:not(.small-tabs) .block-view .lc-item.active:first-child a::before,
.reset .view-cards-list:not(.small-tabs) .block-view .lc-item.active:first-child span::before {
  width: calc(100% - 24px) !important;
  left: 0 !important;
  transform: none !important;
}

.hover-blue:hover {
  background-color: #0699F5;
  border-color: #0699F5;
}

.hover-blue + .link-arrow:hover {
  color: #0699F5;
}

.new-style section.section-black-halfBlue {
  padding: 0;
  overflow: hidden;
}

@media (width: 768px), (width: 576px), (width: 320px) {
  .new-style section.section-black-halfBlue .black-halfBlue-img .slider-for {
    margin-bottom: 40px;
  }
}

.new-style section.section-black-halfBlue .slick-dots {
  display: none;
}

.new-style section.section-black-halfBlue .section-content {
  position: relative;
  z-index: 1;
}

.new-style section.section-black-halfBlue .slider-for {
  opacity: 0;
}

.new-style section.section-black-halfBlue .slider-for.slick-slider {
  opacity: 1;
}

.new-style section.section-black-halfBlue.second {
  background: #F6F8F8;
}

.new-style section.section-black-halfBlue.second .slider-nav .slick-slide {
  width: 74px !important;
  padding-bottom: 12px;
}

@media (width: 992px) {
  .new-style section.section-black-halfBlue.second .slider-nav .slick-slide:nth-child(3) {
    display: none;
  }
}

.new-style section.section-black-halfBlue.second .slider-nav .slick-slide.slick-current {
  border-bottom: solid 3px #fff;
}

.new-style section.section-black-halfBlue.second .slider-nav .slick-slide:last-child {
  margin: 0;
}

.new-style section.section-black-halfBlue.second .slider-nav .slick-slide img {
  height: 40px;
  width: 74px;
  max-width: none;
  margin: 0 auto;
  border: none !important;
  padding-bottom: 0;
  object-fit: contain;
}

.new-style section.section-black-halfBlue.second .slider-nav .slick-track {
  width: 392px !important;
  margin-right: 0 !important;
  justify-content: center;
}

.new-style section.section-black-halfBlue.second .container:after {
  background: linear-gradient(162.63deg, #3293D3 11.83%, #01568D 98.18%);
  width: 392px;
  left: auto;
  right: 20px;
}

@media (max-width: 992px) {
  .new-style section.section-black-halfBlue.second .slider-nav .slick-track {
    width: 248px !important;
  }
  .new-style section.section-black-halfBlue.second .container:after {
    width: 248px;
  }
}

.new-style section.section-black-halfBlue.second .container-before svg {
  display: none;
}

.new-style section.section-black-halfBlue.second h2 {
  color: #171717;
}

.new-style section.section-black-halfBlue.second .black-halfBlue-text p {
  color: #3D4448;
}

.new-style section.section-black-halfBlue.second .black-halfBlue-link li,
.new-style section.section-black-halfBlue.second .black-halfBlue-link li svg path {
  color: #0076C0;
  fill: #0076C0;
}

.new-style section.section-black-halfBlue.second .black-half-list h4,
.new-style section.section-black-halfBlue.second .black-half-list h4 small {
  color: #171717;
}

.new-style section.section-black-halfBlue.second .black-half-list .description {
  color: #3D4448;
}

.new-style section.section-black-halfBlue.second .black-half-list.update h4 {
  font-size: 28px;
  line-height: 40px;
}

@media (max-width: 768px) {
  .new-style section.section-black-halfBlue.second .black-half-list.update h4 {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 320px) {
  .new-style section.section-black-halfBlue.second .black-half-list.update h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

.new-style section.section-black-halfBlue.second .black-half-list.update .description {
  font-variant: small-caps;
  color: #7D7D7D;
}

.new-style section.section-black-halfBlue.second .black-halfBlue-img img {
  filter: none !important;
}

.new-style section.section-black-halfBlue.second .slider-nav .slick-list .slick-track,
.new-style section.section-black-halfBlue.second .black-halfBlue-img img {
  float: right;
}

.new-style section.section-black-halfBlue.second .black-halfBlue-img {
  height: 400px;
}

@media (max-width: 769px) {
  .new-style section.section-black-halfBlue.second .black-halfBlue-img {
    height: auto;
  }
}

.new-style section.section-black-halfBlue.second .slider-nav .slick-current img {
  border-bottom: solid 3px #fff;
}

.new-style section.section-black-halfBlue.second .black-halfBlue-link li:not(:last-child) {
  border-right: solid 1px #D7D7D7;
}

.new-style section.section-black-halfBlue .section-sticky__container .black-halfBlue-img,
.new-style section.section-black-halfBlue .section-sticky__container .slider-nav {
  animation: 2s cubic-bezier(0.1, 0, 0.1, 1) 0.5s 1 normal forwards mqn2-custom-oriole-hero-headline;
  --ds-annotation: "Expressive Display 2";
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}

.new-style section.section-black-halfBlue .animate-text {
  animation: 2s cubic-bezier(0.1, 0, 0.1, 1) 1s 1 normal forwards mqn2-custom-oriole-hero-headline;
  --ds-annotation: "Expressive Display 2";
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  opacity: 0;
}

.new-style section.section-black-halfBlue .animate-text .sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-variant: small-caps;
  margin-bottom: 8px;
  text-transform: uppercase;
  background-color: #0AD3FF;
  background-image: linear-gradient(50deg, #0076BF, #0AD3FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.new-style section.section-black-halfBlue .black-half-list {
  animation: 1s cubic-bezier(0.1, 0, 0.1, 1) 1.5s 1 normal forwards mqn2-custom-oriole-hero-headline;
  --ds-annotation: "Expressive Display 2";
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  opacity: 0;
}

@media (width: 768px) {
  .new-style section.section-black-halfBlue .black-half-list {
    max-width: 550px;
    margin: 0 auto;
  }
}

@media (width: 320px) {
  .new-style section.section-black-halfBlue .black-half-list {
    width: 83%;
    margin: 0 auto;
  }
}

.new-style section.section-black-halfBlue .slick-list {
  overflow: visible;
}

.new-style section.section-black-halfBlue .container {
  position: relative;
  padding: 48px 20px 120px 21px;
}

@media (max-width: 840px) {
  .new-style section.section-black-halfBlue .container {
    padding: 0 16px;
  }
}

@media (max-width: 840px) and (max-width: 320px) {
  .new-style section.section-black-halfBlue .container {
    padding: 0 8px;
  }
}

.new-style section.section-black-halfBlue .container .container-before {
  position: absolute;
  width: 1224px;
  height: 100%;
  left: 375px;
  top: 0px;
}

.new-style section.section-black-halfBlue .container:after {
  position: absolute;
  width: 200%;
  height: 138%;
  left: 124%;
  top: 0px;
  background: #005388;
  content: "";
  display: block;
}

.new-style section.section-black-halfBlue .black-halfBlue-img img {
  filter: drop-shadow(0px 22px 35px rgba(5, 20, 30, 0.2));
  max-width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
}

.new-style section.section-black-halfBlue h2 {
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 24px !important;
  margin-bottom: 16px;
  text-transform: capitalize;
}

.new-style section.section-black-halfBlue h2.no-capitalize {
  text-transform: none;
}

@media screen and (max-width: 376px) {
  .new-style section.section-black-halfBlue h2 {
    font-size: 20px !important;
    margin-bottom: 8px;
  }
}

.new-style section.section-black-halfBlue .block-radio {
  display: flex;
  position: absolute;
}

.new-style section.section-black-halfBlue .block-radio.opacity {
  opacity: 0;
}

.new-style section.section-black-halfBlue .block-radio .block-radio-item-second {
  display: flex;
  align-items: center;
}

.new-style section.section-black-halfBlue .block-radio .block-radio-item {
  margin-left: 32px;
  display: flex;
  align-items: center;
}

.new-style section.section-black-halfBlue .block-radio span,
.new-style section.section-black-halfBlue .block-radio label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: #171717;
  margin: 0 !important;
}

.new-style section.section-black-halfBlue .block-radio input {
  margin-right: 12px;
  cursor: pointer;
}

.new-style section.section-black-halfBlue .block-radio label {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .new-style section.section-black-halfBlue .block-radio {
    display: none;
  }
}

.new-style section.section-black-halfBlue .black-halfBlue-text {
  margin-bottom: 32px;
}

@media screen and (max-width: 992px) {
  .new-style section.section-black-halfBlue .black-halfBlue-text br {
    display: none;
  }
  .new-style section.section-black-halfBlue .black-halfBlue-text p {
    white-space: normal;
  }
}

@media screen and (max-width: 376px) {
  .new-style section.section-black-halfBlue .black-halfBlue-text {
    margin-bottom: 16px;
  }
}

.new-style section.section-black-halfBlue .black-halfBlue-text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

@media screen and (max-width: 376px) {
  .new-style section.section-black-halfBlue .black-halfBlue-text p {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style section.section-black-halfBlue .black-halfBlue-link ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .new-style section.section-black-halfBlue .black-halfBlue-link ul {
    justify-content: center;
  }
}

.new-style section.section-black-halfBlue .black-halfBlue-link li {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li a {
  display: flex;
  align-items: center;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li a:hover svg path {
  fill: #0699F5;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li i {
  margin-left: 8px;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li.d-flex {
  justify-content: center;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li.d-flex a {
  display: flex;
  align-items: center;
  line-height: 0.5;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li.d-flex i {
  margin: 0 8px 0 0;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li.d-flex:hover svg path {
  fill: #0699F5;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li:not(:last-child) {
  border-right: solid 1px #A3DDFF;
  padding-right: 40px;
  margin-right: 40px;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li a:hover {
  color: #0699F5;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li a:hover + i path {
  fill: #0699F5;
}

.new-style section.section-black-halfBlue .black-half-list {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .new-style section.section-black-halfBlue .black-half-list {
    justify-content: space-between;
  }
}

.new-style section.section-black-halfBlue .black-half-list .black-half-item {
  margin-right: 80px;
}

.new-style section.section-black-halfBlue .black-half-list .black-half-item:last-child {
  margin-right: 0;
}

.new-style section.section-black-halfBlue .black-half-list h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 100%;
  color: #FFFFFF;
  margin-bottom: 4px;
}

@media (max-width: 400px) {
  .new-style section.section-black-halfBlue .black-half-list h4 {
    font-size: 32px;
  }
}

.new-style section.section-black-halfBlue .black-half-list h4 small {
  font-weight: 500;
  font-size: 40px;
}

@media (max-width: 400px) {
  .new-style section.section-black-halfBlue .black-half-list h4 small {
    font-size: 32px;
  }
}

.new-style section.section-black-halfBlue .black-half-list .description {
  color: #BAE6FF;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 172%;
  letter-spacing: 0.5px;
}

@media (max-width: 320px) {
  .new-style section.section-black-halfBlue .black-half-list .description {
    font-size: 12px !important;
  }
}

.new-style section.section-black-halfBlue .slider-nav img {
  padding-bottom: 12px;
  cursor: pointer;
  width: 74px;
}

.new-style section.section-black-halfBlue .slider-nav .slick-current img {
  border-bottom: solid 3px  #0076C0;
}

.new-style section.section-black-halfBlue .slider-nav .slick-slide {
  margin-right: 32px;
  width: 74px !important;
}

.new-style section.section-black-halfBlue .slider-nav .slick-list {
  padding: 0 !important;
}

.new-style section.section-black-halfBlue .slider-nav .slick-list .slick-track {
  transform: none !important;
}

.new-style section.section-black-halfBlue.gray-product .container {
  padding: 80px 0;
}

.new-style section.section-black-halfBlue.gray-product .container::before, .new-style section.section-black-halfBlue.gray-product .container::after {
  display: none;
}

.new-style section.section-black-halfBlue.gray-product h2 {
  color: #171717;
}

.new-style section.section-black-halfBlue.gray-product .black-halfBlue-text {
  margin-bottom: 40px;
}

.new-style section.section-black-halfBlue.gray-product .black-halfBlue-text p {
  color: #171717;
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item {
  margin: 0 0 40px 0;
  width: 50%;
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item span {
  color: #575757;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 172%;
  margin-bottom: 4px;
  display: inline-block;
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item .description {
  color: #575757;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 172%;
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item small {
  font-size: 20px;
  font-weight: bold;
  line-height: 0;
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item:nth-child(2n + 1) {
  margin-right: 40px;
  width: calc(50% - 40px);
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item:nth-child(1) h4 {
  color: #0193EF;
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item:nth-child(2) h4 {
  color: #0285D7;
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item:nth-child(3) h4 {
  color: #0076C0;
}

.new-style section.section-black-halfBlue.gray-product .black-half-list .black-half-item:nth-child(4) h4 {
  color: #0469A9;
}

.new-style section.section-black-halfBlue.gray-product .row.margin-top {
  margin-top: -90px;
}

.new-style section.section-black-halfBlue.gray-product .black-halfBlue-link li {
  color: #0076C0;
}

.new-style section.section-black-halfBlue.gray-product .black-halfBlue-link li svg path {
  fill: #0076C0;
}

.new-style section.section-black-halfBlue.gray-product .black-halfBlue-link li:not(:last-child) {
  border-right: solid 1px #C2C2C2;
}

.new-style section.section-black-halfBlue.gray-product .slider-controls {
  position: absolute;
  left: 0;
  bottom: 0;
}

.new-style section.section-black-halfBlue.gray-product .slider-controls .slick-disabled {
  color: #7D7D7D;
}

.card-gray .card-line .inner-img-wrap {
  position: relative;
  height: 180px !important;
  overflow: hidden;
}

.card-gray .card-line .inner-img-wrap .inner-img {
  position: absolute;
  transition: opacity .1s ease-in;
  transform: scale(0.9) !important;
}

.card-gray .card-line .inner-img-wrap .inner-img:nth-child(1) {
  opacity: 0;
  transition-delay: 0s;
}

.card-gray .card-line:hover .inner-img-wrap .inner-img {
  opacity: 0;
  transition: opacity .1s ease-in;
  transform: scale(0.9) !important;
}

.card-gray .card-line:hover .inner-img-wrap .inner-img:nth-child(1) {
  opacity: 1;
}

.small-container .container {
  max-width: 1080px;
}

.small-container.new-style .pci-small .container-bg {
  padding: 0 40px 0 60px !important;
}

.small-container.new-style .pci-small .container-bg .pci-small-row .pci-small-title {
  margin-right: 20px;
  min-width: 241px;
}

.small-container.new-style .pci-small .container-bg .pci-small-row .pci-small-title h3 {
  font-size: 27px;
}

.small-container.new-style .pci-small .container-bg .pci-small-row .pci-small-text {
  margin-right: 30px;
  max-width: 100%;
  min-width: 480px;
}

.small-container.new-style .pci-small .container-bg .pci-small-row .pci-small-btn {
  min-width: 180px;
}

.small-container.new-style .card-gray .card-options .card-options_item {
  margin-right: 22px;
}

.small-container .card-gray .inner-img {
  width: calc(100% - 20px) !important;
  margin: 0 auto !important;
}

.small-container .card-gray .card-line .inner-img-wrap .inner-img {
  width: 100% !important;
  background-size: 90%;
}

@media (max-width: 1240px) and (max-width: 991px) {
  .main-responsive.new-style {
    overflow: hidden;
  }
}

@media (max-width: 1240px) {
  .main-responsive.new-style.no-hidden {
    overflow: inherit;
  }
  .main-responsive.new-style .card-gray.card-gray-second .card-line {
    height: auto;
  }
}

@media (max-width: 1240px) and (width: 992px) {
  .main-responsive.new-style .card-gray.card-gray-second .card-line {
    height: 388px;
  }
}

@media (max-width: 1240px) {
  .main-responsive.new-style .pci-small-row {
    padding: 0 20px !important;
  }
  .main-responsive.new-style .pci-small-row .pci-small-title {
    margin-right: 20px;
  }
  .main-responsive.new-style .pci-small-row .pci-small-title br {
    display: none;
  }
  .main-responsive.new-style .pci-small-row .pci-small-btn {
    min-width: 225px;
  }
}

@media (max-width: 1240px) and (max-width: 768px) {
  .main-responsive.new-style .pci-small-row {
    display: block !important;
    padding: 0 !important;
    text-align: center;
  }
  .main-responsive.new-style .pci-small-row .pci-small-title {
    margin: 0 0 24px 0 !important;
    text-align: center;
  }
  .main-responsive.new-style .pci-small-row .pci-small-title h3 {
    font-size: 24px !important;
    margin: 0 auto !important;
  }
  .main-responsive.new-style .pci-small-row.ml-5 {
    margin: 0 !important;
  }
}

@media (max-width: 1240px) and (max-width: 992px) {
  .main-responsive.new-style .pci-small-row.second {
    display: block !important;
    padding: 0 !important;
    text-align: center;
    width: 100%;
  }
  .main-responsive.new-style .pci-small-row.second .pci-small-title {
    margin: 0 0 24px 0 !important;
  }
  .main-responsive.new-style .pci-small-row.second .pci-small-title h3 {
    margin: 0 auto;
  }
}

@media (max-width: 1240px) and (max-width: 992px) and (max-width: 768px) {
  .main-responsive.new-style .pci-small-row.second .pci-small-title {
    margin: 0 0 8px 0 !important;
  }
}

@media (max-width: 1240px) and (max-width: 992px) {
  .main-responsive.new-style .pci-small-row.second .pci-small-text {
    margin: 0 auto 20px !important;
  }
}

@media (max-width: 1240px) and (max-width: 769px) {
  .main-responsive.new-style .pci-small-row.second.list .pci-small-title {
    margin: 0 0 16px 0 !important;
  }
}

@media (max-width: 1240px) {
  .main-responsive.new-style .pci-small .container-bg {
    padding: 40px 24px !important;
    height: auto;
  }
}

@media (max-width: 1240px) and (max-width: 576px) {
  .main-responsive.new-style .pci-small .container-bg {
    padding: 40px 16px !important;
  }
}

.open-nav2 {
  display: none;
}

@media (max-width: 575px) {
  .open-nav2 {
    display: block;
  }
}

.open-nav2.active {
  transform: rotate(0.5turn);
}

.tabs-wrap .lc-item svg {
  display: none;
}

@media (max-width: 992px) {
  .new-style .pci-small .container-bg {
    background-image: url(images/product/banner-bg-small-992.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .new-style .pci-small .container-bg {
    background-image: url(images/product/banner-bg-small-768.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) {
  .new-style .pci-small .container-bg {
    background-image: url(images/product/banner-bg-small-576.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) and (max-width: 320px) {
  .new-style .pci-small .container-bg {
    background-image: url(images/product/banner-bg-small-320.png) !important;
  }
}

@media (max-width: 992px) {
  .new-style .pci-small.pci-small-oscilloscope .container-bg {
    background-image: url(images/product/banner-bg-small-992-os.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .new-style .pci-small.pci-small-oscilloscope .container-bg {
    background-image: url(images/product/banner-bg-small-768-os.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) {
  .new-style .pci-small.pci-small-oscilloscope .container-bg {
    background-image: url(images/product/banner-bg-small-576-os.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) and (max-width: 320px) {
  .new-style .pci-small.pci-small-oscilloscope .container-bg {
    background-image: url(images/product/banner-bg-small-320-os.png) !important;
  }
}

@media (max-width: 992px) {
  .new-style .pci-small.pci-small-bluetooth .container-bg {
    background-image: url(images/product/banner-bg-small-9.7-2.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .new-style .pci-small.pci-small-bluetooth .container-bg {
    background-image: url(images/product/banner-bg-small-9.7-3.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) {
  .new-style .pci-small.pci-small-bluetooth .container-bg {
    background-image: url(images/product/banner-bg-small-9.7-4.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) and (max-width: 320px) {
  .new-style .pci-small.pci-small-bluetooth .container-bg {
    background-image: url(images/product/banner-bg-small-320-os.png) !important;
  }
}

@media (max-width: 992px) {
  .new-style .pci-small.new .container-bg {
    background-image: url(images/product/banner-bg-small-3-992.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .new-style .pci-small.new .container-bg {
    background-image: url(images/product/banner-bg-small-3-768.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) {
  .new-style .pci-small.new .container-bg {
    background-image: url(images/product/banner-bg-small-3-567.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) and (max-width: 320px) {
  .new-style .pci-small.new .container-bg {
    background-image: url(images/product/banner-bg-small-3-320.png) !important;
  }
}

@media (max-width: 992px) {
  .new-style .pci-small.pci-small-probes .container-bg {
    background-image: url(images/product/banner-bg-small-9.1.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .new-style .pci-small.pci-small-probes .container-bg {
    background-image: url(images/product/banner-bg-small-9.2.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) {
  .new-style .pci-small.pci-small-probes .container-bg {
    background-image: url(images/product/banner-bg-small-576-os.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) and (max-width: 320px) {
  .new-style .pci-small.pci-small-probes .container-bg {
    background-image: url(images/product/banner-bg-small-320-os.png) !important;
  }
}

.new-style .pci-small.pci-small-probes .container-bg .pci-small-row {
  width: 100%;
}

.new-style .pci-small.pci-small-probes .container-bg .pci-small-row .pci-small-title {
  justify-content: center;
}

.new-style .pci-small.pci-small-probes .container-bg .pci-small-row .br-all br {
  display: block !important;
}

@media (max-width: 320px) {
  .new-style .pci-small.pci-small-probes .container-bg .pci-small-row .br-all br {
    display: none !important;
  }
}

@media (max-width: 1140px) {
  .new-style.main-responsive .section-black-top_wrap .section-black-top_left h3 br {
    display: block;
  }
  .new-style.main-responsive .section-black-top_wrap .opener:after {
    display: none;
  }
  .new-style.main-responsive .section-black-top_wrap .tabs-wrap .tabs-pci {
    flex-wrap: wrap;
  }
}

@media (max-width: 1140px) and (max-width: 540px) {
  .new-style.main-responsive .section-black-top_wrap .tabs-wrap a.opener {
    font-size: 12px;
  }
}

@media (max-width: 1140px) {
  .main-responsive.new-style .product-list-description .col-md-6.mb-40 {
    width: 100%;
  }
  .main-responsive.new-style .card-gray.card-gray-second .product-list-description .mb-40 {
    margin-bottom: 24px !important;
    text-align: center;
    max-width: 100%;
  }
  .main-responsive.new-style .card-gray.card-gray-second .product-list-description h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px !important;
  }
  .main-responsive.new-style .card-gray.card-gray-second .product-list-description p {
    font-size: 12px;
    line-height: 20px;
  }
}

@media (max-width: 1140px) and (max-width: 321px) {
  .main-responsive.new-style .card-gray.card-gray-second .product-list-description .col-md-6 {
    text-align: center;
  }
}

@media (max-width: 1140px) {
  .main-responsive.new-style .card-gray.card-gray-second .row {
    margin: 0 -10px;
  }
}

@media (max-width: 1140px) and (max-width: 575px) {
  .main-responsive.new-style .card-gray.card-gray-second .row > div {
    padding: 0 8px;
    margin-bottom: 32px;
  }
  .main-responsive.new-style .card-gray.card-gray-second .row > div:last-child {
    margin-bottom: 24px;
  }
  .main-responsive.new-style .card-gray.card-gray-second .type {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 1140px) {
  .main-responsive.new-style .card-gray.card-gray-second .card-gray_top {
    padding: 28px 24px 0 24px;
  }
  .main-responsive.new-style .card-gray.card-gray-second .card-gray_top .title {
    font-size: 20px;
    line-height: 24px;
  }
  .main-responsive.new-style .card-gray.card-gray-second div.inner-content.small {
    padding: 12px 24px 0 24px !important;
  }
  .main-responsive.new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item .card-options-icon svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  .main-responsive.new-style .card-gray.card-gray-second .card-line .inner-img {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 1140px) and (max-width: 992px) {
  .main-responsive.new-style .card-gray.card-gray-second .card-line .inner-img {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .main-responsive.new-style .card-gray.card-gray-second .card-line .inner-img img {
    min-width: 90px;
  }
}

@media (max-width: 1140px) and (max-width: 840px) {
  .main-responsive.new-style .card-gray.card-gray-second .see-more-product {
    margin-bottom: 40px !important;
  }
}

@media (max-width: 1140px) and (max-width: 840px) {
  .main-responsive.new-style .small-express {
    padding-bottom: 48px;
  }
  .main-responsive.new-style .section-heading h2 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 1140px) and (max-width: 992px) {
  .main-responsive.new-style .express-list .col-bg {
    padding: 40px 16px 40px 16px;
    height: auto;
  }
}

@media (max-width: 1140px) and (max-width: 992px) and (max-width: 577px) {
  .main-responsive.new-style .express-list .col-bg .content {
    max-width: 500px;
  }
}

@media (max-width: 1140px) and (max-width: 992px) {
  .main-responsive.new-style .small-express .row > div {
    margin-bottom: 36px;
  }
}

@media (max-width: 1140px) and (max-width: 992px) and (width: 768px) {
  .main-responsive.new-style .small-express .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1140px) and (max-width: 992px) {
  .main-responsive.new-style .small-express .row > div:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1140px) and (max-width: 992px) and (max-width: 321px) {
  .main-responsive.new-style .express-list .col-bg .title h4 {
    font-size: 16px;
  }
  .main-responsive.new-style .express-list .col-bg .content p {
    font-size: 12px !important;
  }
}

@media (max-width: 1140px) {
  .main-responsive.new-style .open-nav {
    display: none;
  }
}

@media (max-width: 1140px) and (max-width: 321px) {
  .main-responsive.new-style .product-list-slider_filter,
  .main-responsive.new-style .product-list-slider_filter2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .main-responsive.new-style .product-list-slider_filter span,
  .main-responsive.new-style .product-list-slider_filter2 span {
    margin: 0 15px !important;
  }
  .main-responsive.new-style .section-black-top.mob-only .lc-item {
    display: none !important;
  }
  .main-responsive.new-style .section-black-top.mob-only .lc-item svg {
    display: none !important;
  }
  .main-responsive.new-style .section-black-top.mob-only .lc-item.active {
    display: flex !important;
  }
  .main-responsive.new-style .section-black-top.mob-only .lc-item.active svg {
    display: block !important;
  }
  .main-responsive.new-style .section-black-top.mob-only .section-black-top_right.mob-open .lc-item {
    display: flex;
  }
}

@media (max-width: 1140px) and (max-width: 575px) {
  .main-responsive.new-style .section-black-top.no-shadow {
    border-bottom: solid 1px #ccc;
    padding: 16px 0 8px 0 !important;
  }
  .main-responsive.new-style .section-black-top.no-shadow.mob-only {
    margin-bottom: 0;
  }
  .main-responsive.new-style .section-black-top.no-shadow .section-black-top_wrap {
    justify-content: left;
    width: 100%;
  }
  .main-responsive.new-style .section-black-top.no-shadow .section-black-top_right {
    justify-content: left;
    width: 100%;
  }
  .main-responsive.new-style .section-black-top.no-shadow .section-black-top_right.mob-open .lc-item {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-responsive.new-style .section-black-top.no-shadow .section-black-top_right.mob-open .lc-item.mob-only {
    padding: 0 8px;
  }
  .main-responsive.new-style .section-black-top.no-shadow .section-black-top_right.mob-open svg {
    transform: rotate(-180deg);
  }
  .main-responsive.new-style .section-black-top.no-shadow .section-black-top_right.mob-open.mob-none {
    display: block !important;
  }
  .main-responsive.new-style .section-black-top.no-shadow .section-black-top_right.mob-none {
    display: none !important;
  }
  .main-responsive.new-style .section-black-top.no-shadow .open-nav {
    display: block;
  }
  .main-responsive.new-style .section-black-top.no-shadow .open-nav .active {
    transform: rotate(-180deg);
  }
  .main-responsive.new-style .section-black-top.no-shadow .tabs-wrap {
    display: block !important;
    margin: 0;
    width: 100%;
  }
  .main-responsive.new-style .section-black-top.no-shadow .tabs-wrap.list .lc-item.anchor-active {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    align-items: center;
  }
  .main-responsive.new-style .section-black-top.no-shadow .lc-item {
    display: none;
  }
  .main-responsive.new-style .section-black-top.no-shadow .lc-item a {
    color: #171717 !important;
    font-size: 14px;
  }
}

@media (max-width: 1140px) and (max-width: 575px) and (max-width: 576px) {
  .main-responsive.new-style .section-black-top.no-shadow .lc-item a {
    padding: 8px 0 !important;
  }
}

@media (max-width: 1140px) and (max-width: 575px) {
  .main-responsive.new-style .section-black-top.no-shadow .lc-item svg {
    display: block;
  }
}

@media (max-width: 1140px) and (max-width: 576px) {
  .main-responsive.new-style .section-black-top.no-shadow.list-menu {
    border-bottom: solid 1px #ccc;
    padding: 16px 0 8px 0 !important;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu.mob-only {
    margin-bottom: 0;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .section-black-top_wrap {
    justify-content: left;
    width: 100%;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .section-black-top_right {
    justify-content: left;
    width: 100%;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .section-black-top_right.mob-open .lc-item {
    display: flex;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .section-black-top_right.mob-open svg {
    transform: rotate(-180deg);
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .section-black-top_right.mob-open.mob-none {
    display: block !important;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .section-black-top_right.mob-none {
    display: none !important;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .open-nav {
    display: block;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .open-nav .active {
    transform: rotate(-180deg);
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .tabs-wrap {
    display: block !important;
    margin: 0;
    width: 100%;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .tabs-wrap.list .lc-item.anchor-active {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    align-items: center;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .lc-item {
    display: none;
  }
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .lc-item a {
    color: #171717 !important;
    font-size: 14px;
  }
}

@media (max-width: 1140px) and (max-width: 576px) and (max-width: 576px) {
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .lc-item a {
    padding: 8px 0 !important;
  }
}

@media (max-width: 1140px) and (max-width: 576px) {
  .main-responsive.new-style .section-black-top.no-shadow.list-menu .lc-item svg {
    display: block;
  }
}

@media (max-width: 1140px) and (max-width: 992px) {
  .new-style section.section-black-halfBlue .black-half-list .black-half-item {
    margin-right: 64px;
  }
  .new-style section.section-black-halfBlue .black-half-list .black-half-item:last-child {
    margin-right: 0;
  }
  .new-style section.section-black-halfBlue .black-halfBlue-link {
    margin-bottom: 32px;
  }
  .new-style section.section-black-halfBlue .black-halfBlue-link li:not(:last-child) {
    padding-right: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 1140px) and (max-width: 321px) {
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line {
    height: 180px;
    padding-left: 160px !important;
    position: relative;
  }
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line .inner-img {
    margin-bottom: 0px !important;
    position: absolute;
    left: 0;
    top: 0;
    width: 160px;
  }
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line .card-options-icon {
    display: none;
  }
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line .card-gray_top {
    padding: 16px 0 0 0;
  }
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line .card-img-bg {
    background: #F8FAFB !important;
  }
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line div.inner-content {
    background: #F8FAFB !important;
  }
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line div.inner-content.small {
    padding: 12px 0 0 0 !important;
  }
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line div.inner-content.small .card-options .card-options_item .card-options-icon {
    margin: 0 8px 0 0;
  }
}

@media (max-width: 321px) {
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line .card-gray_top {
    padding-top: 28px;
  }
  .main-responsive.new-style .card-gray.card-gray-second .no-progress-test .card-line .card-gray_top.top-small-3 {
    padding-top: 18px;
  }
  .main-responsive.new-style .card-gray.card-gray-second .two-line .card-gray_top {
    padding-top: 40px !important;
  }
  .main-responsive.new-style .card-gray.card-gray-second .two-line .card-gray_top.top-small-2 {
    padding-top: 32px !important;
  }
}

@media (max-width: 768px) {
  .mobile-view-btn.second {
    display: none;
  }
}

.comparison.new .comparison-top {
  max-width: 922px;
  margin: 0 auto;
}

.comparison.new .comparison-top .comparison-img {
  margin-bottom: 24px;
}

.comparison.new .comparison-top .comparison-select {
  margin-bottom: 24px;
}

.comparison.new .comparison-top .comparison-select svg {
  margin-left: 16px;
}

.comparison.new .comparison-top .comparison-select.no-border {
  background: #FFFFFF;
  color: #171717;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 128%;
  text-align: center;
}

.comparison.new .comparison-top .comparison-select.no-border span, .comparison.new .comparison-top .comparison-select.no-border small {
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 500;
}

.comparison.new .comparison-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comparison.new .comparison-button .link-arrow {
  margin-left: 16px;
}

.comparison.new .comparison-button .link-arrow span {
  font-size: 14px;
}

.comparison.new .comparison-body {
  margin-bottom: 24px !important;
}

.comparison.new .comparison-body .text-left {
  color: #171717;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
}

.comparison.new .comparison-body .text-left span {
  font-size: 20px !important;
}

.comparison.new .comparison-body span {
  font-style: normal;
  font-weight: bold;
  font-size: 32px !important;
  line-height: 100%;
}

.comparison.new .comparison-body span.small {
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 160%;
}

.comparison.new .comparison-body span.small-2 {
  font-size: 24px !important;
}

.comparison.new.new-2 {
  padding-top: 100px;
}

.comparison.new.new-2 .comparison-top {
  max-width: 100%;
}

.comparison.new.new-2 .card:not(.first) {
  padding: 72px 0  48px 0 !important;
}

.comparison.new.new-2 .text-left {
  position: absolute;
  top: 32px;
  left: 0;
}

.comparison.new.new-3#accordion .card {
  border: none;
  padding: 0;
}

.comparison.new.new-3 .comparison-body {
  padding: 0;
  margin-bottom: 32px !important;
}

.comparison.new.new-3 .comparison-body_title {
  text-align: left !important;
  margin-bottom: 16px;
}

.comparison.new.new-3 .comparison-body_title span {
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 140%;
  color: #7D7D7D !important;
  text-transform: uppercase;
}

.comparison.new.new-3 .comparison-body_title + .row {
  border: 1px solid #D7D7D7 !important;
  padding: 0 !important;
  margin: 0;
}

.comparison.new.new-3 .comparison-body_title + .row > div {
  padding: 24px !important;
  border-right: solid 1px #D7D7D7 !important;
}

.comparison.new.new-3 .comparison-body_title + .row > div:last-child {
  border-right: none !important;
}

.comparison.new.new-4 {
  padding-top: 130px;
}

.comparison.new.new-4 .comparison-button .link-arrow:hover i {
  margin-left: 0.5rem;
}

.comparison.new.new-4 .comparison-top {
  max-width: 100%;
  width: 100%;
}

.comparison.new.new-4 .comparison-top .comparison-bg-white {
  background: #fff;
  padding: 107px 32px 0 32px;
}

@media (max-width: 1200px) {
  .comparison.new.new-4 .comparison-top .comparison-bg-white {
    padding: 107px 24px 0 24px;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white .comparison-img {
    top: -55px;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white .comparison-img img {
    min-width: 220px;
  }
}

.comparison.new.new-4 .comparison-top .comparison-img {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
}

.comparison.new.new-4 .comparison-top .comparison-img img {
  min-width: 360px;
  filter: drop-shadow(0px 20px 32px rgba(23, 37, 51, 0.2));
}

.comparison.new.new-4 .comparison-top .comparison-body {
  margin-top: 40px;
}

.comparison.new.new-4 .comparison-top .comparison-body_item {
  border-bottom: solid 1px #D7D7D7;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.comparison.new.new-4 .comparison-top .comparison-body_item:last-child {
  border-bottom: none;
}

.comparison.new.new-4 .comparison-top .comparison-body_item .descriptions {
  color: #7D7D7D;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 152%;
  margin-bottom: 8px;
  font-variant: small-caps;
}

.comparison.new.new-4 .comparison-top .comparison-body_item span {
  color: #171717 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
}

.comparison-bg {
  position: relative;
}

.comparison-bg::before {
  background: #F6F8F8;
  height: 220px;
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.new-style.w-85w .card-gray .inner-img {
  transform: scale(0.85);
}

.new-style.w-82w .card-gray .inner-img {
  transform: scale(0.82);
}

.new-style.w-80w .card-gray .inner-img {
  transform: scale(0.8);
}

.new-style.w-75w .card-gray .inner-img {
  transform: scale(0.75);
}

.new-style.w-82w .card-gray .inner-img.animate-small {
  transform: scale(0.95);
  width: calc(100% - 20px) !important;
  transition: 0.5s;
}

.new-style.w-82w .card-gray .inner-img.animate-small:hover {
  transform: scale(1) !important;
  width: calc(100% - 20px) !important;
  transition: 0.5s;
}

.new-style .card-gray .card-line:hover .inner-img {
  transform: scale(0.9);
}

@media (max-width: 540px) {
  .new-style .card-gray .card-line:hover .inner-img {
    transform: scale(0.8) !important;
  }
}

#main .section-black-top.no-shadow {
  box-shadow: none !important;
}

#main .section-black-top.no-shadow.site-header_animation-2 {
  box-shadow: inset 0px -1px 0px #e2e4e6 !important;
  position: fixed;
  width: 100%;
  padding: 0 !important;
  min-height: 80px;
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  #main .section-black-top.no-shadow.site-header_animation-2 {
    min-height: 48px;
  }
}

@media (max-width: 768px) {
  #main .section-black-top.no-shadow.site-header_animation-2.no-mobile {
    position: static;
  }
  #main .section-black-top.no-shadow.site-header_animation-2.no-mobile .section-black-top_right {
    box-shadow: inset 0px -1px 0px #e2e4e6 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 80px;
    display: flex;
    align-items: center;
    background: #fff;
    z-index: 9;
  }
}

@media (max-width: 768px) and (max-width: 768px) {
  #main .section-black-top.no-shadow.site-header_animation-2.no-mobile .section-black-top_right {
    min-height: 48px;
  }
}

@media (max-width: 576px) {
  #main .section-black-top.no-shadow.site-header_animation-2.section-top-update .section-black-top_right .lc-item {
    display: none;
  }
  #main .section-black-top.no-shadow.site-header_animation-2.section-top-update .section-black-top_right .lc-item.mob-only {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #main .section-black-top.no-shadow.site-header_animation-2.section-top-update .section-black-top_right.mob-open .lc-item {
    display: flex;
  }
}

#main .section-black-top.no-shadow.section-top-update2 svg.open-nav2.icon-rotate {
  transform: rotate(180deg);
}

#main .section-black-top.no-shadow.section-top-update2 .container {
  justify-content: space-between;
}

#main .section-black-top.no-shadow.section-top-update2 .container .section-black-top_wrap {
  justify-content: space-between;
  width: 100%;
}

@media (width: 768px) {
  #main .section-black-top.no-shadow.section-top-update2 .section-black-top_right {
    padding-bottom: 5px;
  }
  #main .section-black-top.no-shadow.section-top-update2.site-header_animation-2 .section-black-top_right {
    padding-bottom: 0;
  }
}

@media (max-width: 576px) {
  #main .section-black-top.no-shadow.section-top-update2:not(.site-header_animation-2) .section-black-top_right {
    padding-top: 8px !important;
  }
}

@media (max-width: 320px) {
  #main .section-black-top.no-shadow.section-top-update2.site-header_animation-2 .section-black-top_left .open-nav2 {
    position: fixed;
    top: 16px;
    z-index: 99;
  }
  #main .section-black-top.no-shadow.section-top-update2 .section-black-top_left {
    display: block !important;
  }
  #main .section-black-top.no-shadow.section-top-update2 .title-h3 {
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #main .section-black-top.no-shadow.section-top-update2 .desctope-view-btn {
    display: block !important;
  }
  #main .section-black-top.no-shadow.section-top-update2 .section-black-top_right.mob-none {
    display: flex !important;
  }
  #main .section-black-top.no-shadow.section-top-update2 .section-black-top_right.mob-none a {
    padding-left: 8px !important;
  }
  #main .section-black-top.no-shadow.section-top-update2 .tabs-pci_second {
    display: block !important;
  }
  #main .section-black-top.no-shadow.section-top-update2 .tabs-pci_second .lc-item.active {
    display: block !important;
  }
  #main .section-black-top.no-shadow.section-top-update2 .open-nav2 {
    position: absolute;
    top: 102px;
    right: 10px;
  }
}

.mb-60 {
  margin-bottom: 60px;
}

.new-style .card-gray.card-gray-second .row > div {
  padding: 0 16px;
  margin-bottom: 32px;
}

@media (width: 768px) {
  .new-style .card-gray.card-gray-second .row > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (width: 768px) {
  .new-style .card-gray.card-gray-second .long-text {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
}

@media (max-width: 576px) {
  .new-style .card-gray.card-gray-second .long-text {
    max-width: 500px !important;
  }
}

.new-style .card-gray.card-gray-second .card-line {
  height: 468px;
  background: #F8FAFB;
}

.new-style .card-gray.card-gray-second .card-line .inner-img {
  margin-bottom: 30px !important;
}

.new-style .card-gray.card-gray-second .card-line .card-img-bg,
.new-style .card-gray.card-gray-second .card-line .inner-content {
  background: #F8FAFB;
  transition: 0.5s;
}

.new-style .card-gray.card-gray-second .card-line .inner-img {
  transform: scale(0.8);
  filter: drop-shadow(0px 22px 35px rgba(5, 20, 30, 0.2));
}

.new-style .card-gray.card-gray-second .card-line:hover .card-img-bg,
.new-style .card-gray.card-gray-second .card-line:hover .inner-content {
  background: #F2F4F5;
  transition: 0.5s;
}

.new-style .card-gray.card-gray-second .card-line:hover .card-gray_top .title {
  color: #0076C0;
}

.new-style .card-gray.card-gray-second .card-line:hover .inner-img {
  transform: scale(0.9);
}

@media (max-width: 540px) {
  .new-style .card-gray.card-gray-second .card-line:hover .inner-img {
    transform: scale(0.8) !important;
  }
}

.new-style .card-gray.card-gray-second .product-list-description h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
}

.new-style .card-gray.card-gray-second .product-list-description p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2B2F2F;
}

.new-style .card-gray.card-gray-second .card-gray_top {
  padding: 32px 40px 0 40px;
  margin: 0;
}

.new-style .card-gray.card-gray-second .card-gray_top .type {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
  margin-bottom: 8px;
}

@media (max-width: 576px) {
  .new-style .card-gray.card-gray-second .card-gray_top .type {
    font-size: 10px;
  }
}

.new-style .card-gray.card-gray-second .card-gray_top .type.visible {
  color: #0ABFA3;
}

.new-style .card-gray.card-gray-second .card-gray_top .title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}

.new-style .card-gray.card-gray-second div.inner-content.small {
  padding: 12px 40px 0 40px !important;
}

.new-style .card-gray.card-gray-second div.inner-content.small .card-options {
  display: block;
}

.new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item {
  margin: 0 0 4px 0 !important;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item .card-options-icon {
  margin: 0 12px 0 0;
}

.new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item .card-options-icon svg {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item .card-options-icon svg path {
  fill: #000;
}

.new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item .card-options-number,
.new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item .card-options-type {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

@media (max-width: 321px) {
  .new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item .card-options-number,
  .new-style .card-gray.card-gray-second div.inner-content.small .card-options .card-options_item .card-options-type {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style .card-gray.card-gray-second .inner-img {
  margin-bottom: 48px !important;
}

.new-style .card-gray.card-gray-second .see-more-product {
  text-align: center;
}

.new-style .card-gray.card-gray-second .see-more-product .btn {
  min-width: 228px;
}

.new-style .card-gray.card-gray-second .see-more-product .btn svg {
  margin-left: 8px !important;
}

.new-style .card-gray.card-gray-second .see-more-product .btn:hover {
  background-color: #0699F5;
  border-color: #0699F5;
}

.new-style .card-gray.card-gray-second .product-list-scroll .product-list-wrap {
  height: auto;
  display: flex;
}

.new-style .card-gray.card-gray-second .product-list-scroll .product-list-wrap .inner-img {
  margin-bottom: 36px;
}

.new-style .card-gray.card-gray-second .product-list-slider .slick-list,
.new-style .card-gray.card-gray-second .product-list-slider2 .slick-list {
  overflow: inherit;
}

.new-style .card-gray.card-gray-second .product-list-slider .slick-list .inner-img,
.new-style .card-gray.card-gray-second .product-list-slider2 .slick-list .inner-img {
  margin-bottom: 36px;
}

@media (max-width: 321px) {
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap {
    padding: 0 8px !important;
  }
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap .card-gray_top,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap .card-gray_top {
    padding: 16px 16px 0 16px;
  }
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap .card-gray_top .title,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap .card-gray_top .title {
    font-size: 18px;
  }
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap div.inner-content.small,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap div.inner-content.small {
    padding: 12px 16px 0 16px !important;
  }
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap div.inner-content.small .card-options-icon,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap div.inner-content.small .card-options-icon {
    display: none;
  }
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap div.inner-content.small .card-options-number,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap div.inner-content.small .card-options-number {
    font-size: 12px !important;
  }
}

.new-style .card-gray.card-gray-second .product-list-slider,
.new-style .card-gray.card-gray-second .product-list-slider2 {
  margin: 0 -16px;
}

@media (max-width: 320px) {
  .new-style .card-gray.card-gray-second .product-list-slider,
  .new-style .card-gray.card-gray-second .product-list-slider2 {
    margin: 0 -8px;
  }
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap .inner-img,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap .inner-img {
    height: 150px !important;
  }
}

.new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap,
.new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap {
  padding: 0 16px;
  height: auto;
  display: flex;
}

.new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap .inner-img,
.new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap .inner-img {
  margin-bottom: 16px !important;
}

.new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap .product-list-slider_item,
.new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap .product-list-slider_item {
  width: 100%;
}

@media (max-width: 321px) {
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap .product-list-slider_item,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap .product-list-slider_item {
    background-color: #F8FAFB;
  }
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap .product-list-slider_item .inner-img,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap .product-list-slider_item .inner-img {
    margin-bottom: 0 !important;
  }
}

.new-style .card-gray.card-gray-second .product-list-slider .slick-arrow,
.new-style .card-gray.card-gray-second .product-list-slider2 .slick-arrow {
  opacity: 0;
  transition: .5s;
}

.new-style .card-gray.card-gray-second .product-list-slider:hover .slick-arrow,
.new-style .card-gray.card-gray-second .product-list-slider2:hover .slick-arrow {
  opacity: 1;
  transition: .5s;
}

.new-style .card-gray.card-gray-second .slick-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  z-index: 9;
  cursor: pointer;
}

.new-style .card-gray.card-gray-second .slick-arrow.slick-prev {
  right: auto;
  left: 0;
  transform: rotate(90);
}

.new-style .card-gray.card-gray-second .slick-arrow.slick-disabled {
  display: none !important;
}

.new-style .card-gray.card-gray-second .slick-arrow circle {
  fill: rgba(218, 218, 218, 0.31);
}

.new-style .card-gray.card-gray-second .slick-arrow:hover circle {
  fill: rgba(187, 187, 187, 0.31);
}

.new-style .card-gray.card-gray-second .product-list-slider_filter,
.new-style .card-gray.card-gray-second .product-list-slider_filter2 {
  margin-top: 32px;
}

@media (max-width: 575px) {
  .new-style .card-gray.card-gray-second .product-list-slider_filter,
  .new-style .card-gray.card-gray-second .product-list-slider_filter2 {
    margin-top: 16px;
  }
}

.new-style .card-gray.card-gray-second .product-list-slider_filter span,
.new-style .card-gray.card-gray-second .product-list-slider_filter2 span {
  margin-right: 32px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #171717;
}

.new-style .card-gray.card-gray-second .product-list-slider_filter span.active,
.new-style .card-gray.card-gray-second .product-list-slider_filter2 span.active {
  color: #0076C0;
}

.new-style .card-gray.card-gray-second .product-list-slider_filter span:last-child,
.new-style .card-gray.card-gray-second .product-list-slider_filter2 span:last-child {
  margin-right: 0;
}

.new-style .card-gray.card-gray-second ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 1100px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  border: solid 4px #ccc;
}

.new-style .card-gray.card-gray-second ul li {
  padding-top: 50px;
  padding-bottom: 50px;
  border: solid 1px #ddd;
  text-align: center;
  font-size: 5em;
  cursor: pointer;
}

.new-style .card-gray.card-gray-second ul li.red {
  background: red;
  color: #fff;
}

.new-style .card-gray.card-gray-second ul li.yellow {
  background: yellow;
}

.new-style .card-gray.card-gray-second ul li.blue {
  background: blue;
  color: #fff;
}

.new-style .card-gray.card-gray-second ul.filter {
  margin: 2em auto;
}

.new-style .card-gray.card-gray-second ul.filter li {
  padding: 12px;
  font-size: 1em;
  width: 25%;
  float: left;
}

.new-style .card-gray.card-gray-second p {
  width: 1100px;
  max-width: 100%;
  display: block;
  margin: 2em auto;
}

.new-style .card-gray.card-gray-second .progress,
.new-style .card-gray.card-gray-second .progress2 {
  display: block;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background: #F3F3F3;
  background-image: linear-gradient(to right, #0076C0, #0076C0);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size .4s ease-in-out;
}

.new-style .card-gray.card-gray-second .product-list-scroll {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 40px;
}

.new-style .card-gray.card-gray-second .product-list-scroll .product-list-wrap {
  min-width: 286px;
  max-width: 286px;
  margin-right: 32px;
}

.new-style .card-gray.card-gray-second .product-list-scroll .product-list-wrap:last-child {
  margin-right: 0;
}

.new-style .card-gray.card-gray-second ::-webkit-scrollbar {
  width: 24px;
  height: 8px;
  background-color: #F3F3F3;
  border-bottom: solid 2px #fff;
  border-top: solid 2px #fff;
  cursor: pointer;
}

.new-style .card-gray.card-gray-second ::-webkit-scrollbar-thumb {
  background-color: #0076C0;
  height: 8px;
  cursor: pointer;
}

.big-fonts.small-express .content p {
  font-size: 16px !important;
}

.big-fonts.small-express .link-arrow {
  font-size: 14px !important;
}

.hide-none {
  display: none !important;
}

.container-filter .filterDiv {
  display: none !important;
}

.container-filter .filterDiv.show {
  display: flex !important;
}

body {
  overflow-x: hidden;
}

@media (max-width: 840px) {
  .new-style section.section-default-page.section-breadcrumbs-product {
    padding: 0 !important;
  }
}

@media (max-width: 480px) {
  .reset .breadcrumbs-product svg {
    width: 16px;
    height: 16px;
  }
  .reset .breadcrumbs-product + h3 {
    font-size: 24px !important;
  }
}

.reset section.section-text-list {
  padding-bottom: 120px;
}

@media (width: 768px) {
  .reset section.section-text-list .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .reset section.section-text-list {
    padding-bottom: 40px;
  }
}

@media (max-width: 320px) {
  .reset section.section-text-list {
    padding-top: 40px !important;
  }
}

.reset section.section-text-list p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2B2F2F;
  margin-bottom: 16px;
}

.reset section.section-text-list a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.5px;
  color: #0076C0 !important;
  margin-right: 8px;
}

@media (max-width: 767px) {
  .reset section.section-text-list {
    padding-right: 100px !important;
  }
  .reset section.section-text-list [class^="col"] {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 577px) {
  .reset section.section-text-list {
    padding-right: 0 !important;
  }
}

@media (max-width: 480px) {
  .reset section.section-text-list h3 {
    font-size: 20px;
    line-height: 23px;
  }
  .reset section.section-text-list p {
    font-size: 12px;
    line-height: 20px;
  }
}

.section-resources-new.second {
  padding-bottom: 120px !important;
}

.section-resources-new.second .slick-initialized .slick-slide .col-md-4 {
  max-width: 416px !important;
}

.section-resources-new.second .row {
  border-bottom: solid 1px #D7D7D7;
  border-top: solid 1px #D7D7D7;
  margin: 0;
}

.section-resources-new.second .row [class^="col"] {
  padding: 0;
}

.section-resources-new.second .row [class^="col"]:nth-child(3) .section-resources_item:after,
.section-resources-new.second .row [class^="col"]:nth-child(6) .section-resources_item:after,
.section-resources-new.second .row [class^="col"]:nth-child(9) .section-resources_item:after {
  display: none;
}

.section-resources-new.second .row [class^="col"]:nth-child(7) .section-resources_item,
.section-resources-new.second .row [class^="col"]:nth-child(8) .section-resources_item,
.section-resources-new.second .row [class^="col"]:nth-child(9) .section-resources_item {
  border-bottom: none;
}

.section-resources-new.second .row .section-resources_item {
  padding: 40px;
  border-bottom: solid 1px #D7D7D7;
  height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.section-resources-new.second .row .section-resources_item.no-border {
  border-bottom: none;
}

.section-resources-new.second .row .section-resources_item.border-add {
  border-bottom: solid 1px #D7D7D7 !important;
}

.section-resources-new.second .row .section-resources_item:after {
  display: block;
  position: absolute;
  content: "";
  background: #D7D7D7;
  width: 1px;
  height: calc(100% - 80px);
  left: 100%;
  top: 40px;
}

.section-resources-new.second .row .section-resources_item .type {
  background: #F6F8F8;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
  padding: 2px 8px;
  margin-bottom: 8px;
  display: inline-block;
  margin-right: 3px;
}

@media (max-width: 768px) {
  .section-resources-new.second .row .section-resources_item .type {
    margin-bottom: 16px;
  }
}

@media (max-width: 320px) {
  .section-resources-new.second .row .section-resources_item .type {
    font-size: 10px;
    line-height: 12px;
  }
}

.section-resources-new.second .row .section-resources_item .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #171717;
  margin-bottom: 16px;
}

.section-resources-new.second .row .section-resources_item .description p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2B2F2F;
}

@media (max-width: 320px) {
  .section-resources-new.second .row .section-resources_item .description p {
    font-size: 12px;
  }
}

.section-resources-new.second .row .section-resources_item .description ul {
  margin: 0;
  padding: 0;
}

.section-resources-new.second .row .section-resources_item .description ul li {
  padding: 0;
  display: flex;
  align-items: top;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 16px;
}

.section-resources-new.second .row .section-resources_item .description ul li:last-child {
  margin-bottom: 0;
}

.section-resources-new.second .row .section-resources_item .description ul li .simbol {
  margin-right: 16px;
}

.section-resources-new.second .row .section-resources_item .resources-item_bottom {
  margin-top: auto;
}

.section-resources-new.second .row .section-resources_item .resources-item_bottom svg {
  margin-left: 8px;
}

.section-resources-new.second .row .section-resources_item .resources-item_bottom a {
  font-size: 14px;
  display: flex;
}

.section-resources-new.second .row .section-resources_item .resources-item_bottom a:hover {
  transition: none !important;
}

.section-resources-new.second .row .section-resources_item .resources-item_bottom a:hover path {
  fill: #0699F5;
}

@media (max-width: 992px) {
  .section-resources-new.second .row [class^="col"] {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .section-resources-new.second .row [class^="col"]:nth-child(3) .section-resources_item:after {
    display: block;
  }
  .section-resources-new.second .row [class^="col"]:nth-child(2) .section-resources_item:after,
  .section-resources-new.second .row [class^="col"]:nth-child(4) .section-resources_item:after,
  .section-resources-new.second .row [class^="col"]:nth-child(8) .section-resources_item:after {
    display: none;
  }
}

@media (max-width: 768px) {
  .section-resources-new.second {
    padding-bottom: 40px !important;
  }
  .section-resources-new.second .section-resources_item .title {
    font-size: 20px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 577px) {
  .section-resources-new.second {
    padding-bottom: 64px !important;
  }
  .section-resources-new.second .slick-dots {
    bottom: -24px !important;
  }
  .section-resources-new.second .row {
    border: none;
  }
  .section-resources-new.second .row [class^="col"] {
    flex: auto;
    max-width: 100%;
  }
  .section-resources-new.second .row .section-resources_item {
    border-top: solid 1px #D7D7D7 !important;
    border-bottom: solid 1px #D7D7D7 !important;
    height: 265px !important;
  }
}

@media (max-width: 577px) and (max-width: 320px) {
  .section-resources-new.second .row .section-resources_item {
    height: 250px !important;
  }
}

@media (max-width: 577px) {
  .section-resources-new.second .row .section-resources_item .resources-item_top {
    margin-bottom: 32px;
  }
  .section-resources-new.second .row .section-resources_item:after {
    display: none !important;
  }
}

@media (max-width: 577px) and (max-width: 320px) {
  .section-resources-new.second .row .section-resources_item {
    padding: 40px 16px !important;
  }
}

#tab-pci-3 .options-header + #accordion .card {
  padding: 16px 8px !important;
}

@media (max-width: 768px) {
  .mt-customer {
    margin-top: 8px;
  }
}

@media (max-width: 546px) {
  .mt-customer {
    margin-top: 16px;
  }
}

@media (max-width: 320px) {
  .mt-customer {
    margin-top: 8px;
  }
}

.block-view.view-cards {
  padding-top: 20px;
}

.section-what-new {
  background: #F6F8F8;
  padding: 120px 0 !important;
}

.section-what-new .section-heading h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
}

.section-what-new p {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  color: #3D4448;
  margin-bottom: 16px !important;
}

.section-what-new p:last-child {
  margin: 0 !important;
}

@media (max-width: 768px) {
  .section-what-new {
    padding: 48px 0 !important;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .section-what-new {
    padding: 40px 0 !important;
  }
}

@media (max-width: 768px) {
  .section-what-new .section-heading h3 {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .section-what-new .section-heading h3 {
    font-size: 14px;
    line-height: 20px;
  }
  .section-what-new p {
    font-size: 12px !important;
    line-height: 20px;
  }
}

.section-filters-top {
  margin-bottom: 32px;
}

@media (max-width: 320px) {
  .section-filters-top {
    margin-bottom: 16px;
  }
}

.section-filters-top .section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 480px) {
  .section-filters-top .section-heading {
    display: block;
    text-align: center;
  }
  .section-filters-top .section-heading h2 {
    margin-bottom: 8px !important;
  }
}

.section-filters-top .section-heading h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #171717;
}

.section-filters-top .section-heading a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.5px;
  color: #0076C0;
}

.section-filters-top .section-heading a:hover {
  color: #0699F5;
}

.section-filters-top .section-heading a:hover path {
  fill: #0699F5;
}

.section-filters-top .section-heading a svg {
  margin-left: 8px;
}

.section-filters-section_top {
  background: #F6F8F8;
}

.section-filters-section_top.has-border {
  border-top: solid 1px #D7D7D7;
}

.section-filters-section_top .block-filters-top span {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.5px;
  color: #171717;
  margin-right: 8px;
  cursor: pointer;
}

.section-filters-section_top .block-filters-top span svg path {
  fill: #171717;
}

.section-filters-section_top .block-filters-top .block-filters-result {
  display: none;
}

.section-filters-section_top .block-filters-top .close-text + svg {
  display: none;
}

.section-filters-section_top .block-filters-top .close-filter-button.open .show-text {
  display: none;
}

.section-filters-section_top .block-filters-top .close-filter-button.open .show-text + svg {
  display: none;
}

.section-filters-section_top .block-filters-top .close-filter-button.open .close-text + svg {
  display: inline-block;
}

.section-filters-section_top .block-filters-top .close-filter-button:not(.open) + .block-filters-result {
  display: block !important;
  min-width: 0;
}

@media (max-width: 768px) {
  .section-filters-section_top .block-filters-top .close-filter-button:not(.open) + .block-filters-result {
    position: absolute;
    right: 8px;
  }
}

@media (max-width: 576px) {
  .section-filters-section_top .block-filters-top .close-filter-button:not(.open) + .block-filters-result {
    position: static;
  }
}

.section-filters-section_top [class^="col"] {
  position: static;
}

.section-filters-section_top .col-md-9,
.section-filters-section_top .col-md-10 {
  display: flex;
  align-items: center;
}

.section-filters-section_top .col-md-9 h4,
.section-filters-section_top .col-md-10 h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  margin: 0 124px 0 0;
  cursor: pointer;
}

.section-filters-bottom.hover .card-hover-descriptions,
.section-filters-bottom.hover .card-hover-link {
  display: none;
  padding: 0 40px 0 40px !important;
}

.section-filters-bottom.hover .card-hover-descriptions p,
.section-filters-bottom.hover .card-hover-link p {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #3D4448;
  margin: 12px 0 0 0;
}

.section-filters-bottom.hover .card-hover-link {
  position: absolute;
  bottom: 40px;
}

.section-filters-bottom.hover .card-hover-link a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #0076C0;
  display: flex;
  align-items: center;
}

.section-filters-bottom.hover .card-hover-link a svg {
  margin-left: 8px;
}

.section-filters-bottom.hover .card-line:hover {
  background: #F2F4F5;
}

.section-filters-bottom.hover .card-line:hover .card-hover-descriptions,
.section-filters-bottom.hover .card-line:hover .card-hover-link {
  display: block;
}

.section-filters-bottom.hover .card-line:hover .card-hover-link a:hover {
  color: #0699F5;
}

.section-filters-bottom.hover .card-line:hover .card-hover-link a:hover svg path {
  fill: #0699F5;
}

.section-filters-bottom.hover .card-line:hover .card-img-bg {
  background: none !important;
}

.section-filters-bottom.hover .card-line:hover .card-gray_top .title {
  color: #000000 !important;
}

.filter-title-mob {
  display: none !important;
  min-width: 100%;
}

@media (max-width: 767px) {
  .filter-title-mob {
    display: block !important;
  }
  .filter-title-desc {
    display: none !important;
  }
  .block-filters-top .filter-title-mob {
    min-width: 0;
  }
  .block-filters-top .filter-title-mob .clear__text {
    color: #0699F5;
  }
}

.section-filters-midle {
  border-top: none !important;
  margin-bottom: 32px !important;
}

@media (max-width: 320px) {
  .section-filters-midle {
    margin-bottom: 16px !important;
  }
}

.section-filters-midle.open {
  padding-bottom: 16px !important;
}

.section-filters-midle:not(.open) .block-filters-result {
  display: none;
}

.section-filters-midle .block-filters-column {
  padding: 0 !important;
}

.section-filters-midle .block-filters-column input {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  opacity: 0;
  position: absolute;
  display: inline-block;
}

.section-filters-midle .block-filters-column input:checked + .checkbox-icon {
  background: url(./images/checkbox-bg-2.svg);
  background-size: contain;
  border: none !important;
}

.section-filters-midle .block-filters-column input[disabled] + i + span {
  color: #D7D7D7;
}

.section-filters-midle .block-filters-column .checkbox-icon {
  margin-top: 2px;
  min-width: 20px;
  min-height: 20px;
  background-size: cover;
  width: 20px;
  height: 20px;
  border: solid 1px #D7D7D7;
  border-radius: 0;
  margin-right: 8px;
  display: inline-block;
}

@media (max-width: 320px) {
  .section-filters-midle .block-filters-column label {
    font-size: 12px !important;
  }
}

@media (max-width: 992px) {
  .section-filters-midle .row-filter.second .col-md-9 {
    display: flex;
    flex-wrap: wrap;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column {
    min-width: 33.3%;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) {
    min-width: 100%;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) ul li {
    width: 33.3%;
  }
}

@media (max-width: 992px) and (max-width: 840px) {
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column {
    min-width: 50%;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(4), .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) {
    min-width: 100%;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(4) ul, .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(4) ul li, .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) ul li {
    width: 50%;
  }
}

@media (max-width: 992px) and (width: 768px) {
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) ul li:nth-child(2),
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) ul li:nth-child(4) {
    margin-top: -40px;
  }
}

@media (max-width: 992px) and (max-width: 767px) {
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column {
    min-width: 33.3%;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(4) {
    min-width: 33.3%;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(4) ul {
    display: block;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(4) ul li {
    width: 100%;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column:nth-child(5) ul li {
    width: 33.3%;
  }
}

@media (max-width: 992px) and (max-width: 578px) {
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column {
    min-width: 50% !important;
  }
  .section-filters-midle .row-filter.second .col-md-9 .block-filters-column ul li {
    width: 100% !important;
  }
}

.row-filter {
  width: 100%;
}

.row-filter .col-md-9 {
  display: flex;
  flex-wrap: wrap;
}

.new-style .section-filters-bottom .inner-img.big {
  background-size: cover !important;
}

@media (max-width: 540px) {
  .new-style .section-filters-bottom .inner-img.big {
    height: 110px !important;
  }
}

@media (max-width: 992px) {
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div .card-options-number {
    font-size: 15px !important;
  }
}

@media (max-width: 768px) {
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div .card-options-number {
    font-size: 15px !important;
  }
}

@media (max-width: 768px) and (max-width: 767px) {
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 768px) and (max-width: 767px) and (max-width: 480px) {
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) and (max-width: 480px) {
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div .card-gray_top {
    padding: 16px 16px 0 16px;
  }
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div div.inner-content.small {
    padding: 12px 16px 0 16px !important;
  }
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div div.inner-content.small .card-options-icon {
    display: none;
  }
  .new-style .section-filters-bottom .card-gray.card-gray-second .row > div .card-options-number {
    font-size: 12px !important;
  }
}

.section-breadcrumbs {
  background: #F8FAFB;
  padding: 24px 0 56px 0 !important;
  background-position: center;
}

@media (max-width: 840px) {
  .section-breadcrumbs {
    padding: 8px 0 40px 0 !important;
  }
}

@media (max-width: 768px) {
  .section-breadcrumbs {
    padding: 72px 0 !important;
  }
}

.section-breadcrumbs.section-breadcrumbs-bg {
  background-image: url(./images/product/breadcrumbs-bg.jpg);
}

.section-breadcrumbs.section-breadcrumbs-bg .breadcrumbs-product ul a {
  color: #fff !important;
}

.section-breadcrumbs.section-breadcrumbs-bg .breadcrumbs-product ul path {
  fill: #fff;
}

.section-breadcrumbs.section-breadcrumbs-bg h3, .section-breadcrumbs.section-breadcrumbs-bg h1 {
  color: #fff;
  margin-bottom: 32px !important;
}

@media (max-width: 575px) {
  .section-breadcrumbs.section-breadcrumbs-bg h3, .section-breadcrumbs.section-breadcrumbs-bg h1 {
    margin-bottom: 10px !important;
  }
}

.section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list {
  display: flex;
  justify-content: center;
}

.section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list .breadcrumbs-list_item {
  padding: 0 72px;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  color: #FFFFFF;
  border-right: solid 1px #fff;
}

@media (max-width: 575px) {
  .section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list .breadcrumbs-list_item {
    padding: 0 0 10px 0 !important;
    text-align: center;
    border: none;
  }
}

.section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list .breadcrumbs-list_item:first-child {
  padding-left: 0;
}

.section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list .breadcrumbs-list_item:last-child {
  padding-right: 0;
  border-right: none;
}

.section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list .breadcrumbs-list_item svg {
  margin-right: 8px;
}

@media (max-width: 680px) {
  .section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list .breadcrumbs-list_item {
    padding: 0 40px;
  }
}

.section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list a:hover {
  color: #0699F5;
}

.section-breadcrumbs.section-breadcrumbs-bg .section-breadcrumbs_list a:hover path {
  fill: #0699F5;
}

.section-breadcrumbs .breadcrumbs-product {
  margin: 0;
}

@media (max-width: 480px) {
  .section-breadcrumbs .breadcrumbs-product svg {
    width: 16px;
    height: 16px;
  }
}

.section-breadcrumbs h3, .section-breadcrumbs h1 {
  text-align: center;
  margin: 8px 0 0 0 !important;
  font-weight: 500 !important;
}

.section-black-top.text-center .section-black-top_wrap {
  justify-content: center;
}

.new-style section.section-default-page.section-unmatched {
  padding: 40px 0 180px 0 !important;
}

.new-style section.section-default-page.section-unmatched h2 {
  margin-bottom: 8px;
  font-size: 32px;
  line-height: 40px;
}

@media (max-width: 321px) {
  .new-style section.section-default-page.section-unmatched h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.new-style section.section-default-page.section-unmatched h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 321px) {
  .new-style section.section-default-page.section-unmatched h4 {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style section.section-default-page.section-unmatched .section-unmatched-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #222222;
}

.new-style section.section-default-page.section-unmatched .section-unmatched-item {
  margin-bottom: 40px;
}

.new-style section.section-default-page.section-unmatched .section-unmatched-item span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #2B2F2F;
}

.new-style section.section-default-page.section-unmatched .section-unmatched-item h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #171717;
  margin: 16px 0;
}

@media (max-width: 576px) {
  .new-style section.section-default-page.section-unmatched .section-unmatched-item {
    margin-bottom: 32px;
  }
  .new-style section.section-default-page.section-unmatched .section-unmatched-item span {
    font-size: 12px;
  }
  .new-style section.section-default-page.section-unmatched .section-unmatched-item h4 {
    font-size: 16px;
    margin: 12px 0;
  }
}

@media (max-width: 321px) {
  .new-style section.section-default-page.section-unmatched .section-unmatched-item {
    margin-bottom: 16px;
  }
  .new-style section.section-default-page.section-unmatched .section-unmatched-item h4 {
    font-size: 14px;
    margin: 4px 0;
  }
}

@media (max-width: 992px) {
  .new-style section.section-default-page.section-unmatched .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .new-style section.section-default-page.section-unmatched .col-lg-6 h2 br {
    display: none;
  }
}

.new-style .jcf-select-drop {
  overflow: auto;
  max-height: 300px;
}

.new-style section.section-default-page.section-synchronize {
  background: linear-gradient(269.88deg, #01558B 0.09%, #1C96E8 99.88%);
  text-align: center;
  padding-bottom: 200px;
}

.new-style section.section-default-page.section-synchronize .section-synchronize_img {
  margin-top: -240px;
}

.new-style section.section-default-page.section-synchronize .section-synchronize_img img {
  max-width: 100% !important;
}

.new-style section.section-default-page.section-synchronize .section-synchronize_icon {
  margin-top: 40px;
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .new-style section.section-default-page.section-synchronize .section-synchronize_icon {
    margin-top: 32px;
  }
}

@media screen and (max-width: 768px) and (max-width: 320px) {
  .new-style section.section-default-page.section-synchronize .section-synchronize_icon {
    margin-top: 28px;
  }
}

@media screen and (max-width: 321px) {
  .new-style section.section-default-page.section-synchronize .section-synchronize_icon svg {
    width: 48px;
    height: 48px;
  }
}

.new-style section.section-default-page.section-synchronize h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-bottom: 8px;
}

@media screen and (max-width: 321px) {
  .new-style section.section-default-page.section-synchronize h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.new-style section.section-default-page.section-synchronize p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #F6F8F8;
}

@media screen and (max-width: 321px) {
  .new-style section.section-default-page.section-synchronize p {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style section.section-default-page.section-supported {
  padding: 120px 0;
}

.new-style section.section-default-page.section-supported h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  color: #171717;
  margin-bottom: 8px;
}

@media screen and (max-width: 321px) {
  .new-style section.section-default-page.section-supported h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.new-style section.section-default-page.section-supported .section-supported_descriptions {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #3D4448;
  margin-bottom: 40px;
}

@media screen and (max-width: 321px) {
  .new-style section.section-default-page.section-supported .section-supported_descriptions {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 24px;
  }
}

.new-style section.section-default-page.section-supported .section-supported_item {
  background: #F6F8F8;
  box-shadow: 0px 10px 25px rgba(27, 54, 72, 0.12);
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 575px) {
  .new-style .tabs-pci_second {
    display: none !important;
  }
}

.new-style .mob-open .tabs-pci_second {
  display: flex !important;
}

.new-style .section-black-top_wrap .tabs-wrap .tabs-pci_second .lc-item a {
  color: #171717 !important;
}

.new-style .section-black-top_wrap .tabs-wrap .tabs-pci_second .lc-item.active a {
  color: #7D7D7D !important;
}

.new-style section.section-default-page.section-jamming {
  background: #F6F8F8;
  padding: 120px 0 112px 0;
  min-height: 655px;
}

.new-style section.section-default-page.section-jamming h2 {
  font-size: 32px;
  margin-bottom: 8px;
}

.new-style section.section-default-page.section-jamming .section-section-jamming_item {
  margin-bottom: 32px;
}

.new-style section.section-default-page.section-jamming .section-section-jamming_item h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 198%;
  color: #171717;
}

@media (max-width: 321px) {
  .new-style section.section-default-page.section-jamming .section-section-jamming_item {
    margin-bottom: 16px;
  }
  .new-style section.section-default-page.section-jamming .section-section-jamming_item h4 {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style section.section-default-page.section-jamming .section-section-jamming_item:last-child {
  margin-bottom: 0;
}

@media (max-width: 321px) {
  .new-style section.section-default-page.section-jamming .section-section-jamming_item p {
    font-size: 12px;
  }
}

.new-style section.section-default-page.section-jamming .tabs-pci {
  border: none;
}

.new-style section.section-default-page.section-jamming .tabs-pci .lc-item {
  padding: 0;
  text-align: left;
  margin-bottom: 20px !important;
}

.new-style section.section-default-page.section-jamming .tabs-pci .lc-item:last-child {
  margin-bottom: 0;
}

.new-style section.section-default-page.section-jamming .tabs-pci .lc-item.active a {
  background: none;
  color: #0076C0 !important;
  border-left: solid 2px #0076C0;
  position: relative;
}

.new-style section.section-default-page.section-jamming .tabs-pci .lc-item.active a::before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #0076C0;
  transform: translate(-50%, -50%);
}

.new-style section.section-default-page.section-jamming .tabs-pci .opener {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #7D7D7D !important;
  padding: 0 0 0 16px;
  height: 52px;
  display: flex;
  align-items: center;
  border-left: solid 2px #D7D7D7;
}

.new-style section.section-default-page.section-jamming .tabs-pci .opener:hover {
  background: none;
}

.new-style section.section-default-page.section-jamming .tabs-pci .opener:after {
  display: none;
}

.new-style section.section-default-page.section-jamming .slider-mob {
  padding: 0 60px;
  display: none;
  margin: 0;
}

@media (max-width: 420px) {
  .new-style section.section-default-page.section-jamming .slider-mob {
    padding: 0;
  }
  .new-style section.section-default-page.section-jamming .slider-mob .slider-mob_content p {
    font-size: 12px;
  }
}

.new-style section.section-default-page.section-jamming .slider-mob .slider-mob_title {
  text-align: center;
  margin-bottom: 32px;
}

.new-style section.section-default-page.section-jamming .slider-mob .slider-mob_title span {
  color: #0076C0 !important;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
}

.new-style section.section-default-page.section-jamming .slider-mob .slider-mob_title span::after {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  width: 52px;
  height: 2px;
  background: #0076C0;
  transform: translate(-50%, 0);
}

.new-style section.section-default-page.section-jamming .slider-mob .slider-mob_title span::before {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #0076C0;
  transform: translate(-50%, -50%);
}

@media (max-width: 420px) {
  .new-style section.section-default-page.section-jamming .mb-5 {
    margin-bottom: 32px !important;
  }
}

@media (max-width: 768px) {
  .new-style section.section-default-page.section-jamming {
    min-height: 0;
    padding-bottom: 80px !important;
  }
  .new-style section.section-default-page.section-jamming .slick-dots {
    bottom: -40px;
  }
  .new-style section.section-default-page.section-jamming .slider-mob {
    display: block;
  }
  .new-style section.section-default-page.section-jamming .tab-desc {
    display: none;
  }
  .new-style section.section-default-page.section-jamming .col-md-6.m-auto {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (max-width: 768px) and (max-width: 420px) {
  .new-style section.section-default-page.section-jamming .col-md-6.m-auto {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .new-style section.section-default-page.section-jamming .col-md-6.m-auto .section-section-jamming_descriptions {
    font-size: 12px;
  }
}

.new-style section.section-default-page.section-software {
  padding: 120px 0;
}

.new-style section.section-default-page.section-software h2 {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #171717;
}

.new-style section.section-default-page.section-software .section-software_descriptions {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #222222;
}

@media (max-width: 321px) {
  .new-style section.section-default-page.section-software .section-software_descriptions {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style section.section-default-page.section-software p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

@media (max-width: 321px) {
  .new-style section.section-default-page.section-software p {
    font-size: 12px;
    line-height: 20px;
  }
}

@media (width: 576px) {
  .new-style section.section-default-page.section-software .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.new-style .hide {
  display: none !important;
}

.new-style .see-more-product .close2 {
  display: none !important;
}

.new-style .show .hide {
  display: block !important;
}

.new-style .show .see-more-product .close2 {
  display: inline-block !important;
}

.new-style .show .see-more-product .open {
  display: none !important;
}

.new-style .section-default-page.section-every-detail {
  padding-top: 120px;
  position: relative;
}

@media (max-width: 767px) {
  .new-style .section-default-page.section-every-detail .row .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) and (max-width: 577px) {
  .new-style .section-default-page.section-every-detail .row {
    justify-content: center;
  }
  .new-style .section-default-page.section-every-detail .row .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 32px;
  }
  .new-style .section-default-page.section-every-detail .row .col-4:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) and (max-width: 577px) and (max-width: 540px) {
  .new-style .section-default-page.section-every-detail .row .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.new-style .section-default-page.section-every-detail .row > div a {
  height: 100%;
}

.new-style .section-default-page.section-every-detail .svg-bg {
  position: absolute;
  top: 0;
}

.new-style .section-default-page.section-every-detail .svg-bg:after {
  display: block;
  content: "";
  background: #F6F8F8;
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
}

.new-style .section-default-page.section-every-detail .container {
  position: relative;
}

.new-style .section-default-page.section-every-detail h2 {
  text-align: center;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-transform: capitalize;
  color: #171717;
}

@media (max-width: 768px) {
  .new-style .section-default-page.section-every-detail h2 {
    margin-bottom: 32px;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .new-style .section-default-page.section-every-detail h2 {
    margin-bottom: 16px;
  }
}

.new-style .section-default-page.section-every-detail .section-every-detail_card {
  background: #FFFFFF;
  box-shadow: 0px 0px 32px rgba(191, 191, 191, 0.36);
  padding: 40px 24px 40px 24px;
  height: 100%;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card:hover {
  box-shadow: 0px 18px 32px rgba(185, 194, 205, 0.46);
}

@media (max-width: 320px) {
  .new-style .section-default-page.section-every-detail .section-every-detail_card:hover {
    box-shadow: 0px 0px 32px rgba(191, 191, 191, 0.36);
  }
}

.new-style .section-default-page.section-every-detail .section-every-detail_card.no-hover {
  box-shadow: 0px 0px 32px rgba(191, 191, 191, 0.36);
}

.new-style .section-default-page.section-every-detail .section-every-detail_card .icon {
  margin-bottom: 24px;
}

@media (max-width: 320px) {
  .new-style .section-default-page.section-every-detail .section-every-detail_card .icon {
    margin-bottom: 16px;
  }
}

.new-style .section-default-page.section-every-detail .section-every-detail_card .title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 8px;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card .text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 32px;
}

@media (max-width: 768px) {
  .new-style .section-default-page.section-every-detail .section-every-detail_card .text {
    margin-bottom: 36px;
  }
}

.new-style .section-default-page.section-every-detail .section-every-detail_card ul {
  margin-bottom: 42px;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card ul li {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  padding: 0;
  display: flex;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card ul li svg {
  margin-right: 8px;
  min-width: 16px;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card ul li:last-child {
  margin-bottom: 0;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card .linl-more {
  position: absolute;
  bottom: 40px;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card .linl-more .link-arrow {
  font-size: 14px;
  display: flex;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card .linl-more .link-arrow svg {
  margin-left: 4px;
}

.new-style .section-default-page.section-every-detail .section-every-detail_card .linl-more .link-arrow:hover path {
  fill: #0699F5;
}

@media (max-width: 992px) {
  .new-style .section-default-page.section-every-detail .section-every-detail_card {
    padding: 32px 16px 40px 16px;
  }
}

@media (max-width: 768px) {
  .new-style .section-default-page.section-every-detail .section-every-detail_card ul {
    display: none;
  }
  .new-style .section-default-page.section-every-detail .section-every-detail_card .title {
    font-size: 16px;
    line-height: 24px;
  }
  .new-style .section-default-page.section-every-detail .section-every-detail_card .icon svg {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 540px) {
  .new-style .section-default-page.section-every-detail .section-every-detail_card .title {
    font-size: 14px;
    line-height: 20px;
  }
  .new-style .section-default-page.section-every-detail .section-every-detail_card .text {
    font-size: 12px;
    line-height: 20px;
  }
  .new-style .section-default-page.section-every-detail .section-every-detail_card ul {
    display: block;
  }
  .new-style .section-default-page.section-every-detail .section-every-detail_card ul li {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style .section-default-page.section-experience {
  padding-top: 120px;
}

.new-style .section-default-page.section-experience .slick-slide {
  min-height: inherit;
}

.new-style .section-default-page.section-experience h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #171717;
  margin-bottom: 40px;
}

.new-style .section-default-page.section-experience .slick-slider.slick-vertical .slick-track {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.new-style .section-default-page.section-experience .slick-slider.slick-vertical .slick-list {
  transition: .5s;
}

.new-style .section-default-page.section-experience .slick-slider.slick-vertical .slick-list.pb-0 {
  margin-bottom: -110px;
}

@media (min-width: 577px) {
  .new-style .section-default-page.section-experience .slick-list {
    height: auto !important;
  }
}

@media (min-width: 576px) {
  .new-style .section-default-page.section-experience .slick-list .slick-track {
    height: 500px !important;
  }
}

.new-style .section-default-page.section-experience .slider-vertical-slide {
  transition: 0.5s;
}

@media (min-width: 576px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide {
    margin-bottom: 50px;
  }
}

.new-style .section-default-page.section-experience .slider-vertical-slide.slick-slide .vertical-slide_img {
  opacity: 0 !important;
  transition-delay: 0s;
}

.new-style .section-default-page.section-experience .slider-vertical-slide.slick-active .vertical-slide_img {
  opacity: 1 !important;
  transition-delay: 2s;
  transition-property: opacity;
  transition-duration: 1s;
  transition-delay: 0.8s;
}

.new-style .section-default-page.section-experience .slider-vertical-slide .title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 43px;
  color: #171717;
  margin-bottom: 8px;
}

@media (max-width: 320px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide .title {
    font-size: 16px;
    line-height: 20px;
  }
}

.new-style .section-default-page.section-experience .slider-vertical-slide .description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
  margin-bottom: 32px;
}

@media (max-width: 577px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide .description {
    margin-bottom: 16px;
  }
}

@media (max-width: 577px) and (max-width: 320px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide .description {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style .section-default-page.section-experience .slider-vertical-slide .experience-item {
  border-top: solid 1px #D7D7D7;
}

.new-style .section-default-page.section-experience .slider-vertical-slide .experience-item p {
  padding: 32px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

@media (max-width: 577px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide .experience-item p {
    padding: 16px 0;
    font-size: 12px;
    line-height: 20px;
  }
}

@media (max-width: 577px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide .experience-item:last-child p {
    padding-bottom: 0;
  }
}

.new-style .section-default-page.section-experience .slider-vertical-slide .vertical-slide_img {
  margin-top: 16px;
}

.new-style .section-default-page.section-experience .slick-dots {
  bottom: -32px;
  display: none !important;
}

@media (max-width: 768px) {
  .new-style .section-default-page.section-experience {
    padding-bottom: 70px !important;
  }
  .new-style .section-default-page.section-experience .slick-dots {
    display: flex !important;
  }
  .new-style .section-default-page.section-experience h2 {
    margin-bottom: 32px;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .new-style .section-default-page.section-experience h2 {
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) and (min-width: 577px) {
  .new-style .section-default-page.section-experience .slick-list .slick-track {
    height: 820px !important;
  }
}

@media (max-width: 768px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide .row {
    flex-direction: column;
  }
  .new-style .section-default-page.section-experience .slider-vertical-slide .row .col-md-6:nth-child(1) {
    order: 1;
  }
  .new-style .section-default-page.section-experience .slider-vertical-slide .row .col-md-6 {
    order: 0;
    margin: 0 auto;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (max-width: 768px) and (max-width: 576px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide .row .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .new-style .section-default-page.section-experience .slider-vertical-slide .row .vertical-slide_img {
    margin-bottom: 32px;
  }
  .new-style .section-default-page.section-experience .slider-vertical-slide .row .vertical-slide_img img {
    margin: 0 auto;
  }
}

.new-style .pci-small-oscilloscope {
  padding: 120px 0;
  z-index: 9;
  position: relative;
}

@media (max-width: 768px) {
  .new-style .pci-small-oscilloscope {
    padding: 40px 0;
  }
}

.new-style .pci-small-oscilloscope .pci-small-row {
  justify-content: space-between;
  width: 100%;
  padding: 0 65px;
}

.new-style .pci-small-oscilloscope .btn-white svg {
  margin-left: 8px;
}

.new-style .pci-small-probes .pci-small-title {
  margin-left: 120px;
}

@media (max-width: 992px) {
  .new-style .pci-small-probes .pci-small-title {
    margin-left: 75px;
  }
}

.new-style .pci-small-probes .pci-small-row {
  justify-content: space-between;
  width: 100%;
  padding: 0 65px;
}

.new-style .table-specifications-scroll {
  display: flex;
  width: 1600px;
}

@media (min-width: 1940px) {
  .new-style .table-specifications-scroll {
    width: 1800px;
  }
}

.new-style .table-specifications-scroll .table-specifications_left {
  min-width: 200px;
}

.new-style .table-specifications-scroll .table-specifications_left .table-left_block {
  padding: 24px 32px 24px 0;
  height: 200px;
}

.new-style .table-specifications-scroll .table-specifications_left .table-left_block.h-auto {
  height: 80px !important;
}

.new-style .table-specifications-scroll .table-specifications_left .table-left_block h4 {
  font-size: 21px;
  line-height: 24px;
  margin: 0 0 4px 0;
}

@media screen and (max-width: 769px) {
  .new-style .table-specifications-scroll .table-specifications_left .table-left_block h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 376px) {
  .new-style .table-specifications-scroll .table-specifications_left .table-left_block h4 {
    font-size: 14px;
    line-height: 18px;
  }
}

.new-style .table-specifications-scroll .table-specifications_left .table-left_block h4 span.active {
  display: none;
}

.new-style .table-specifications-scroll .table-specifications_left .table-left_block h4 span.active.show {
  display: block;
}

.new-style .table-specifications-scroll .table-specifications_left .table-left_block a {
  font-size: 14px;
  line-height: 170%;
}

@media screen and (max-width: 376px) {
  .new-style .table-specifications-scroll .table-specifications_left .table-left_block a {
    font-size: 12px;
  }
  .new-style .table-specifications-scroll .table-specifications_left .table-left_block a i {
    top: 7px;
  }
}

.new-style .table-specifications-scroll .table-specifications_right {
  border-left: solid 1px #D7D7D7;
  border-bottom: solid 1px #D7D7D7;
  border-right: solid 1px #D7D7D7;
  width: 100%;
  overflow: auto;
}

.new-style .table-specifications-scroll .table-right_top .table-scroll-row {
  display: flex;
  align-items: center;
}

.new-style .table-specifications-scroll .table-right_top .table-scroll-row > div {
  border-bottom: solid 1px #D7D7D7;
  height: 80px !important;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-style .table-specifications-scroll .table-right_top h4 {
  font-size: 16px;
  line-height: 152%;
  font-variant: small-caps;
}

@media (max-width: 321px) {
  .new-style .table-specifications-scroll .table-right_top h4 {
    font-size: 12px;
  }
}

.new-style .table-specifications-scroll .table-scroll-row {
  display: flex;
}

.new-style .table-specifications-scroll .table-scroll-block {
  min-width: 244px;
  padding: 24px 16px;
  height: 200px;
  border-bottom: solid 1px #D7D7D7;
  position: relative;
}

.new-style .table-specifications-scroll .table-scroll-block span {
  display: block;
  font-size: 13px;
  line-height: 152%;
  font-variant: small-caps;
  color: #0076C0;
  cursor: pointer;
}

.new-style .table-specifications-scroll .table-scroll-block.open .open-select {
  display: block;
}

.new-style .table-specifications-scroll .table-scroll-block .open-select {
  position: absolute;
  background: #FFF;
  box-shadow: 0px 0px 32px rgba(191, 191, 191, 0.36);
  padding: 8px;
  z-index: 9;
  width: 180px;
  display: none;
  left: 50%;
  transform: translate(-50%, 0);
}

.new-style .table-specifications-scroll .table-scroll-block .open-select .open-select_item {
  color: #3D4448;
  font-size: 16px;
  line-height: 152%;
  font-variant: small-caps;
  padding: 8px;
  cursor: pointer;
  text-align: left;
}

.new-style .table-specifications-scroll .table-scroll-block .open-select .open-select_item.active {
  background: #F6F8F8;
}

.new-style .table-specifications-scroll ::-webkit-scrollbar {
  width: 24px;
  height: 8px;
  background-color: #F3F3F3;
  border-bottom: solid 2px #fff;
  border-top: solid 2px #fff;
  cursor: pointer;
}

.new-style .table-specifications-scroll ::-webkit-scrollbar-thumb {
  background-color: #0076C0;
  height: 8px;
  cursor: pointer;
}

@media (max-width: 321px) {
  .new-style .table-specifications-scroll .table-specifications_left {
    min-width: 150px;
  }
  .new-style .table-specifications-scroll .table-specifications_left .table-left_block {
    padding: 24px 16px 24px 0;
  }
  .new-style .table-specifications-scroll .table-scroll-block {
    min-width: 90px;
  }
}

.new-style .accordion-top.second {
  padding: 48px 0;
}

.new-style .accordion-top.second h3 {
  font-size: 32px;
  line-height: 40px;
}

@media (max-width: 768px) {
  .new-style .accordion-top.second h3 {
    font-size: 28px;
  }
}

.new-style .accordion-top.second .card-header-mob {
  display: none;
}

@media (max-width: 769px) {
  .new-style .accordion-top.second {
    padding: 0;
  }
  .new-style .accordion-top.second .card-header-mob {
    display: block;
  }
  .new-style .accordion-top.second .card-header-mob h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 16px;
    text-transform: uppercase;
    color: #0076BF;
    margin: 0;
  }
  .new-style .accordion-top.second .card-header-mob.show h4 {
    color: #171717;
  }
  .new-style .accordion-top.second .card-header_left-bottom h4 {
    display: none;
  }
  .new-style .accordion-top.second .row {
    display: block;
  }
  .new-style .accordion-top.second .col-md-8 {
    display: block !important;
    max-width: 100%;
    text-align: center;
  }
  .new-style .accordion-top.second .col-md-4 {
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 40px 0 16px 0;
  }
  .new-style .accordion-top.second .col-md-4 .card-header_left-top {
    width: 48px;
    height: 24px;
    margin: 0 16px;
  }
  .new-style .accordion-top.second .col-md-4 .card-header_left-top .switch-toggle__label {
    width: 48px;
    height: 24px;
  }
  .new-style .accordion-top.second .col-md-4 .card-header_left-top .switch-toggle__text::after {
    width: 48px;
    height: 24px;
  }
  .new-style .accordion-top.second .col-md-4 .card-header_left-top .switch-toggle__input:checked ~ .switch-toggle__text::before {
    left: 4px;
  }
  .new-style .accordion-top.second .col-md-4 .card-header_left-bottom {
    width: 130px;
  }
}

@media (max-width: 321px) {
  .new-style .accordion-top.second .card-header-mob h4 {
    font-size: 14px !important;
  }
  .new-style .accordion-top.second .col-md-4 .card-header_left-bottom {
    width: 110px;
  }
  .new-style .accordion-top.second .col-md-4 .card-header_left-bottom p {
    font-size: 14px !important;
  }
}

.new-style .new-h3 {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
}

@media (max-width: 768px) {
  .reset div.mb-120 {
    margin-bottom: 48px !important;
  }
}

@media (max-width: 768px) {
  section.pci-small.new {
    margin-top: 16px !important;
  }
}

.new-style .slick-dots {
  bottom: 0;
}

.new-style .slick-dots li {
  margin: 0 4px 0 0;
  padding: 0;
}

.new-style .slick-dots li:last-child {
  margin-right: 0;
}

.new-style .slick-dots button {
  width: 20px;
  height: 2px;
  background: #D7D7D7;
  border: none !important;
  border-radius: 0;
}

.new-style .slick-dots .slick-active button {
  background: #0076C0;
}

main .tab-holder2 .tab-hidden {
  position: absolute !important;
  left: -9999px !important;
  top: -9999px !important;
  display: block !important;
  width: 100% !important;
}

.section-black-halfBlue .mob-view,
.view-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  section.pci-small.new {
    padding-top: 8px !important;
  }
}

@media (max-width: 320px) {
  section.pci-small.new .pci-small-btn {
    margin-top: 16px !important;
  }
}

@media (max-width: 991px) {
  .comparison.new.new-4 {
    padding-top: 64px;
  }
  .comparison.new.new-4 .comparison-top .row {
    justify-content: center;
  }
  .comparison.new.new-4 .comparison-top .row .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .comparison.new.new-4 .comparison-top .row .col-md-4:last-child {
    display: none;
  }
}

@media (max-width: 991px) and (max-width: 769px) {
  .comparison.new.new-4 .comparison-top .comparison-body_item .descriptions {
    font-size: 14px;
  }
  .comparison.new.new-4 .comparison-top .comparison-body_item span {
    font-size: 28px !important;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white {
    padding: 85px 16px 0 16px;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white .comparison-img {
    width: 100%;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white .comparison-img img {
    min-width: 70%;
    width: 70%;
    margin: 0 auto;
    display: block;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white .comparison-button a.btn-default {
    padding: 6px 10px;
    font-size: 11px;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white .comparison-button .link-arrow {
    font-size: 12px;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white .comparison-select.no-border span {
    font-size: 16px;
  }
}

@media (max-width: 991px) and (max-width: 577px) {
  .comparison.new.new-4 .comparison-top .row {
    margin: 0 -0.5rem;
  }
  .comparison.new.new-4 .comparison-top .row > div {
    padding: 0 0.5rem;
  }
  .comparison.new.new-4 .comparison-top .comparison-select.no-border {
    min-height: 48px;
    line-height: 1px;
  }
}

@media (max-width: 991px) and (max-width: 540px) {
  .comparison.new.new-4 .comparison-top .comparison-img {
    top: -32px !important;
  }
  .comparison.new.new-4 .comparison-top .comparison-select.no-border {
    line-height: 48px;
  }
  .comparison.new.new-4 .comparison-top .comparison-select.no-border small {
    display: none;
  }
  .comparison.new.new-4 .comparison-top .comparison-select.no-border + .comparison-button {
    margin-bottom: 64px;
  }
  .comparison.new.new-4 .comparison-top .comparison-bg-white {
    padding: 32px 8px 0 8px;
  }
  .comparison.new.new-4 .comparison-top .comparison-button {
    display: block;
  }
  .comparison.new.new-4 .comparison-top .comparison-button a.btn-default {
    padding: 6px;
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
  }
  .comparison.new.new-4 .comparison-top .comparison-button .link-arrow {
    display: block;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .section-software .col-lg-3 {
    margin-bottom: 32px;
  }
}

@media (max-width: 992px) and (max-width: 321px) {
  .section-software .col-lg-3 {
    margin-bottom: 16px;
  }
}

@media (max-width: 992px) {
  .section-software .col-lg-3:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 992px) and (width: 992px) {
  .section-software .col-9.m-auto {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .section-software .col-lg-3.col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 992px) and (max-width: 540px) {
  .section-software .col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .desctop-view {
    display: none;
  }
  .view-mobile {
    display: block;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .section-black-top_wrap .section-black-top_left {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    flex-wrap: initial;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (width: 768px) {
  .section-black-top_wrap .section-black-top_left {
    margin-bottom: 8px;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .desctope-view-btn {
    display: block !important;
  }
  .section-black-top_right {
    justify-content: center;
    width: 100%;
  }
  .section-black-top_right .mobile-view-btn {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .new-style section.section-default-page.section-unmatched {
    padding-top: 0 !important;
  }
  .new-style section.section-default-page.section-unmatched .section-unmatched-title {
    text-align: center;
    margin-bottom: 32px;
  }
}

@media (max-width: 992px) and (max-width: 575px) {
  .new-style section.section-default-page.section-unmatched .col-lg-6 .row .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .new-style section.section-default-page.section-synchronize {
    padding-bottom: 200px !important;
  }
}

@media (max-width: 992px) and (max-width: 769px) {
  .new-style section.section-default-page.section-synchronize {
    padding-bottom: 142px !important;
  }
}

@media (max-width: 992px) and (max-width: 769px) and (width: 768px) {
  .new-style section.section-default-page.section-synchronize .row .col-md-8 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (max-width: 992px) and (max-width: 769px) and (max-width: 321px) {
  .new-style section.section-default-page.section-synchronize {
    padding-bottom: 72px !important;
  }
}

@media (max-width: 992px) and (width: 992px) {
  .new-style section.section-default-page.section-synchronize .col-md-8 {
    flex: 0 0 85%;
    max-width: 85%;
  }
}

@media (max-width: 992px) and (max-width: 769px) {
  .new-style section.section-default-page.section-supported {
    padding: 40px 0;
  }
}

@media (max-width: 992px) and (max-width: 769px) and (max-width: 576px) {
  .new-style section.section-default-page.section-supported .section-supported_list .row > div {
    margin-bottom: 16px;
    padding: 0 0.5rem;
  }
  .new-style section.section-default-page.section-supported .section-supported_list .row > div:last-child {
    margin-bottom: 0;
  }
}

@media (width: 992px) {
  .section-black-halfBlue .block-order .col-md-4 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .section-black-halfBlue .block-order .col-md-8 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .section-black-halfBlue .block-order .new-style section.section-default-page.section-unmatched .col-lg-6 + .col-lg-6 {
    padding: 0 80px;
  }
}

@media (width: 992px) {
  .new-style section.section-default-page.section-unmatched .col-lg-6 + .col-lg-6 {
    padding: 0 80px;
  }
  .new-style section.section-default-page.section-software .section-software_img + .row {
    padding: 0 60px;
  }
}

@media (width: 768px) {
  .new-style section.section-default-page.section-unmatched .col-lg-6 + .col-lg-6 {
    padding: 0 80px;
  }
  .new-style section.section-default-page.section-software .section-software_img + .row {
    padding: 0 60px;
  }
}

.mob-only {
  display: none;
}

@media (max-width: 576px) {
  .mob-only {
    display: block;
  }
}

@media (width: 768px) {
  #tab-pci-3 .spec-section .row .col-sm-4,
  #tab-pci-3 .spec-section .row .col-md-4,
  #tab-pci-4 .spec-section .row .col-sm-4,
  #tab-pci-4 .spec-section .row .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  #tab-pci-3 .spec-section .row .col-sm-8,
  #tab-pci-3 .spec-section .row .col-md-8,
  #tab-pci-4 .spec-section .row .col-sm-8,
  #tab-pci-4 .spec-section .row .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

.spec-section #accordion .card > div {
  padding: 0;
}

.spec-section #accordion .card > div .row {
  margin: 0;
}

@media (width: 576px) {
  .spec-section #accordion .card > div .row {
    margin: 0 -1rem !important;
  }
}

.spec-section #accordion .card > div .row [class^="col"] {
  padding: 0;
}

@media (width: 576px) {
  .spec-section #accordion .card > div .row [class^="col"] {
    padding: 0 1rem;
  }
}

.spec-section #accordion .card > div .row [class^="col"] li {
  padding: 0;
}

.spec-section #accordion .card:last-child {
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .spec-section #accordion .accordion-arrow {
    top: 24px !important;
  }
}

@media (max-width: 768px) and (max-width: 321px) {
  .spec-section #accordion .accordion-arrow {
    top: 16px !important;
  }
}

@media (max-width: 321px) {
  .spec-section #accordion .card {
    padding: 16px 0 !important;
  }
  .spec-section #accordion p, .spec-section #accordion li {
    font-size: 12px !important;
  }
  .spec-section #accordion h4 {
    font-size: 14px !important;
  }
  .spec-section #accordion .specifications-list_block {
    margin-bottom: 16px !important;
  }
  .spec-section #accordion .specifications-list_block h4 {
    font-size: 12px !important;
  }
}

@media (max-width: 840px) {
  .new-style section.section-default-page {
    padding: 48px 0 !important;
  }
}

@media (max-width: 840px) and (max-width: 320px) {
  .new-style section.section-default-page {
    padding: 40px 0 !important;
  }
}

@media (max-width: 840px) {
  .new-style section.section-default-page.section-black-halfBlue {
    padding: 40px 0 20px 0 !important;
  }
  .new-style section.section-default-page.spec-section {
    padding-top: 0 !important;
  }
  .new-style section.section-default-page.pt-0 {
    padding-top: 0 !important;
  }
  .new-style section.section-default-page.pb-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 840px) and (max-width: 321px) {
  .new-style section.section-default-page.section-black-link {
    padding: 8px 0 40px 0 !important;
  }
}

@media (max-width: 840px) {
  .new-style section.section-default-page.section-black-top {
    padding: 16px 0 8px 0 !important;
    box-shadow: none;
  }
}

@media (max-width: 840px) and (max-width: 768px) {
  .new-style section.section-default-page.section-black-top {
    padding: 0 !important;
  }
}

@media (max-width: 840px) {
  .new-style section.section-default-page.section-black-top h3 {
    font-size: 18px;
  }
}

@media (max-width: 840px) and (max-width: 575px) {
  .new-style section.section-default-page.section-black-top h3 {
    font-size: 16px !important;
  }
  .new-style section.section-default-page.section-black-top .desctope-view-btn {
    display: none !important;
  }
  .new-style section.section-default-page.section-black-top .section-black-top_right {
    justify-content: center;
  }
}

@media (max-width: 840px) and (max-width: 768px) {
  .new-style section.section-default-page .black-link ul li {
    padding: 0 24px;
  }
}

@media (max-width: 840px) and (max-width: 768px) and (max-width: 576px) {
  .new-style section.section-default-page .black-link ul li {
    width: 100%;
    text-align: left !important;
    padding: 16px 0;
    border-right: none;
    border-bottom: solid 1px #D6D6D6;
  }
  .new-style section.section-default-page .black-link ul li:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

@media (max-width: 840px) {
  .new-style section.section-default-page.section-supported .col-md-3 {
    width: 50%;
  }
  .new-style section.section-default-page.section-supported .col-md-3:nth-child(1), .new-style section.section-default-page.section-supported .col-md-3:nth-child(2) {
    margin-bottom: 32px;
  }
  .new-style section.section-default-page.section-supported .section-supported_item {
    height: 60px;
  }
  .new-style section.section-default-page.section-supported .section-supported_item svg {
    width: 70%;
    margin: 0 auto;
    display: block;
  }
  .new-style section.section-default-page.section-unmatched {
    padding: 40px 0 100px 0 !important;
  }
}

@media (max-width: 840px) and (max-width: 321px) {
  .new-style section.section-default-page.section-unmatched {
    padding-top: 0px !important;
  }
}

@media (max-width: 840px) {
  .new-style section.section-default-page.section-unmatched br {
    display: none;
  }
}

@media (max-width: 840px) and (width: 768px) {
  .new-style section.section-default-page.section-unmatched br {
    display: block;
  }
}

@media (max-width: 840px) {
  .new-style section.section-default-page.section-synchronize .section-synchronize_img {
    margin-top: -140px;
  }
}

@media (max-width: 840px) and (max-width: 640px) {
  .new-style section.section-default-page.section-synchronize .section-synchronize_img {
    margin-top: -100px;
    margin-bottom: 0;
  }
}

@media (max-width: 840px) {
  section.section-black-halfBlue.second .container:after {
    width: 100% !important;
    height: 228px;
    right: 0 !important;
    top: -40px;
  }
}

@media (max-width: 840px) and (max-width: 576px) {
  section.section-black-halfBlue.second .container:after {
    height: 155px;
  }
}

@media (max-width: 840px) and (max-width: 321px) {
  section.section-black-halfBlue.second .container:after {
    height: 120px;
  }
}

@media (max-width: 840px) {
  section.section-black-halfBlue.second .container .black-half-list .black-half-item {
    margin: 0 64px 20px 0;
  }
  section.section-black-halfBlue.second .container .black-half-list .black-half-item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 840px) and (max-width: 575px) {
  section.section-black-halfBlue.second .container .black-half-list .black-half-item {
    width: 50%;
    margin-right: 0;
  }
  section.section-black-halfBlue.second .container .black-half-list .black-half-item:nth-child(2), section.section-black-halfBlue.second .container .black-half-list .black-half-item:nth-child(4) {
    margin-right: 0;
  }
}

@media (max-width: 840px) {
  .section-black-halfBlue .block-order .col-md-4 {
    order: 1;
    min-width: 80%;
  }
  .section-black-halfBlue .block-order .col-md-8 {
    order: 0;
    min-width: 70%;
  }
  .section-black-halfBlue .desc-view {
    display: none;
  }
  .section-black-halfBlue .slider-nav {
    margin-bottom: 40px;
  }
  .section-black-halfBlue .slider-nav .slick-slide.slick-current {
    border-bottom: solid 3px #0076C0 !important;
  }
  .black-halfBlue-link ul {
    margin-bottom: 32px !important;
  }
  .section-black-halfBlue .col-md-8 {
    flex: 100%;
    max-width: 100%;
  }
  .section-black-halfBlue .mob-view {
    display: block;
  }
  section.section-black-halfBlue.second .slider-nav .slick-list .slick-track {
    float: none !important;
    justify-content: center !important;
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  section.section-black-halfBlue.second.big-after .container:after {
    height: 268px;
  }
}

@media (max-width: 576px) {
  section.section-black-halfBlue.second.big-after .container:after {
    height: 210px;
  }
}

@media (max-width: 320px) {
  section.section-black-halfBlue.second.big-after .container:after {
    height: 140px;
  }
}

@media (max-width: 768px) {
  section.section-black-halfBlue.second.big-after2 .container:after {
    height: 220px;
  }
}

@media (max-width: 576px) {
  section.section-black-halfBlue.second.big-after2 .container:after {
    height: 180px;
  }
}

@media (max-width: 320px) {
  section.section-black-halfBlue.second.big-after2 .container:after {
    height: 115px;
  }
}

@media (max-width: 321px) {
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap {
    height: 236px !important;
  }
  .new-style .card-gray.card-gray-second .product-list-slider .product-list-wrap .inner-img,
  .new-style .card-gray.card-gray-second .product-list-slider2 .product-list-wrap .inner-img {
    height: 88px !important;
  }
}

.section-login-new {
  background-color: #F6F8F8;
}

.section-login-new .container.small {
  max-width: 1028px;
}

.section-login-new .form-footer-second {
  display: none;
}

.section-login-new form.new-footer .form-footer-first {
  display: none;
}

.section-login-new form.new-footer .form-footer-second {
  display: block;
}

.section-login-new .form-login a {
  color: #0076C0;
}

.section-login-new .form-login a:hover {
  color: #0699F5;
  text-decoration: none;
}

.section-login-new .language-block .d-flex {
  justify-content: center;
  align-items: center;
}

.section-login-new .language-block label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #7D7D7D;
  margin: 0;
}

.section-login-new .language-block .jcf-select .jcf-select-text span {
  font-size: 14px !important;
  color: #0076C0 !important;
}

.section-login-new .language-block .form-row .jcf-select {
  width: auto;
}

.section-login-new .language-block .form-row .jcf-select .jcf-select-opener {
  margin-left: 5px;
}

.section-login-new .language-block .form-row .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #0076C0 !important;
  border-top: 2px solid #0076C0 !important;
}

.section-login-new.small-container .container {
  max-width: 816px;
}

.section-login-new .form-row input[type=text],
.section-login-new .form-row input[type=email],
.section-login-new .form-row input[type=phone],
.section-login-new .form-row input[type=password],
.section-login-new .form-row textarea,
.section-login-new .form-row select {
  border: 1px solid #D7D7D7;
  border-radius: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  height: 40px;
}

.section-login-new .form-row .selected + .jcf-select .jcf-select-text span {
  color: #171717 !important;
}

.section-login-new .form-row .jcf-select {
  border: 1px solid #D7D7D7;
  height: 40px;
}

.section-login-new .form-row .jcf-select .jcf-select-text {
  padding-left: 8px !important;
  background: none !important;
}

.section-login-new .form-row .jcf-select .jcf-select-text span {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  color: #7D7D7D !important;
}

.section-login-new .form-row .jcf-select .jcf-select-opener {
  height: 35px !important;
}

.section-login-new .form-row .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #231F20 !important;
  border-top: 2px solid #231F20 !important;
}

.section-login-new .form-row .jcf-select .jcf-select-drop-content .jcf-option {
  font-weight: 400;
}

.section-login-new .input-opacity {
  display: none;
}

.section-login-new .input-opacity.view {
  display: block;
}

.section-login-new.section-login-half .login-half-block-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section-login-new.section-login-half .login-half-block-wrap .login-half-block {
  width: 376px;
  position: relative;
}

.section-login-new.section-login-half .login-half-block-wrap .login-half-block:first-child:after {
  display: block;
  content: "";
  background: #C4C4C4;
  width: 1px;
  height: 100%;
  position: absolute;
  left: calc(100% + 40px);
  top: 0;
}

.section-login-new.section-login-half .login-half-block-wrap .login-half-block p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.section-login-new.section-login-half .form-login > .row {
  margin-left: 0 -1rem !important;
  margin-right: 0 -1rem !important;
}

.section-login-new.section-login-half .form-login > .row > .col-md-6 {
  padding: 0 1rem;
}

.reset .section-login-new select.select-blue + .jcf-select {
  border: none;
  background: none;
}

.reset .section-login-new select.select-blue + .jcf-select .jcf-select-text {
  background: none;
  padding: 0;
}

.reset .section-login-new select.select-blue + .jcf-select .jcf-select-text span {
  color: #0076C0 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.reset .section-login-new select.select-blue + .jcf-select .jcf-select-opener {
  width: 16px;
  height: 16px;
  background: none;
}

.reset .section-login-new select.select-blue + .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #0076C0;
  border-top: 2px solid #0076C0;
  width: 8px;
  height: 8px;
}

.reset .section-login-new select.select-blue + .jcf-select .jcf-select-drop {
  min-width: 190px;
}

.reset .section-login-new select.select-blue + .jcf-select .jcf-select-drop .jcf-select-drop-content .jcf-option {
  font-size: 14px;
}

.reset .pbb-120 {
  padding-bottom: 120px !important;
}

.skew-style .btn-default:disabled {
  background-color: #D7D7D7;
}

.reset .section-login-new .container.small .explore-content {
  padding: 40px 80px;
  border: none;
}

@media (max-width: 640px) {
  .reset .section-login-new .container.small .explore-content {
    padding: 40px 20px;
  }
}

.reset .section-login-new .container.small .explore-content p {
  color: #3D4448;
}

.reset .section-login-new .container.small .explore-content input::placeholder,
.reset .section-login-new .container.small .explore-content textarea::placeholder,
.reset .section-login-new .container.small .explore-content select {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7D7D7D;
}

.reset .section-login-new .container.small .explore-content .jcf-select-opener:after {
  border-right: 2px solid #231F20;
  border-top: 2px solid #231F20;
}

.reset .section-login-new .container.small .explore-content .password-control {
  position: absolute;
  right: 24px;
  top: 38px;
  cursor: pointer;
}

.reset .section-login-new .container.small .explore-content .password-control .close-password {
  display: none;
}

.reset .section-login-new .container.small .explore-content .password-control.view {
  top: 39px;
}

.reset .section-login-new .container.small .explore-content .password-control.view .close-password {
  display: block;
}

.reset .section-login-new .container.small .explore-content .password-control.view .show-password {
  display: none;
  margin-top: -5px;
}

.reset .section-login-new .container.small .explore-content .change-password .password-close {
  position: absolute;
  right: 30px;
  top: 38px;
}

.reset .section-login-new .container.small .explore-content .change-password span.active {
  display: none;
}

.reset .section-login-new .container.small .explore-content .change-password.change-password-active span.first {
  display: none;
}

.reset .section-login-new .container.small .explore-content .change-password.change-password-active span.active {
  display: inline-block;
}

.reset .section-login-new .container.small .explore-content .change-password input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0076C0;
}

.reset .section-login-new .container.small .explore-content .change-password label.error {
  top: 90%;
}

.reset .section-login-new .container.small .explore-content .change-password label:not(.error) {
  display: flex;
  justify-content: space-between;
}

.reset .section-login-new .container.small .explore-content .change-password label:not(.error) span {
  color: #171717;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.reset .section-login-new .container.small .explore-content .change-password label:not(.error) span b {
  color: #7d7d7d;
  font-style: normal;
  font-weight: 400;
}

.reset .section-login-new .container.small .explore-content .change-password label:not(.error) a {
  color: #0076C0;
  margin-left: 5px;
  font-weight: 500;
}

.reset .section-login-new .container.small .explore-content .change-password label:not(.error) a:hover {
  color: #0699F5;
  text-decoration: none;
}

.reset .section-login-new .container.small .explore-content .form-row {
  margin: 0 -1rem;
}

.reset .section-login-new .container.small .explore-content .form-row [class^="col"] {
  padding: 0 1rem;
  max-width: 440px;
}

@media (max-width: 766px) {
  .reset .section-login-new .container.small .explore-content .form-row [class^="col"] {
    max-width: 100%;
  }
}

.reset .section-login-new .container.small .explore-content label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
}

.reset .section-login-new .container.small .explore-content h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #171717;
  text-transform: capitalize;
}

.reset .section-login-new .container.small .explore-content .sublime-title {
  font-size: 16px;
  line-height: 24px;
}

.reset .section-login-new .container.small .explore-content .info-text p {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: top;
  justify-content: center;
}

.reset .section-login-new .container.small .explore-content .info-text p i {
  margin-right: 12px;
}

.reset .section-login-new .container.small .explore-content h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 32px;
  position: relative;
}

.reset .section-login-new .container.small .explore-content form h4:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  background-color: #D7D7D7;
  height: 1px;
  width: 100%;
}

.reset .section-login-new .container.small .explore-content form h4 span {
  background: #fff;
  position: relative;
  z-index: 99;
  padding-right: 16px;
}

.reset .section-login-new .container.small .explore-content label.error {
  position: absolute;
  width: 100%;
  right: 16px;
  top: -6px;
  text-align: right;
  font-weight: 400;
}

.reset .section-login-new .container.small .explore-content .prompt {
  font-size: 14px;
  line-height: 20px;
  color: #7D7D7D;
}

.reset .section-login-new .container.small .explore-content .form-checkbox {
  margin-bottom: 16px;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .checkbox-label .checkbox-icon {
  width: 20px;
  height: 20px;
  border: solid 1px #D7D7D7;
  border-radius: 0;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .checkbox-label input + label.error + .checkbox-icon {
  border: solid 1px #D7D7D7 !important;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .checkbox-label input:checked + .checkbox-icon,
.reset .section-login-new .container.small .explore-content .form-checkbox .checkbox-label input:checked + label + .checkbox-icon {
  background: url(./images/checkbox-bg-2.svg);
  background-size: contain;
  border: none !important;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .row > div {
  max-width: 100%;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .checkbox-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .checkbox-label .checkbox-icon {
  margin-top: 2px;
  min-width: 20px;
  min-height: 20px;
  background-size: cover;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .checkbox-label a {
  color: #0076C0;
  margin-left: 5px;
  font-weight: 500;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .checkbox-label a:hover {
  color: #0699F5;
  text-decoration: none;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .form-submit {
  max-width: 408px;
  width: 100%;
  height: 40px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
}

.reset .section-login-new .container.small .explore-content .form-checkbox .form-submit:not(:disabled):hover {
  background-color: #0699F5;
  border-color: #0699F5;
}

.reset .section-login-new .container.small .explore-content .form-checkbox a.sign-out {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0076C0;
  display: block;
}

.reset .section-login-new .container.small .explore-content .form-checkbox a.sign-out:hover {
  color: #0699F5;
  text-decoration: none;
}

.dropdown-item.link-signin {
  position: relative;
}

.dropdown-item.link-signin .drop {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: auto;
  z-index: 3;
  right: 50%;
  transform: translate(50%, 0);
  left: auto;
  top: -9999em;
  min-width: 180px;
  overflow: hidden;
  box-shadow: 0 0.5rem 0.75rem #ececec;
}

.dropdown-item.link-signin:hover > .drop {
  visibility: visible;
  opacity: 1;
  top: 100%;
  transition: opacity 250ms ease, max-height 650ms ease, visibility 0s linear 0s, top 0s linear 0s;
  right: 0;
}

.dropdown-item.link-signin:hover > .drop li {
  text-transform: capitalize;
}

.oscilloscope-mobile {
  display: none;
}

@media (max-width: 769px) {
  .oscilloscope-mobile {
    display: block;
  }
}

.small-tabs {
  padding-top: 40px;
}

.section-experience#stick .link-arrow {
  font-size: 14px !important;
  margin-top: 16px;
  position: relative;
  z-index: 9999999;
  cursor: pointer;
}

.section-experience#stick .link-arrow svg {
  margin-left: 8px;
}

.section-experience#stick .link-arrow:hover path {
  fill: #0699F5;
}

@media (width: 576px) {
  .section-experience#stick .slick-list.draggable {
    height: 650px !important;
  }
}

.section-experience#stick.second {
  height: 250vh;
}

.section-experience#stick.second .col-md-6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-experience#stick.second .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #171717;
  margin-bottom: 16px;
}

.section-experience#stick.second .vertical-slide_img {
  display: flex;
  justify-content: center;
}

.section-experience#stick.second .section-content .slider-vertical-slide {
  height: 150vh;
}

.section-accordion #accordion .card:first-child .show .card-body.top {
  margin-top: -120px;
}

.black-icon h4:hover {
  color: #0699F5;
  cursor: pointer;
}

.black-icon h4:hover + svg path {
  fill: #0699F5;
}

@media (min-width: 991px) {
  .pb-450 {
    padding-bottom: 450px;
  }
  .section-experience#stick {
    position: sticky;
    top: 10px;
    height: 400vh;
  }
  .section-experience#stick > .container {
    position: sticky;
    top: 135px;
  }
  .section-experience#stick .section-content {
    position: sticky;
    width: 100%;
    top: 235px;
  }
  .section-experience#stick .section-content .slider-vertical-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    height: 100vh;
  }
  .section-experience#stick .section-content .slider-vertical-slide.active {
    opacity: 1;
  }
  .section-experience#stick .section-content .slider-vertical-slide.active + .active {
    opacity: 0 !important;
  }
}

.reset .section-login-new .container .explore-content .form-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.reset .section-login-new .container .explore-content .form-footer p {
  margin: 0 8px 0 0;
  font-size: 14px;
}

.reset .section-login-new .container .explore-content .form-footer a {
  color: #0076C0;
  font-weight: 500;
}

.reset .section-login-new .container .explore-content .form-footer a:hover {
  color: #0699F5;
  text-decoration: none;
}

.reset .section-login-new .container .explore-content .form-footer i {
  margin: 0 8px;
}

header#header .dropdown-item.dropdown-languages {
  display: block;
}

@media (max-width: 320px) {
  header#header .navbar-brand svg {
    width: 100%;
  }
}

.reset.new-skew-style #main.new-style .section-overview-menu .tabs-pci a {
  font-weight: 500 !important;
}

.new-style section.section-default-page.section-overview {
  padding-bottom: 120px;
}

.new-style section.section-default-page.section-overview .section-overview-content {
  display: flex;
  position: relative;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci {
  border-left: solid 2px #D7D7D7;
  border-bottom: 0;
  min-width: 422px;
  position: sticky;
  top: 134px;
}

@media (max-width: 992px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci {
    min-width: 322px;
  }
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci.top {
  top: 40px;
}

@media (max-width: 768px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci {
    display: none;
  }
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci .lc-item {
  margin-bottom: 24px;
  padding: 0 0 0 16px;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci .lc-item:last-child {
  margin-bottom: 0;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci .lc-item a {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: #171717 !important;
  text-align: left;
  background: none;
  padding: 0;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci .lc-item.anchor-active {
  position: relative;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci .lc-item.anchor-active::before {
  width: 2px;
  height: 24px;
  left: -2px;
  top: 0;
  background: #0076C0;
  display: block;
  content: "";
  position: absolute;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci .lc-item.anchor-active a {
  color: #7D7D7D !important;
  font-weight: 500 !important;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text {
  width: 100%;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-1,
.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-2,
.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-3,
.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-4,
.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-5,
.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-6,
.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-7,
.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-8,
.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text #overview-9 {
  position: absolute;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  color: #171717;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 i {
  display: none;
}

@media (max-width: 992px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 {
    font-size: 24px !important;
    line-height: 32px;
  }
}

@media (max-width: 992px) and (max-width: 321px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 span {
    padding-right: 25px;
  }
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
  margin: 0;
}

@media (max-width: 321px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text p {
    font-size: 12px;
    line-height: 20px;
  }
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text .collapse {
  display: block;
  padding: 0;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text .collapse li {
  color: #3D4448;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text table th {
  background-color: #F6F8F8;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-variant: small-caps;
  color: #3D4448;
  padding: 16px;
  text-transform: uppercase;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text table tr {
  background: none !important;
  border-bottom: solid 1px #D7D7D7;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text table tr:last-child {
  border: none;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text table tr td {
  padding: 32px 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #3D4448;
  vertical-align: top;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-text table tr td:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

@media (max-width: 480px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text table tr th,
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text table tr td {
    padding: 16px 8px;
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text {
    margin-bottom: 40px;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text .section-overview-item {
    padding: 24px 0;
    border-bottom: solid 1px #EAEDEE;
    margin: 0 !important;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text .section-overview-item:first-child {
    padding-top: 0;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text .section-overview-item [class^="col"] {
    padding: 0 !important;
  }
}

@media (max-width: 768px) and (max-width: 321px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text .section-overview-item {
    padding: 32px 0;
  }
}

@media (max-width: 768px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text .section-overview-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 {
    margin: 0;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 i {
    display: block;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 i svg {
    transform: scale(1.6);
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 i svg {
    transform: scale(1.25);
  }
}

@media (max-width: 768px) {
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 i.open {
    display: none;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 i.open.collapsed {
    display: block;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 i.open.collapsed + .collapsed {
    display: none;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 .open.collapsed + .accordion-close {
    display: none;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text h2 + div {
    margin-top: 16px;
  }
  .new-style section.section-default-page.section-overview .section-overview-content .section-overview-text .collapse:not(.show) {
    display: none;
    padding: 0 !important;
  }
}

.mqn2-h7v {
  position: relative;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px auto 6px auto;
}

@media (max-width: 767px) {
  .mqn2-h7v {
    margin: 30px auto 30px auto;
    order: 3;
  }
}

.mqn2-h7w {
  display: flex;
  left: 0;
  right: 0;
}

.mqn2-h7v .mqn2-ad4 {
  height: 8px;
  width: 8px;
  -webkit-transform: none;
  transform: none;
}

@-webkit-keyframes mqn2-custom-oriole-hero-copygroup {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes mqn2-custom-oriole-hero-copygroup {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes mqn2-custom-oriole-hero-headline {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes mqn2-custom-oriole-hero-headline {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes mqn2-custom-oriole-hero-body {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes mqn2-custom-oriole-hero-body {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes mqn2-custom-oriole-hero-cta {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes mqn2-custom-oriole-hero-cta {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes mqn2-custom-oriole-hero-video {
  0% {
    opacity: 0;
    -webkit-transform: translateY(35px);
    transform: translateY(35px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes mqn2-custom-oriole-hero-video {
  0% {
    opacity: 0;
    -webkit-transform: translateY(35px);
    transform: translateY(35px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.mqn2-ivz {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #dfebc0;
  -webkit-transform: scale(var(--scale));
  transform: scale(var(--scale));
  transition: .05s transform linear;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  will-change: transform;
}

@media (max-width: 1023px) {
  .mqn2-ivz {
    -webkit-transform: scale(var(--mobile-scale));
    transform: scale(var(--mobile-scale));
    border-radius: var(--mobile-border-radius);
  }
}

@media (min-width: 1024px) {
  .mqn2-ivz {
    border-radius: var(--desktop-border-radius);
  }
}

.mqn2-ixz {
  background-color: #fafafa !important;
}

.mqn2-ivz.video-ready .mqn2-iw3 {
  -webkit-animation: 1s cubic-bezier(0.1, 0, 0.16, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-copygroup;
  animation: 1s cubic-bezier(0.1, 0, 0.16, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-copygroup;
}

@media (max-width: 1023px) {
  .mqn2-ivz.video-ready .mqn2-iw3 {
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
  }
}

.mqn2-ivz.video-ready .mqn2-iw4 {
  -webkit-animation: 1s cubic-bezier(0.1, 0, 0.08, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-headline;
  animation: 1s cubic-bezier(0.1, 0, 0.08, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-headline;
}

@media (max-width: 1023px) {
  .mqn2-ivz.video-ready .mqn2-iw4 {
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
  }
}

.mqn2-ivz.video-ready .mqn2-j2k {
  -webkit-animation: 1s cubic-bezier(0.1, 0, 0.08, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-body;
  animation: 1s cubic-bezier(0.1, 0, 0.08, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-body;
}

@media (max-width: 1023px) {
  .mqn2-ivz.video-ready .mqn2-j2k {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
  }
}

.mqn2-ivz.video-ready .mqn2-iw5 {
  -webkit-animation: 1s cubic-bezier(0.1, 0, 0.09, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-cta;
  animation: 1s cubic-bezier(0.1, 0, 0.09, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-cta;
}

@media (max-width: 1023px) {
  .mqn2-ivz.video-ready .mqn2-iw5 {
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
  }
}

.mqn2-ivz.video-ready .mqn2-iw2 {
  -webkit-animation: 1s cubic-bezier(0.1, 0, 0.15, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-video;
  animation: 1s cubic-bezier(0.1, 0, 0.15, 1) 0s 1 normal forwards mqn2-custom-oriole-hero-video;
}

@media (max-width: 1023px) {
  .mqn2-ivz.video-ready .mqn2-iw2 {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
  }
}

.mqn2-iw0 {
  display: grid;
}

@media (max-width: 599px) {
  .mqn2-iw0 {
    --grid-gap: 24px;
    --grid-gap-no-unit: 24;
    --grid-column-width: calc( ( (100% - (32px) - (3 * 24px) ) / 4 ));
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .mqn2-iw0 {
    --grid-gap: 24px;
    --grid-gap-no-unit: 24;
    --grid-column-width: calc( ((min(100vw, 600px)) - 48px - (11 * 24px)) / 12 );
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .mqn2-iw0 {
    --grid-gap: 24px;
    --grid-gap-no-unit: 24;
    --grid-column-width: calc( ( ( 100vw - (48px) - (11 * 24px) ) / 12 ));
  }
}

@media (min-width: 1440px) {
  .mqn2-iw0 {
    --grid-gap: 24px;
    --grid-gap-no-unit: 24;
    --grid-column-width: calc( ((min(100vw, 1392px)) - (11 * 24px)) / 12 );
  }
}

@media (max-width: 599px) {
  .mqn2-iw0 {
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .mqn2-iw0 {
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .mqn2-iw0 {
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
}

@media (min-width: 1440px) {
  .mqn2-iw0 {
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
}

@media (max-width: 599px) {
  .mqn2-iw0 {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 16px;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .mqn2-iw0 {
    grid-template-columns: repeat(12, 1fr);
    padding: 0 24px;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .mqn2-iw0 {
    grid-template-columns: repeat(12, 1fr);
    padding: 0 24px;
  }
}

@media (min-width: 1440px) {
  .mqn2-iw0 {
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
    max-width: 1392px;
  }
}

[mqn-grid-overlay=true] .mqn2-iw0 > * {
  box-shadow: inset 0 0 0 1px orchid;
}

.mqn2-iw1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 599px) {
  .mqn2-iw1 {
    grid-column-end: span 4;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .mqn2-iw1 {
    grid-column-start: 1;
    grid-column-end: span 12;
  }
}

@media (min-width: 1024px) {
  .mqn2-iw1 {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
}

@media (max-width: 599px) {
  .mqn2-iw2 {
    grid-column-end: span 4;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 40px;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .mqn2-iw2 {
    grid-column-start: 1;
    grid-column-end: span 12;
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media (min-width: 1024px) {
  .mqn2-iw2 {
    grid-column-start: 7;
    grid-column-end: span 5;
  }
}

.mqn2-iy3 {
  transition: 1s opacity ease;
}

.mqn2-iy4 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 1s opacity ease;
}

@media (min-width: 1024px) {
  .mqn2-iy4 {
    display: none;
  }
}

.mqn2-iw3 {
  will-change: transform;
}

@media (max-width: 1023px) {
  .mqn2-iw3 {
    text-align: center;
  }
}

.mqn2-iw4 {
  --ds-annotation: "Expressive Display 2";
  font-weight: 600;
  font-family: "Google Sans Display","Google Sans","Roboto","Arial",sans-serif;
  color: #485861;
  text-align: center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  opacity: 0;
}

@media (max-width: 599px) {
  .mqn2-iw4 {
    max-width: calc(272px + 88 * (100vw - 360px) / 239);
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 361px) {
  .mqn2-iw4 {
    max-width: 272px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .mqn2-iw4 {
    max-width: 456px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1023px) {
  .mqn2-iw4 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0;
    letter-spacing: -2px;
  }
}

@media (min-width: 1024px) {
  .mqn2-iw4 {
    font-size: 64px;
    line-height: 68px;
    letter-spacing: 0;
    letter-spacing: -4px;
  }
}

.mqn2-iw4 sup {
  top: 0;
  vertical-align: super;
}

@media (max-width: 767px) {
  .mqn2-iw4 sup {
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0;
  }
}

@media (min-width: 768px) {
  .mqn2-iw4 sup {
    font-size: 24px;
    line-height: 1;
    letter-spacing: 0;
  }
}

.mqn2-j2k {
  --ds-annotation: "Standard Body";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: #485861;
  text-align: center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  opacity: 0;
}

@media (max-width: 599px) {
  .mqn2-j2k {
    max-width: calc(272px + 88 * (100vw - 360px) / 239);
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 361px) {
  .mqn2-j2k {
    max-width: 272px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .mqn2-j2k {
    max-width: 456px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .mqn2-j2k {
    max-width: 524px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1023px) {
  .mqn2-j2k {
    margin-top: 24px;
  }
}

@media (min-width: 1024px) {
  .mqn2-j2k {
    margin-top: 16px;
  }
}

.mqn2-iw5 {
  display: flex;
  margin-top: 24px;
  justify-content: center;
  will-change: transform;
  opacity: 0;
}

.mqn2-iw5 .mqn2-af4.mqn-ext-button--ds-mid-emphasis-grey {
  border-color: #485861;
}

.mqn2-iw5 .mqn2-af4.mqn-ext-button--ds-mid-emphasis-grey:not(:hover) .mqn2-af6 {
  color: #485861;
}

.mqn2-iw2 {
  opacity: 0;
}

.mqn2-ivz.video-play-primary .mqn2-iy4 {
  z-index: 1;
  opacity: 0;
}

.mqn2-ivz.video-play-primary .mqn2-iy3 {
  z-index: 2;
  opacity: 1;
}

.mqn2-ivz.video-play-secondary .mqn2-iy4 {
  z-index: 1;
  opacity: 1;
}

.mqn2-ivz.video-play-secondary .mqn2-iy3 {
  z-index: 2;
  opacity: 0;
}

.mqn2-ivz.video-play-immediate .mqn2-iy3, .mqn2-ivz.video-play-immediate .mqn2-iy4 {
  transition-duration: 0s;
}

.reset .dynamic-new .angle-small.small .slick-dots {
  display: none;
}

.reset .dynamic-new #accordion .card .row [class^="col"] {
  padding: 0;
}

.reset .dynamic-new .small-tabs {
  padding-top: 0;
}

.reset .dynamic-new .small-tabs .block-view .lc-item a {
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 140% !important;
}

.reset .dynamic-new .section-dynamic h2 {
  margin-bottom: 24px;
}

.reset .dynamic-new .section-dynamic .page-text p {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 150%;
  color: #231F20;
}

.reset .dynamic-new .section-dynamic .page-text ul {
  padding-left: 8px;
}

.reset .dynamic-new .section-dynamic .page-text .list-dots li {
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #231F20;
  margin-bottom: 8px;
}

.reset .dynamic-new .section-dynamic .page-button {
  margin-top: 32px;
  margin-bottom: 24px;
}

.reset .dynamic-new .section-dynamic .page-button a {
  min-width: 188px;
  margin-bottom: 0;
}

.reset .dynamic-new .section-dynamic .page-links-new,
.reset .dynamic-new .section-dynamic .page-links-video {
  display: flex;
  flex-wrap: wrap;
}

.reset .dynamic-new .section-dynamic .page-links-new a,
.reset .dynamic-new .section-dynamic .page-links-video a {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #4CAF50;
  margin-right: 27px;
  display: flex;
  align-items: center;
}

.reset .dynamic-new .section-dynamic .page-links-new a:hover,
.reset .dynamic-new .section-dynamic .page-links-video a:hover {
  text-decoration: underline;
}

.reset .dynamic-new .section-dynamic .page-links-new a svg,
.reset .dynamic-new .section-dynamic .page-links-video a svg {
  margin-left: 4px;
}

.reset .dynamic-new .section-dynamic .page-links-new a:last-child,
.reset .dynamic-new .section-dynamic .page-links-video a:last-child {
  margin-right: 0;
}

.reset .dynamic-new .section-dynamic .page-links-video a:first-child {
  margin-right: 13px;
  padding-right: 13px;
  border-right: 1px solid #C4C4C4;
}

.reset .dynamic-new .section-dynamic .page-links-video a:last-child {
  border-right: none;
}

.reset .dynamic-new #accordion {
  margin-top: 24px;
}

.reset .dynamic-new #accordion h3 {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #231F20;
}

.reset .dynamic-new #accordion .card-header {
  padding: 0;
}

.reset .dynamic-new #accordion .card:first-child .show .card-body {
  margin-top: 0;
}

.reset .dynamic-new #accordion .card-body {
  padding: 0;
  margin-top: 0 !important;
}

.reset .dynamic-new #accordion .card:first-child {
  border-top: solid 1px #EAEDEE;
}

.reset .dynamic-new #accordion .card .accordion-arrow {
  right: 0;
  top: 27px;
}

.reset .dynamic-new h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #231F20;
  margin-bottom: 24px;
}

.reset .dynamic-new .link-arrow.grean {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #4CAF50;
}

.reset .dynamic-new .section-dynamic-tabs {
  margin-bottom: 40px;
}

.reset .dynamic-new .section-dynamic-tabs .block-view .tabs-pci {
  justify-content: center;
}

.reset .dynamic-new .section-dynamic-tabs .block-view .tabs-pci .lc-item {
  margin-right: 8px;
}

.reset .dynamic-new .section-dynamic-tabs .block-view .tabs-pci .lc-item a {
  padding: 12px 16px;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #231F20;
}

.reset .dynamic-new .section-dynamic-tabs .block-view .tabs-pci .lc-item a::before {
  display: none;
}

.reset .dynamic-new .section-dynamic-tabs .block-view .tabs-pci .lc-item.active {
  background: #0076BF;
}

.reset .dynamic-new .section-dynamic-tabs .block-view .tabs-pci .lc-item.active a {
  color: #fff;
}

.reset .dynamic-new .section-resources-new.second .row.third {
  border-bottom: none;
}

.reset .dynamic-new .section-resources-new.second .row.third .section-resources_item {
  height: auto;
}

.reset .dynamic-new .section-resources-new.second .row.third .section-resources_item .title {
  font-size: 24px;
  line-height: 140%;
  margin: 0;
  text-align: center;
}

.reset .dynamic-new .section-resources-new.second .row.five .section-resources_item {
  height: 324px;
}

.reset .dynamic-new .section-resources-new.second .row.five .section-resources_item .title {
  font-size: 24px;
  line-height: 140%;
  margin: 0 0 16px 0;
}

.reset .dynamic-new .section-resources-new.second.pb-60 {
  padding-bottom: 60px !important;
}

.reset .dynamic-new .section-resources_slide .slick-dots {
  bottom: -45px;
}

.reset .dynamic-new .section-resources_slide .slick-dots li {
  margin-left: 12px;
  padding: 0;
}

.reset .dynamic-new .section-resources_slide .slick-dots li button {
  width: 48px;
  height: 4px;
  background: #C4C4C4;
  border-radius: 0;
  border: none;
}

.reset .dynamic-new .section-resources_slide .slick-dots li.slick-active button {
  background: #0076C0;
}

.reset .dynamic-new .section-resources_slide .slick-dots li:first-child {
  margin-left: 0;
}

.reset .dynamic-new .webinars-tab {
  margin-bottom: 40px;
}

.reset .dynamic-new .webinars-tab-item h2 {
  margin-bottom: 24px;
}

.reset .dynamic-new .webinars-tab-item p {
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #231F20;
  margin-bottom: 24px;
}

.reset .dynamic-new .webinars-tab-item .webinars-tab-item_link li {
  padding: 0;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
}

.reset .dynamic-new .webinars-tab-item .webinars-tab-item_link li span {
  color: #231F20;
  font-weight: 500;
  margin-right: 4px;
}

.reset .dynamic-new .webinars-tab-item .webinars-tab-item_link li:last-child {
  margin-bottom: 0;
}

.reset .dynamic-new .webinars-tab-item .webinars-tab-item_link li a {
  color: #0076C0;
  font-weight: 500;
  font-size: 15px;
}

.reset .dynamic-new .webinars-tab-item .webinars-tab-item_link li a:hover {
  text-decoration: underline;
}

.reset .dynamic-new .webinars-tab-item .webinars-tab-item_link li svg {
  margin-left: 4px;
}

.section-ts {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 1.25rem 0;
}

@media (min-width: 576px) {
  .section-ts {
    padding: 2rem 0;
  }
}

@media (min-width: 768px) {
  .section-ts {
    padding: 4rem 0 2.5rem;
  }
}

@media (min-width: 1200px) {
  .section-ts {
    padding: 1.5rem 0 0;
  }
}

.section-ts:before {
  bottom: 0;
  transform-origin: 0;
  transform: skewY(-4.8deg);
}

.section-ts:after {
  bottom: 0;
  transform-origin: 0;
  transform: skewY(4.8deg);
}

@media (min-width: 1200px) {
  .section-ts .title {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .section-ts .section-heading p {
    font-size: 1.25rem;
  }
}

@media (min-width: 992px) {
  .section-ts p {
    font-size: 1.5rem;
  }
}

.section-what {
  color: rgba(255, 255, 255, 0.6);
  position: relative;
  padding: 1.25rem 0;
}

@media (min-width: 576px) {
  .section-what {
    padding: 2rem 0;
  }
}

@media (min-width: 768px) {
  .section-what {
    padding: 4rem 0 2.5rem;
  }
}

@media (min-width: 1200px) {
  .section-what {
    padding-top: 8.6%;
    padding-bottom: 4.3%;
  }
}

@media (min-width: 1200px) {
  .section-what p {
    font-size: 1.25rem;
  }
}

.section-gray-dark .title {
  color: #fff;
}

.section-external-validation {
  background-color: #fff;
  padding: 1.25rem 0;
}

@media (min-width: 576px) {
  .section-external-validation {
    padding: 2rem 0;
  }
}

@media (min-width: 768px) {
  .section-external-validation {
    padding: 4rem 0 2.5rem;
  }
}

@media (min-width: 1200px) {
  .section-external-validation {
    padding: 4.3% 0;
    font-size: 1.25rem;
  }
}

.section-valid, .section-provide {
  padding: 1.25rem 0;
  color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 576px) {
  .section-valid, .section-provide {
    padding: 2rem 0;
  }
}

@media (min-width: 768px) {
  .section-valid, .section-provide {
    padding: 4rem 0 2.5rem;
  }
}

@media (min-width: 1200px) {
  .section-valid, .section-provide {
    padding: 4.3% 0 8.6%;
  }
}

@media (min-width: 1200px) {
  .section-valid .section-heading p, .section-provide .section-heading p {
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .section-provide {
    padding: 5rem 0;
  }
}

@media (min-width: 1200px) {
  .section-provide p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
}

.section-different {
  position: relative;
  z-index: 1;
  padding: 1.25rem 0;
  background-color: #fff;
}

@media (min-width: 576px) {
  .section-different {
    padding: 2rem 0;
  }
}

@media (min-width: 768px) {
  .section-different {
    padding: 4rem 0 2.5rem;
  }
}

@media (min-width: 1200px) {
  .section-different {
    padding: 1.5rem 0 4.3%;
  }
}

.section-different:before {
  bottom: 0;
  transform-origin: 0;
  transform: skewY(-4.8deg);
}

.section-different .title {
  font-weight: 500;
}

@media (min-width: 1200px) {
  .section-different .title {
    margin-bottom: .75rem;
  }
}

.section-different .inner-img {
  height: 180px;
}

.section-different .inner-content {
  padding: 40px;
}

.section-different .list-check {
  list-style: none;
  padding: 0;
}

.section-different .list-check li {
  margin-bottom: 16px;
  padding-left: 28px;
  position: relative;
  color: #596369;
}

.section-different .list-check li:last-child {
  margin-bottom: 0;
}

.section-different .list-check li .icon-chek {
  position: absolute;
  left: 0;
  top: 5px;
}

.section-training-slider {
  padding: 64px 0 150px 0;
}

.section-training-slider .slick-track,
.section-training-slider .slick-slide {
  width: 100% !important;
}

.section-training-slider .container {
  overflow: hidden;
}

.section-training-slider .slick-slide > div {
  padding: 0;
}

@media (max-width: 1199px) {
  .section-training-slider .slide-event {
    margin-bottom: 20px;
  }
}

.section-izotope {
  padding-bottom: 48px !important;
}

@media screen and (max-width: 376px) {
  .section-izotope {
    padding-bottom: 16px !important;
  }
}

.section-izotope .green {
  color: #4CAF50 !important;
}

.section-izotope .green p {
  color: #4CAF50 !important;
}

.section-izotope .tab-hidden {
  position: absolute !important;
  left: -9999px !important;
  top: -9999px !important;
  display: block !important;
  width: 100% !important;
}

.section-izotope .izotope-sub-filters .izotope-filters {
  margin: 0;
}

.section-izotope .izotope-sub-filters .izotope-filters .lc-item {
  padding: 0 3px;
  width: auto;
}

@media screen and (max-width: 940px) {
  .section-izotope .izotope-sub-filters .izotope-filters .lc-item {
    width: 33%;
    text-align: center;
  }
}

@media screen and (max-width: 820px) {
  .section-izotope .izotope-sub-filters .izotope-filters .lc-item {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .section-izotope .izotope-sub-filters .izotope-filters .lc-item {
    width: 100%;
  }
}

.section-izotope .izotope-sub-filters .izotope-filters .lc-item.hidden {
  display: none;
}

.section-izotope .izotope-sub-filters .izotope-filters .lc-item .opener {
  padding: 12px 32px;
  font-size: 20px;
}

.section-izotope .izotope-sub-filters .izotope-filters .lc-item .opener::after {
  display: none;
}

.section-izotope .izotope-sub-filters .izotope-filters .lc-item .opener.small {
  padding: 12px 16px;
}

@media screen and (max-width: 570px) {
  .section-izotope .izotope-sub-filters .izotope-filters .lc-item .opener {
    padding: 12px;
    font-size: 16px;
  }
}

.section-izotope .izotope-sub-filters .izotope-filters .lc-item.active .opener {
  color: #fff;
  background-color: #0076C0;
}

.section-izotope .izotope-sub-filters .sub-filters label {
  color: #323232;
  font-size: 16px;
  font-weight: 500;
}

.section-izotope .izotope-sub-filters .sub-filters button,
.section-izotope .izotope-sub-filters .sub-filters .button {
  color: #0076BF;
  font-size: 16px;
  background: none;
  border: none;
  padding: 8px 16px;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
}

.section-izotope .izotope-sub-filters .sub-filters button:hover, .section-izotope .izotope-sub-filters .sub-filters button.is-checked,
.section-izotope .izotope-sub-filters .sub-filters .button:hover,
.section-izotope .izotope-sub-filters .sub-filters .button.is-checked {
  text-decoration: none;
  color: #323232;
}

.section-izotope .grid {
  margin: 0 -16px;
}

.section-izotope .grid.height .card-line {
  position: relative;
}

.section-izotope .grid.height .card-line .inner-content {
  padding: 15px 24px 75px 24px !important;
  min-height: 236px;
}

.section-izotope .grid.height .card-line .card-bottom {
  width: calc(100% - 48px);
  position: absolute;
  bottom: 40px;
  left: 24px;
}

.section-izotope .grid.height .card-line .card-bottom .form-row {
  display: flex;
  align-items: flex-end;
}

.section-izotope .grid.height .card-line .card-bottom .form-row label {
  margin: 0;
}

.section-izotope .grid.small .element-item {
  padding: 0 8px;
}

.section-izotope .grid.small .element-item .form-row a.link {
  display: none;
  text-decoration: underline;
}

.section-izotope .grid.small .element-item .form-row a.link:hover {
  text-decoration: none;
}

.section-izotope .grid.small .element-item .form-row span.text {
  color: #000;
}

.section-izotope .grid.small .element-item .card-line.add-link .form-row span.text {
  display: none;
}

.section-izotope .grid.small .element-item .card-line.add-link .form-row a.link {
  display: inline-block;
}

.section-izotope .grid.small .element-item .inner-content {
  padding: 15px 24px 40px 24px;
}

.section-izotope .grid.small .element-item .inner-content .inner-info {
  min-height: 0;
  margin: 0;
}

.section-izotope .grid.small .element-item .inner-content .card-bottom label {
  color: #0076BF;
}

.section-izotope .grid.small .element-item .inner-content .title {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 4px;
}

.section-izotope .grid.small .element-item .inner-content .card-text p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.section-izotope .grid.small .element-item .inner-content .list-characteristics {
  margin: 0;
}

.section-izotope .grid.small .element-item .inner-content .list-characteristics li {
  line-height: 1;
  margin: 0 0 8px 0;
}

.section-izotope .grid.small .element-item .inner-content .list-characteristics li:last-child {
  margin: 0;
}

.section-izotope .grid.small .element-item .inner-content .list-items li:first-child {
  padding-left: 0;
}

.section-izotope .grid.small .element-item .inner-content .list-items li:last-child {
  padding-right: 0;
}

.section-izotope .element-item {
  margin: 0 0 16px 0;
}

.section-izotope .container {
  overflow: hidden;
}

.section-izotope .izotope-select {
  margin: 0 0 40px 0;
}

.section-izotope .izotope-select .jcf-select {
  width: 366px;
  margin: 0 auto;
  position: relative;
}

.section-izotope .izotope-select .jcf-select::before, .section-izotope .izotope-select .jcf-select::after {
  position: absolute;
  left: calc(100% + 21px);
  top: 50%;
  width: 999%;
  height: 1px;
  background: #F6F8F8;
  display: block;
  content: "";
}

.section-izotope .izotope-select .jcf-select::after {
  left: auto;
  right: calc(100% + 21px);
}

.section-izotope .izotope-filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.section-izotope .izotope-filters .button {
  margin-right: 2px;
  width: 170px;
  height: 48px;
  border: none;
  background: #F6F8F8;
  font-size: 20px;
  font-weight: 500;
  transition: .5s;
  outline: none;
}

@media screen and (max-width: 1200px) {
  .section-izotope .izotope-filters .button {
    width: auto;
    padding: 0 16px;
  }
}

.section-izotope .izotope-filters .button:hover {
  background: #146194;
  transition: .5s;
  color: #fff;
}

.section-izotope .izotope-filters .button:last-child {
  margin-right: 0;
}

.section-izotope .izotope-filters .button.is-checked {
  background: #0076BF;
  color: #fff;
  transition: .5s;
}

.section-izotope .izotope-filters .button.is-checked:hover {
  background: #146194;
}

.section-izotope .inner-img {
  height: 115px;
}

.section-izotope .card-line.p-0 {
  position: relative;
}

.section-izotope .card-line.p-0.register .register-show {
  display: inline-block;
}

.section-izotope .card-line.p-0.register .card-button .register-show + a {
  display: none;
}

.section-izotope .card-line.p-0.register .hold-date .register-show.local + .date {
  display: none;
}

.section-izotope .card-line.p-0 .register-show {
  display: none;
}

.section-izotope .card-line.p-0 .inner-content {
  padding: 32px 40px 40px 40px;
}

@media screen and (max-width: 376px) {
  .section-izotope .card-line.p-0 .inner-content {
    padding: 24px;
  }
}

.section-izotope .card-line.p-0 .title {
  font-size: 24px;
  line-height: 36px;
}

.section-izotope .card-line.p-0 .inner-info {
  min-height: 165px;
  margin-bottom: 24px;
}

@media screen and (max-width: 769px) {
  .section-izotope .card-line.p-0 .inner-info {
    min-height: 0;
  }
}

.section-izotope .card-line.p-0 .inner-info p {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
}

.section-izotope .card-line.p-0 .status {
  position: absolute;
  left: 0;
  top: 20px;
  text-transform: uppercase;
  font-size: 14px;
  background: #4CAF50;
  color: #fff;
  padding: 8px 12px;
  line-height: 1;
}

.section-izotope .card-line.p-0 .card-bottom {
  width: 100%;
}

.section-izotope .card-line.p-0 .card-bottom .card-button {
  display: flex;
  align-items: center;
}

.section-izotope .card-line.p-0 .card-bottom .card-button .btn-default {
  margin-right: 46px;
  min-width: 148px;
}

.section-izotope .card-line.p-0 .hold-date {
  margin-bottom: 16px;
  color: #231F20;
}

.section-izotope .card-line.p-0 .hold-date .date {
  margin-right: 32px;
}

.section-izotope .card-line.p-0 .hold-date i {
  margin-right: 8px;
}

.section-izotope.oscilloscopes .card-footer-img img {
  width: 50px;
  transition: .5s;
}

.section-izotope.oscilloscopes .card-footer-img img:hover {
  transform: scale(1.1);
  transition: .5s;
}

.section-explore {
  padding: 64px 0 80px 0;
}

.section-explore .section-heading {
  margin-bottom: 40px;
}

.section-explore h2 {
  margin-bottom: 12px;
}

.section-explore .sub-title {
  color: #596369;
  font-size: 20px;
  line-height: 30px;
  margin-top: 12px;
}

@media screen and (max-width: 769px) {
  .section-explore .sub-title {
    font-size: 16px;
  }
}

@media screen and (max-width: 769px) and (max-width: 376px) {
  .section-explore .sub-title {
    font-size: 14px;
  }
}

.section-explore .sub-title b {
  font-size: 20px;
  font-weight: 500;
  display: block;
}

.container.small {
  max-width: 856px;
}

.container.small .explore-content {
  padding: 40px;
  box-shadow: 0 0.5rem 0.75rem #ececec;
  border: 1px solid #EAEDEE;
  background: #fff;
}

@media screen and (max-width: 376px) {
  .container.small .explore-content {
    padding: 24px;
  }
}

.container.small .agreement {
  align-items: center;
}

.container.small .agreement p {
  font-size: 12px;
  line-height: 18px;
}

.container.small .agreement p a {
  text-decoration: underline;
}

.container.small .agreement p a:hover {
  text-decoration: none;
}

#pardot-form .form-field span.value + span {
  display: flex;
}

#pardot-form .form-field span.value + span .inline {
  margin-left: 10px;
}

#pardot-form .form-field input[type='checkbox'] + label {
  display: inline-block;
}

#pardot-form .form-field span.description {
  display: block;
}

#pardot-form .description {
  font-size: 12px;
  line-height: 18px;
}

#pardot-form .description a:hover {
  text-decoration: underline;
}

#pardot-form .submit input {
  background: #0076C0;
  color: #f7f9f9;
  border: none;
  padding: 14px 1.5rem;
  line-height: 1rem;
}

#pardot-form .submit input:hover {
  background: #0067a7;
  color: #f7f9f9;
}

.form-row,
#pardot-form .form-field {
  margin-bottom: 16px !important;
}

.form-row:last-child,
#pardot-form .form-field:last-child {
  margin-bottom: 0 !important;
}

.form-row label,
#pardot-form .form-field label {
  color: #231F20;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.form-row label span,
#pardot-form .form-field label span {
  color: #BDBDBD;
  font-weight: normal;
}

.form-row input[type=text],
.form-row input[type=email],
.form-row input[type=phone],
.form-row input[type=password],
.form-row textarea,
.form-row select,
#pardot-form .form-field input[type=text],
#pardot-form .form-field input[type=email],
#pardot-form .form-field input[type=phone],
#pardot-form .form-field input[type=password],
#pardot-form .form-field textarea,
#pardot-form .form-field select {
  width: 100%;
  height: 44px;
  border: 2px solid #F2F2F2;
  border-radius: 2px;
  outline: none;
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #231F20;
  transition: .5s;
}

.form-row input[type=text]:focus,
.form-row input[type=email]:focus,
.form-row input[type=phone]:focus,
.form-row input[type=password]:focus,
.form-row textarea:focus,
.form-row select:focus,
#pardot-form .form-field input[type=text]:focus,
#pardot-form .form-field input[type=email]:focus,
#pardot-form .form-field input[type=phone]:focus,
#pardot-form .form-field input[type=password]:focus,
#pardot-form .form-field textarea:focus,
#pardot-form .form-field select:focus {
  border-color: #0076C0;
  transition: .5s;
}

.form-row input[type=text].error,
.form-row input[type=email].error,
.form-row input[type=phone].error,
.form-row input[type=password].error,
.form-row textarea.error,
.form-row select.error,
#pardot-form .form-field input[type=text].error,
#pardot-form .form-field input[type=email].error,
#pardot-form .form-field input[type=phone].error,
#pardot-form .form-field input[type=password].error,
#pardot-form .form-field textarea.error,
#pardot-form .form-field select.error {
  border-color: #ff0000;
}

.form-row label.error,
#pardot-form .form-field label.error {
  margin: 8px 0 0 0;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  color: #ff0000;
}

.form-row textarea,
#pardot-form .form-field textarea {
  height: 132px;
  min-height: 44px;
}

.form-row input::placeholder,
.form-row textarea::placeholder,
.form-row select,
#pardot-form .form-field input::placeholder,
#pardot-form .form-field textarea::placeholder,
#pardot-form .form-field select {
  color: #828282;
  font-size: 16px;
  line-height: 24px;
}

.form-row .checkbox-label,
#pardot-form .form-field .checkbox-label {
  cursor: pointer;
}

.form-row .checkbox-label input,
#pardot-form .form-field .checkbox-label input {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  opacity: 0;
  position: absolute;
  display: inline-block;
}

.form-row .checkbox-label input + label.error,
#pardot-form .form-field .checkbox-label input + label.error {
  display: none !important;
}

.form-row .checkbox-label input + label.error + .checkbox-icon,
#pardot-form .form-field .checkbox-label input + label.error + .checkbox-icon {
  border-color: red !important;
}

.form-row .checkbox-label input:checked + .checkbox-icon,
.form-row .checkbox-label input:checked + label.error + .checkbox-icon,
#pardot-form .form-field .checkbox-label input:checked + .checkbox-icon,
#pardot-form .form-field .checkbox-label input:checked + label.error + .checkbox-icon {
  background: url(https://assets.lcry.net/images/checkbox-bg.svg);
  border: none;
  background-repeat: no-repeat;
}

.form-row .checkbox-label .checkbox-icon,
#pardot-form .form-field .checkbox-label .checkbox-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  border: solid 2px #F2F2F2;
  border-radius: 3px;
  display: inline-block;
}

.form-row .checkbox-label .checkbox-icon,
#pardot-form .form-field .checkbox-label .checkbox-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  border: solid 2px #F2F2F2;
  border-radius: 3px;
  display: inline-block;
}

.oscilloscopes span.seriesCheckBox {
  display: flex;
}

.oscilloscopes input:checked + .checkbox-icon + span {
  display: none;
}

.section-external .external-content {
  position: relative;
}

.section-external .col {
  margin-bottom: 32px;
}

.section-external div.card-line .inner-content {
  padding-top: 26px;
}

.section-external p {
  margin: 0;
}

.section-external .number {
  color: #231F20;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 8px;
  font-weight: 500;
}

.section-validation {
  padding: 64px 0 150px 0;
}

.section-validation .col {
  margin-bottom: 32px;
}

.section-validation .icons {
  margin-right: 10px;
}

.section-validation .icons svg {
  width: 27px;
  height: 27px;
}

.section-different .inner-img {
  height: 180px;
}

.section-different .inner-content {
  padding: 40px;
}

.section-different .list-check {
  list-style: none;
  padding: 0;
}

.section-different .list-check li {
  margin-bottom: 16px;
  padding-left: 28px;
  position: relative;
  color: #596369;
}

.section-different .list-check li:last-child {
  margin-bottom: 0;
}

.section-different .list-check li .icon-chek {
  position: absolute;
  left: 0;
  top: 5px;
}

.section-overview .card-line a {
  color: #0076C0;
}

.section-overview .card-line a:hover {
  text-decoration: underline;
}

.section-overview .card-line.gray {
  background: #f7f9f9;
}

.section-overview .card-blue h3 {
  font-size: 16px;
  margin-bottom: 17px;
  text-transform: uppercase;
}

.section-overview .card-blue .list-check {
  margin-bottom: 20px;
}

.section-overview .col-lg-6 .card-blue h3 {
  text-transform: none;
}

.section-overview .col-right p {
  font-size: 20px;
  color: #231F20;
  font-weight: 600;
}

.section-summary .card-line {
  margin-bottom: 32px;
}

.section-summary .card-line:last-child {
  margin-bottom: 0;
}

.section-summary .card-line h3 {
  margin-bottom: 8px;
}

.section-summary .card-line p {
  margin-bottom: 17px;
}

.reset .section-speakers .card-line .inner-img {
  min-width: 254px;
  height: 100% !important;
}

.reset .section-speakers .card-line .card-speakers-name h3 {
  font-size: 20px !important;
  line-height: 30px !important;
  margin-bottom: 4px;
}

.reset .section-speakers .card-line .card-speakers-position h3 {
  font-size: 16px !important;
  margin-bottom: 12px;
}

.reset .section-speakers .card-line.horizontale {
  display: flex !important;
}

@media screen and (max-width: 540px) {
  .reset .section-speakers .card-line.horizontale {
    display: block !important;
    height: auto;
  }
  .reset .section-speakers .card-line.horizontale .inner-img {
    min-width: 254px;
    min-height: 250px;
  }
}

.reset .m-0 {
  margin: 0 !important;
}

.advantages {
  display: flex;
  justify-content: space-between;
}

.advantages .advantages-left,
.advantages .advantages-right {
  display: flex;
  flex-direction: column;
}

.advantages .advantages-left .card-line:before,
.advantages .advantages-right .card-line:before {
  content: '';
  display: block;
  height: 8px;
  position: absolute;
  left: 100%;
  right: 0;
  background-color: inherit;
  bottom: -8px;
  transform-origin: 0;
  transform: skewY(-35deg);
  width: 200px;
  background: #0076BF;
}

@media screen and (max-width: 1224px) {
  .advantages .advantages-left .card-line:before,
  .advantages .advantages-right .card-line:before {
    display: none;
  }
}

@media screen and (max-width: 864px) {
  .advantages .advantages-left .card-line,
  .advantages .advantages-right .card-line {
    margin: 0 auto;
  }
}

.advantages .advantages-right {
  margin-top: 155px;
}

@media screen and (max-width: 1224px) {
  .advantages .advantages-right {
    margin-top: 0;
  }
}

.advantages .advantages-right .card-line:before {
  left: auto;
  right: 100%;
  transform: skewY(35deg);
  bottom: auto;
  top: 100%;
  margin-top: -140px;
}

.advantages .advantages-right .advantages-icon {
  left: auto !important;
  right: calc(180px + 100%);
}

.advantages .advantages-center {
  background: #0076BF;
  width: 8px;
}

.advantages .advantages-center.secondary {
  position: relative;
  top: 100px;
  height: 1000px;
}

@media screen and (max-width: 864px) {
  .advantages {
    display: block;
  }
  .advantages .advantages-center.secondory {
    height: auto;
  }
}

section.section-advantages .mobile {
  display: none;
  text-align: center;
}

@media screen and (max-width: 864px) {
  section.section-advantages .mobile {
    display: block;
  }
  section.section-advantages .no-mobile {
    display: none;
  }
}

section.section-advantages .card-line {
  border-bottom: solid 8px #0076BF;
  position: relative;
  max-width: 392px;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1224px) {
  section.section-advantages .card-line {
    margin-bottom: 90px !important;
  }
}

section.section-advantages .card-line .inner-content {
  padding-top: 55px !important;
  background: #fff;
  z-index: 99999;
  position: relative;
}

section.section-advantages .card-line .card-sub-title {
  margin-bottom: 10px;
  color: #0076C0;
  font-size: 16px;
}

section.section-advantages .card-line .card-text p {
  color: #231F20;
}

section.section-advantages .card-line .advantages-icon {
  width: 98px;
  height: 98px;
  background: #fff;
  border: solid 8px #0076BF;
  border-radius: 50%;
  position: absolute;
  left: calc(180px + 100%);
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1224px) {
  section.section-advantages .card-line .advantages-icon {
    top: auto;
    bottom: 100%;
    left: 50% !important;
    transform: translate(-50%, 50%);
  }
}

section.section-advantages .advantages-center-top .card-line,
section.section-advantages .advantages-center-bottom .card-line {
  margin: 0 auto;
}

section.section-advantages .advantages-center-top .advantages-icon,
section.section-advantages .advantages-center-bottom .advantages-icon {
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 50%);
}

@media screen and (max-width: 864px) {
  section.section-advantages .advantages-center-top {
    margin-top: 90px !important;
  }
}

section.section-advantages .advantages-center-bottom .card-line {
  margin-bottom: 90px !important;
}

section.section-advantages .advantages-center-bottom .card-line:last-child {
  margin-bottom: 0 !important;
}

section.section-advantages .advantages-center-bottom .card-line:last-child:before {
  display: none;
}

section.section-advantages .advantages-center-bottom .card-line:before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 8px;
  background: #0076BF;
}

@media screen and (max-width: 864px) {
  section.section-advantages .advantages-center-bottom .card-line:before {
    display: none;
  }
}

.nowrap p {
  white-space: nowrap;
}

b, strong {
  font-weight: 500;
}

.position-top {
  position: relative;
  top: 50px;
}

@media (max-width: 768px) {
  .position-top {
    position: static;
  }
  .position-top img {
    transform: scale(0.8);
  }
}

.min-w {
  min-width: 170px;
}

.skew-style .section-heading .main-title-form {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
}

.reset.new-skew-style #wrapper {
  overflow: hidden;
}

@media (width: 768px) {
  .reset.new-skew-style #main.grid-column .section-filters-bottom .card-gray.card-gray-second .row > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.reset.new-skew-style #main section.default-section-new {
  padding: 60px 0;
}

@media (width: 768px) {
  .reset.new-skew-style #main section.default-section-new .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.reset.new-skew-style #main section.default-section-new.pt-80 {
  padding-top: 80px !important;
}

@media (max-width: 768px) {
  .reset.new-skew-style #main section.default-section-new.pt-80 {
    padding-top: 48px !important;
  }
}

.reset.new-skew-style #main section.default-section-new.pt-120 {
  padding-top: 120px;
}

@media (max-width: 768px) {
  .reset.new-skew-style #main section.default-section-new.pt-120 {
    padding-top: 48px !important;
  }
}

.reset.new-skew-style #main section.default-section-new.pb-80 {
  padding-bottom: 80px !important;
}

@media (max-width: 768px) {
  .reset.new-skew-style #main section.default-section-new.pb-80 {
    padding-bottom: 48px !important;
  }
}

.reset.new-skew-style #main section.default-section-new.pb-120 {
  padding-bottom: 120px !important;
}

@media (max-width: 768px) {
  .reset.new-skew-style #main section.default-section-new.pb-120 {
    padding-bottom: 48px !important;
  }
}

@media (max-width: 768px) {
  .reset.new-skew-style #main section.default-section-new {
    padding: 48px 0 !important;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .reset.new-skew-style #main section.default-section-new {
    padding: 40px 0 !important;
  }
}

@media (max-width: 768px) {
  .reset.new-skew-style #main section.default-section-new h2.mb-32 {
    text-align: center;
  }
  .reset.new-skew-style #main section.default-section-new.section-filters-bottom {
    padding-bottom: 16px !important;
  }
}

.reset.new-skew-style #main section.default-section-new.pt-0 {
  padding-top: 0 !important;
}

.reset.new-skew-style #main section.default-section-new.pb-0 {
  padding-bottom: 0 !important;
}

.reset.new-skew-style #main section.default-section-new.section-banner {
  padding: 0 !important;
  height: 232px;
  text-align: center;
}

.reset.new-skew-style #main section.default-section-new.section-banner .container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.reset.new-skew-style #main section.default-section-new.section-banner .container h1 {
  color: #FFFFFF;
}

.reset.new-skew-style #main section.default-section-new.section-banner .container .sub-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.reset.new-skew-style #main section.default-section-new.section-banner .breadcrumbs-product {
  position: absolute;
  top: 0;
  left: 32px;
  z-index: 9;
}

.reset.new-skew-style #main section.default-section-new.section-banner .breadcrumbs-product li, .reset.new-skew-style #main section.default-section-new.section-banner .breadcrumbs-product a {
  font-weight: normal;
  font-size: 14px;
  line-height: 172%;
  display: flex;
  align-items: center;
  color: #FFFFFF !important;
}

.reset.new-skew-style #main section.default-section-new.section-banner .breadcrumbs-product li path, .reset.new-skew-style #main section.default-section-new.section-banner .breadcrumbs-product a path {
  fill: #FFFFFF;
}

.reset.new-skew-style #main section.default-section-new.search-question .search-wrap {
  max-width: 816px;
  margin: 0 auto 40px;
  position: relative;
}

.reset.new-skew-style #main section.default-section-new.search-question .search-wrap .search-result {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0 1px 0.75rem #ececec;
  width: 100%;
  padding: 16px 24px;
  z-index: 99999;
  top: calc(100% + 4px);
}

.reset.new-skew-style #main section.default-section-new.search-question .search-wrap .search-result h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-variant: small-caps;
  color: #7D7D7D;
  margin-bottom: 20px;
}

.reset.new-skew-style #main section.default-section-new.search-question .search-wrap .search-result p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #171717;
  margin-bottom: 20px !important;
}

.reset.new-skew-style #main section.default-section-new.search-question .search {
  width: 100%;
  height: 48px;
  border: 1px solid #D7D7D7;
  padding-left: 20px;
  outline: none;
}

.reset.new-skew-style #main section.default-section-new.search-question .search.active, .reset.new-skew-style #main section.default-section-new.search-question .search:focus {
  border: 1px solid #0076C0;
}

.reset.new-skew-style #main section.default-section-new.search-question .btn {
  position: absolute;
  right: 4px;
  top: 4px;
  height: calc(100% - 8px);
  display: flex;
  align-items: center;
}

.reset.new-skew-style #main section.default-section-new.search-question .btn .icons {
  margin-left: 8px;
}

.reset.new-skew-style #main section.default-section-new.search-question .tech-library .item-question {
  box-shadow: 0px 0px 25px rgba(191, 191, 191, 0.4);
  width: 180px;
  height: 180px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.reset.new-skew-style #main section.default-section-new.search-question .tech-library .item-question:hover {
  box-shadow: 0px 0px 22px rgba(191, 191, 191, 0.7);
  cursor: pointer;
}

.reset.new-skew-style #main section.default-section-new.search-question .tech-library .item-question i {
  margin-bottom: 12px;
}

.reset.new-skew-style #main section.default-section-new.search-question .tech-library .item-question span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #0076C0;
}

.reset.new-skew-style #main section.default-section-new .section-or {
  position: relative;
  margin-bottom: 40px;
}

.reset.new-skew-style #main section.default-section-new .section-or span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  background-color: #fff;
  padding: 0 24px;
  display: inline-block;
  z-index: 9999;
  position: relative;
}

.reset.new-skew-style #main section.default-section-new .section-or::before {
  position: absolute;
  display: block;
  width: calc(100% - 200px);
  height: 1px;
  background-color: #D7D7D7;
  left: 100px;
  top: 10px;
  content: "";
}

.reset.new-skew-style #main section.default-section-new.gray {
  background: #F6F8F8;
}

.reset.new-skew-style #main section.default-section-new .section-heading,
.reset.new-skew-style #main section.default-section-new .section-header {
  margin-bottom: 40px;
}

.reset.new-skew-style #main section.default-section-new .section-heading .sub-tittle,
.reset.new-skew-style #main section.default-section-new .section-header .sub-tittle {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #3D4448;
}

.reset.new-skew-style #main section.default-section-new.video-list.section-izotope .element-item {
  margin: 0 0 32px 0;
}

.reset.new-skew-style #main section.default-section-new.video-list.section-izotope .element-item:hover .video-top_img img {
  transform: scale(1.1);
  transition: 1s;
}

.reset.new-skew-style #main section.default-section-new.video-list.section-izotope .element-item .video-top_img {
  overflow: hidden;
}

.reset.new-skew-style #main section.default-section-new.video-list.section-izotope .element-item .video-top_img img {
  transition: 1s;
}

.reset.new-skew-style #main section.default-section-new.video-list.section-izotope .show-more-block .btn {
  min-width: 232px;
}

.reset.new-skew-style #main section.default-section-new.video-list.section-izotope .item-none {
  display: none;
}

.reset.new-skew-style #main section.default-section-new.video-list.section-izotope.show .item-none {
  display: block;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-description {
  padding: 40px 0;
  border-bottom: solid 1px #D7D7D7;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-description p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  max-width: 816px;
  margin: 0;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-description .video-single-share span {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  text-align: center;
  color: #0076C0;
  margin-right: 4px;
  cursor: pointer;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-description .video-single-share:hover span,
.reset.new-skew-style #main section.default-section-new.video-single .video-single-description .video-single-share:hover path {
  color: #0699F5;
  fill: #0699F5;
}

.reset.new-skew-style #main section.default-section-new.video-single.new {
  border-bottom: solid 1px #D7D7D7;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-related {
  padding: 40px 0;
  border-bottom: solid 1px #D7D7D7;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-related h2 {
  margin-bottom: 40px;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-related {
  padding: 40px 0;
  border-bottom: solid 1px #D7D7D7;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-related h3 {
  margin-bottom: 40px;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-related-product {
  padding-top: 40px;
}

.reset.new-skew-style #main section.default-section-new.video-single .video-single-related-product h3 {
  margin-bottom: 40px;
}

.reset.new-skew-style #main section.default-section-new.search-question.search-question-video .section-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reset.new-skew-style #main section.default-section-new.search-question.search-question-video .search-wrap {
  margin: 0;
  width: 816px;
}

.reset.new-skew-style #main section.default-section-new.search-question.search-question-video .tabs-filter {
  position: relative;
  top: 0;
}

.reset.new-skew-style #main section.default-section-new.search-question.search-question-video .tabs-filter-list {
  z-index: 9;
}

.reset.new-skew-style #main section.default-section-new.search-question.search-question-video .btn.btn-default {
  background: none;
  text-align: right;
  border: none;
  justify-content: end;
  padding: 0 10px 0 0;
}

.reset.new-skew-style #main section.default-section-new.search-question.search-question-video .tabs-filter label {
  margin: 0 5px 0 0;
  color: #171717;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .video-card.small {
  min-height: 0;
  background: none;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .video-card.small .video-card-bottom {
  padding: 20px 0 0 0 !important;
  background: none;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .video-card.small .description {
  margin-bottom: 8px;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .video-card.small .characteristics {
  margin-bottom: 0;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .video-card:hover .video-card-top::before {
  opacity: 0;
}

.reset.new-skew-style #main section.default-section-new.video-list.new h3 {
  font-size: 24px !important;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .video-card.small {
  width: 100%;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .video-card.small .video-card-top {
  height: 200px;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .video-card.small .video-card-top .video-top_img img {
  height: 200px;
}

.reset.new-skew-style #main section.default-section-new.video-list.new .element-item {
  padding: 0px 16px;
}

.reset.new-skew-style #main section.default-section-new.video-list.new h3.grid-title {
  font-size: 24px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}

.reset.new-skew-style #main section.default-section-new.video-list.new h3.grid-title span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: #F6F8F8;
  border-radius: 2px;
  margin-left: 8px;
  padding: 0 4px;
  display: inline-block;
}

.reset.new-skew-style #main a {
  text-decoration: none;
}

.reset.new-skew-style #main h1, .reset.new-skew-style #main h2, .reset.new-skew-style #main h3, .reset.new-skew-style #main h4, .reset.new-skew-style #main h5, .reset.new-skew-style #main h6 {
  margin-bottom: 16px;
  line-height: 40px;
  font-weight: 500;
  color: #171717;
}

.reset.new-skew-style #main h1 {
  font-size: 32px;
}

@media (max-width: 768px) {
  .reset.new-skew-style #main h1 {
    font-size: 28px !important;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .reset.new-skew-style #main h1 {
    font-size: 24px !important;
  }
}

.reset.new-skew-style #main h2 {
  font-size: 28px;
}

@media (max-width: 768px) {
  .reset.new-skew-style #main h2 {
    font-size: 24px !important;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .reset.new-skew-style #main h2 {
    font-size: 20px !important;
  }
}

.reset.new-skew-style #main h3 {
  font-size: 24px;
}

@media (max-width: 768px) {
  .reset.new-skew-style #main h3 {
    font-size: 16px !important;
  }
}

.reset.new-skew-style #main h4 {
  font-size: 20px;
}

.reset.new-skew-style #main h4 {
  font-size: 16px;
}

.reset.new-skew-style #main p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2B2F2F;
  margin-bottom: 16px;
}

.reset.new-skew-style #main p:last-child {
  margin-bottom: 0;
}

@media (max-width: 320px) {
  .reset.new-skew-style #main p {
    font-size: 12px !important;
  }
}

.reset.new-skew-style #main button.btn.btn-default {
  padding: 8px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  background: #0076C0;
  border-color: #0076C0;
  transition: 0.5s;
}

.reset.new-skew-style #main button.btn.btn-default.no-bg {
  background: none;
  text-align: right;
  border: none;
  -ms-flex-pack: end;
  justify-content: end;
  padding: 0 10px 0 0;
}

.reset.new-skew-style #main button.btn.btn-default.no-bg:hover {
  background: none;
  border: none;
}

.reset.new-skew-style #main button.btn.btn-default:hover {
  background: #0699F5;
  border-color: #0699F5;
  transition: 0.5s;
}

.reset.new-skew-style #main button.btn.btn-default.btn-default-outline {
  background: none;
}

.reset.new-skew-style #main button.btn.btn-default.btn-default-outline:hover {
  color: #0699F5;
  border-color: #0699F5;
}

.reset.new-skew-style #main button.btn.btn-default.btn-default-disabled {
  background: #D7D7D7;
  border-color: #D7D7D7;
  cursor: default;
}

.reset.new-skew-style #main button.btn.btn-default.btn-default-disabled:hover {
  background: #D7D7D7;
  border-color: #D7D7D7;
}

.reset.new-skew-style #main button.btn.btn-default.big {
  min-width: 392px;
}

.reset.new-skew-style #main a:not(.btn):hover svg path {
  fill: #0699F5;
}

.reset.new-skew-style #main a:not(.btn).link-arrow {
  font-size: 14px;
}

.reset.new-skew-style #main a:not(.btn).link-arrow-new {
  display: flex;
  font-size: 14px;
  align-items: center;
  font-weight: 500;
}

.reset.new-skew-style #main a:not(.btn).link-arrow-new span {
  margin-right: 4px;
}

.reset.new-skew-style #main .form-row .checkbox-label input:checked + .checkbox-icon,
.reset.new-skew-style #main .form-row .checkbox-label input:checked + label.error + .checkbox-icon,
.reset.new-skew-style #main #pardot-form .form-field .checkbox-label input:checked + .checkbox-icon,
.reset.new-skew-style #main #pardot-form .form-field .checkbox-label input:checked + label.error + .checkbox-icon,
.reset #main .form-row .checkbox-label input:checked + .checkbox-icon,
.reset #main .form-row .checkbox-label input:checked + label.error + .checkbox-icon,
.reset #main #pardot-form .form-field .checkbox-label input:checked + .checkbox-icon,
.reset #main #pardot-form .form-field .checkbox-label input:checked + label.error + .checkbox-icon {
  background: url(./images/checkbox-bg-3.svg);
  border: none;
  background-repeat: no-repeat;
}

.reset.new-skew-style #main .form-title,
.reset #main .form-title {
  margin-bottom: 32px;
  position: relative;
  font-size: 20px;
  line-height: 24px;
}

.reset.new-skew-style #main .form-title:after,
.reset #main .form-title:after {
  display: block;
  background-color: #D7D7D7;
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  content: "";
  width: 100%;
  height: 1px;
}

.reset.new-skew-style #main .form-title span,
.reset #main .form-title span {
  position: relative;
  background-color: #fff;
  z-index: 1;
  padding-right: 16px;
}

@media (max-width: 320px) {
  .reset.new-skew-style #main .form-title span,
  .reset #main .form-title span {
    font-size: 16px;
    line-height: 24px;
  }
}

.reset.new-skew-style #main .form-row label,
.reset.new-skew-style #main #pardot-form .form-field label,
.reset #main .form-row label,
.reset #main #pardot-form .form-field label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 8px;
}

.reset.new-skew-style #main .form-row .checkbox-label,
.reset.new-skew-style #main #pardot-form .form-field .checkbox-label,
.reset #main .form-row .checkbox-label,
.reset #main #pardot-form .form-field .checkbox-label {
  margin-bottom: 32px;
}

.reset.new-skew-style #main .form-row .checkbox-label .checkbox-icon,
.reset.new-skew-style #main #pardot-form .form-field .checkbox-label .checkbox-icon,
.reset #main .form-row .checkbox-label .checkbox-icon,
.reset #main #pardot-form .form-field .checkbox-label .checkbox-icon {
  min-width: 20px;
  min-height: 20px;
  top: 5px;
  position: relative;
  border: solid 1px #D7D7D7;
  border-radius: 0;
}

.reset.new-skew-style #main .jcf-select .jcf-select-text span,
.reset #main .jcf-select .jcf-select-text span {
  font-size: 14px !important;
  color: #0076C0 !important;
}

.reset.new-skew-style #main .form-row .jcf-select,
.reset #main .form-row .jcf-select {
  width: auto;
}

.reset.new-skew-style #main .form-row .jcf-select .jcf-select-opener,
.reset #main .form-row .jcf-select .jcf-select-opener {
  margin-left: 5px;
}

.reset.new-skew-style #main .form-row .jcf-select .jcf-select-opener:after,
.reset #main .form-row .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #0076C0 !important;
  border-top: 2px solid #0076C0 !important;
}

.reset.new-skew-style #main .form-row input[type=text],
.reset.new-skew-style #main .form-row input[type=email],
.reset.new-skew-style #main .form-row input[type=phone],
.reset.new-skew-style #main .form-row input[type=password],
.reset.new-skew-style #main .form-row textarea,
.reset.new-skew-style #main .form-row select,
.reset #main .form-row input[type=text],
.reset #main .form-row input[type=email],
.reset #main .form-row input[type=phone],
.reset #main .form-row input[type=password],
.reset #main .form-row textarea,
.reset #main .form-row select {
  border: 1px solid #D7D7D7;
  border-radius: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  height: 40px;
}

.reset.new-skew-style #main .form-row input[type=text]:focus,
.reset.new-skew-style #main .form-row input[type=email]:focus,
.reset.new-skew-style #main .form-row input[type=phone]:focus,
.reset.new-skew-style #main .form-row input[type=password]:focus,
.reset.new-skew-style #main .form-row textarea:focus,
.reset.new-skew-style #main .form-row select:focus,
.reset #main .form-row input[type=text]:focus,
.reset #main .form-row input[type=email]:focus,
.reset #main .form-row input[type=phone]:focus,
.reset #main .form-row input[type=password]:focus,
.reset #main .form-row textarea:focus,
.reset #main .form-row select:focus {
  border: 1px solid #0076C0;
}

.reset.new-skew-style #main input::placeholder,
.reset.new-skew-style #main textarea::placeholder,
.reset.new-skew-style #main select,
.reset #main input::placeholder,
.reset #main textarea::placeholder,
.reset #main select {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7D7D7D !important;
}

.reset.new-skew-style #main .form-row textarea,
.reset #main .form-row textarea {
  min-height: 80px;
  resize: none;
}

.reset.new-skew-style #main .form-row .selected + .jcf-select .jcf-select-text span,
.reset #main .form-row .selected + .jcf-select .jcf-select-text span {
  color: #171717 !important;
}

.reset.new-skew-style #main .form-row .jcf-select,
.reset #main .form-row .jcf-select {
  border: 1px solid #D7D7D7;
  height: 40px;
}

.reset.new-skew-style #main .form-row .jcf-select .jcf-select-text,
.reset #main .form-row .jcf-select .jcf-select-text {
  padding-left: 8px !important;
  background: none !important;
}

.reset.new-skew-style #main .form-row .jcf-select .jcf-select-text span,
.reset #main .form-row .jcf-select .jcf-select-text span {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  color: #7D7D7D !important;
}

.reset.new-skew-style #main .form-row .jcf-select .jcf-select-opener,
.reset #main .form-row .jcf-select .jcf-select-opener {
  height: 35px !important;
}

.reset.new-skew-style #main .form-row .jcf-select .jcf-select-opener:after,
.reset #main .form-row .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #231F20 !important;
  border-top: 2px solid #231F20 !important;
}

.reset.new-skew-style #main .form-row .jcf-select .jcf-select-drop-content .jcf-option,
.reset #main .form-row .jcf-select .jcf-select-drop-content .jcf-option {
  font-weight: 400;
}

.reset.new-skew-style #main .form-checkbox,
.reset #main .form-checkbox {
  margin-bottom: 16px;
}

.reset.new-skew-style #main .form-checkbox .checkbox-label .checkbox-icon,
.reset #main .form-checkbox .checkbox-label .checkbox-icon {
  width: 20px;
  height: 20px;
  border: solid 1px #D7D7D7;
  border-radius: 0;
}

.reset.new-skew-style #main .form-checkbox .checkbox-label input + label.error + .checkbox-icon,
.reset #main .form-checkbox .checkbox-label input + label.error + .checkbox-icon {
  border: solid 1px #D7D7D7 !important;
}

.reset.new-skew-style #main .form-checkbox .checkbox-label input:checked + .checkbox-icon,
.reset.new-skew-style #main .form-checkbox .checkbox-label input:checked + label + .checkbox-icon,
.reset #main .form-checkbox .checkbox-label input:checked + .checkbox-icon,
.reset #main .form-checkbox .checkbox-label input:checked + label + .checkbox-icon {
  background: url(./images/checkbox-bg-2.svg);
  background-size: contain;
  border: none !important;
}

.reset.new-skew-style #main .form-checkbox .row > div,
.reset #main .form-checkbox .row > div {
  max-width: 100%;
}

.reset.new-skew-style #main .form-checkbox .checkbox-label,
.reset #main .form-checkbox .checkbox-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

.reset.new-skew-style #main .form-checkbox .checkbox-label .checkbox-icon,
.reset #main .form-checkbox .checkbox-label .checkbox-icon {
  margin-top: 2px;
  min-width: 20px;
  min-height: 20px;
  background-size: cover;
}

.reset.new-skew-style #main table th,
.reset #main table th {
  background-color: #F6F8F8 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-variant: small-caps !important;
  color: #3D4448 !important;
  padding: 16px !important;
  text-transform: uppercase !important;
}

.reset.new-skew-style #main table tr,
.reset #main table tr {
  background: none !important;
  border-bottom: solid 1px #D7D7D7;
}

.reset.new-skew-style #main table tr:last-child,
.reset #main table tr:last-child {
  border: none;
}

.reset.new-skew-style #main table tr td,
.reset #main table tr td {
  padding: 32px 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #3D4448;
  vertical-align: top;
}

.reset.new-skew-style #main table tr td:first-child,
.reset #main table tr td:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

@media (max-width: 480px) {
  .reset.new-skew-style #main table tr th,
  .reset.new-skew-style #main table tr td,
  .reset #main table tr th,
  .reset #main table tr td {
    padding: 16px 8px !important;
    font-size: 12px !important;
  }
}

.reset.new-skew-style #main .lc-item.active a::before {
  bottom: -3px !important;
}

.reset.new-skew-style #main .tabs-pci .quantity {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-variant: small-caps;
  color: #7D7D7D;
  background: #F6F8F8;
  border-radius: 2px;
  margin: 0 0 0 8px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  font-style: normal;
}

.reset.new-skew-style #main .tabs-pci.border-no {
  border: none;
}

.reset.new-skew-style #main .tabs-pci .active a {
  font-weight: 500 !important;
}

.reset.new-skew-style #main .tabs-pci .active .quantity {
  color: #171717;
}

.reset.new-skew-style #main .tabs-pci .lc-item {
  margin: 0 20px;
}

.reset.new-skew-style #main .tabs-pci .lc-item:first-child {
  margin-left: 0;
}

.reset.new-skew-style #main .tabs-pci .lc-item a {
  padding: 12px 0;
}

.reset.new-skew-style #main .tabs-filter {
  position: absolute;
  right: 0;
  top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.5px;
  color: #0076C0;
  cursor: pointer;
}

.reset.new-skew-style #main .tabs-filter span {
  margin-right: 8px;
}

.reset.new-skew-style #main .tabs-filter .tabs-filter-list {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(191, 191, 191, 0.25);
  padding: 8px;
  width: 180px;
  position: absolute;
  right: 0;
  display: none;
}

.reset.new-skew-style #main .tabs-filter .tabs-filter-list ul {
  padding: 0;
  margin: 0;
}

.reset.new-skew-style #main .tabs-filter .tabs-filter-list ul li {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 152%;
  color: #3D4448;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reset.new-skew-style #main .tabs-filter .tabs-filter-list ul li svg {
  display: none;
}

.reset.new-skew-style #main .tabs-filter .tabs-filter-list ul li:hover, .reset.new-skew-style #main .tabs-filter .tabs-filter-list ul li.active {
  background: #F6F8F8;
}

.reset.new-skew-style #main .tabs-filter .tabs-filter-list ul li:hover svg, .reset.new-skew-style #main .tabs-filter .tabs-filter-list ul li.active svg {
  display: inline-block;
}

.reset.new-skew-style #main .tabs-filter.open .tabs-filter-list {
  display: block;
}

.reset.new-skew-style #main .sub-filters button {
  font-weight: 500;
  text-decoration: none;
}

.reset.new-skew-style #main .video-card {
  width: 288px;
  min-height: 304px;
  background: #F6F8F8;
}

.reset.new-skew-style #main .video-card.small {
  min-height: 280px;
}

.reset.new-skew-style #main .video-card .video-card-top {
  height: 160px;
  position: relative;
}

.reset.new-skew-style #main .video-card .video-card-top:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 118, 191, 0.5), rgba(0, 118, 191, 0.5)), url(image.png);
  display: block;
  content: "";
  top: 0;
  left: 0;
  opacity: 0;
  transition: .5s;
}

.reset.new-skew-style #main .video-card .video-card-top .video-top_img img {
  height: 160px;
  object-fit: cover;
  width: 100%;
}

.reset.new-skew-style #main .video-card .video-card-top .video-top_time {
  position: absolute;
  top: 10px;
  right: 10px;
}

.reset.new-skew-style #main .video-card .video-card-top .video-top_time span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #F6F8F8;
  background: rgba(125, 125, 125, 0.7);
  border-radius: 2px;
  padding: 2px 8px;
}

.reset.new-skew-style #main .video-card .video-card-top .video-top_play {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.reset.new-skew-style #main .video-card .video-card-top .video-top_videos {
  background: rgba(215, 215, 215, 0.7);
  height: 32px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset.new-skew-style #main .video-card .video-card-top .video-top_videos span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  margin-right: 5px;
}

.reset.new-skew-style #main .video-card .video-card-top .video-top_videos path {
  fill: #FFFFFF;
}

.reset.new-skew-style #main .video-card .video-card-bottom {
  background: #F6F8F8;
  padding: 24px;
}

.reset.new-skew-style #main .video-card .video-card-bottom .characteristics {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 8px 0;
}

.reset.new-skew-style #main .video-card .video-card-bottom .characteristics li {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #7D7D7D;
  padding: 0 12px 0 0;
  margin: 0 12px 0 0;
  position: relative;
  text-transform: uppercase;
}

.reset.new-skew-style #main .video-card .video-card-bottom .characteristics li::before {
  display: block;
  position: absolute;
  left: 100%;
  top: 5px;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #ccc;
  transform: translate(-50%, 0);
}

.reset.new-skew-style #main .video-card .video-card-bottom .characteristics li:last-child {
  padding: 0;
  margin: 0;
}

.reset.new-skew-style #main .video-card .video-card-bottom .characteristics li:last-child::before {
  display: none;
}

.reset.new-skew-style #main .video-card .video-card-bottom .description p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #171717;
}

.reset.new-skew-style #main .video-card:hover .video-card-bottom .description p {
  color: #0076C0;
}

.reset.new-skew-style #main .video-card:hover .video-card-top::before {
  opacity: 1;
  transition: .5s;
}

.reset.new-skew-style #main .horizontal-card {
  padding: 40px 24px;
  background: #F6F8F8;
  display: flex;
}

.reset.new-skew-style #main .horizontal-card:hover {
  background: #f0f2f2;
}

.reset.new-skew-style #main .horizontal-card .horizontal-card_left {
  margin-right: 16px;
}

.reset.new-skew-style #main .horizontal-card .horizontal-card_right .type {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #219653;
  margin-bottom: 8px;
}

.reset.new-skew-style #main .horizontal-card .horizontal-card_right .type.gray {
  color: #7D7D7D;
}

.reset.new-skew-style #main .horizontal-card .horizontal-card_right .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #171717;
  margin-bottom: 12px;
}

.reset.new-skew-style #main .section-video-library {
  border-bottom: solid 1px #D7D7D7;
}

.reset.new-skew-style #main .section-video-library .container {
  position: relative;
}

.reset.new-skew-style #main .section-video-library .video-library_slider {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.reset.new-skew-style #main .section-video-library .video-library_slider:after {
  content: "";
  display: block;
  position: absolute;
  right: -240px;
  top: 0;
  width: 300px;
  height: 50px;
  background: linear-gradient(269.83deg, #FFFFFF 82.38%, rgba(255, 255, 255, 0.3) 99.83%);
}

.reset.new-skew-style #main .section-video-library .video-library_slider .video-library_item {
  padding: 8px 16px;
  background: #F6F8F8;
  border-radius: 24px;
  color: #171717;
  margin: 0 8px;
  cursor: pointer;
}

.reset.new-skew-style #main .section-video-library .video-library_slider .video-library_item button {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  border: none;
  background: none;
  outline: none;
}

.reset.new-skew-style #main .section-video-library .video-library_slider .video-library_item button span {
  display: none;
  margin-left: 5px;
}

.reset.new-skew-style #main .section-video-library .video-library_slider .video-library_item:hover, .reset.new-skew-style #main .section-video-library .video-library_slider .video-library_item.active {
  background: #F2F4F5;
}

.reset.new-skew-style #main .section-video-library .video-library_slider .video-library_item.active {
  background: #0076C0;
}

.reset.new-skew-style #main .section-video-library .video-library_slider .video-library_item.active span {
  display: inline-block;
}

.reset.new-skew-style #main .section-video-library .video-library_slider .video-library_item.active button {
  color: #fff;
}

.reset.new-skew-style #main .section-video-library .video-library_slider .slick-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  cursor: pointer;
  z-index: 9;
  transform: translate(0, -50%);
}

.reset.new-skew-style #main .section-video-library .video-library_slider .slick-arrow.slick-disabled {
  display: none !important;
}

.reset.new-skew-style #main .section-video-library .video-library_slider .slick-arrow.next {
  right: 0;
  left: auto;
}

.reset.new-skew-style #main .section-video-library:hover .slick-arrow {
  display: block !important;
}

.reset.new-skew-style #main .icon-close-search {
  padding-right: 8px;
  margin-right: 8px;
  border-right: solid 1px #D7D7D7;
  min-width: 34px;
  min-height: 24px;
}

.reset.new-skew-style #main .video-inner-wrap {
  display: flex;
  border-bottom: solid 1px #E5E5E5;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-left {
  padding-right: 30px;
  border-right: solid 1px #E5E5E5;
  height: 568px;
  overflow: auto;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-left iframe {
  height: 360px;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-left .video-single-description {
  padding: 32px 0 40px 0 !important;
  border: none !important;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-left .video-single-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-left .video-single-title h3 {
  margin: 0;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right {
  min-width: 498px;
  height: 568px;
  overflow: auto;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F3F3F3;
  border-bottom: solid 2px #fff;
  border-top: solid 2px #fff;
  cursor: pointer;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right::-webkit-scrollbar-thumb {
  background-color: #0076C0;
  height: 8px;
  cursor: pointer;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right .video-inner-item {
  padding: 16px;
  display: flex;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right .video-inner-item .video-inner-img {
  margin-right: 12px;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right .video-inner-item .video-inner-img img {
  width: 120px;
  height: 64px;
  min-width: 120px;
  min-height: 64px;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right .video-inner-item .video-inner-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #171717;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right .video-inner-item .video-inner-time {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-variant: small-caps;
  color: #7D7D7D;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right .video-inner-item:hover, .reset.new-skew-style #main .video-inner-wrap .video-inner-right .video-inner-item.active {
  background: #F6F8F8;
  cursor: pointer;
}

.reset.new-skew-style #main .video-inner-wrap .video-inner-right .video-inner-item.active .video-inner-img img {
  border: solid 2px #0076C0;
}

.reset.new-skew-style #main .section-izotope .grid.border-yes {
  margin-bottom: 32px;
  position: relative;
}

.reset.new-skew-style #main .section-izotope .grid.border-yes:after {
  position: absolute;
  width: calc(100% - 32px);
  height: 1px;
  background-color: #D7D7D7;
  display: block;
  content: "";
  left: 16px;
  top: 100%;
}

.reset.new-skew-style #main .section-overview-text-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.reset.new-skew-style #main .section-overview-text-wrap p {
  margin: 0;
}

.reset.new-skew-style #main .section-overview-text-wrap p b {
  text-transform: uppercase;
  font-weight: 500;
}

.reset.new-skew-style #main .section-overview-text-wrap a {
  font-weight: 500;
  font-size: 14px;
}

.reset.new-skew-style #main .section-overview-text-wrap .overview-text-icon {
  margin-right: 12px;
}

.reset.new-skew-style #main .section-over-text-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.reset.new-skew-style #main .section-over-text-info svg,
.reset.new-skew-style #main .section-over-text-info img {
  margin-right: 16px;
  min-width: 93px;
}

.reset.new-skew-style #main .section-overview-item ul li {
  font-size: 16px;
  color: #3D4448;
}

.reset.new-skew-style #main .section-overview-item p {
  color: #3D4448;
}

.reset.new-skew-style #main .link-over {
  display: flex;
  align-items: center;
}

.reset.new-skew-style #main .link-over img {
  margin-right: 8px;
}

.reset.new-skew-style #main .fs-20 {
  font-size: 20px;
}

.reset.new-skew-style #main .section-banner.bg {
  background-image: url(images/support/sup-bg.png);
}

.reset.new-skew-style #main .agreement p {
  font-size: 15px;
}

.reset.new-skew-style #main .section-overview-bigvideo {
  display: none;
}

.reset.new-skew-style #main .section-overview-bigvideo h3 {
  font-size: 32px;
  line-height: 40px;
}

.reset.new-skew-style #main .section-overview-bigvideo.show {
  display: block;
}

.reset.new-skew-style #main #contant-form .form-row {
  margin-bottom: 32px !important;
}

.reset.new-skew-style #main #contant-form .form-row.mb-0 {
  margin-bottom: 0 !important;
}

.reset.new-skew-style #main .contant-locations .contant-locations-inner {
  border: 1px solid #D7D7D7;
}

.reset.new-skew-style #main .contant-locations .contant-locations-inner ul {
  margin: 0;
}

.reset.new-skew-style #main .contant-locations .contant-locations-inner ul li {
  padding: 0;
  margin: 0 0 16px 0;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  align-items: center;
}

.reset.new-skew-style #main .contant-locations .contant-locations-inner ul li:last-child {
  margin: 0;
}

.reset.new-skew-style #main .contant-locations .contant-locations-inner ul li .icon {
  margin-right: 16px;
}

.reset.new-skew-style #main .contant-locations .contant-locations-top {
  padding: 40px 40px 24px 40px;
}

.reset.new-skew-style #main .contant-locations .contant-locations-title {
  background: #F6F8F8;
  height: 40px;
}

.reset.new-skew-style #main .contant-locations .contant-locations-title .row {
  margin: 0;
}

.reset.new-skew-style #main .contant-locations .contant-locations-title [class^="col"] {
  padding: 0;
}

.reset.new-skew-style #main .contant-locations .contant-locations-title .locations-title_item {
  padding: 0 40px;
}

.reset.new-skew-style #main .contant-locations .contant-locations-title span {
  font-weight: 500;
  font-size: 12px;
  line-height: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
}

.reset.new-skew-style #main .contant-locations .contant-locations-bottom {
  padding: 24px 0 40px 0;
}

.reset.new-skew-style #main .contant-locations .contant-locations-bottom .row {
  margin: 0;
}

.reset.new-skew-style #main .contant-locations .contant-locations-bottom [class^="col"] {
  padding: 0;
}

.reset.new-skew-style #main .contant-locations .contant-locations-bottom [class^="col"]:last-child .locations-bottom_item {
  border-right: none;
}

.reset.new-skew-style #main .contant-locations .locations-bottom_item {
  padding: 0 40px;
  border-right: solid 1px #D7D7D7;
  height: 100%;
}

.reset.new-skew-style #main .modal.modal-contact .modal-content {
  border: none;
}

.reset.new-skew-style #main .modal.modal-contact .modal-content a {
  color: #0076C0;
}

.reset.new-skew-style #main .modal.modal-contact .modal-dialog {
  transition: none !important;
}

.reset.new-skew-style #main .modal.modal-contact .modal-dialog.big {
  max-width: 1200px;
}

.reset.new-skew-style #main .modal.modal-contact .contant-locations-top h2 {
  display: flex;
  align-items: center;
}

.reset.new-skew-style #main .modal.modal-contact .contant-locations-top .type {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.reset.new-skew-style #main .modal.modal-contact .contant-locations-top span {
  background: #F6F8F8;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
  padding: 2px 8px;
  margin-right: 8px;
  display: inline-block;
}

.reset.new-skew-style #main .modal.modal-contact .contant-locations-top .close {
  position: absolute;
  right: 30px;
  opacity: 1;
}

.reset.new-skew-style #main .section-contact-select {
  background: #F6F8F8;
  box-shadow: inset 0px -1px 0px #E2E4E6;
  padding: 20px 0;
}

.reset.new-skew-style #main .section-contact-accordion .card-header h4 {
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.reset.new-skew-style #main .section-contact-accordion .card-header h4 span {
  background: #F6F8F8;
  border-radius: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-variant: small-caps;
  color: #171717;
  margin-left: 8px;
  padding: 0 4px;
  display: inline-block;
}

.reset.new-skew-style #main .section-contact-item .contact-item_top {
  margin-bottom: 32px;
}

.reset.new-skew-style #main .section-contact-item .contact-item_img {
  margin-bottom: 24px;
}

.reset.new-skew-style #main .section-contact-item .contact-item_title {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.reset.new-skew-style #main .section-contact-item .contact-item_title h3 {
  font-size: 24px;
  line-height: 32px;
  margin: 0 8px 0 0;
}

.reset.new-skew-style #main .section-contact-item .contact-item_title .type-wrap {
  display: flex;
  align-items: center;
}

.reset.new-skew-style #main .section-contact-item .contact-item_title .type-wrap .type {
  background: #F6F8F8;
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
  text-align: center;
  margin-left: 8px;
  padding: 2px 8px;
  display: inline-block;
  font-weight: 500;
}

.reset.new-skew-style #main .section-contact-item .description ul {
  margin: 0;
}

.reset.new-skew-style #main .section-contact-item .description li {
  padding: 0;
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
}

.reset.new-skew-style #main .section-contact-item .description li:last-child {
  margin: 0;
}

.reset.new-skew-style #main .section-contact-item .description li .simbol {
  margin-right: 16px;
}

.reset.new-skew-style #main .section-contact-item .description li a {
  color: #0076C0;
}

.reset.new-skew-style #main .test-wrap section.contact-caunt {
  display: none;
}

.reset.new-skew-style #main .test-wrap.ger .contact-caunt-accordion {
  display: none;
}

.reset.new-skew-style #main .test-wrap.ger .contact-ger {
  display: block;
}

.reset.new-skew-style #main .test-wrap.usa .contact-caunt-accordion {
  display: none;
}

.reset.new-skew-style #main .test-wrap.ukr .contact-caunt-accordion {
  display: none;
}

.reset.new-skew-style #main .section-contact-select .row,
.reset.new-skew-style #main .section-contact-select2 .row,
.reset.new-skew-style #main .section-contact-select3 .row {
  align-items: center;
}

.reset.new-skew-style #main .section-contact-select #select-product-reset,
.reset.new-skew-style #main .section-contact-select #select-product-reset2,
.reset.new-skew-style #main .section-contact-select #select-product-reset3,
.reset.new-skew-style #main .section-contact-select2 #select-product-reset,
.reset.new-skew-style #main .section-contact-select2 #select-product-reset2,
.reset.new-skew-style #main .section-contact-select2 #select-product-reset3,
.reset.new-skew-style #main .section-contact-select3 #select-product-reset,
.reset.new-skew-style #main .section-contact-select3 #select-product-reset2,
.reset.new-skew-style #main .section-contact-select3 #select-product-reset3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.5px;
  color: #0076C0;
  cursor: pointer;
}

.reset.new-skew-style #main .section-contact-select #select-product-reset,
.reset.new-skew-style #main .section-contact-select #select-product-reset2,
.reset.new-skew-style #main .section-contact-select .select-speciality-sub,
.reset.new-skew-style #main .section-contact-select .select-speciality-sub2,
.reset.new-skew-style #main .section-contact-select #select-product-reset3,
.reset.new-skew-style #main .section-contact-select .select-speciality-sub3,
.reset.new-skew-style #main .section-contact-select2 #select-product-reset,
.reset.new-skew-style #main .section-contact-select2 #select-product-reset2,
.reset.new-skew-style #main .section-contact-select2 .select-speciality-sub,
.reset.new-skew-style #main .section-contact-select2 .select-speciality-sub2,
.reset.new-skew-style #main .section-contact-select2 #select-product-reset3,
.reset.new-skew-style #main .section-contact-select2 .select-speciality-sub3,
.reset.new-skew-style #main .section-contact-select3 #select-product-reset,
.reset.new-skew-style #main .section-contact-select3 #select-product-reset2,
.reset.new-skew-style #main .section-contact-select3 .select-speciality-sub,
.reset.new-skew-style #main .section-contact-select3 .select-speciality-sub2,
.reset.new-skew-style #main .section-contact-select3 #select-product-reset3,
.reset.new-skew-style #main .section-contact-select3 .select-speciality-sub3 {
  display: none;
}

.reset.new-skew-style #main .section-contact-select.ger #select-product-reset,
.reset.new-skew-style #main .section-contact-select.ger #select-product-reset2,
.reset.new-skew-style #main .section-contact-select.ger #select-product-reset3, .reset.new-skew-style #main .section-contact-select.usa #select-product-reset,
.reset.new-skew-style #main .section-contact-select.usa #select-product-reset2,
.reset.new-skew-style #main .section-contact-select.usa #select-product-reset3, .reset.new-skew-style #main .section-contact-select.ukr #select-product-reset,
.reset.new-skew-style #main .section-contact-select.ukr #select-product-reset2,
.reset.new-skew-style #main .section-contact-select.ukr #select-product-reset3,
.reset.new-skew-style #main .section-contact-select2.ger #select-product-reset,
.reset.new-skew-style #main .section-contact-select2.ger #select-product-reset2,
.reset.new-skew-style #main .section-contact-select2.ger #select-product-reset3,
.reset.new-skew-style #main .section-contact-select2.usa #select-product-reset,
.reset.new-skew-style #main .section-contact-select2.usa #select-product-reset2,
.reset.new-skew-style #main .section-contact-select2.usa #select-product-reset3,
.reset.new-skew-style #main .section-contact-select2.ukr #select-product-reset,
.reset.new-skew-style #main .section-contact-select2.ukr #select-product-reset2,
.reset.new-skew-style #main .section-contact-select2.ukr #select-product-reset3,
.reset.new-skew-style #main .section-contact-select3.ger #select-product-reset,
.reset.new-skew-style #main .section-contact-select3.ger #select-product-reset2,
.reset.new-skew-style #main .section-contact-select3.ger #select-product-reset3,
.reset.new-skew-style #main .section-contact-select3.usa #select-product-reset,
.reset.new-skew-style #main .section-contact-select3.usa #select-product-reset2,
.reset.new-skew-style #main .section-contact-select3.usa #select-product-reset3,
.reset.new-skew-style #main .section-contact-select3.ukr #select-product-reset,
.reset.new-skew-style #main .section-contact-select3.ukr #select-product-reset2,
.reset.new-skew-style #main .section-contact-select3.ukr #select-product-reset3 {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.oscilloscopes .select-oscilloscope,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes .select-oscilloscope,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes .select-oscilloscope {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.oscilloscopes2 .select-oscilloscope2,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes2 .select-oscilloscope2,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes2 .select-oscilloscope2 {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.oscilloscopes3 .select-oscilloscope3,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes3 .select-oscilloscope3,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes3 .select-oscilloscope3 {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.protocol .disabled-opacity-wrap, .reset.new-skew-style #main .section-contact-select.oscilloscopes .disabled-opacity-wrap, .reset.new-skew-style #main .section-contact-select.protocol2 .disabled-opacity-wrap, .reset.new-skew-style #main .section-contact-select.oscilloscopes2 .disabled-opacity-wrap, .reset.new-skew-style #main .section-contact-select.protocol3 .disabled-opacity-wrap, .reset.new-skew-style #main .section-contact-select.oscilloscopes3 .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select2.protocol .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select2.protocol2 .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes2 .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select2.protocol3 .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes3 .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select3.protocol .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select3.protocol2 .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes2 .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select3.protocol3 .disabled-opacity-wrap,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes3 .disabled-opacity-wrap {
  display: none;
}

.reset.new-skew-style #main .section-contact-select.protocol .select-product,
.reset.new-skew-style #main .section-contact-select2.protocol .select-product,
.reset.new-skew-style #main .section-contact-select3.protocol .select-product {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.protocol2 .select-product2,
.reset.new-skew-style #main .section-contact-select2.protocol2 .select-product2,
.reset.new-skew-style #main .section-contact-select3.protocol2 .select-product2 {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.protocol3 .select-product3,
.reset.new-skew-style #main .section-contact-select2.protocol3 .select-product3,
.reset.new-skew-style #main .section-contact-select3.protocol3 .select-product3 {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.oscilloscopes #select-product-reset, .reset.new-skew-style #main .section-contact-select.protocol #select-product-reset,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes #select-product-reset,
.reset.new-skew-style #main .section-contact-select2.protocol #select-product-reset,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes #select-product-reset,
.reset.new-skew-style #main .section-contact-select3.protocol #select-product-reset {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.oscilloscopes2 #select-product-reset2, .reset.new-skew-style #main .section-contact-select.protocol2 #select-product-reset2,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes2 #select-product-reset2,
.reset.new-skew-style #main .section-contact-select2.protocol2 #select-product-reset2,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes2 #select-product-reset2,
.reset.new-skew-style #main .section-contact-select3.protocol2 #select-product-reset2 {
  display: block;
}

.reset.new-skew-style #main .section-contact-select.oscilloscopes3 #select-product-reset3, .reset.new-skew-style #main .section-contact-select.protocol3 #select-product-reset3,
.reset.new-skew-style #main .section-contact-select2.oscilloscopes3 #select-product-reset3,
.reset.new-skew-style #main .section-contact-select2.protocol3 #select-product-reset3,
.reset.new-skew-style #main .section-contact-select3.oscilloscopes3 #select-product-reset3,
.reset.new-skew-style #main .section-contact-select3.protocol3 #select-product-reset3 {
  display: block;
}

.reset.new-skew-style #main .section-document .section-document-head {
  background: #F6F8F8;
  height: 56px;
}

.reset.new-skew-style #main .section-document .section-document-head .row {
  margin: 0;
}

.reset.new-skew-style #main .section-document .section-document-head span {
  font-weight: 500;
  font-size: 16px;
  line-height: 56px;
  text-transform: uppercase;
  color: #3D4448;
}

.reset.new-skew-style #main .section-document.show .row.no-show {
  display: flex !important;
}

.reset.new-skew-style #main .section-document .section-document-content .row {
  padding: 32px 0;
  margin: 0;
  border-bottom: solid 1px #D7D7D7;
}

.reset.new-skew-style #main .section-document .section-document-content .row.no-border {
  border-bottom: none;
}

.reset.new-skew-style #main .section-document .section-document-content .row.no-show {
  display: none;
}

.reset.new-skew-style #main .section-document .section-document-content .row [class^="col"]:last-child {
  text-align: right;
}

.reset.new-skew-style #main .section-document .section-document-content h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 8px;
}

.reset.new-skew-style #main .section-document .section-document-content p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
}

.reset.new-skew-style #main .section-document .section-document-content a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #0076C0;
}

.reset.new-skew-style #main .section-document .section-document-content a:hover {
  color: #0699F5;
}

.reset.new-skew-style #main .section-document .section-document-content a svg {
  margin-left: 8px;
}

.new-skew-style .form-row .checkbox-label input:checked + .checkbox-icon,
.new-skew-style .form-row .checkbox-label input:checked + label.error + .checkbox-icon,
.new-skew-style #pardot-form .form-field .checkbox-label input:checked + .checkbox-icon,
.new-skew-style #pardot-form .form-field .checkbox-label input:checked + label.error + .checkbox-icon {
  background: url(./images/checkbox-bg-3.svg);
  border: none;
  background-repeat: no-repeat;
}

.new-skew-style .form-title {
  margin-bottom: 32px;
  position: relative;
  font-size: 20px;
  line-height: 24px;
}

.new-skew-style .form-title:after {
  display: block;
  background-color: #D7D7D7;
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  content: "";
  width: 100%;
  height: 1px;
}

.new-skew-style .form-title span {
  position: relative;
  background-color: #fff;
  z-index: 1;
  padding-right: 16px;
}

.new-skew-style .form-row label,
.new-skew-style #pardot-form .form-field label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 8px;
}

.new-skew-style .form-row .checkbox-label,
.new-skew-style #pardot-form .form-field .checkbox-label {
  margin-bottom: 32px;
}

.new-skew-style .form-row .checkbox-label .checkbox-icon,
.new-skew-style #pardot-form .form-field .checkbox-label .checkbox-icon {
  min-width: 20px;
  min-height: 20px;
  top: 5px;
  position: relative;
  border: solid 1px #D7D7D7;
  border-radius: 0;
}

.new-skew-style .jcf-select .jcf-select-text span {
  font-size: 14px !important;
  color: #0076C0 !important;
}

.new-skew-style .form-row .jcf-select {
  width: auto;
}

.new-skew-style .form-row .jcf-select .jcf-select-opener {
  margin-left: 5px;
}

.new-skew-style .form-row .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #0076C0 !important;
  border-top: 2px solid #0076C0 !important;
}

.new-skew-style .form-row input[type=text],
.new-skew-style .form-row input[type=email],
.new-skew-style .form-row input[type=phone],
.new-skew-style .form-row input[type=password],
.new-skew-style .form-row textarea,
.new-skew-style .form-row select {
  border: 1px solid #D7D7D7;
  border-radius: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  height: 40px;
}

.new-skew-style .form-row input[type=text]:focus,
.new-skew-style .form-row input[type=email]:focus,
.new-skew-style .form-row input[type=phone]:focus,
.new-skew-style .form-row input[type=password]:focus,
.new-skew-style .form-row textarea:focus,
.new-skew-style .form-row select:focus {
  border: 1px solid #0076C0;
}

.new-skew-style input::placeholder,
.new-skew-style textarea::placeholder,
.new-skew-style select {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7D7D7D !important;
}

.new-skew-style .form-row textarea {
  min-height: 80px;
  resize: none;
}

.new-skew-style .form-row .selected + .jcf-select .jcf-select-text span {
  color: #171717 !important;
}

.new-skew-style .form-row .jcf-select {
  border: 1px solid #D7D7D7;
  height: 40px;
}

.new-skew-style .form-row .jcf-select .jcf-select-text {
  padding-left: 8px !important;
  background: none !important;
}

.new-skew-style .form-row .jcf-select .jcf-select-text span {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  color: #7D7D7D !important;
}

.new-skew-style .form-row .jcf-select .jcf-select-opener {
  height: 35px !important;
}

.new-skew-style .form-row .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #231F20 !important;
  border-top: 2px solid #231F20 !important;
}

.new-skew-style .form-row .jcf-select .jcf-select-drop-content .jcf-option {
  font-weight: 400;
}

.new-skew-style .form-checkbox {
  margin-bottom: 16px;
}

.new-skew-style .form-checkbox .checkbox-label .checkbox-icon {
  width: 20px;
  height: 20px;
  border: solid 1px #D7D7D7;
  border-radius: 0;
}

.new-skew-style .form-checkbox .checkbox-label input + label.error + .checkbox-icon {
  border: solid 1px #D7D7D7 !important;
}

.new-skew-style .form-checkbox .checkbox-label input:checked + .checkbox-icon,
.new-skew-style .form-checkbox .checkbox-label input:checked + label + .checkbox-icon {
  background: url(./images/checkbox-bg-2.svg);
  background-size: contain;
  border: none !important;
}

.new-skew-style .form-checkbox .row > div {
  max-width: 100%;
}

.new-skew-style .form-checkbox .checkbox-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

.new-skew-style .form-checkbox .checkbox-label .checkbox-icon {
  margin-top: 2px;
  min-width: 20px;
  min-height: 20px;
  background-size: cover;
}

.datasheet-wrapper {
  display: flex;
  position: relative;
}

.datasheet-wrapper .datasheet-sidebar {
  min-width: 365px;
  width: 365px;
}

.datasheet-wrapper .datasheet-sidebar-top {
  height: 104px;
  display: flex;
  align-items: center;
  position: relative;
}

.datasheet-wrapper .datasheet-sidebar-top:after {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  right: 0;
  background: #D7D7D7;
  width: 1000%;
  height: 1px;
}

.datasheet-wrapper .datasheet-sidebar-top a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  text-align: center;
  color: #0076C0;
}

.datasheet-wrapper .datasheet-sidebar-top a svg {
  margin-left: 8px;
}

.datasheet-wrapper .datasheet-sidebar-top a:hover {
  color: #0699F5;
}

.datasheet-wrapper .datasheet-sidebar-top a:hover svg path {
  fill: #0699F5;
}

.datasheet-wrapper .datasheet-sidebar-tabs {
  padding-top: 40px;
  position: sticky;
  top: 84px;
  overflow: auto;
  height: 100vh;
}

.datasheet-wrapper .datasheet-sidebar-tabs .nav-pills > li {
  margin-bottom: 40px;
  padding: 0;
  width: 100%;
}

.datasheet-wrapper .datasheet-sidebar-tabs .number {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #7D7D7D;
  display: inline-block;
  margin-bottom: 16px;
}

.datasheet-wrapper .datasheet-sidebar-tabs .title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #7D7D7D;
  cursor: pointer;
}

.datasheet-wrapper .datasheet-sidebar-tabs .show .number {
  background: linear-gradient(180deg, #0076BF 0%, #0699F5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.datasheet-wrapper .datasheet-sidebar-tabs .show .title {
  color: #171717;
}

.datasheet-wrapper .datasheet-sidebar-tabs .show + .links {
  max-height: 100%;
  transition: .5s;
  padding-top: 32px;
}

.datasheet-wrapper .datasheet-sidebar-tabs .show + .links .anchor-active span {
  color: #171717 !important;
  border-right: solid 2px #0076C0;
  display: block;
  width: 100%;
}

.datasheet-wrapper .datasheet-sidebar-tabs .links {
  max-height: 0;
  overflow: hidden;
  transition: .5s;
  padding-top: 0;
  border: none;
}

.datasheet-wrapper .datasheet-sidebar-tabs .links .new-scroll {
  cursor: pointer;
  font-weight: 500;
  color: #7D7D7D;
}

.datasheet-wrapper .datasheet-sidebar-tabs .links .lc-item {
  margin-bottom: 16px;
  padding: 0;
}

.datasheet-wrapper .datasheet-sidebar-tabs .links .lc-item a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7D7D !important;
  padding: 0;
  background: none;
  text-align: left;
}

.datasheet-wrapper .datasheet-sidebar-tabs .links .lc-item.anchor-active a {
  color: #171717 !important;
  border-right: solid 2px #0076C0;
}

.datasheet-wrapper .datasheet-sidebar-tabs .links .lc-item:last-child {
  margin-bottom: 0;
}

.datasheet-wrapper .datasheet-content {
  width: 100%;
  padding-left: 32px;
  padding-top: 40px;
  border-left: solid 1px #D7D7D7;
}

.datasheet-wrapper .datasheet-content section {
  padding-bottom: 120px;
}

.datasheet-wrapper .datasheet-content .datasheet-heading .datasheet-heading-top {
  text-align: center;
  margin-bottom: 80px;
}

.datasheet-wrapper .datasheet-content .datasheet-heading .datasheet-heading-top h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #7D7D7D;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.datasheet-wrapper .datasheet-content .datasheet-heading .datasheet-heading-top .number {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  background: linear-gradient(180deg, #0076BF 0%, #0699F5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  display: inline-block;
}

.datasheet-wrapper .datasheet-content .datasheet-heading .datasheet-heading-top .title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #171717;
}

.datasheet-wrapper .datasheet-content .datasheet-heading .datasheet-heading-bottom {
  margin-bottom: 80px;
}

.datasheet-wrapper .datasheet-content .datasheet-heading .datasheet-heading-bottom p {
  color: #3D4448;
}

.datasheet-wrapper .datasheet-content #accordion {
  margin-bottom: 80px;
}

.datasheet-wrapper .datasheet-content #accordion .show .card-body {
  margin-top: 0 !important;
  padding: 8px 0 0 0 !important;
}

.datasheet-wrapper .datasheet-content #accordion h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
  margin-bottom: 8px;
}

.datasheet-wrapper .datasheet-content .datasheet-content-text .datasheet-content-item {
  margin-bottom: 80px;
}

.datasheet-wrapper .datasheet-content .datasheet-content-text .datasheet-content-item p {
  margin-bottom: 32px;
  color: #3D4448;
}

.datasheet-wrapper .datasheet-content .list-dots li {
  padding-left: 18px !important;
}

.datasheet-wrapper .datasheet-content .list-dots li:before {
  background: #0076C0;
  width: 6px;
  height: 6px;
}

.datasheet-wrapper .datasheet-table table th {
  background-color: #F6F8F8 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-variant: small-caps !important;
  color: #3D4448 !important;
  padding: 16px !important;
  text-transform: uppercase !important;
}

.datasheet-wrapper .datasheet-table table tr {
  background: none !important;
  border-bottom: solid 1px #D7D7D7;
}

.datasheet-wrapper .datasheet-table table tr:last-child {
  border: none;
}

.datasheet-wrapper .datasheet-table table tr td {
  padding: 32px 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #3D4448;
  vertical-align: top;
}

.datasheet-wrapper .datasheet-table table tr td:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #171717;
}

@media (max-width: 480px) {
  .datasheet-wrapper .datasheet-table table tr th,
  .datasheet-wrapper .datasheet-table table tr td {
    padding: 16px 8px !important;
    font-size: 12px !important;
  }
}

.datasheet-wrapper .datasheet-table .datasheet-qa {
  background: #F6F8F8;
  padding: 16px;
  display: flex;
  align-items: center;
}

.datasheet-wrapper .datasheet-table .datasheet-qa .datasheet-qa-left {
  margin-right: 16px;
}

.datasheet-wrapper .datasheet-table .datasheet-qa .datasheet-qa-right h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 4px;
}

.datasheet-wrapper .datasheet-table .datasheet-qa .datasheet-qa-right .datasheet-right-link span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0076C0;
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

.datasheet-wrapper .datasheet-table .datasheet-qa .datasheet-qa-right .datasheet-right-link span::before {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  content: "";
  display: block;
  background: #D7D7D7;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.block-radio-item input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.block-radio-item input + label {
  display: flex;
  align-items: center;
  user-select: none;
}

.block-radio-item input + label span {
  padding-left: 16px !important;
}

.block-radio-item input + label::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  background: url(./images/radio.svg);
}

.block-radio-item input:checked + label::before {
  background: url(./images/radio-active.svg);
}

.section-overview-bigvideo {
  display: none;
}

.section-overview-bigvideo h3 {
  font-size: 32px;
  line-height: 40px;
}

.section-overview-bigvideo.show {
  display: block;
}

.reset #main section.section-form .section-heading h2 {
  font-size: 32px;
}

.reset.skew-style .card-half {
  height: auto !important;
  width: 100%;
  background: none !important;
  cursor: pointer;
}

.reset.skew-style .card-half .card-half-top {
  background: #F6F8F8;
  display: flex;
  padding: 32px 0;
  min-height: 195px;
}

.reset.skew-style .card-half .card-half-top:hover {
  background: #f0f2f2;
}

.reset.skew-style .card-half .card-half-top .card-half-top_left {
  width: 140px;
}

.reset.skew-style .card-half .card-half-top .inner-img {
  height: 100%;
  margin: 0 !important;
}

.reset.skew-style .card-half .card-half-top .type {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
  margin-bottom: 8px;
}

.reset.skew-style .card-half .card-half-top .title {
  margin-bottom: 12px;
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
}

.reset.skew-style .card-half .card-half-top .card-options_item {
  display: flex;
  align-items: center;
}

.reset.skew-style .card-half .card-half-top .card-options_item .card-options-icon {
  margin: 0 12px 0 0;
}

.reset.skew-style .card-half .card-half-top .card-options_item .card-options-icon svg {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.reset.skew-style .card-half .card-half-top .card-options_item .card-options-number {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

.reset.skew-style .card-half .card-half-bottom .card-half-description {
  padding: 24px 0 16px 0;
}

.reset.skew-style .card-half .card-half-bottom .card-half-description p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3D4448;
}

@media (max-width: 320px) {
  .reset.skew-style .card-half .card-half-bottom .card-half-description {
    padding: 20px 0 12px 0;
  }
  .reset.skew-style .card-half .card-half-bottom .card-half-description p {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

.reset.skew-style .card-half .card-half-bottom .card-half-link a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #0076C0;
  display: flex;
  align-items: center;
}

.reset.skew-style .card-half .card-half-bottom .card-half-link a svg {
  margin-left: 6px;
}

.reset.skew-style .card-half .card-half-bottom .card-half-link a:hover {
  color: #0699F5;
}

.reset.skew-style .card-half .card-half-bottom .card-half-link a:hover svg path {
  fill: #0699F5;
}

.section-contact-map iframe {
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #D7D7D7 !important;
}

.reset .modal-contact-small .modal-dialog.small {
  max-width: 600px;
}

.reset .modal-contact-small .modal-dialog.small .modal-content {
  padding: 40px;
}

.reset .modal-contact-small .modal-dialog.small .close {
  position: absolute;
  right: 40px;
  top: 40px;
  opacity: 1;
}

.reset .modal-contact-small .modal-dialog.small h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #171717;
  margin-bottom: 16px;
}

.reset .modal-contact-small .modal-dialog.small .link {
  display: flex;
  margin-bottom: 32px;
}

.reset .modal-contact-small .modal-dialog.small .link .icon {
  margin-right: 14px;
}

.reset .modal-contact-small .modal-dialog.small .link a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0076C0;
}

.reset .modal-contact-small .modal-dialog.small .list {
  margin-bottom: 32px;
}

.reset .modal-contact-small .modal-dialog.small .list-dots li {
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.reset .modal-contact-small .modal-dialog.small .list-dots li::before {
  width: 6px;
  height: 6px;
  top: 5px;
}

.complate-item-select {
  border-bottom: solid 1px  #D7D7D7;
}

.complate-item-select .complate-select {
  display: flex;
  justify-content: center;
  margin: 0 -80px;
  align-items: center;
  position: relative;
}

.complate-item-select .complate-select [class^="col"] {
  padding: 0 40px;
  position: relative;
}

.complate-item-select .complate-select .reset {
  color: #0076C0;
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  right: 140px;
}

.complate-item-select .complate-select i.icon {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
}

.complate-item-select .complate-select i path {
  fill: #0076C0;
}

.complate-item-select .complate-select select[disabled] + span + i path {
  fill: #D7D7D7;
}

.complate-item-select .reset {
  opacity: 0;
}

.complate-item-select + .section-result {
  display: none;
}

.complate-item-select + .section-result h1 {
  display: none;
}

.complate-item-select + .section-result .reset {
  opacity: 1;
}

.complate-item-select.option1-1.option2-1.option3-1 .img-big, .complate-item-select.option1-1.option2-2.option3-1 .img-big {
  display: none;
}

.complate-item-select.option1-1.option2-1.option3-1 .result-table.a, .complate-item-select.option1-1.option2-2.option3-1 .result-table.a {
  display: block !important;
}

.complate-item-select.option1-1.option2-1.option3-1 .reset, .complate-item-select.option1-1.option2-2.option3-1 .reset {
  opacity: 1;
}

.complate-item-select.option1-1.option2-1.option3-2 .img-big, .complate-item-select.option1-1.option2-2.option3-2 .img-big {
  display: none;
}

.complate-item-select.option1-1.option2-1.option3-2 .result-table.b, .complate-item-select.option1-1.option2-2.option3-2 .result-table.b {
  display: block !important;
}

.complate-item-select.option1-1.option2-1.option3-2 .reset, .complate-item-select.option1-1.option2-2.option3-2 .reset {
  opacity: 1;
}

.complate-item-select.option1-1.option2-1.option3-3 .img-big, .complate-item-select.option1-1.option2-2.option3-3 .img-big {
  display: none;
}

.complate-item-select.option1-1.option2-1.option3-3 .result-table.c, .complate-item-select.option1-1.option2-2.option3-3 .result-table.c {
  display: block !important;
}

.complate-item-select.option1-1.option2-1.option3-3 .reset, .complate-item-select.option1-1.option2-2.option3-3 .reset {
  opacity: 1;
}

.complate-item-select.option1-2.option2-1.option3-1 .img-big {
  display: none;
}

.complate-item-select.option1-2.option2-1.option3-1 .result-table.d {
  display: block !important;
}

.complate-item-select.option1-2.option2-1.option3-1 .reset {
  opacity: 1;
}

.complate-item-select.option1-2.option2-1.option3-2 .img-big {
  display: none;
}

.complate-item-select.option1-2.option2-1.option3-2 .result-table.e {
  display: block !important;
}

.complate-item-select.option1-2.option2-1.option3-2 .reset {
  opacity: 1;
}

.complate-item-select.option1-2.option2-1.option3-3 .img-big {
  display: none;
}

.complate-item-select.option1-2.option2-1.option3-3 .result-table.f {
  display: block !important;
}

.complate-item-select.option1-2.option2-1.option3-3 .reset {
  opacity: 1;
}

.complate-item-select.option1-2.option2-2.option3-1 .img-big {
  display: none;
}

.complate-item-select.option1-2.option2-2.option3-1 .result-table.g {
  display: block !important;
}

.complate-item-select.option1-2.option2-2.option3-1 .reset {
  opacity: 1;
}

.complate-item-select.option1-2.option2-2.option3-2 .img-big {
  display: none;
}

.complate-item-select.option1-2.option2-2.option3-2 .result-table.h {
  display: block !important;
}

.complate-item-select.option1-2.option2-2.option3-2 .reset {
  opacity: 1;
}

.complate-item-select.option1-2.option2-2.option3-3 .img-big {
  display: none;
}

.complate-item-select.option1-2.option2-2.option3-3 .result-table.j {
  display: block !important;
}

.complate-item-select.option1-2.option2-2.option3-3 .reset {
  opacity: 1;
}

.complate-item-select.option1-3.option2-1.option3-1 .img-big {
  display: none;
}

.complate-item-select.option1-3.option2-1.option3-1 .result-table.k {
  display: block !important;
}

.complate-item-select.option1-3.option2-1.option3-1 .reset {
  opacity: 1;
}

.complate-item-select.option1-3.option2-1.option3-2 .img-big {
  display: none;
}

.complate-item-select.option1-3.option2-1.option3-2 .result-table.l {
  display: block !important;
}

.complate-item-select.option1-3.option2-1.option3-2 .reset {
  opacity: 1;
}

.complate-item-select.option1-3.option2-1.option3-3 .img-big {
  display: none;
}

.complate-item-select.option1-3.option2-1.option3-3 .result-table.m {
  display: block !important;
}

.complate-item-select.option1-3.option2-1.option3-3 .reset {
  opacity: 1;
}

.complate-item-select.option1-3.option2-2.option3-1 .img-big {
  display: none;
}

.complate-item-select.option1-3.option2-2.option3-1 .result-table.n {
  display: block !important;
}

.complate-item-select.option1-3.option2-2.option3-1 .reset {
  opacity: 1;
}

.complate-item-select.option1-3.option2-2.option3-2 .img-big {
  display: none;
}

.complate-item-select.option1-3.option2-2.option3-2 .result-table.p {
  display: block !important;
}

.complate-item-select.option1-3.option2-2.option3-2 .reset {
  opacity: 1;
}

.complate-item-select.option1-3.option2-2.option3-3 .img-big {
  display: none;
}

.complate-item-select.option1-3.option2-2.option3-3 .result-table.q {
  display: block !important;
}

.complate-item-select.option1-3.option2-2.option3-3 .reset {
  opacity: 1;
}

.complate-item-select.option1-4.option2-1.option3-1 .img-big, .complate-item-select.option1-4.option2-2.option3-1 .img-big {
  display: none;
}

.complate-item-select.option1-4.option2-1.option3-1 .result-table.r, .complate-item-select.option1-4.option2-2.option3-1 .result-table.r {
  display: block !important;
}

.complate-item-select.option1-4.option2-1.option3-1 .reset, .complate-item-select.option1-4.option2-2.option3-1 .reset {
  opacity: 1;
}

.complate-item-select.option1-4.option2-1.option3-2 .img-big, .complate-item-select.option1-4.option2-2.option3-2 .img-big {
  display: none;
}

.complate-item-select.option1-4.option2-1.option3-2 .result-table.s, .complate-item-select.option1-4.option2-2.option3-2 .result-table.s {
  display: block !important;
}

.complate-item-select.option1-4.option2-1.option3-2 .reset, .complate-item-select.option1-4.option2-2.option3-2 .reset {
  opacity: 1;
}

.complate-item-select.option1-4.option2-1.option3-3 .img-big, .complate-item-select.option1-4.option2-2.option3-3 .img-big {
  display: none;
}

.complate-item-select.option1-4.option2-1.option3-3 .result-table.t, .complate-item-select.option1-4.option2-2.option3-3 .result-table.t {
  display: block !important;
}

.complate-item-select.option1-4.option2-1.option3-3 .reset, .complate-item-select.option1-4.option2-2.option3-3 .reset {
  opacity: 1;
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc {
  background-image: url(./images/product/breadcrumbs-bg3.jpg);
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc h3 {
  font-weight: 400 !important;
}

.complate-item .complate-item-top {
  text-align: center;
  margin-bottom: 40px;
}

.complate-item h3 {
  margin-bottom: 16px;
}

.complate-item .description {
  max-width: 816px;
  margin: 0 auto;
}

.complate-item .visual {
  max-width: 1027px;
  margin: 0 auto;
}

.complate-item .complate-item-column {
  padding: 40px 0;
  border-top: solid 1px #D7D7D7;
  border-bottom: solid 1px #D7D7D7;
  padding: 40px;
}

.complate-item .complate-item-column .item-column {
  padding-right: 40px;
  margin-right: 40px;
  border-right: solid 1px #ccc;
  height: 100%;
}

.complate-item .complate-item-column .item-column h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #171717;
  margin-bottom: 16px;
}

.complate-item .complate-item-column .col-md-4:last-child .item-column {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

.complate-item-bunner h3 {
  margin: 24px 0 !important;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.cdc3 {
  background-image: url(./images/product/breadcrumbs-bg6.jpg);
  padding: 68px 0 !important;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.cdc3 h3 {
  color: #fff !important;
  font-size: 32px !important;
  line-height: 40px !important;
}

@media (max-width: 768px) {
  #main .section-breadcrumbs.section-breadcrumbs-bg.cdc3 {
    padding: 72px 0 !important;
  }
  #main .section-breadcrumbs.section-breadcrumbs-bg.cdc3 h3 {
    margin: 0 !important;
    font-size: 28px !important;
    line-height: 40px !important;
  }
}

@media (max-width: 540px) {
  #main .section-breadcrumbs.section-breadcrumbs-bg.cdc3 {
    padding: 57px 0 !important;
  }
  #main .section-breadcrumbs.section-breadcrumbs-bg.cdc3 h3 {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

.reset.new-skew-style #main .card-half .card-half-top .title.big {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
}

@media (max-width: 992px) {
  .reset.new-skew-style #main .card-half .card-half-top .title.big {
    max-height: 75px;
    overflow: hidden;
  }
}

@media (max-width: 992px) and (max-width: 576px) {
  .reset.new-skew-style #main .card-half .card-half-top .title.big {
    max-height: 65px;
  }
}

@media (max-width: 992px) and (max-width: 576px) and (max-width: 320px) {
  .reset.new-skew-style #main .card-half .card-half-top .title.big {
    max-height: 100px;
  }
}

@media (max-width: 768px) {
  .reset.new-skew-style #main .card-half .card-half-top .title.big {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .reset.new-skew-style #main .card-half .card-half-top .title.big {
    font-size: 20px;
    line-height: 24px;
  }
}

.reset.new-skew-style #main .card-half .card-half-top .title.big br {
  display: none;
}

@media (max-width: 576px) {
  .reset.new-skew-style #main .card-half .card-half-top .title.big br {
    display: block;
  }
}

.link-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.5px;
  color: #0076C0 !important;
  font-style: normal;
}

.link-text:hover {
  color: #0699F5 !important;
}

.link-text svg {
  margin-left: 8px;
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc2 {
  background-image: url(./images/product/breadcrumbs-bg5.jpg);
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc2 h3 {
  color: #fff !important;
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc2 .bunner-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 72px;
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc2 .bunner-list .bunner-list-item {
  padding: 10px 72px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  border-right: solid 1px #D7D7D7;
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc2 .bunner-list .bunner-list-item:first-child {
  padding-left: 0;
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc2 .bunner-list .bunner-list-item:last-child {
  padding-right: 0;
  border-right: none;
}

.section-breadcrumbs.section-breadcrumbs-bg.cdc2 .bunner-list .bunner-list-item svg {
  margin-right: 18px;
}

.ul-check li {
  display: flex;
  margin-bottom: 8px;
}

.ul-check li svg {
  margin-right: 8px;
}

.small-card {
  background: #F6F8F8;
  padding: 40px 24px;
  display: flex;
}

.small-card .small-card-left {
  margin-right: 16px;
  padding-top: 10px;
}

.small-card .small-card-left img {
  min-width: 98px;
}

.small-card .small-card-right .status {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
  margin-bottom: 8px;
}

.small-card .small-card-right .status.active {
  color: #219653;
}

.small-card .small-card-right .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #171717;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-card .small-card-right a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  text-align: center;
  color: #0076C0;
}

.small-card .small-card-right a i {
  margin-left: 5px;
}

.reset.new-skew-style #main .section-complete-item ul.watch-list {
  margin: 0;
  display: flex;
}

.reset.new-skew-style #main .section-complete-item ul.watch-list li {
  padding: 0 16px 0 0;
  margin: 0 16px 0 0;
  border-right: solid 1px #D7D7D7;
}

.reset.new-skew-style #main .section-complete-item ul.watch-list li:last-child {
  border-right: none;
}

.reset.new-skew-style #main .section-complete-item ul.watch-list a {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #0076C0;
}

.reset.new-skew-style #main .section-complete-item ul.watch-list a svg {
  margin-left: 8px;
}

.reset.new-skew-style #main .section-complete-item table thead th {
  background: none !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
}

.reset.new-skew-style #main .section-complete-item table thead tr {
  border-bottom: solid 1px #D7D7D7;
}

.reset.new-skew-style #main .section-complete-item tr td {
  font-size: 16px;
  line-height: 24px;
}

.reset.new-skew-style #main .section-complete-item span.grean {
  color: #4CAF50;
}

.reset.new-skew-style #main .section-complete-item span.red {
  color: #F74545;
}

.reset.new-skew-style #main .section-complete-item .table-separate li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.reset.new-skew-style #main .section-complete-item .table-separate span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

.reset.new-skew-style #main .section-complete-item .table-separate .tr-separ td {
  position: relative;
}

.reset.new-skew-style #main .section-complete-item .table-separate .tr-separ td:last-child:before {
  display: none;
}

.reset.new-skew-style #main .section-complete-item .table-separate .tr-separ td:before {
  position: absolute;
  left: 100%;
  top: 50%;
  width: 1px;
  height: 48px;
  background: #D7D7D7;
  display: block;
  content: "";
  transform: translate(-50%, -50%);
}

.reset.new-skew-style #main .section-complete-item b {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
}

.card-small-half .card-half-top {
  padding: 32px 40px;
  background: #F6F8F8;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.card-small-half .card-half-top .card-half-top_left {
  margin-right: 32px;
}

.card-small-half .card-half-top .card-half-top_left img {
  min-width: 100px;
}

.card-small-half .card-half-top .type {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
  margin-bottom: 8px;
}

.card-small-half .card-half-top .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #231F20;
}

.card-small-half .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3D4448;
  margin-bottom: 16px;
}

.card-small-half .link-arrow i {
  top: 0;
}

#main .result-table {
  display: none;
}

#main .result-table h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin: 0;
}

#main .result-table .line {
  border-bottom: solid 1px #D7D7D7;
  padding: 32px 0;
}

#main .result-table .line:last-child {
  border-bottom: none;
}

#main .result-table .result-item {
  padding: 16px;
  background: #F6F6F6;
  height: 100%;
}

#main .result-table .result-item.grean {
  background: #ECFFEB;
}

#main .result-table .result-item.grean .type {
  background: #11AD04;
}

#main .result-table .result-item.yellow {
  background: #FFFCEB;
}

#main .result-table .result-item.yellow .type {
  background: #E5A500;
}

#main .result-table .result-item.red {
  background: #FFEBEB;
}

#main .result-table .result-item.red .type {
  background: #F74545;
}

#main .result-table .result-item .result-item_top {
  display: flex;
  justify-content: space-between;
}

#main .result-table .result-item .result-item_top .info {
  cursor: pointer;
  position: relative;
}

#main .result-table .result-item .result-item_top .info:hover .info-description {
  display: block;
}

#main .result-table .result-item .result-item_top .info-description {
  display: none;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  background: rgba(133, 130, 130, 0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 276px;
  transform: translate(-50%, 20px);
  z-index: 9;
  backdrop-filter: blur(20px);
  filter: drop-shadow(0 4px 14px rgba(0, 0, 0, 0.2));
}

#main .result-table .result-item .result-item_top .info-description:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid rgba(133, 130, 130, 0.9);
  bottom: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

#main .result-table .result-item .result-item_bottom {
  margin-top: 24px;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}

#main .result-table .result-item .result-item_bottom .result-bottom-second {
  margin-top: auto;
}

#main .result-table .result-item .type {
  font-weight: 600;
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 3px 8px;
  background-color: #171717;
  display: flex;
  align-items: center;
}

@media (max-width: 320px) {
  #main .result-table .result-item .type {
    font-size: 10px;
    line-height: 12px;
  }
}

#main .result-table .result-item .type svg {
  margin-right: 6px;
}

#main .result-table .result-item .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 8px;
}

#main .result-table .result-item .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
}

#main .result-table .result-item .link {
  font-size: 14px;
  line-height: 170%;
  color: #0076C0;
  margin-top: 16px;
}

#main .result-table .result-item .link svg {
  margin-left: 8px;
}

.link-arrow.arrow-right svg {
  margin-left: 4px;
}

.link-arrow.arrow-right:hover {
  color: #0699F5 !important;
}

.link-arrow.arrow-right:hover path {
  fill: #0699F5;
}

.img-description {
  margin-top: 8px;
}

.img-description p {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 24px;
  color: #7D7D7D !important;
}

.jcf-option-disabled {
  color: #7D7D7D !important;
}

.nav-scroll {
  overflow: auto;
}

.specifications-itemBlock {
  margin-bottom: 24px;
}

.specifications-itemBlock:last-child {
  margin-bottom: 0;
}

.next2 {
  margin-bottom: 80px;
}

.block-ac h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
}

.specifications-tabs th {
  background: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717 !important;
}

.specifications-tabs tr {
  background: none !important;
  border-bottom: solid 1px #D7D7D7;
}

.specifications-tabs tr td:first-child {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 152%;
  font-variant: small-caps;
  color: #3D4448;
}

.specifications-tabs tr:last-child {
  border-bottom: none;
}

.specifications-tabs .grean {
  color: #4CAF50;
}

.specifications-tabs .red {
  color: #F74545;
}

.block-filters-2.second .block-filters-content .block-filters-column {
  width: 40%;
}

.section-filters-section_top.background-none {
  background-color: #fff;
}

.vertical-top {
  align-items: start !important;
}

.d-center {
  display: flex;
  justify-content: center;
}

.btn-default-outline.more {
  padding: 8px 48px !important;
}

.btn-default-outline.more svg {
  margin-left: 10px;
}

.btn-default-outline.more:hover path {
  fill: #fff;
}

.btn-default-outline.more:focus {
  color: #fff;
}

.pt-32 {
  padding-top: 32px;
}

.second-tabs .lc-item.active a::before {
  display: none !important;
}

.second-tabs .lc-item a.active .title {
  color: #8F8F8F !important;
  font-weight: 400 !important;
}

.second-tabs .lc-item a.active .title:before {
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  height: 4px !important;
  bottom: -2px !important;
  z-index: 99;
  content: "";
  position: absolute;
  display: block;
  background: #0076C0;
  width: calc(100% - 24px) !important;
}

.section-filters-midle.m-top.open {
  margin-top: -16px;
  transition: none;
}

.table-information h4 {
  margin: 0 !important;
}

.img-description {
  font-size: 14px;
  color: #7D7D7D !important;
}

.no-scroll {
  pointer-events: none;
}

.section-filters-bottom.hover.p-new .card-half .card-half-top {
  padding: 40px;
}

@media (max-width: 992px) {
  .section-filters-bottom.hover.p-new .card-half .card-half-top {
    padding: 40px 20px;
  }
}

@media (max-width: 768px) {
  .section-filters-bottom.hover.p-new .card-half .card-half-top {
    padding: 32px 20px;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .section-filters-bottom.hover.p-new .card-half .card-half-top {
    padding: 32px;
    display: flex;
    justify-content: space-around;
  }
}

.section-filters-bottom.hover.p-new .card-half-top_left {
  margin-right: 32px;
  width: auto !important;
}

.section-filters-bottom.hover.p-new .card-half-top_left .inner-img {
  transform: none !important;
  filter: none !important;
}

.section-filters-bottom.hover.p-new .card-half-top_right {
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.section-filters-bottom.hover.p-new .card-half-top .title {
  font-size: 25px;
  margin: 0;
}

span.new-scroll,
a.new-scroll-link {
  cursor: pointer;
  color: #171717 !important;
  font-weight: 500;
  white-space: nowrap;
}

span.new-scroll:hover,
a.new-scroll-link:hover {
  color: #7D7D7D !important;
}

span.new-scroll:hover svg path,
a.new-scroll-link:hover svg path {
  fill: #7D7D7D !important;
}

.anchor-active span.new-scroll {
  color: #7D7D7D;
}

.horizontal-card.small {
  padding: 40px 24px;
  background: #F6F8F8;
  display: flex;
}

.horizontal-card.small:hover {
  background: #f0f2f2;
}

.horizontal-card.small .horizontal-card_left {
  margin-right: 16px;
}

.horizontal-card.small .horizontal-card_right .type {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #219653;
  margin-bottom: 8px;
}

.horizontal-card.small .horizontal-card_right .type.gray {
  color: #7D7D7D;
}

.horizontal-card.small .horizontal-card_right .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #171717;
  margin-bottom: 12px;
}

.section-horizontal-card {
  padding-bottom: 120px !important;
}

.section-horizontal-card .horizontal-card_left {
  display: flex;
  align-items: center;
}

.section-horizontal-card h2 {
  font-size: 32px !important;
}

.section-horizontal-card .horizontal-card.small .horizontal-card_right .type {
  color: #7D7D7D;
}

.section-horizontal-card .horizontal-card.small a {
  color: #0076C0 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
}

.section-horizontal-card .horizontal-card.small a i {
  margin-left: 8px;
}

.section-horizontal-card .horizontal-card.small a:hover {
  color: #0699F5 !important;
}

.section-horizontal-card .horizontal-card.small a:hover path {
  fill: #0699F5;
}

.container.small .agreement p {
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  font-weight: 400;
}

.container.small .agreement p a {
  font-weight: 500;
  text-decoration: none;
}

.container.small .agreement p a:hover {
  text-decoration: underline;
}

.container.small {
  max-width: 896px;
}

button.btn.btn-default.big {
  min-width: 392px;
}

button.btn.btn-default.big:hover {
  background: #0699F5;
  border-color: #0699F5;
}

@media (max-width: 480px) {
  button.btn.btn-default.big {
    min-width: 100%;
  }
}

.section-accordion #accordion .card:first-child .show .card-body {
  margin-top: -70px;
}

.section-accordion #accordion .card .collapse.show .row {
  margin: 0;
  padding: 0;
}

.section-accordion .new-style #accordion .show .card-body {
  margin-top: -70px;
}

.accordion-icons .accordion-arrow svg {
  transform: scale(1.5);
}

.product-modal.modal-dialog {
  max-width: 816px !important;
}

.product-modal.modal-dialog .modal-content {
  padding: 40px;
}

.product-modal.modal-dialog .close {
  right: 16px;
  opacity: 1;
  top: 10px;
}

.product-modal.modal-dialog .close path {
  fill: #000;
}

.product-modal.modal-dialog h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 0;
}

.product-modal.modal-dialog .product-modal-item {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: solid 1px #D7D7D7;
}

.product-modal.modal-dialog .product-modal-item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.product-modal.modal-dialog .product-modal-item span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

.product-modal.modal-dialog .product-modal-item a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #0076C0;
}

.product-modal.modal-dialog .product-modal-item a:hover {
  color: #0699f5;
}

.product-modal.modal-dialog .product-modal-item a:hover path {
  fill: #0699f5;
}

.product-modal.modal-dialog .product-modal-item a i {
  margin-left: 4px;
}

.new-style .no-filter .card-gray.card-gray-second .card-line .inner-img {
  filter: none;
  background-size: auto;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-8, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-9, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-20, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career {
  background-image: url(./images/product/breadcrumbs-bg-3.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 992px) {
  .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-8, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-9, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-20, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career {
    background-image: url(./images/product/breadcrumbs-bg-3-2.png);
  }
}

@media (max-width: 768px) {
  .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-8, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-9, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-20, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career {
    background-image: url(./images/product/breadcrumbs-bg-3-3.png);
    padding: 0 !important;
    height: 184px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3 .breadcrumbs-product, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-8 .breadcrumbs-product, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-9 .breadcrumbs-product, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-20 .breadcrumbs-product, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career .breadcrumbs-product {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3 .title-h3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-8 .title-h3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-9 .title-h3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-20 .title-h3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career .title-h3 {
    margin: 0 !important;
  }
}

@media (max-width: 576px) {
  .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-8, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-9, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-20, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career {
    background-image: url(./images/product/breadcrumbs-bg-3-4.png);
  }
}

@media (max-width: 320px) {
  .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-8, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-9, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-20, .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career {
    background-image: url(./images/product/breadcrumbs-bg-3-5.png);
  }
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-4 {
  background-image: url(./images/product/breadcrumbs-bg-4.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-5 {
  background-image: url(./images/product/breadcrumbs-bg-5.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-8 {
  background-image: url(./images/product/breadcrumbs-bg-8.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-9 {
  background-image: url(./images/product/ssdbanner.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-20 {
  background-image: url(./images/product/breadcrumbs-bg-20.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-event-solutions.border-all {
  border-top: solid 1px #D7D7D7;
  position: relative;
}

.section-event-solutions.border-all::after {
  position: absolute;
  content: "";
  display: block;
  height: 1px;
  bottom: 0;
  left: 0;
  background: #D7D7D7;
  width: 100%;
}

.border-none {
  border: none !important;
}

.big-arrow .accordion-arrow svg {
  transform: scale(1.2);
}

@media (max-width: 320px) {
  .big-arrow .accordion-arrow svg {
    transform: scale(0.9);
  }
}

.new-style .accordion-arrow-wrap #accordion .card .accordion-arrow {
  top: 25px;
}

.new-style .accordion-arrow-wrap #accordion .card .specifications-list {
  padding: 8px 0 0 0 !important;
}

.new-style .accordion-arrow-wrap #accordion .card .specifications-list ul {
  margin: 0;
}

.contact-frame {
  height: 700px;
}

.pb-120 {
  padding-bottom: 120px !important;
}

@media (max-width: 768px) {
  .pb-120 {
    padding-bottom: 48px !important;
  }
}

h3.color-white {
  color: #fff !important;
}

.status-card {
  position: absolute;
  top: 16px;
  right: 34px;
  background: #0ABFA3;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.new-tabs-type .block-view.view-cards {
  border-bottom: solid 1px #D7D7D7;
}

.new-tabs-type .tabs-pci span:before {
  display: none !important;
}

.new-tabs-type .tabs-pci span.active .title {
  color: #7D7D7D !important;
}

.new-tabs-type .tabs-pci span.active:before {
  display: block !important;
  width: calc(100% - 24px) !important;
  left: 0 !important;
  transform: translate(15px, 0) !important;
  content: "";
  position: absolute;
  background: #0076C0;
  transition: .5s;
  height: 4px !important;
  bottom: -2px !important;
  z-index: 99;
}

.new-tabs-type table.gray strong {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  display: inline-block;
}

.new-tabs-type table.gray a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #0076C0;
  white-space: nowrap;
}

.new-tabs-type table.gray a:hover {
  color: #0699F5 !important;
}

.new-tabs-type table.gray a:hover path {
  fill: #0699F5;
}

@media (max-width: 768px) {
  .new-tabs-type section {
    padding-bottom: 0 !important;
  }
}

.options-block h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.options-block p {
  color: #3D4448;
}

.options-block .options {
  display: flex;
  flex-wrap: wrap;
}

.options-block .options .options-item:not(:last-child) {
  border-right: solid 1px #D7D7D7;
  padding-right: 16px;
  margin-right: 16px;
}

.options-block .options .options-item:not(:last-child) b {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  padding-right: 8px;
}

@media (max-width: 320px) {
  .options-block .options .options-item:not(:last-child) {
    padding-right: 8px;
    margin-right: 8px;
  }
  .options-block .options .options-item:not(:last-child) b {
    font-size: 14px;
  }
}

@media (width: 576px) {
  .options-block .options .options-item:nth-child(2) {
    margin-right: 50px;
  }
}

@media (max-width: 576px) {
  .options-block .options .options-item:nth-child(3),
  .options-block .options .options-item:nth-child(4) {
    position: relative;
    top: 16px;
    display: flex;
    align-items: center;
  }
}

.options-block .options a {
  color: #0076C0;
}

.options-block .options a:hover {
  color: #0699F5 !important;
}

.options-block .options a:hover path {
  fill: #0699F5;
}

.small-fonts-new strong {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@media (max-width: 320px) {
  .small-fonts-new strong {
    font-size: 16px;
  }
}

.new-style section.section-default-page.section-overview.block-close {
  padding-top: 120px !important;
}

.new-style section.section-default-page.bottom-bar {
  padding: 30px 0 !important;
  box-shadow: 2px 2px 5px #d1d1d1;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
}

.new-style section.section-default-page.bottom-bar .container {
  position: relative;
}

.new-style section.section-default-page.bottom-bar .icon {
  position: fixed;
  bottom: 98px;
  z-index: 9;
  right: 80px;
}

@media (max-width: 768px) {
  .new-style section.section-default-page.bottom-bar .icon {
    right: 35px;
  }
}

@media (max-width: 576px) {
  .new-style section.section-default-page.bottom-bar .icon {
    display: none;
  }
}

.new-style section.section-default-page.bottom-bar.block-close {
  display: none;
}

.new-style section.section-default-page.bottom-bar .bottom-bar-wrap-icon {
  position: fixed;
  right: 55px;
  bottom: 112px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: .5s;
}

@media (max-width: 768px) {
  .new-style section.section-default-page.bottom-bar .bottom-bar-wrap-icon {
    right: 10px;
  }
}

@media (max-width: 576px) {
  .new-style section.section-default-page.bottom-bar .bottom-bar-wrap-icon {
    bottom: 100px;
    z-index: 9;
    right: -10px;
  }
  .new-style section.section-default-page.bottom-bar .bottom-bar-wrap-icon svg g {
    display: none !important;
  }
}

.new-style section.section-default-page.bottom-bar .bottom-bar-wrap-icon svg path {
  transition: .5s;
}

@media (max-width: 576px) {
  .new-style section.section-default-page.bottom-bar {
    padding: 16px 0 !important;
  }
}

.new-style section.section-default-page.bottom-bar .bottom-bar-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media (max-width: 576px) {
  .new-style section.section-default-page.bottom-bar .bottom-bar-wrap {
    display: block;
  }
  .new-style section.section-default-page.bottom-bar .bottom-bar-wrap button {
    display: block;
    width: 100%;
    border: none;
    text-align: left;
  }
  .new-style section.section-default-page.bottom-bar .bottom-bar-wrap button:hover {
    background: none !important;
    color: #005388 !important;
  }
  .new-style section.section-default-page.bottom-bar .bottom-bar-wrap button:hover svg path {
    fill: #005388;
  }
}

.new-style section.section-default-page.bottom-bar button {
  min-width: 240px;
  position: relative;
}

@media (max-width: 768px) {
  .new-style section.section-default-page.bottom-bar button {
    min-width: 210px;
  }
}

@media (max-width: 576px) {
  .new-style section.section-default-page.bottom-bar button {
    min-width: 210px;
  }
}

.new-style section.section-default-page.bottom-bar button svg {
  margin-right: 8px;
}

.new-style section.section-default-page.bottom-bar button:hover svg path {
  fill: #fff;
}

.new-style section.section-default-page.bottom-bar button:last-child:after {
  display: none;
}

.new-style section.section-default-page.bottom-bar button:after {
  content: "";
  display: block;
  position: absolute;
  background: #F3F3F3;
  width: 1px;
  height: 40px;
  right: -70px;
  top: 0;
}

@media (max-width: 992px) {
  .new-style section.section-default-page.bottom-bar button:after {
    right: -32px;
  }
}

@media (max-width: 768px) {
  .new-style section.section-default-page.bottom-bar button:after {
    right: -14px;
  }
}

@media (max-width: 576px) {
  .new-style section.section-default-page.bottom-bar button:after {
    width: 100%;
    height: 1px;
    left: 0;
    right: auto;
    top: 100%;
  }
}

@media (max-width: 320px) {
  .new-style section.section-default-page.bottom-bar:hover .bottom-bar-wrap-icon path {
    fill: #fff;
  }
}

.card-half-link span {
  color: #0076C0 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  padding-right: 5px;
}

.card-half.card-line:hover .card-half-link span {
  color: #0699F5 !important;
}

.fixed-icon-block {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 113px;
  right: 55px;
  z-index: 999;
}

@media (max-width: 768px) {
  .fixed-icon-block {
    right: 10px;
  }
}

@media (max-width: 768px) and (max-width: 576px) {
  .fixed-icon-block {
    bottom: 25px;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .fixed-icon-block {
    bottom: 80px;
    right: 0px;
  }
}

.fixed-icon-block .default svg {
  transition: .5s;
  cursor: pointer;
}

.fixed-icon-block .default svg path {
  transition: .5s;
}

.fixed-icon-block .top {
  display: none;
}

.fixed-icon-block .top.active {
  display: block;
}

@media (max-width: 520px) {
  .fixed-icon-block .top {
    display: none !important;
  }
}

.fixed-icon-block .contact,
.fixed-icon-block .top {
  opacity: 0;
}

.fixed-icon-block .contact.active, .fixed-icon-block .contact.active-all,
.fixed-icon-block .top.active,
.fixed-icon-block .top.active-all {
  opacity: 1;
  position: relative;
}

.fixed-icon-block .contact .tooltip,
.fixed-icon-block .top .tooltip {
  display: none;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(0px, -50%);
  background: none;
}

.fixed-icon-block .contact .tooltip svg:hover,
.fixed-icon-block .top .tooltip svg:hover {
  transform: none;
}

.fixed-icon-block .contact .tooltip svg:hover path,
.fixed-icon-block .top .tooltip svg:hover path {
  transform: none;
}

.fixed-icon-block .contact:hover .tooltip,
.fixed-icon-block .top:hover .tooltip {
  display: block;
}

.fixed-icon-block .contact:hover .default,
.fixed-icon-block .top:hover .default {
  display: none;
}

.fixed-icon-block .contact:hover .hover,
.fixed-icon-block .top:hover .hover {
  display: block;
  cursor: pointer;
}

.fixed-icon-block .contact:hover .hover svg path,
.fixed-icon-block .top:hover .hover svg path {
  fill: #0076c0 !important;
}

@media (max-width: 768px) {
  .fixed-icon-block .contact:hover .tooltip,
  .fixed-icon-block .contact:hover .hover,
  .fixed-icon-block .top:hover .tooltip,
  .fixed-icon-block .top:hover .hover {
    display: none;
  }
  .fixed-icon-block .contact:hover .default,
  .fixed-icon-block .top:hover .default {
    display: block;
  }
}

.fixed-icon-block .contact .hover,
.fixed-icon-block .top .hover {
  display: none;
}

.new-style .new-font section.section-black-halfBlue.second h2 {
  font-size: 32px !important;
  line-height: 40px;
  white-space: nowrap;
}

@media (max-width: 992px) {
  .new-style .new-font section.section-black-halfBlue.second h2 {
    white-space: normal;
  }
  .new-style .new-font section.section-black-halfBlue.second h2 br {
    display: none;
  }
}

@media (max-width: 768px) {
  .new-style .new-font section.section-black-halfBlue.second h2 {
    font-size: 28px !important;
    line-height: 40px;
  }
}

@media (max-width: 320px) {
  .new-style .new-font section.section-black-halfBlue.second h2 {
    font-size: 24px !important;
    line-height: 32px;
  }
  .new-style .new-font section.section-black-halfBlue.second h2 br {
    display: none;
  }
}

.new-style .new-font section.section-default-page.section-overview .section-overview-content .section-overview-text h2 {
  font-size: 28px;
  line-height: 40px;
}

.reset .view-cards-list:not(.small-tabs) .block-view.update .lc-item.active:first-child span::before {
  width: calc(100% - 50px) !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
}

@media (max-width: 768px) {
  .no-thead thead {
    display: none;
  }
}

.small-td tr td:first-child {
  max-width: 200px;
}

@media (max-width: 768px) {
  .small-td tr:first-child td {
    padding-top: 0 !important;
  }
  .small-td tr {
    display: block !important;
  }
  .small-td tr td {
    display: block !important;
    min-width: 100%;
  }
}

@media (max-width: 768px) and (max-width: 576px) {
  .small-td tr td br {
    display: none;
  }
}

@media (max-width: 768px) {
  .small-td tr td:first-child {
    padding: 32px 0 16px 0 !important;
  }
  .small-td tr td:last-child {
    padding: 0 0 32px 0 !important;
  }
  .small-td tr:first-child td {
    padding-top: 0 !important;
  }
}

.color-white {
  color: #fff !important;
}

body.reset .view-cards-list:not(.small-tabs) .block-view.update .lc-item.active:first-child span.pl-0::before {
  left: 0 !important;
  transform: none !important;
  width: calc(100% - 25px) !important;
}

#accordion.transition-none .show .card-body {
  transition: none !important;
}

#accordion.transition-none .card-body {
  transition: none !important;
}

#wrapper {
  overflow: hidden;
}

@media (max-width: 992px) {
  .block-order {
    text-align: center;
  }
  .block-order .col-md-4 {
    margin: 0 auto;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .block-order.text-left {
    text-align: center !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  #main .pci-small.responsive-block {
    margin: 0 !important;
  }
}

@media (max-width: 992px) {
  #main .pci-small.responsive-block .container-bg {
    justify-content: center;
  }
  #main .pci-small.responsive-block .pci-small-row {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  #main .pci-small.responsive-block .pci-small-title {
    margin: 0 0 16px 0 !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  #main .pci-small.responsive-block .pci-small-title {
    margin: 0 0 8px 0 !important;
  }
}

@media (max-width: 992px) {
  #main .pci-small.responsive-block .pci-small-text {
    margin: 0 0 32px 0 !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  #main .pci-small.responsive-block .pci-small-text {
    margin: 0 0 16px 0 !important;
  }
}

@media (max-width: 992px) and (max-width: 320px) {
  #main .pci-small.responsive-block .pci-small-text br {
    display: none;
  }
}

@media (max-width: 992px) {
  #main .new-padding {
    padding: 48px 0 !important;
  }
}

@media (max-width: 992px) and (max-width: 320px) {
  #main .new-padding {
    padding: 40px 0 !important;
  }
}

@media (max-width: 768px) {
  .new-style section.section-default-page.section-responsive-new {
    padding-top: 32px !important;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .new-style section.section-default-page.section-responsive-new {
    padding-top: 16px !important;
  }
}

.table-responsive-new a.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .table-responsive-new a.mobile-only {
    display: block;
    margin-top: 16px;
  }
  .table-responsive-new td:nth-child(3n) {
    display: none;
  }
}

@media (max-width: 320px) {
  .table-responsive-new td:nth-child(2n),
  .table-responsive-new thead {
    display: none;
  }
}

.table-responsive-new p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
  margin: 0;
}

@media (max-width: 320px) {
  .container.small .agreement p {
    font-size: 12px;
    line-height: 20px;
  }
  .sub-tittle br {
    display: none;
  }
}

@media (max-width: 576px) {
  .reset .mb-80.m-mob-none {
    margin-bottom: 0 !important;
  }
}

.br-mobile br {
  display: none;
}

@media (max-width: 380px) {
  .br-mobile br {
    display: block;
  }
}

.reset #main table.small-caps th {
  font-variant: small-caps !important;
  text-transform: none !important;
}

@media (max-width: 575px) {
  .new-style .section-black-top_wrap .tabs-wrap .tabs-pci_second.nav-select .lc-item a {
    color: #171717 !important;
  }
}

.no-hidden {
  overflow: inherit !important;
}

.no-hidden main,
.no-hidden #wrapper {
  overflow: inherit !important;
}

@media (max-width: 768px) {
  .no-hidden main,
  .no-hidden #wrapper {
    overflow: hidden !important;
  }
}

.small-td.tabs-w tr td:first-child {
  min-width: 300px;
}

.section-faq .big-arrow {
  max-width: 1000px;
  margin: 0 auto;
}

.section-faq .card-header h4 {
  margin: 0 -1rem !important;
}

.section-faq .row {
  margin: 0 !important;
}

@media (max-width: 768px) {
  .section-faq #accordion .card .accordion-arrow {
    top: 25px;
  }
}

@media (max-width: 768px) and (max-width: 768px) {
  .section-faq #accordion .card .accordion-arrow svg {
    transform: scale(1.25);
  }
}

@media (max-width: 321px) {
  .section-faq #accordion .card {
    padding: 16px 0;
  }
}

.lc-item-svg {
  display: inline-block !important;
}

body #main .section-black-top {
  padding: 0 !important;
}

body #main .section-black-top .container {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body #main.add-scroll .section-black-top {
  position: fixed;
  width: 100%;
  left: 0;
  padding: 0 !important;
  box-shadow: inset 0px -1px 0px #e2e4e6 !important;
}

body #main.add-scroll .section-black-top .container {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-line-new {
  background: #F6F8F8;
  padding: 40px;
  text-align: center;
  transition: 0.5s;
}

.card-line-new:hover {
  background: #E8EAEA;
  transition: 0.5s;
}

.card-line-new .card-line-new_visible {
  position: absolute;
  top: 20px;
  left: 36px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #0ABFA3;
}

.card-line-new .card-line-new_img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
}

.card-line-new .card-line-new_title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #171717;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .card-line-new .card-line-new_title {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .card-line-new .card-line-new_title {
    font-size: 16px;
  }
}

.card-line-new .card-line-new_type {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #7D7D7D;
}

@media (max-width: 768px) {
  .card-line-new .card-line-new_type {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 768px) and (max-width: 320px) {
  .card-line-new .card-line-new_type {
    font-size: 14px;
    line-height: 20px;
  }
}

.card-line-new .card-line-new_options ul {
  list-style: none;
  margin: 0;
}

.card-line-new .card-line-new_options ul li {
  padding: 0;
  margin: 0 0 8px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #171717;
}

.card-line-new .card-line-new_options ul li:last-child {
  margin: 0;
}

@media (max-width: 320px) {
  .card-line-new .card-line-new_options ul li {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 4px 0;
  }
}

@media (max-width: 768px) {
  .card-line-new .card-line-new_options ul li br {
    display: none;
  }
}

@media (width: 576px) {
  .card-line-new {
    padding: 32px;
  }
  .card-line-new a {
    display: flex;
  }
  .card-line-new .card-line-new_img {
    margin: 0 24px 0 0;
    min-width: 185px;
  }
  .card-line-new .card-line-wrap,
  .card-line-new .card-line-new_options ul li {
    text-align: left;
  }
}

@media (max-width: 320px) {
  .card-line-new {
    padding: 32px;
  }
  .card-line-new .card-line-new_type {
    margin-bottom: 12px;
  }
}

#filterOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  display: none;
}

#filterOptions li {
  padding: 8px 16px;
  margin: 0;
  background: #F6F8F8;
  border-radius: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #171717;
  margin-right: 16px;
  cursor: pointer;
}

#filterOptions li:last-child {
  margin: 0;
}

#filterOptions li.active {
  background: #0076C0;
  color: #FFF;
}

.section-card-line-new .product-list-description {
  display: none;
}

.section-card-line-new .product-list-description.active {
  display: block;
}

.section-card-line-new .card-gray .row > div {
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .mt-md-16 {
    margin-top: 16px !important;
  }
}

@media (max-width: 577px) {
  .mb-sm-32 {
    margin-bottom: 32px !important;
  }
}

.new-style .card-gray .row > div.long-text {
  margin-bottom: 32px;
}

.reset .section-black-top.has-shadow {
  box-shadow: inset 0px -1px 0px #e2e4e6 !important;
}

@media (max-width: 768px) {
  .reset .section-black-top.has-shadow .container {
    min-height: 64px !important;
  }
}

@media (max-width: 576px) {
  .reset .section-black-top.has-shadow .container {
    min-height: 48px !important;
    justify-content: left !important;
  }
  .reset .section-black-top.has-shadow .lc-item {
    display: none;
  }
  .reset .section-black-top.has-shadow .lc-item.mob-only {
    display: block;
  }
  .reset .section-black-top.has-shadow .lc-item.mob-only a {
    color: #171717 !important;
    padding: 0;
  }
  .reset .section-black-top.has-shadow .lc-item.mob-only svg {
    display: block;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}

@media (max-width: 768px) {
  .reset .section-black-top.has-shadow .mob-open .lc-item,
  .reset .section-black-top.has-shadow .mob-open .d-flex {
    display: block !important;
  }
  .reset .section-black-top.has-shadow .mob-open .mob-only {
    margin-top: 14px;
    padding-bottom: 8px;
  }
  .reset .section-black-top.has-shadow .mob-open .mob-only svg {
    transform: rotate(180deg);
  }
  .reset .section-black-top.has-shadow .mob-open a, .reset .section-black-top.has-shadow .mob-open span {
    text-align: left;
  }
  .reset .section-black-top.has-shadow .mob-open .opener {
    padding-left: 0 !important;
  }
}

.nowrap-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-resources-new.second .border-list .row .section-resources_item {
  border: none !important;
}

@media (max-width: 992px) {
  .section-resources-new.second .border-list .row .col-md-4:nth-child(1) .section-resources_item,
  .section-resources-new.second .border-list .row .col-md-4:nth-child(2) .section-resources_item {
    border-bottom: solid 1px #D7D7D7 !important;
  }
  .section-resources-new.second .border-list .row .col-md-4 .section-resources_item.no-border {
    border-bottom: none !important;
  }
}

@media (max-width: 992px) and (max-width: 576px) {
  .section-resources-new.second .border-list .row .section-resources_item,
  .section-resources-new.second .border-list .row .col-md-4 .section-resources_item.no-border {
    border-bottom: solid 1px #D7D7D7 !important;
    height: auto !important;
  }
}

@media (max-width: 576px) {
  .section-resources-new.second .border-list .row {
    border-top: solid 1px #D7D7D7 !important;
    border-bottom: none !important;
  }
}

@media (max-width: 992px) {
  .section-black-top-scroll .section-black-top_wrap {
    overflow: hidden;
    overflow-x: auto;
    display: block !important;
  }
  .section-black-top-scroll .section-black-top_wrap .section-black-top_right {
    display: block !important;
  }
}

.reset #main table.table-responsive-new tr td:first-child {
  max-width: 600px;
}

.long-text p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2B2F2F;
}

#main.new-style .card-gray .row > div {
  padding: 0 12px;
  margin-bottom: 40px;
}

.border-b-none {
  border-bottom: none !important;
}

.reset .block-view .tabs-pci.tabs-scroll {
  white-space: nowrap;
  flex-wrap: inherit;
  overflow: hidden;
  overflow-x: auto;
}

@media (max-width: 992px) {
  .md-pl-32 {
    padding-left: 40px !important;
  }
}

@media (max-width: 576px) {
  #main.new-style .card-gray .row > div {
    margin-bottom: 32px;
  }
}

@media (max-width: 320px) {
  .new-style #accordion .specifications-list_block p {
    font-size: 14px;
  }
}

a.btn.btn-default.mobile-view-btn.second:hover {
  color: #fff !important;
}

.section-izotope-training-services .button-group.izotope-filters {
  justify-content: center;
}

.section-izotope-training-services .button-group.izotope-filters button {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #171717;
  padding: 8px 16px;
  border: solid 1px #D7D7D7;
  border-radius: 20px;
  margin-right: 16px;
  cursor: pointer;
  transition: 0.5s;
  width: auto;
  height: auto;
  background-color: #fff;
}

.section-izotope-training-services .card-line {
  border: 1px solid #EAEDEE;
}

.section-izotope-training-services .card-line .title {
  font-weight: normal;
}

.section-izotope-training-services .card-line .card-bottom {
  opacity: 1;
}

.new-style section.section-black-halfBlue .container {
  padding: 120px 20px 120px 21px;
}

section a.btn:hover {
  color: #fff !important;
}

.section-ssd-solutions .section-ssd-solutions_col-horizontal .card {
  display: flex;
  flex-direction: row;
}

.section-ssd-solutions .section-ssd-solutions_col-horizontal .card .card-content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-ssd-solutions .section-ssd-solutions_col-horizontal .card .card-content h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.section-ssd-solutions .section-ssd-solutions_col-horizontal .card .card-content p {
  margin-bottom: 32px;
}

.section-ssd-solutions .section-ssd-solutions_col-horizontal .card .card-img {
  max-width: 600px;
}

.section-about-oakgate-small .container-small {
  max-width: 790px;
  margin: 0 auto 56px;
}

.section-about-oakgate-small h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.skew-style p {
  color: #2B2F2F;
}

.new-style #accordion .card .accordion-arrow {
  right: 0;
}

.reset .mb-48 {
  margin-bottom: 48px !important;
}

@media (max-width: 768px) {
  .reset .mb-48 {
    margin-bottom: 40px !important;
  }
}

.section-resources-new.second .border-list .row .col-md-4:nth-child(1) .section-resources_item.border-none,
.section-resources-new.second .border-list .row .col-md-4:nth-child(2) .section-resources_item.border-none {
  border-bottom: none !important;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career {
  background-image: url(./images/career/career-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-about-career .indicators-card .indicators-card_number {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #171717;
  margin-bottom: 4px;
}

.section-about-career .indicators-card .indicators-card_characteristics {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 172%;
  font-variant: small-caps;
  color: #7D7D7D;
  margin-bottom: 16px;
}

.section-about-career .indicators-card .indicators-card_description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2B2F2F;
}

@media (max-width: 992px) {
  .section-about-career .col-md-9 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .section-about-career .d-desctop {
    display: none !important;
  }
  .section-about-career .d-992 {
    display: block !important;
  }
}

@media (max-width: 768px) {
  .section-about-career .d-992 {
    display: none !important;
  }
  .section-about-career .d-768 {
    display: block !important;
  }
}

@media (max-width: 576px) {
  .section-about-career .col-md-9 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
  .section-about-career .d-768 {
    display: none !important;
  }
  .section-about-career .d-576 {
    display: block !important;
  }
  .section-about-career .col-md-4 {
    margin-bottom: 32px;
  }
  .section-about-career .col-md-4:last-child {
    margin-bottom: 0;
  }
  .section-about-career .col-md-4 .indicators-card_number {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 576px) and (max-width: 320px) {
  .section-about-career .col-md-4 .indicators-card_number {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 320px) {
  .section-about-career .d-576 {
    display: none !important;
  }
  .section-about-career .d-320 {
    display: block !important;
  }
}

.section-culture .slick-track {
  margin: 0 -16px;
}

.section-culture .culture-slider-item {
  background: #FFFFFF;
  border: 1px solid #D7D7D7;
  padding: 40px 32px;
  margin: 0 16px;
}

.section-culture .slick-list {
  overflow: inherit;
}

.section-culture .culture-slider-item_icon {
  margin-bottom: 48px;
}

.section-culture .culture-slider-item_title h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
}

.section-culture .culture-slider-item_description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
}

.section-culture .slick-arrow {
  position: absolute;
  top: -70px;
  right: 50px;
  cursor: pointer;
}

.section-culture .slick-arrow.prev {
  right: 110px;
}

.section-culture .slick-arrow:hover path {
  fill: #0076C0;
}

@media (max-width: 992px) {
  .section-culture .col-md-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .section-culture .slick-list {
    overflow: hidden;
  }
  .section-culture .culture-slider-item_title h4 {
    font-size: 19px;
  }
}

@media (max-width: 992px) and (max-width: 320px) {
  .section-culture .culture-slider-item_title h4 {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  .section-culture .culture-slider-item {
    min-height: 330px;
  }
}

@media (max-width: 992px) and (max-width: 576px) {
  .section-culture .culture-slider-item {
    min-height: 300px;
  }
}

@media (max-width: 992px) and (max-width: 576px) and (max-width: 320px) {
  .section-culture .culture-slider-item {
    min-height: 0;
  }
}

@media (max-width: 768px) {
  .section-culture .culture-slider-item {
    padding: 32px 24px;
  }
  .section-culture .culture-slider-item_icon {
    margin-bottom: 34px;
  }
  .section-culture .col-md-5 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (max-width: 576px) {
  .section-culture .col-md-5 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .section-culture .slick-arrow {
    display: none;
  }
  .section-culture .slick-track {
    transform: none !important;
    margin: 0 -16px;
    display: flex;
    width: auto !important;
    flex-wrap: wrap;
  }
  .section-culture .slick-track .culture-slider-item {
    min-width: calc(50% - 32px) !important;
    width: calc(50% - 32px) !important;
  }
  .section-culture .slick-track .culture-slider-item:nth-child(1), .section-culture .slick-track .culture-slider-item:nth-child(2) {
    margin-bottom: 32px;
  }
}

@media (max-width: 576px) and (max-width: 320px) {
  .section-culture .slick-track .culture-slider-item {
    min-width: calc(100% - 32px) !important;
    width: calc(100% - 32px) !important;
    min-height: 0;
  }
  .section-culture .slick-track .culture-slider-item:nth-child(1), .section-culture .slick-track .culture-slider-item:nth-child(2), .section-culture .slick-track .culture-slider-item:nth-child(3) {
    margin-bottom: 32px;
  }
}

.section-benefits #accordion .card.pt-0 .accordion-arrow {
  top: 17px;
}

.section-benefits.section-faq .container > .row {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important;
}

@media (max-width: 992px) {
  .section-benefits br {
    display: none;
  }
}

@media (max-width: 768px) {
  .section-benefits .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .section-benefits .big-arrow {
    margin-top: 32px;
  }
}

@media (max-width: 576px) {
  .section-benefits #accordion .card.pt-0 .accordion-arrow {
    top: 0px;
  }
  .section-benefits #accordion {
    margin-top: 32px;
  }
  .section-benefits .accordion-arrow svg {
    transform: scale(0.7) !important;
  }
}

@media (max-width: 320px) {
  .section-benefits .accordion-arrow svg {
    width: 14px;
    height: 14px;
    transform: scale(1.1) !important;
  }
  .section-benefits #accordion .card.pt-0 .accordion-arrow {
    top: 0px !important;
  }
}

@media screen and (max-width: 376px) {
  body.reset.skew-style main,
  body.reset.skew-style p {
    font-size: 14px;
  }
}

.section-college-graduates {
  position: relative;
}

.section-college-graduates .section-college-left p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2B2F2F;
}

.section-college-graduates .section-college-left li {
  margin-bottom: 8px;
}

.section-college-graduates .section-college-left li:last-child {
  margin-bottom: none;
}

.section-college-graduates .section-college-right {
  position: absolute;
  right: 0;
  height: 100%;
  max-width: 50%;
}

.section-college-graduates .section-college-right img {
  height: 100%;
  max-width: 120%;
}

.section-college-graduates .col-md-6 {
  position: static;
}

@media (max-width: 992px) {
  .section-college-graduates .section-college-left {
    padding-top: 0 !important;
    text-align: center;
  }
  .section-college-graduates .section-college-left p {
    max-width: 90%;
    margin: 0 auto;
  }
  .section-college-graduates .section-college-left ul {
    max-width: 90%;
    margin: 0 auto;
  }
  .section-college-graduates .section-college-left ul li {
    display: inline-block;
    width: 33%;
    text-align: left;
  }
  .section-college-graduates .section-college-left h4 {
    font-size: 20px;
  }
}

@media (max-width: 992px) and (max-width: 320px) {
  .section-college-graduates .section-college-left h4 {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  .section-college-graduates .col-md-6 {
    min-width: 100%;
  }
  .section-college-graduates .col-md-6 .section-college-right {
    display: none;
  }
  .section-college-graduates .section-college-left_img {
    display: block !important;
    margin: 0 -16px 40px -16px !important;
  }
}

@media (max-width: 768px) {
  .section-college-graduates .section-college-left ul {
    max-width: 100%;
  }
  .section-college-graduates .section-college-left ul li {
    font-size: 14px;
    width: 32%;
  }
}

@media (max-width: 768px) and (max-width: 576px) {
  .section-college-graduates .section-college-left ul li {
    width: calc(50% - 8px);
    font-size: 14px;
  }
}

@media (max-width: 768px) and (max-width: 576px) {
  .section-college-graduates .section-college-left ul {
    text-align: left;
  }
}

@media (max-width: 320px) {
  .section-college-graduates .section-college-left p {
    max-width: 100%;
  }
  .section-college-graduates .section-college-button a {
    width: 225px;
  }
  .section-college-graduates .section-college-button a.mr-3 {
    margin: 0 0 16px 0 !important;
  }
  .section-college-graduates .section-college-left ul li {
    font-size: 14px;
    width: 100%;
  }
  .section-college-graduates .section-college-left_img img {
    height: 200px;
    object-fit: cover;
  }
}

.section-positions .positions-content {
  margin: 0 -16px;
}

.section-positions .positions-content-slide {
  margin: 0 16px;
}

.section-positions .slick-dots {
  bottom: -35px;
}

.section-positions .positions-catd {
  padding: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #D7D7D7;
  margin-bottom: 32px;
  justify-content: space-between;
}

.section-positions .positions-catd:last-child {
  margin-bottom: 0;
}

.section-positions .positions-catd .positions-catd-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 16px;
}

.section-positions .positions-catd .positions-catd-description {
  margin-bottom: 24px;
}

.section-positions .positions-catd .positions-catd-description p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
}

.section-positions .positions-catd .positions-catd-bottom ul {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.section-positions .positions-catd .positions-catd-bottom ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
  padding: 0 24px 0 0;
  margin: 0 24px 0 0;
  border-right: solid 1px #D7D7D7;
}

.section-positions .positions-catd .positions-catd-bottom ul li:last-child {
  padding: 0;
  margin: 0;
  border-right: none;
}

.section-positions .positions-catd-right {
  min-width: 200px;
  text-align: right;
}

.section-positions .search .icon {
  position: absolute;
  right: 25px;
  bottom: 7px;
}

@media (max-width: 768px) {
  .section-positions {
    padding-bottom: 68px !important;
  }
  .section-positions .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
}

@media (max-width: 576px) {
  .section-positions .positions-catd {
    display: block;
  }
  .section-positions .positions-catd-right {
    margin-top: 32px;
    text-align: left;
  }
}

@media (max-width: 320px) {
  .section-positions .positions-catd {
    padding: 32px;
  }
}

.reset .section-recruitment p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
  margin-bottom: 24px;
}

.reset .section-recruitment p:last-child {
  margin-bottom: 0x;
}

@media (max-width: 992px) {
  .new-style .pci-career .container-bg {
    background-image: url(images/product/banner-bg-small-career2.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) {
  .new-style .pci-career .container-bg {
    background-image: url(images/product/banner-bg-small-career3.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) {
  .new-style .pci-career .container-bg {
    background-image: url(images/product/banner-bg-small-career4.png) !important;
  }
}

@media (max-width: 992px) and (max-width: 768px) and (max-width: 576px) and (max-width: 320px) {
  .new-style .pci-career .container-bg {
    background-image: url(images/product/banner-bg-small-career5.png) !important;
  }
}

#main .section-black-top.no-shadow.section-top-update2 .container .section-black-top_wrap {
  justify-content: space-between;
  width: 100%;
}

.reset main ul.list-dots.dark li {
  color: #171717;
}

.reset main ul.list-dots.dark li::before {
  background: #000;
}

.new-style section.section-black-halfBlue .black-half-list.more .black-half-item {
  margin-right: 60px;
}

.new-style section.section-black-halfBlue .black-half-list.more .black-half-item:last-child {
  margin-right: 0;
}

.new-style section.section-black-halfBlue .black-half-list.more h4, .new-style section.section-black-halfBlue .black-half-list.more small {
  font-size: 28px;
  line-height: 40px;
}

.new-style section.section-black-halfBlue .black-half-list.more .description {
  color: #7D7D7D;
  font-variant: small-caps;
}

.new-style section.section-default-page.section-black-link.black-version {
  padding: 40px 0 !important;
  border-top: solid 1px #D7D7D7;
  border-bottom: solid 1px #D7D7D7;
}

.new-style section.section-default-page.section-black-link.black-version li i {
  text-align: center;
  display: block;
  margin-bottom: 10px;
}

.new-style section.section-default-page.section-black-link.black-version span {
  display: block;
  color: #000;
  font-size: 14px;
  line-height: 170%;
}

.new-style section.section-default-page.section-black-link.black-version span svg {
  margin-left: 10px;
}

.new-style section.section-default-page.section-black-link.black-version .new-scroll:hover path {
  fill: #0076C0 !important;
}

.new-style .section-default-page.section-every-detail.black-version .svg-bg {
  right: 0;
}

.new-style .section-default-page.section-every-detail.black-version .svg-bg:after {
  left: auto;
  right: 100%;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card {
  padding: 0 32px 40px 32px;
  box-shadow: none;
  border: solid 1px #D7D7D7;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card .icon {
  margin: 0 -32px 32px -32px;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card .icon svg {
  width: 100%;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card .title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px !important;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card .text {
  margin-bottom: 16px;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card ul li {
  padding-left: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card .linl-more {
  position: static;
}

.section-experience .container {
  max-width: 1060px;
}

.section-experience .tabs-pci {
  justify-content: space-between;
}

.section-experience .linl-more span {
  font-size: 14px;
  line-height: 170%;
}

.section-experience .block-view .lc-item.active a:before {
  transition: .2s !important;
}

.experience-slider .experience-slider-item_img img,
.experience-slider .glance-slider-item_img img,
.glance-slider .experience-slider-item_img img,
.glance-slider .glance-slider-item_img img {
  margin: 0 auto;
}

.experience-slider .slick-arrow,
.glance-slider .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 9;
  cursor: pointer;
}

.experience-slider .slick-arrow path,
.glance-slider .slick-arrow path {
  fill: #7d7d7d;
}

.experience-slider .slick-arrow.next,
.glance-slider .slick-arrow.next {
  right: 0;
}

.experience-slider .slick-arrow.slick-disabled,
.glance-slider .slick-arrow.slick-disabled {
  display: none !important;
}

.experience-slider .slick-arrow:hover path,
.glance-slider .slick-arrow:hover path {
  fill: #0076C0;
}

.glance-slider .slick-arrow {
  top: 22%;
}

.glance-slider .slick-dots {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 170px;
}

.glance-slider .slick-dots li:first-child:before {
  display: block;
  width: 1px;
  height: 24px;
  content: "";
  position: absolute;
  left: 80px;
  top: 0;
  background-color: #D7D7D7;
}

.glance-slider .slick-dots li {
  position: relative;
}

.glance-slider .slick-dots li button {
  display: none;
}

.glance-slider .slick-dots li::after {
  display: block;
  width: 100px;
  height: 30px;
  content: "Front view";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #171717;
  cursor: pointer;
}

.glance-slider .slick-dots li.slick-active:after {
  color: #0076C0;
}

.glance-slider .slick-dots li:last-child:after {
  content: "Side view";
  left: 100px;
}

.section-glance .glance-slider-item_top {
  text-align: center;
}

.section-glance .glance-slider-item_top a {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #171717;
  padding-right: 16px;
  margin-right: 16px;
  border-right: solid 1px #D7D7D7;
}

.section-glance .glance-slider-item_top a:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

.section-glance .glance-slider-item_top a:hover {
  color: #0076C0;
}

.section-glance .glance-slider-item_img img {
  margin: 0 auto;
  display: block;
}

.section-glance .glance-slider-item_content li {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3D4448;
  margin-bottom: 24px;
}

.section-glance .glance-slider-item_content li:last-child {
  margin-bottom: 0;
}

.section-glance .glance-slider-item_content li svg {
  min-width: 30px;
  min-height: 30px;
  margin-right: 10px;
}

#wrapper {
  overflow-x: initial;
}

#wrapper #main .section-best-choice-wrap {
  display: flex;
  position: relative;
}

#wrapper #main .section-best-choice-wrap .first-table {
  min-width: 200px;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick {
  position: relative;
  z-index: 9;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick tr {
  border: none;
  background-color: #F6F8F8 !important;
  position: relative;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick tr:after {
  display: block;
  content: "";
  background-color: #d7d7d7 !important;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick tr td {
  border-right: solid 1px #4CAF50;
  border-left: solid 1px #4CAF50;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick thead td {
  border-top: solid 1px #4CAF50;
  background-color: #F6F8F8;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick thead td .secnd-table-top:after {
  display: none;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick thead td .title {
  color: #4CAF50;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick tbody tr:last-child td {
  border-bottom: solid 1px #4CAF50;
  background-color: #F6F8F8;
}

#wrapper #main .section-best-choice-wrap .section-best-choice-slider-item.statick tbody tr:last-child:after {
  display: none;
}

#wrapper #main .section-best-choice-wrap .secnd-table-top {
  position: relative;
}

#wrapper #main .section-best-choice-wrap .secnd-table-top::after {
  display: block;
  width: 1px;
  height: 100%;
  background-color: #D7D7D7;
  content: "";
  right: -15px;
  top: 0;
  position: absolute;
}

#wrapper #main .section-best-choice-wrap .slick-slider {
  position: static;
  width: 800px !important;
}

#wrapper #main .section-best-choice-wrap .arrow {
  position: absolute;
  z-index: 99;
  top: 80px;
  cursor: pointer;
  left: 0;
}

#wrapper #main .section-best-choice-wrap .arrow.next {
  right: -120px;
  left: auto;
}

#wrapper #main .section-best-choice-wrap .arrow.slick-disabled {
  display: none !important;
}

#wrapper #main .section-best-choice-wrap .arrow:hover path {
  fill: #0076C0;
}

#wrapper #main .section-best-choice-wrap .first-table thead {
  position: relative;
}

#wrapper #main .section-best-choice-wrap .first-table thead::before {
  display: block;
  width: 999999px;
  height: 268px;
  content: "";
  left: -9999px;
  top: -8px;
  position: absolute;
  background-repeat: repeat-x;
  z-index: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMCIgaGVpZ2h0PSIyMzQiIHZpZXdCb3g9IjAgMCAxOTIwIDIzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF82OTQ2XzExMzkwOSkiPgo8bWFzayBpZD0icGF0aC0xLWluc2lkZS0xXzY5NDZfMTEzOTA5IiBmaWxsPSJ3aGl0ZSI+CjxwYXRoIGQ9Ik0wIDhIMTkyMFYyMThIMFY4WiIvPgo8L21hc2s+CjxwYXRoIGQ9Ik0wIDhIMTkyMFYyMThIMFY4WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTAgOUgxOTIwVjdIMFY5Wk0xOTIwIDIxN0gwVjIxOUgxOTIwVjIxN1oiIGZpbGw9IiNEN0Q3RDciIG1hc2s9InVybCgjcGF0aC0xLWluc2lkZS0xXzY5NDZfMTEzOTA5KSIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2RfNjk0Nl8xMTM5MDkiIHg9Ii0xMiIgeT0iMCIgd2lkdGg9IjE5NDQiIGhlaWdodD0iMjM0IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQgZHk9IjQiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNiIvPgo8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJvdXQiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMDYgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd182OTQ2XzExMzkwOSIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvd182OTQ2XzExMzkwOSIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K");
}

#wrapper #main .section-best-choice-wrap .first-table {
  width: 200px;
  max-width: 200px;
}

#wrapper #main .section-best-choice-wrap .first-table tr td {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
}

#wrapper #main .section-best-choice-wrap .first-table thead td {
  min-width: 0 !important;
}

#wrapper #main .section-best-choice-wrap .secnd-table tbody tr td {
  text-align: center;
  min-width: 350px;
  max-width: 350px;
  font-weight: normal;
}

#wrapper #main .section-best-choice-wrap .secnd-table tbody tr td span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

#wrapper #main .section-best-choice-wrap .secnd-table tbody tr td a {
  display: block;
  font-size: 14px;
  line-height: 170%;
  color: #0076C0;
  margin-top: 10px;
}

#wrapper #main .section-best-choice-wrap .secnd-table tbody .span-wrap {
  display: flex;
  justify-content: center;
}

#wrapper #main .section-best-choice-wrap .secnd-table tbody .span-wrap span {
  width: 50%;
}

#wrapper #main .section-best-choice-wrap .secnd-table tbody .span-wrap span:first-child {
  border-right: solid 1px #D9D9D9;
}

#wrapper #main .section-best-choice-wrap .secnd-table tbody .span-wrap span b {
  display: block;
  font-weight: normal;
  padding-top: 5px;
}

#wrapper #main .section-best-choice-wrap thead tr {
  min-height: 210px;
}

#wrapper #main .section-best-choice-wrap thead tr td {
  text-align: center;
  min-width: 350px;
}

#wrapper #main .section-best-choice-wrap thead tr td.active {
  border-top: solid 2px #4CAF50;
}

#wrapper #main .section-best-choice-wrap thead tr td.active .title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #4CAF50;
}

#wrapper #main .section-best-choice-wrap thead tr svg.icon {
  height: 74px;
  margin-bottom: 12px;
}

#wrapper #main .section-best-choice-wrap thead tr .title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 12px;
}

#wrapper #main .section-best-choice-wrap thead tr .link-arrow svg {
  margin-left: 8px;
}

#wrapper #main .section-best-choice-wrap tr {
  height: 90px;
  display: inline-block;
  width: 100%;
}

#wrapper #main .section-best-choice-wrap tr:nth-child(2), #wrapper #main .section-best-choice-wrap tr:nth-child(12) {
  height: 160px;
}

#wrapper #main .section-best-choice-wrap tr:nth-child(2) td, #wrapper #main .section-best-choice-wrap tr:nth-child(12) td {
  height: 160px;
}

#wrapper #main .section-best-choice-wrap tr:nth-child(3) {
  height: 150px;
}

#wrapper #main .section-best-choice-wrap tr:nth-child(3) td {
  height: 150px;
}

#wrapper #main .section-best-choice-wrap tr:nth-child(4), #wrapper #main .section-best-choice-wrap tr:nth-child(7) {
  height: 140px;
}

#wrapper #main .section-best-choice-wrap tr:nth-child(4) td, #wrapper #main .section-best-choice-wrap tr:nth-child(7) td {
  height: 140px;
}

#wrapper #main .section-best-choice-wrap tr:nth-child(5), #wrapper #main .section-best-choice-wrap tr:nth-child(6), #wrapper #main .section-best-choice-wrap tr:nth-child(8), #wrapper #main .section-best-choice-wrap tr:nth-child(9), #wrapper #main .section-best-choice-wrap tr:nth-child(13) {
  height: 120px;
}

#wrapper #main .section-best-choice-wrap tr:nth-child(5) td, #wrapper #main .section-best-choice-wrap tr:nth-child(6) td, #wrapper #main .section-best-choice-wrap tr:nth-child(8) td, #wrapper #main .section-best-choice-wrap tr:nth-child(9) td, #wrapper #main .section-best-choice-wrap tr:nth-child(13) td {
  height: 120px;
}

.video-content-product .video-content-product_title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #171717;
  margin-bottom: 8px;
}

.video-content-product .video-content-product_details {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7D7D7D;
}

.video-content-product .video-content-product_details span:first-child {
  position: relative;
  padding-right: 12px;
  margin-right: 12px;
}

.video-content-product .video-content-product_details span:first-child:after {
  position: absolute;
  width: 4px;
  height: 4px;
  display: block;
  content: "";
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #D7D7D7;
  border-radius: 5px;
}

.event-content-product .event-content-product_wrap {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: solid 1px #D7D7D7;
}

.event-content-product .event-content-product_wrap:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.event-content-product .event-content-product_wrap h3 {
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #171717;
}

.event-content-product .event-item {
  border: solid 1px #D7D7D7;
  padding: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  justify-content: space-between;
}

.event-content-product .event-item:last-child {
  margin-bottom: 0;
}

.event-content-product .event-item .event-item-type {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7D7D7D;
  margin-bottom: 24px;
  position: relative;
  padding-left: 30px;
}

.event-content-product .event-item .event-item-type::after {
  position: absolute;
  width: 16px;
  height: 16px;
  display: block;
  content: "";
  left: 0;
  top: 0;
  background-color: #51BAD1;
}

.event-content-product .event-item .event-item-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 16px;
}

.event-content-product .event-item .event-item-content {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
  margin-bottom: 24px;
}

.event-content-product .event-item .event-item-bottom {
  display: flex;
}

.event-content-product .event-item .event-item-bottom span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
}

.event-content-product .event-item .event-item-bottom span svg {
  margin-right: 8px;
}

.event-content-product .event-item .event-item-bottom span:first-child {
  padding-right: 24px;
  margin-right: 24px;
  border-right: solid 1px #D7D7D7;
}

.posters-content-product .posters-content-product-item {
  background-color: #F6F8F8;
  padding: 50px 40px;
  text-align: center;
}

.posters-content-product .posters-content-product-item:hover {
  background-color: #F2F4F5;
}

.posters-content-product .posters-content-product-item .posters-content-product_img {
  margin-bottom: 24px;
}

.posters-content-product .posters-content-product-item .posters-content-product_title {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #231F20;
}

.posters-content-product .posters-content-product-item .posters-content-product_description {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #3D4448;
}

a.link {
  color: #0076C0 !important;
}

a.link:hover {
  color: #0699F5 !important;
}

#wrapper .table-wrap h3 {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 32px;
}

#wrapper .table-wrap .link svg {
  margin-left: 10px;
}

.table-wrap-list {
  margin-bottom: 32px;
}

.table-wrap-list ul {
  display: flex;
  padding: 0;
  margin: 0;
}

.table-wrap-list ul li {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #171717;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.table-wrap-list ul li.active {
  color: #0076C0;
}

.nav-pills-slider li {
  width: auto !important;
}

.nav-pills-slider .arrow {
  position: absolute;
  top: 6px;
  cursor: pointer;
  z-index: 9;
  background: #fff;
  padding: 0 40px;
  left: -40px;
}

.nav-pills-slider .arrow.next {
  right: -40px;
  left: auto;
}

.nav-pills-slider .arrow:hover path {
  fill: #0076C0;
}

.nav-pills-slider .arrow.slick-disabled {
  display: none !important;
}

.table-models-slider .arrow {
  position: absolute;
  top: 25px;
  z-index: 99;
  cursor: pointer;
  left: -75px;
}

.table-models-slider .arrow.next {
  right: -70px;
  left: auto;
}

.table-models-slider .arrow.slick-disabled {
  display: none !important;
}

.table-models-slider .arrow path {
  fill: #7d7d7d;
}

.table-models-slider .arrow:hover path {
  fill: #0076C0;
}

.table-models-slider p {
  font-weight: normal;
}

.table-models-slider:not(.slick-slider) {
  display: flex;
}

.checkbox-label input[disabled] + .checkbox-icon {
  background: #D7D7D7;
  cursor: default;
}

.statick-models-table-wrap {
  display: flex;
}

.statick-models-table-wrap .table-models thead {
  height: 100px;
}

.statick-models-table-wrap .table-models tbody tr {
  display: inline-block;
  height: 120px;
  width: 100%;
}

.statick-models-table-wrap .table-models tbody tr.big2 {
  height: 210px;
}

.statick-models-table-wrap .table-models tbody tr.big3 {
  height: 500px;
}

.statick-models-table-wrap .table-models tbody tr.big4 {
  height: 280px;
}

.statick-models-table-wrap .table-models tbody tr.big5 {
  height: 800px;
}

.statick-models-table-wrap .table-models tbody tr.big6 {
  height: 350px;
}

.statick-models-table-wrap .table-models tbody tr.big7 {
  height: 260px;
}

.statick-models-table-wrap .table-models tbody tr.big8 {
  height: 305px;
}

.statick-models-table-wrap .table-models tbody tr.big9 {
  height: 330px;
}

.statick-models-table-wrap .table-models tbody tr.big10 {
  height: 550px;
}

.statick-models-table-wrap .table-models tbody tr.big11 {
  height: 230px;
}

.statick-models-table-wrap .table-models tbody tr.no-border {
  height: 70px;
}

.statick-models-table-wrap .statick-models-table {
  width: 300px;
}

.statick-models-table-wrap .table-models-slider {
  width: calc(100% - 300px);
}

.statick-models-table-wrap .table-models-slider p {
  font-weight: normal !important;
}

.statick-models-table-wrap .table-models-slider .table-models-slider-item {
  width: 306px !important;
}

#wrapper #main .table-models .form-row-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

#wrapper #main .table-models .form-row-top label {
  margin: 0;
}

#wrapper #main .table-models p {
  width: 270px;
}

#wrapper #main .table-models .form-row {
  border-right: solid 1px #ccc;
}

#wrapper #main .table-models thead {
  position: relative;
}

#wrapper #main .table-models thead::before, #wrapper #main .table-models thead::after {
  display: block;
  width: 10000%;
  height: 1px;
  background-color: #ccc;
  content: "";
  left: -1000%;
  top: 0;
  position: absolute;
}

#wrapper #main .table-models thead::after {
  top: 100%;
}

#wrapper #main .table-models thead th {
  background-color: #fff !important;
  text-transform: none !important;
  font-variant: normal !important;
}

#wrapper #main .table-models tbody td:first-child p {
  font-weight: 500;
}

#wrapper #main .table-models tbody tr.no-border {
  border: none !important;
}

#wrapper #main .table-models tbody tr.no-border td {
  padding-bottom: 0;
}

#wrapper #main .table-models tbody tr.no-border td h4 {
  margin: 0;
}

.show-filters {
  padding: 32px 0;
  display: flex;
}

.show-filters label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  margin: 0 24px 0 0;
}

.show-filters .span-wrap {
  display: flex;
}

.show-filters .span-wrap span {
  font-size: 14px;
  line-height: 24px;
  color: #171717;
  font-weight: 500;
  cursor: pointer;
}

.show-filters .span-wrap span:first-child {
  padding-right: 24px;
  margin-right: 24px;
  border-right: solid 1px #ccc;
}

.show-filters .span-wrap span.filter-go {
  color: #D7D7D7;
  cursor: default;
  pointer-events: none;
}

.show-filters .span-wrap span.filter-go.active {
  cursor: pointer;
  pointer-events: auto;
  color: #000;
}

.show-filters .span-wrap span.active {
  color: #0076C0;
}

.show-filters .span-wrap span.color {
  color: #0076C0 !important;
}

.reset .block-view.tabs-scroll div.tabs-pci {
  border: none;
  position: relative;
}

.reset .block-view.tabs-scroll div.tabs-pci::after {
  display: block;
  width: 9999%;
  height: 2px;
  background-color: #ccc;
  content: "";
  left: -999%;
  top: calc(100% - 2px);
  position: absolute;
}

.reset .block-view.tabs-scroll .lc-item a,
.reset .block-view.tabs-scroll .lc-item span {
  margin-right: 56px;
  padding: 20px 0px 10px 0 !important;
}

.reset .block-view.tabs-scroll .lc-item a .title,
.reset .block-view.tabs-scroll .lc-item span .title {
  white-space: nowrap;
}

.reset .block-view.tabs-scroll .lc-item:last-child a,
.reset .block-view.tabs-scroll .lc-item:last-child span {
  margin-right: 0px;
}

.reset .block-view.tabs-scroll .lc-item a.active .title:before {
  width: calc(100% - 0px) !important;
  bottom: 0 !important;
}

.new-style .section-categories {
  transition: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background-color: #F6F8F8;
}

::-webkit-scrollbar-thumb {
  background-color: #0076C0;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0699F5;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-22 {
  background-image: url(./images/product/Case-Study-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.new-style section.section-default-page.section-overview .section-overview-content .section-overview-menu .tabs-pci.top-40 {
  top: 40px;
}

.caption {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7D7D7D;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-career.frontline {
  background-image: url(./images/frontline/front-line-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.culture-slider-item_link a {
  font-size: 14px;
}

.section-faq-button {
  display: flex;
  align-items: center;
}

.section-faq-button a {
  font-size: 14px;
}

@media (max-width: 768px) {
  .career-slider .slick-slide {
    display: none;
  }
  .career-slider .slick-slide.slick-active {
    display: block;
  }
  .career-slider .slick-slide {
    display: none;
  }
  .section-culture .career-slider .slick-arrow {
    top: -134px;
  }
}

@media (max-width: 576px) {
  .section-culture .career-slider .slick-track .culture-slider-item {
    min-width: 0 !important;
    width: 100% !important;
  }
  .section-culture .career-slider .slick-arrow {
    top: -160px;
  }
}

@media (max-width: 992px) and (max-width: 576px) {
  .section-culture .career-slider .slick-track .culture-slider-item {
    min-height: 200px;
  }
}

.reset .section-text-list-img {
  text-align: center;
}

.reset .section-text-list-img h3 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 50px;
}

.reset .section-text-list-img img {
  margin: 0 auto;
}

.reset .section-text-list-img h4 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.reset .section-text-list-img svg {
  margin-bottom: 14px;
}

.section-ssd-solutions .section-ssd-solutions_col {
  width: 392px;
  margin: 0 16px;
}

.section-ssd-solutions .section-ssd-solutions_col .card-content {
  padding: 32px 32px 40px 32px;
}

.section-ssd-solutions .section-ssd-solutions_col .card-content h4 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
}

.section-ssd-solutions .section-ssd-solutions_col .card-content p {
  margin-bottom: 16px;
}

.section-ssd-solutions .section-ssd-solutions_col .card-content a {
  font-size: 14px;
  line-height: 170%;
}

.section-ssd-solutions .section-ssd-solutions_row {
  display: flex;
  justify-content: center;
}

.section-ssd-solutions .card {
  background-color: #fff;
  border: solid 1px #D7D7D7;
}

.section-about-oakgate .title {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #171717;
  margin-bottom: 4px;
}

.section-about-oakgate .description {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 172%;
  font-variant: small-caps;
  color: #7D7D7D;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-30 {
  background-image: url(./images/product/breadcrumbs-bg-30.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-23 {
  background-image: url(./images/product/Case-Study-bg-2.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-24 {
  background-image: url(./images/product/Case-Study-bg-3.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.reset.new-skew-style #main .section-equipment h4 {
  font-size: 20px;
}

.reset.new-skew-style #main .section-equipment table th {
  background-color: #fff !important;
}

.reset.new-skew-style #main .section-equipment a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #0076C0;
  white-space: nowrap;
}

.reset.new-skew-style #main .section-applications h3 {
  font-size: 20px;
}

.reset.new-skew-style #main .section-applications #accordion .card {
  padding: 8px 0;
}

.reset.new-skew-style #main .section-applications #accordion .card .accordion-arrow {
  top: 25px;
}

.section-ssd-solutions_col.h-100 img {
  height: 220px;
  width: 100%;
  object-fit: contain;
}

.font-24 {
  font-size: 24px !important;
}

@media (min-width: 1200px) {
  .section-banner,
  .bg-video-holder {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 677px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .section-banner .banner p,
  .bg-video-holder .banner p {
    font-size: 16px;
  }
}

.max-w-100 {
  max-width: 100% !important;
}

.section-resources-new.second .video-tab .row {
  border: none;
  margin: 0 -1rem;
}

.section-resources-new.second .video-tab .row [class^="col"] {
  padding: 0 1rem;
  margin-bottom: 32px;
}

.section-resources-new.second .video-tab .row iframe {
  width: 100%;
  height: 222px;
}

.reset #main .language-block-blue .form-row .jcf-select {
  border: none;
  position: relative;
  bottom: 5px;
}

.reset #main .language-block-blue .form-row .jcf-select span {
  color: #0076C0 !important;
}

.reset #main .language-block-blue .form-row .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #0076C0 !important;
  border-top: 2px solid #0076C0 !important;
}

.reset #main .language-block-blue .form-row .selected + .jcf-select .jcf-select-text span {
  color: #0076C0 !important;
}

.section-breadcrumbs.section-breadcrumbs-bg h1.title-h3 {
  color: #fff !important;
  margin-bottom: 32px !important;
  font-size: 32px !important;
}

.new-style .tabs-pci .lc-item a {
  color: #8F8F8F !important;
}

.new-style .tabs-pci .lc-item.active a {
  color: #231F20 !important;
}

p.comments {
  font-weight: 500 !important;
}

table.height-auto tbody tr {
  height: auto !important;
}

table.height-auto tbody tr td {
  height: auto !important;
  white-space: nowrap;
}

.list-dots li:before {
  width: 8px;
  height: 8px;
}

.video-tab .row iframe {
  width: 100%;
  height: 222px;
}

.col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reset .section-frontline-bg {
  background-image: url(./images/frontline/Frontline-bg-app.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.reset .section-frontline-bg h3,
.reset .section-frontline-bg .col-md-5 p {
  color: #F3F3F3;
}

.reset .section-frontline-bg path {
  fill: #fff;
}

.reset .section-frontline-bg .culture-slider-item path {
  fill: #0076C0;
}

.section-about-indicators.small .indicators-wrap {
  display: flex;
  flex-wrap: wrap;
}

.section-about-indicators.small .indicators-wrap .indicators-card {
  margin-right: 64px;
}

.section-about-indicators.small .indicators-wrap .indicators-card:last-child {
  margin-right: 0;
}

section a:hover {
  color: #0699F5 !important;
}

section a:hover path {
  fill: #0699F5 !important;
}

.col-apsolute {
  position: sticky;
  top: 100px;
}

.secnd-table-top img {
  height: 100px;
  margin-bottom: 10px;
}

#wrapper #main .section-best-choice-wrap .height-auto tr:nth-child(6) td {
  height: 112px !important;
}

main a.link-video {
  color: #0076C0;
  font-size: 14px;
  font-weight: 500;
}

main a.link-video svg {
  margin-left: 5px;
  position: relative;
  bottom: 2px;
}

.section-no-border #accordion .card {
  border-bottom: none;
  padding: 0 !important;
}

.posters-wrap .posters-card {
  background: #F6F8F8;
  padding: 26px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.posters-wrap .posters-card .posters-card-img {
  text-align: center;
  margin-bottom: 24px;
}

.posters-wrap .posters-card .posters-card-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #231F20;
  margin-bottom: 20px;
}

.posters-wrap .posters-card .posters-card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3D4448;
}

.posters-wrap .posters-card .posters-card-btn {
  margin-top: auto;
}

.posters-wrap .posters-card .posters-card-btn a {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #0076C0;
}

.posters-wrap .posters-card .posters-card-btn a:hover {
  color: #0699F5 !important;
}

.section-breadcrumbs {
  padding: 0 !important;
  height: 272px;
  display: flex;
}

.section-breadcrumbs .container {
  justify-content: center;
  display: flex;
  align-items: center;
}

#main li {
  color: #2B2F2F;
}

.section-ssd-solutions .section-ssd-solutions_col .card-content a.mr-3 {
  padding-right: 16px;
  border-right: solid 1px #D7D7D7;
}

.section-faq .big-arrow-2 {
  max-width: 800px;
  margin: 0 auto;
}

.section-ssd-solutions .section-ssd-solutions_col.big-link-text .card-content a.mr-3 {
  padding: 0 0 16px 0;
  border-bottom: solid 1px #D7D7D7;
  border-right: none;
  margin: 0 0 16px 0 !important;
}

.section-small-padding #accordion .row {
  padding: 8px 0;
}

.section-breadcrumbs .container {
  align-items: inherit;
  flex-direction: column;
}

.section-breadcrumbs.section-breadcrumbs-bg {
  position: relative;
}

.section-breadcrumbs .breadcrumbs-product {
  position: absolute;
  top: 24px;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-mil {
  background-image: url(./images/mil/bd-mil.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-mil h1 {
  text-transform: capitalize;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-mil h1 + p {
  color: #F3F3F3 !important;
}

.text-flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.section-content.small-content {
  max-width: 816px;
  margin: 0 auto;
}

section.mil-slider {
  background-image: url(./images/mil/mil-big-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

section.mil-slider h3 {
  color: #fff !important;
}

section.mil-slider .row p {
  color: #F3F3F3  !important;
}

section.mil-slider .culture-slider-item {
  min-height: 280px;
  min-width: 392px;
}

section.mil-slider .culture-slider-item_icon {
  margin-bottom: 32px;
}

section.mil-slider .slick-arrow path {
  fill: #fff;
}

#wrapper #main .section-best-choice-mil .section-best-choice-wrap thead tr td {
  text-align: center;
  min-width: 200px;
  max-width: 200px;
}

#wrapper #main .section-best-choice-mil .section-best-choice-wrap .secnd-table tbody tr td {
  text-align: center;
  min-width: 200px;
  max-width: 200px;
}

#wrapper #main .section-best-choice-mil .section-best-choice-wrap .secnd-table-top::after {
  right: -16px;
}

#wrapper #main .section-best-choice-mil .section-best-choice-wrap thead tr {
  display: flex;
  align-items: center;
}

.reset.new-skew-style #main .section-best-choice-mil a:not(.btn).link-arrow-new {
  justify-content: center;
}

#wrapper #main .section-best-choice-mil .section-best-choice-wrap .height-auto tr:nth-child(6) td {
  height: auto !important;
}

#main .section-best-choice-mil .section-active {
  line-height: 32px;
}

.section-best-choice-mil .secnd-table-top .name {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.section-best-choice-mil .secnd-table-top .sub-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7D7D;
  margin-bottom: 32px;
}

.section-best-choice-mil .secnd-table-top a {
  color: #0076C0;
}

#main .small-fonts.pci-mil .container .pci-small-row .pci-small-title {
  flex-direction: column;
  align-items: normal;
}

#main .small-fonts.pci-mil .container .pci-small-row .pci-small-title h3 {
  margin-bottom: 8px;
  color: #fff;
  font-size: 28px;
}

#main .small-fonts.pci-mil .container .pci-small-row .pci-small-title p {
  color: #fff;
}

.posters-wrap .posters-card {
  padding: 40px;
}

.new-style .new-font section.section-black-halfBlue.second h1 {
  font-size: 32px !important;
  line-height: 40px;
  white-space: nowrap;
}

.new-style section.section-black-halfBlue .black-half-list.more h2 {
  font-size: 28px;
  line-height: 40px;
  color: #171717;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: inherit;
}

.new-style section.section-black-halfBlue .black-half-list.more h2 small {
  color: #171717;
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card h3.title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px !important;
  color: #171717;
  font-style: normal;
  font-weight: 500;
}

.reset .block-view .lc-item a h3 {
  font-size: 20px;
  margin: 0;
  color: #8F8F8F !important;
  letter-spacing: normal;
}

.new-style .tabs-pci .lc-item.active a h3 {
  color: #231F20 !important;
}

#wrapper #main .section-best-choice-wrap .first-table tr td h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3D4448;
  margin: 0;
}

.new-style .small-fonts.pci-small .container .pci-small-row .pci-small-title h2 {
  font-size: 28px;
  color: #fff;
  margin: 0;
}

.second-tabs .lc-item a .title {
  font-size: 20px;
}

.reset h3.strong {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  margin: 0;
}

#wrapper #main .table-models tbody tr.no-border td h3 {
  margin: 0;
  font-size: 24px;
  line-height: 1.333334;
  font-weight: 500;
}

.table-wrap-list ul li h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.reset h2.new-scroll {
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
  color: #171717 !important;
  font-size: 16px;
}

.reset .anchor-active h2.new-scroll {
  color: #7D7D7D !important;
}

#wrapper #main .section-best-choice-wrap.rapid .first-table {
  width: 300px;
  max-width: 300px;
}

#wrapper #main .section-best-choice-wrap.rapid tr,
#wrapper #main .section-best-choice-wrap.rapid tr td {
  height: auto !important;
}

.new-style section.section-black-halfBlue .black-halfBlue-link li a {
  color: #0076C0;
}

.reset .block-view .tabs-pci.scroll .lc-item a {
  padding: 20px 18px;
}

.reset .block-view .tabs-pci.scroll .lc-item a h3 {
  font-size: 18px;
}

.experience-slider .slick-arrow {
  left: -60px;
}

.experience-slider .slick-arrow.next {
  right: -60px;
  left: auto;
}

#wrapper {
  overflow: initial;
}

.new-style .section-default-page.section-every-detail.black-version .section-every-detail_card .icon img {
  width: 100%;
}

.link-blue {
  color: #0076c0 !important;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 500;
}

body.reset section.section-services-bg {
  background-image: url(images/austin-labs/services-bg.png);
}

body.reset section.section-services-bg h3,
body.reset section.section-services-bg h3 + div p {
  color: #fff;
}

body.reset section.section-services-bg .slick-arrow path {
  fill: #fff;
}

.secnd-table-top img {
  height: 70px;
  margin-bottom: 10px !important;
}

.statick-models-table-wrap.rapid .table-models thead {
  height: 215px;
}

.statick-models-table-wrap.rapid .table-models thead .form-row {
  min-height: 160px;
}

.statick-models-table-wrap.rapid .table-models-slider td {
  text-align: center;
}

.statick-models-table-wrap.rapid .table-models-slider thead div,
.statick-models-table-wrap.rapid .table-models-slider thead li {
  text-align: center;
  display: block !important;
  padding: 0;
}

.rapid .section-every-detail_card .icon {
  margin-bottom: 24px;
  height: 200px;
}

.rapid .section-every-detail_card .icon img {
  height: 100%;
  object-fit: contain;
}

a.link-arrow {
  font-size: 14px;
}

.reset .h3 {
  font-size: 28px !important;
  margin: 0 0 0.75rem 0 !important;
  letter-spacing: 0.2px !important;
  line-height: 1.5 !important;
  font-weight: 500 !important;
}

@media (min-width: 992px) {
  .column-content .h6 {
    color: #0076C0;
    padding: 0 0 0 0.5rem;
    margin: 0 0 0.75rem -0.5rem;
    position: relative;
    border: 0;
    display: inline-block;
  }
  .column-content .h6::before {
    width: 3px;
    min-height: calc(100% - 0.5rem);
    position: absolute;
    top: 0.25rem;
    right: 100%;
    background-color: #0076C0;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .column-content .h6::before {
    content: '';
  }
}

.column-content ul {
  margin-bottom: 16px !important;
}

.reset .section-black-top {
  z-index: 2;
}

header {
  overflow: inherit;
}

.new-style .section-black-top_wrap .tabs-wrap .tabs-pci_second .lc-item a {
  color: #7D7D7D !important;
}

.new-style .section-black-top_wrap .tabs-wrap .tabs-pci_second .lc-item.active a {
  color: #171717 !important;
}

.reset #main .section-best-choice table tr td {
  padding: 16px;
}

.statick-models-table-wrap .table-models tbody tr {
  height: 56px;
}

.statick-models-table-wrap .table-models tbody tr td {
  padding: 16px !important;
}

.link-blue {
  font-size: 14px;
}

.d-space-b {
  justify-content: space-between !important;
}

.interoperability-block {
  margin-bottom: 48px;
}

.interoperability-block:last-child {
  margin-bottom: 0;
}

.interoperability-block .interoperability-block-header {
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: solid 1px #D7D7D7;
}

.interoperability-block .interoperability-block-header span {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  background: linear-gradient(180deg, #4EBCFF 0%, #0076BF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.interoperability-block .interoperability-block-body .title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 16px;
}

.interoperability-block .interoperability-block-body a {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #0076C0;
}

.interoperability-block li::before {
  background: #3D4448 !important;
  width: 6px !important;
  height: 6px !important;
  display: block;
  position: absolute;
  top: 10px !important;
  left: 0;
  content: "";
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-01 {
  background-image: url(./images/product/breadcrumbs-bg-01.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.list-check.black .icon-chek:before {
  color: #7D7D7D;
}

.list-dots.black li:before {
  background: #3D4448 !important;
  top: 6px !important;
}

.section-block-img-absolute {
  position: relative;
}

.section-block-img-absolute .container:after {
  display: none !important;
}

.section-block-img-absolute .block-img-absolute {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 45%;
  text-align: right;
}

.section-block-img-absolute .block-img-absolute img {
  height: 100%;
  object-position: left;
}

@media (max-width: 768px) {
  .section-block-img-absolute .block-img-absolute {
    position: static;
    height: 100%;
    width: auto;
    margin-bottom: 24px;
  }
}

.new-style section.section-black-halfBlue.section-black-halfBlue-small-f h2 {
  font-size: 32px !important;
  line-height: 40px;
}

.new-style section.section-black-halfBlue.section-black-halfBlue-small-f h4 {
  font-size: 28px;
  line-height: 40px;
}

.new-style section.section-black-halfBlue.section-black-halfBlue-small-f .black-half-list .description {
  font-variant: all-small-caps;
}

.section-breadcrumbs.section-breadcrumbs-bg.automation-services-bg {
  background-image: url(./images/product/automation-services-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

body.reset section.section-services-bg.section-services-bg-2 {
  background-image: url(images/product/Rectangle-1247.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-3.section-breadcrumbs-training-bg {
  background-image: url(./images/product/section-breadcrumbs-training-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.section-upcoming-training .section-upcoming-training-filter {
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
}

.section-upcoming-training .section-upcoming-training-filter .filter-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #171717;
  padding: 8px 16px;
  border: solid 1px #D7D7D7;
  border-radius: 20px;
  margin-right: 16px;
  cursor: pointer;
  transition: 0.5s;
}

.section-upcoming-training .section-upcoming-training-filter .filter-item:last-child {
  margin-right: 0;
}

.section-upcoming-training .section-upcoming-training-filter .filter-item.active {
  background-color: #0076C0;
  color: #fff;
}

.section-upcoming-training .section-upcoming-training-filter .filter-item:hover {
  background-color: #0076C0;
  color: #fff;
  transition: 0.5s;
}

.section-upcoming-training .section-upcoming-training-card .card {
  background: none;
  border: 1px solid #D7D7D7;
  padding-bottom: 32px;
  height: 100%;
  justify-content: space-around;
}

.section-upcoming-training .section-upcoming-training-card .card .card-content {
  padding: 32px 32px 0 32px;
}

.section-upcoming-training .section-upcoming-training-card .card .card-img {
  margin-bottom: 32px;
}

.section-upcoming-training .section-upcoming-training-card .card .card-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 16px;
}

.section-upcoming-training .section-upcoming-training-card .card .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}

.section-upcoming-training .section-upcoming-training-card .card .card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  margin-top: auto;
}

.new-style section.section-black-halfBlue.section-black-halfBlue-small-f .black-half-list .black-half-item {
  margin-right: 60px;
}

.new-style #accordion .card .accordion-arrow {
  z-index: 1;
}

.instructors_block .instructors_item {
  margin-bottom: 40px;
  display: flex;
}

.instructors_block .instructors_item:last-child {
  margin-bottom: 0;
}

.instructors_block .instructors_item .instructors_item-left {
  margin-right: 32px;
}

.instructors_block .instructors_item .instructors_item-left img {
  min-width: 180px;
  min-height: 180px;
}

.instructors_block .instructors_item .instructors_item-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 8px;
}

.instructors_block .instructors_item .instructors_item-position {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7D7D7D;
  margin-bottom: 24px;
}

.instructors_block .instructors_item .instructors_item-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter {
  background-image: url(./images/walter/walter-main-img.png);
  height: 800px;
  text-align: center;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter .date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #F3F3F3;
  margin-bottom: 24px;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter h1 {
  font-weight: 500;
  font-size: 56px !important;
  line-height: 40px !important;
  color: #FFFFFF;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter ul.technologies {
  display: flex;
  justify-content: center;
  margin-bottom: 54px;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter ul.technologies li {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF !important;
  position: relative;
  margin-right: 32px;
  padding: 0;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter ul.technologies li:last-child {
  margin-right: 0;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter ul.technologies li:last-child::after {
  display: none;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter ul.technologies li::after {
  position: absolute;
  background-color: #fff;
  width: 4px;
  height: 4px;
  display: block;
  content: "";
  border-radius: 50%;
  right: -16px;
  top: 10px;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter svg {
  margin: 0 auto 22px;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter .quote {
  margin-bottom: 22px;
}

#main .section-breadcrumbs.section-breadcrumbs-bg.section-breadcrumbs-bg-walter .quote p {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
}

#main .walter-section-top .walter-section-top_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#main .small-container {
  max-width: 816px;
  margin: 0 auto;
}

#main .text-more p + p {
  display: none;
}

#main .text-more span {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.5px;
  color: #0076C0;
  cursor: pointer;
  margin-left: 4px;
}

#main .text-more span:hover {
  color: #0699F5 !important;
}

#main .text-more span:hover + svg path {
  fill: #0699F5 !important;
}

#main .text-more.active p + p {
  display: block;
}

#main .text-more.active p:first-child span,
#main .text-more.active p:first-child span + svg {
  display: none;
}

#main .walter-section img {
  margin: 0 auto;
  display: block;
}

#main .walter-section .caption {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 170%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #7D7D7D;
  margin-top: 8px;
}

#main .walter-section .italick {
  font-style: italic;
}

#main .walter-section h2 {
  margin-bottom: 32px !important;
}

#main .walter-section ul {
  margin-bottom: 32px !important;
}

#main .walter-section .walter-datasheet ul {
  display: flex;
  flex-wrap: wrap;
}

#main .walter-section .walter-datasheet ul li {
  color: #0076C0;
  margin: 0 10px 10px 0;
  padding: 0 10px 0 0;
  border-right: 1px solid #ccc;
}

#main .walter-section .walter-datasheet ul li:last-child {
  border-right: none;
}

#main .walter-slider .slick-arrow {
  top: 50%;
  position: absolute;
  z-index: 9;
  cursor: pointer;
}

#main .walter-slider .slick-arrow.next {
  right: 0;
}

#main .walter-slider img {
  width: auto;
  height: 540px;
}

#main .walter-slider .slick-track {
  align-items: center;
}

#main .walter-slider .slick-dots {
  bottom: -70px;
}

#main .walter-slider .slick-dots li {
  margin: 0 2px 0 0;
  padding: 0;
}

#main .walter-slider .slick-dots li button {
  width: 20px;
  height: 2px;
  background: #D7D7D7;
  border: none;
  border-radius: 0;
}

#main .walter-slider .slick-dots li.slick-active button {
  background: #0076C0;
}

.section-lifecycle-new .lc-item i:before,
.section-lifecycle-new .lc-item span {
  color: #000;
}

.section-lifecycle-new .lc-item.active i:before,
.section-lifecycle-new .lc-item.active span {
  color: #fff;
}

.new-style .card-line.hover {
  box-shadow: 0px 0px 32px rgba(191, 191, 191, 0.36);
}

.white .lc-item a {
  background-color: #fff;
}

a.btn.btn-default-outline:hover {
  color: #fff !important;
}

.table-description {
  color: #3d4448;
}

.table-description strong {
  font-weight: bold;
}

.reset .section-banner-slide {
  padding: 0;
  height: 420px;
}

.reset .section-banner-slide .banner-slide-item {
  height: 420px;
  position: relative;
  background-position: center;
  background-size: cover;
}

.reset .section-banner-slide .banner-slide-item:before {
  background-color: rgba(11, 27, 37, 0.5);
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: "";
}

.reset .section-banner-slide .banner-slide-item.default:before {
  background-color: rgba(0, 118, 192, 0.5);
}

.reset .section-banner-slide .banner-slide-item.blue:before {
  background: rgba(20, 97, 148, 0.5);
}

.reset .section-banner-slide .banner-slide-content {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
}

.reset .section-banner-slide .banner-slide-content h2, .reset .section-banner-slide .banner-slide-content h1 {
  color: #fff;
}

.reset .section-banner-slide .banner-slide-content span.page {
  text-transform: uppercase;
  margin-bottom: 8px;
  color: #fff;
}

.reset .section-banner-slide .banner-slide-content .banner-slide-text {
  margin-bottom: 24px;
}

.reset .section-banner-slide .banner-slide-content .banner-slide-text p {
  color: #fff;
}

.reset .section-banner-slide.small {
  height: 320px;
  padding: 0 !important;
}

.reset .section-banner-slide.small .banner-slide-item {
  height: 320px;
}

.reset .logos-list-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.reset .logos-list-wrap .logos-item {
  margin: 0 50px 20px 0;
}

.reset .logos-list-wrap .logos-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 920px) {
  .reset .logos-list-wrap {
    max-width: 650px;
    margin: 0 auto;
  }
  .reset .logos-list-wrap .logos-item {
    margin: 0 5px 20px 5px;
    width: calc(25% - 10px);
    text-align: center;
  }
}

.reset .section-approach .approach-img {
  text-align: center;
}

.reset .section-approach .approach-img img {
  margin-bottom: 16px;
}

.reset .section-approach .approach-img a {
  text-transform: uppercase;
}

.reset .section-automotive-list {
  padding: 40px 0;
}

.reset .section-automotive-list .card-line.horizontale .inner-img {
  min-width: 410px;
  background-size: cover;
  background-color: inherit;
}

@media (max-width: 539px) {
  .reset .section-automotive-list .card-line.horizontale .inner-img {
    min-width: 0;
  }
}

.reset .also-interest {
  padding-top: 20px;
}

.reset .also-interest .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.reset .also-interest .container .label {
  font-weight: 600;
  margin-bottom: 10px;
}

.reset .also-interest .container ul {
  display: flex;
  flex-wrap: wrap;
}

.reset .also-interest .container a {
  color: #0076C0;
}

.reset .also-interest .container a:hover {
  color: #003f66;
}

.reset #categoryTop {
  background: #EAEDEE;
  height: 100%;
}

.reset #categoryTop .subNav {
  background: none;
  height: 100%;
  margin: 0;
  width: auto;
}

.reset #categoryTop .subNav a {
  display: block;
}

.reset .product-banner-title {
  min-height: 120px;
  color: #0076C0;
}

.reset .product-banner-title h2 {
  color: #0076C0;
}

@media (max-width: 719px) {
  .reset .section-product.product-banner-title + .product-line .product-content {
    padding-top: 0;
  }
}

.reset .section-product.product-line .container {
  border-bottom: 1px solid #EAEDEE;
}

.reset .section-product.product-line:nth-child(3) .container {
  border-bottom: none;
}

.reset .section-product.product-line .product-content {
  padding: 20px 0;
}

.reset .section-product.product-line .product-content p a {
  color: #0076C0;
}

.reset .section-product.product-line .product-content p a:hover {
  color: #003f66;
}

.reset .section-product.product-line .product-content .img-text {
  text-align: center;
  font-style: italic;
  font-size: 12px;
}

.reset .section-product.product-line .product-content-subtitle {
  margin-bottom: 24px;
  font-size: 20px;
  color: #231F20;
}

.reset .product-bottom-block {
  background: #EAEDEE;
  border-radius: 10px;
  margin-top: 85px;
}

.reset .product-bottom-block .row {
  margin: 0;
}

.reset .product-bottom-block .product-bottom-img {
  margin-top: -85px;
}

.reset .product-bottom-block .product-bottom-content {
  padding: 20px 0;
  text-align: center;
}

.reset .product-bottom-block .product-bottom-content .product-bottom-text {
  margin-bottom: 20px;
}

.reset .product-bottom-block .product-bottom-content p a {
  color: #008ecd;
}

.reset .product-bottom-block .product-bottom-content p a:hover {
  color: #003f66;
}

.reset .slide-content.hidden {
  opacity: 0;
}

.reset .section-default-page {
  padding: 30px 0;
}

@media screen and (max-width: 840px) {
  .reset .section-default-page img {
    max-width: 400px;
    width: 100%;
  }
  .reset .section-default-page img.big-img {
    max-width: 100%;
  }
}

@media screen and (max-width: 1140px) {
  .reset .section-default-page .col-xl-4,
  .reset .section-default-page .col-xl-3 {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 768px) {
  .reset .section-default-page .col-xl-4:last-child,
  .reset .section-default-page .col-xl-3:last-child {
    margin-bottom: 0;
  }
}

.reset .section-default-page .container-gray {
  box-shadow: 0 0.5rem 0.75rem #ececec;
  background-color: #F6F8F8;
  border: 1px solid #EAEDEE;
  padding: 24px;
}

.reset .section-default-page p a {
  color: #0076C0;
}

.reset .section-default-page p a:hover {
  text-decoration: underline;
}

.reset .section-default-page p a.green {
  color: #4CAF50;
}

.reset .section-default-page h2.green {
  color: #4CAF50;
}

.reset .section-default-page h2.blue {
  color: #0076C0;
}

.reset .section-default-page .page-button {
  margin-top: 24px;
}

.reset .section-default-page .page-button a, .reset .section-default-page .page-button span {
  margin: 0 16px 16px 0;
  position: relative;
}

.reset .section-default-page .page-button a:last-child, .reset .section-default-page .page-button span:last-child {
  margin-right: 0;
}

.reset .section-default-page .page-button.right {
  margin: 0;
}

.reset .section-default-page .page-button.right a {
  margin: 0 0 16px 0;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .reset .section-default-page .page-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
  }
  .reset .section-default-page .page-button span, .reset .section-default-page .page-button a {
    margin: 0 4px 8px 4px;
    width: calc(50% - 10px);
  }
}

.reset .section-default-page .card-line.number .card-title h3 {
  color: #231F20;
  font-size: 24px;
  font-weight: normal;
}

.reset .section-default-page .card-line.number .card-title h3 .icon {
  display: inline-block;
  color: #fff;
  width: 37px;
  height: 37px;
  line-height: 37px;
  padding: 0;
  background-color: #4CAF50;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  margin-right: 15px;
  font-style: normal;
}

.reset .section-default-page .tabs-area .tab-hidden {
  position: absolute !important;
  left: -9999px !important;
  top: -9999px !important;
  display: block !important;
  width: 100% !important;
}

.reset .section-default-page .tabs-area .tabs-pci {
  display: flex;
}

@media screen and (max-width: 940px) {
  .reset .section-default-page .tabs-area .tabs-pci {
    display: block;
  }
  .reset .section-default-page .tabs-area .tabs-pci .lc-item {
    margin-bottom: 8px;
  }
}

.reset .section-default-page .tabs-area .lc-item a,
.reset .section-default-page .tabs-area .lc-item span {
  padding: 8px;
  font-size: 13px;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 60px;
}

.reset .section-default-page .tabs-area .lc-item a:after,
.reset .section-default-page .tabs-area .lc-item span:after {
  display: none;
}

.reset .section-default-page .tabs-area .tab-holder p {
  font-size: 13px;
}

.reset .upcoming-row {
  margin-top: 8px;
  padding-top: 8px;
  border-top: solid 1px #EAEDEE;
  flex-wrap: wrap;
}

.reset .upcoming-row:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

.reset .height-auto {
  height: auto !important;
  min-height: 0 !important;
}

.reset .flex-betwen {
  justify-content: space-between;
}

.reset .uppercase {
  text-transform: uppercase !important;
}

.reset .h-100 {
  height: 100% !important;
}

.reset .w-100 {
  width: 100% !important;
}

.reset .mb-24 {
  margin-bottom: 24px !important;
}

.reset .mb-32 {
  margin-bottom: 32px !important;
}

.reset .mt-32 {
  margin-top: 32px !important;
}

.reset .w-100 {
  width: 100% !important;
}

.reset .mb-40 {
  margin-bottom: 40px !important;
}

.reset .mb-64 {
  margin-bottom: 64px !important;
}

.reset .mb-80 {
  margin-bottom: 80px !important;
}

.reset .mb-120 {
  margin-bottom: 120px !important;
}

.reset .p-40 {
  padding: 40px !important;
}

@media screen and (max-width: 376px) {
  .reset .p-40 {
    padding: 24px !important;
  }
}

.reset .p-24 {
  padding: 24px !important;
}

.reset .pt-40 {
  padding-top: 40px !important;
}

.reset .pt-0 {
  padding-top: 0 !important;
}

.reset .pb-0 {
  padding-bottom: 0 !important;
}

.reset .pb-40 {
  padding-bottom: 40px !important;
}

.reset .pb-64 {
  padding-bottom: 64px !important;
}

.reset .d-f-start {
  align-items: flex-start;
}

.reset .d-f-center {
  display: flex;
  align-items: center;
}

.reset .justify-content {
  justify-content: center !important;
}

@media (min-width: 1200px) {
  .reset .mb-lg-0, .reset .my-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .reset .mb-md-0,
  .reset .my-md-0 {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .reset .mb-md-0,
  .reset .my-md-0 {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 376px) {
  .reset .mb-sm-40 {
    margin-bottom: 40px !important;
  }
}

.reset .section-quotations .card-line label {
  color: #231F20;
  font-weight: 500;
}

.reset .section-quotations p {
  margin-bottom: 8px;
}

.reset .section-quotations p a {
  color: #0076C0;
}

.reset .quotations-try-top {
  margin-bottom: 80px;
}

@media screen and (max-width: 993px) {
  .reset .quotations-try-top {
    margin-bottom: 23px;
  }
}

.reset .quotations-try-bottom .row.mb-40:last-child {
  margin: 0;
}

.reset .section-links {
  border-bottom: solid 1px #EAEDEE;
  padding: 25px 0;
}

.reset .section-links.section-links-izotope {
  border-bottom: none;
  padding: 0;
}

.reset .section-links.section-links-izotope.open .izotope-filters {
  display: block;
}

.reset .section-links.section-links-izotope.open .slide {
  width: 200px;
  transform: translate(-50%, 0);
  left: 50%;
}

.reset .section-links.section-links-izotope.open .slide .button {
  width: 100%;
  margin-bottom: 8px;
}

.reset .section-links.section-links-izotope.open .slide .button:last-child {
  margin-bottom: 0;
}

.reset .section-links ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.reset .section-links ul li {
  margin: 0 10px 0 0;
}

.reset .section-links ul li:last-child {
  margin-right: 10px;
}

.reset .section-links ul li a {
  color: #231F20;
  font-weight: 600;
}

.reset .section-links ul li a:hover {
  color: #0076C0;
}

.reset .section-links.open ul {
  display: block;
}

.reset .section-links.open ul li {
  margin: 0;
  padding: 0;
}

.reset .section-links.open ul li:last-child {
  margin-bottom: 0;
}

.reset .section-links.open ul a {
  display: block;
}

.reset .show-list .multi-level {
  display: block;
}

.reset .holder-form-items {
  display: -ms-flexbox;
  display: flex;
}

.reset .holder-form-items .dropdown-menu {
  min-width: 160px;
}

.reset .holder-form-items .dropdown-menu a {
  display: block;
  padding: 8px 6px;
}

.reset .holder-form-items .dropdown-menu a:hover {
  color: #fff;
  background-color: #0076c0;
}

.reset .holder-form-items .dropdown-submenu {
  position: relative;
  font-size: inherit;
}

.reset .holder-form-items .dropdown-submenu:hover:after {
  content: "\e921";
  position: absolute;
  background-color: transparent;
  font-size: 8px;
  right: 10px;
  top: 50%;
  display: block;
  margin: 0;
  border: 0;
  transform: translateY(-50%);
  color: #fff;
}

.reset .holder-form-items .dropdown-submenu:hover > a {
  color: #fff;
  background-color: #0076c0;
}

.reset .holder-form-items .dropdown-item {
  width: 100%;
}

.reset ul.dropdown-menu.multi-level {
  list-style: none !important;
  border: 1px solid;
  font-size: inherit;
  margin-top: 2px;
  font-size: 10px;
}

.reset ul.dropdown-menu.multi-level li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.reset ul.dropdown-menu.multi-level a {
  color: #000;
}

.reset ul.dropdown-menu.multi-level a:focus {
  outline: none;
}

.reset ul.dropdown-menu.multi-level a:after {
  content: none;
}

.reset input[type="search"],
.reset .dropdown-toggle {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid;
  position: relative;
  padding: 6px 2rem 6px .5rem;
}

.reset input[type="search"]:after,
.reset .dropdown-toggle:after {
  content: "\e921";
  position: absolute;
  background-color: transparent;
  font-size: 8px;
  right: 10px;
  top: 50%;
  display: block;
  margin: 0;
  border: 0;
  transform: rotate(90deg) translateY(-50%);
  transform-origin: top;
}

.reset input[type="search"]:focus,
.reset .dropdown-toggle:focus {
  outline: none;
}

.reset .dropdown-submenu > ul.dropdown-menu {
  top: 0;
  padding: 0;
  font-size: inherit;
  left: calc(100% - .25rem);
}

.reset .dropdown-menu {
  z-index: 1;
  border: 1px solid;
  border-radius: 0;
}

.reset .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.reset .dropdown-submenu.pull-left {
  float: none;
}

.reset .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 0;
}

.reset #wrapper .content-container,
.reset #wrapper .column-center {
  overflow: visible;
}

.reset .open-form.open + .contact-form {
  max-height: 9999px;
  margin-top: 24px;
  visibility: visible;
}

.reset .contact-form {
  max-height: 0;
  margin-top: 0;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .reset .contact-form .form-row .col-md-6:last-child {
    margin-top: 16px;
  }
}

.reset .open .datasheet-dropdown {
  max-height: 999px;
  transition: .5s;
  visibility: visible;
}

.reset .datasheet-dropdown {
  position: absolute;
  z-index: 9;
  top: 100%;
  left: 0;
  background: #008ecd;
  padding: 0;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: .5s;
  box-shadow: 0 0.5rem 0.75rem #ececec;
  background-color: #fff;
  border: 1px solid #EAEDEE;
  visibility: hidden;
}

.reset .datasheet-dropdown li {
  margin: 0 !important;
  border-bottom: 1px solid #EAEDEE;
  text-align: left;
  padding: 0 !important;
}

.reset .datasheet-dropdown li:last-child {
  border-bottom: none;
}

.reset .datasheet-dropdown li a {
  font-size: 12px;
  color: #0076C0;
  padding: 5px;
  display: inline-block;
  margin: 0 !important;
}

.reset .datasheet-dropdown li a:hover {
  color: #0067a7;
}

.reset .justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset .align-center {
  display: flex;
  align-items: center;
}

.reset .preference-content {
  padding: 32px 0;
}

.reset .preference-content .preference-content-header {
  margin-bottom: 24px;
}

.reset .preference-content .preference-content-header h1, .reset .preference-content .preference-content-header h2 {
  color: #0076C0;
}

.reset .preference-content .preference-text a {
  color: #0076C0;
  text-decoration: underline;
}

.reset .preference-content .preference-text a:hover {
  text-decoration: none;
}

.reset .preference-content .preference-text .preference-text-top {
  margin-bottom: 24px;
}

.reset .preference-content .preference-text table {
  margin-bottom: 24px;
}

.reset span.img-title {
  font-weight: 500;
  color: #fff;
  margin-top: 5px;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 30px;
  width: calc(100% - 80px);
  text-align: left;
}

.reset .preference-footer {
  padding-bottom: 50px;
}

.reset #videos h3 {
  color: #fff;
  font-size: 16px;
}

.reset section#docs .description,
.reset section#briefs .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reset .bottom-block {
  align-items: center;
}

.reset .green-text h2, .reset .green-text .link-arrow {
  color: #4CAF50 !important;
}

.reset .green-text h2 {
  font-size: 44px !important;
}

.reset .green-text ul.green span {
  font-weight: 400;
}

.reset .green-text .title,
.reset .blue-text .title {
  font-weight: 400;
}

.reset .blue-text h2, .reset .blue-text .link-arrow {
  color: #0076C0 !important;
}

.reset .blue-text ul.green span {
  font-weight: 500;
}

.reset .blue-text .inner-img {
  background-size: auto !important;
}

.reset .bottom-block-text {
  font-weight: 500;
  font-size: 26px;
  line-height: 28.6px;
  color: #000;
}

.reset .slide-event {
  justify-content: flex-start;
  height: 100%;
  flex-direction: column;
}

.reset .slide-event .top-card {
  margin-bottom: 24px;
}

.reset .slide-event .bottom-card {
  margin-top: auto;
}

.reset .slide-event .title-slide {
  min-height: 0;
  margin-bottom: 8px;
  font-size: 22px;
}

.reset .section-heading.line h3 {
  padding-bottom: 8px;
  border-bottom: solid 1px #EAEDEE;
}

.reset .section-training-filters {
  padding: 24px 0 40px 0;
}

.reset .section-training-filters .row > div {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.reset .search-wrap {
  position: relative;
}

.reset .search-wrap input {
  padding-right: 30px;
}

.reset .search-wrap .icon-search {
  position: absolute;
  right: 25px;
  bottom: 14px;
}

.reset .block-view {
  position: relative;
}

.reset .block-view .tabs-pci {
  display: flex;
  flex-wrap: wrap;
}

.reset .block-view .lc-item {
  padding: 0;
  flex: inherit;
  width: auto;
}

.reset .block-view .lc-item a, .reset .block-view .lc-item span {
  cursor: pointer;
  padding: 20px 24px;
  background: none !important;
  color: #231F20;
  font-size: 20px;
  font-weight: 500 !important;
  position: relative;
  display: inline-block;
}

@media (max-width: 768px) {
  .reset .block-view .lc-item a, .reset .block-view .lc-item span {
    font-size: 16px;
  }
}

@media (max-width: 768px) and (max-width: 321px) {
  .reset .block-view .lc-item a, .reset .block-view .lc-item span {
    font-size: 14px;
  }
}

.reset .block-view .lc-item a::before, .reset .block-view .lc-item a::after, .reset .block-view .lc-item span::before, .reset .block-view .lc-item span::after {
  display: none;
}

.reset .block-view .lc-item a:hover, .reset .block-view .lc-item span:hover {
  color: #0076C0;
}

.reset .block-view .lc-item a::before, .reset .block-view .lc-item span::before {
  display: block;
  width: 0;
  height: 2px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: #0076C0;
  transition: .5s;
}

.reset .block-view .lc-item.active a {
  color: #0076C0;
}

.reset .block-view .lc-item.active a:before {
  transition: .5s;
  width: 100%;
}

.reset .block-view .view-select {
  position: absolute;
  top: 25px;
  right: 0;
}

.reset .block-view .view-select span {
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
}

@media (max-width: 740px) {
  .reset .block-view .view-select {
    position: static;
    margin: 20px 0;
    text-align: center;
  }
}

.reset .block-view.view-cards span.view-cards rect {
  fill: #0076C0;
}

.reset .block-view.view-cards span.view-cards path {
  fill: #fff;
}

.reset .block-view.view-cards .title-slide a {
  pointer-events: none;
}

.reset .block-view.view-list .title-slide a {
  color: #0076C0;
  pointer-events: inherit;
}

.reset .block-view.view-list span.view-cards rect {
  fill: #F6F8F8;
}

.reset .block-view.view-list span.view-cards path {
  fill: #596369;
}

.reset .block-view.view-list span.view-list rect {
  fill: #0076C0;
}

.reset .block-view.view-list span.view-list path {
  fill: #fff;
}

.reset .block-view .tabs-content .slide-event {
  min-height: 0;
  padding: 24px;
  position: relative;
  box-shadow: none;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.reset .block-view .tabs-content .slide-event .top-card {
  margin-bottom: 0;
  width: calc(100% - 200px);
}

@media (max-width: 740px) {
  .reset .block-view .tabs-content .slide-event .top-card {
    width: 100%;
  }
}

.reset .block-view .tabs-content .slide-event .footer-slide {
  margin: 0;
}

.reset .block-view .tabs-content .slide-event .bottom-card {
  margin-top: 0;
  min-width: 150px;
  text-align: right;
}

@media (max-width: 740px) {
  .reset .block-view .tabs-content .slide-event .bottom-card {
    text-align: left;
    margin-top: 20px;
  }
}

.reset .block-view .tabs-content#tab-pci-1 .local {
  color: #231F20;
  margin-right: 8px;
}

.reset .block-view .tabs-content#tab-pci-1 .date {
  color: #596369;
  border-left: solid 1px #596369;
  padding-left: 8px;
}

.reset .block-view .tabs-content#tab-pci-1 .calendar {
  padding: 0 10px;
  border-left: solid 1px #596369;
}

.reset .block-view .tabs-content#tab-pci-1 .calendar a {
  color: #0076C0;
  font-weight: 500;
}

.reset .block-view .tabs-content#tab-pci-1 .calendar a:hover {
  text-decoration: underline;
}

.reset .block-view .pagination-block {
  margin-top: 38px;
}

.reset main .demand .line-none {
  display: none;
}

.reset .border-top-green {
  padding-top: 40px !important;
  border-top: solid 1px #4CAF50 !important;
}

.reset thead.green th {
  background-color: #4CAF50 !important;
}

.reset .white-space {
  white-space: nowrap;
}

.reset .big-text p {
  font-size: 20px !important;
  font-weight: 400;
}

.reset .demand-webinars .card-line.horizontale .inner-content {
  padding: 16px;
}

.reset .demand-webinars .card-title h3 {
  font-size: 20px !important;
  line-height: 30px !important;
}

.reset .demand-webinars .card-line.horizontale .inner-img {
  width: 210px;
  min-width: 210px;
  background-size: contain;
  background-color: #fff;
  background-position: left top !important;
}

.reset .demand-webinars .card-part {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #231F20;
}

.reset .demand-webinars .card-time {
  font-size: 14px;
  line-height: 21px;
  color: #231F20;
}

body .show-register {
  display: none !important;
}

body.register .show-register {
  display: flex !important;
}

body.register .no-show-register {
  display: none !important;
}

.reset.skew-style .section-login .section-heading {
  margin-bottom: 32px;
}

.reset.skew-style .section-login [class^="row"] {
  margin: 0 -0.5rem;
}

.reset.skew-style .section-login [class^="col"] {
  padding: 0 0.5rem;
}

.reset.skew-style .section-login .sub-title p {
  color: #231F20;
  font-size: 16px;
}

.reset.skew-style .section-login h2 {
  margin-bottom: 24px !important;
}

.reset.skew-style .section-login .link-back {
  font-weight: 500;
}

.reset.skew-style .section-login label.error + .jcf-select {
  border-color: inherit;
}

.table-list {
  border: solid 1px #EAEDEE;
  display: flex;
  flex-wrap: wrap;
}

.table-list .table-column-item {
  border-bottom: 1px solid #EAEDEE;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 12px 24px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #231F20;
}

@media (max-width: 920px) {
  .table-list .table-column-item {
    font-size: 12px;
  }
}

.table-list-column.first .table-column-item {
  justify-content: start;
}

.table-list-column.active {
  box-shadow: 0px 4px 8px #eaedee;
}

.table-list-column.active .table-column-item:nth-child(2n+1) {
  background: #F6F8F8;
}

.table-list-column.active .table-column-item:first-child {
  background: #0076C0;
  color: #fff;
}

.table-list .table-column-item:first-child {
  background: #F6F8F8;
  font-weight: 500;
  font-size: 20px;
}

.table-list .table-column-item:last-child {
  border-bottom: none;
}

.table-list-description {
  font-size: 14px;
  color: #596369;
}

#videos iframe {
  width: 100%;
  height: 200px;
}

.login-page .column-left-container {
  border-right: none;
  float: none;
  margin: 0;
  position: static;
}

.login-page .column-center-container {
  float: none;
  position: static;
  width: 100%;
}

.login-page .column-container {
  float: none;
  margin-left: 0;
  position: static;
  width: 100%;
}

.login-page .column-center {
  margin: 0;
  position: static;
}

.reset .section-login .sub-title p {
  color: #231F20;
  font-size: 16px;
}

.reset .section-login .sub-title b {
  font-size: 20px;
  font-weight: 500;
  display: block;
}

.reset .explore-content.small {
  max-width: 392px;
}

.reset span.prompt,
.reset a.prompt {
  color: #BDBDBD;
  font-size: 12px;
  margin-top: 5px;
  display: inline-block;
}

.reset a.prompt {
  color: #0076C0;
}

.tooltip {
  opacity: 1 !important;
}

a.tooltip {
  border-radius: 50%;
  position: relative !important;
  border: solid 1px #000;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: .5s;
  z-index: 0;
}

.tooltip:hover {
  background-color: #000;
  color: #fff;
  transition: .5s;
}

.tooltip-inner {
  box-shadow: 0px 4px 8px #eaedee;
  background: #fff;
  color: #000;
  position: absolute;
  z-index: 99999;
  top: 0;
  transform: translate(20px, -50%);
  width: 400px;
  max-width: 999px;
  border: solid 1px #ccc;
}

.tooltip .tooltip-arrow {
  top: 50%;
  right: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  display: block;
  border: 10px solid transparent;
  border-right: 10px solid #000;
  margin-right: -20px;
  transform: translate(0, -50%);
}

.tooltip .arrow {
  margin-left: 20px;
}

@media (max-width: 720px) {
  a.tooltip {
    position: absolute !important;
    right: 10px;
  }
  .tooltip-inner {
    box-shadow: 0px 4px 8px #eaedee;
    background: #fff;
    color: #000;
    position: absolute;
    z-index: 99999;
    top: 100%;
    transform: translate(0, 20px);
    width: 270px;
    right: 100%;
    border: solid 1px #ccc;
    max-width: 270px;
    font-size: 12px;
  }
  .tooltip .tooltip-arrow {
    top: 100%;
    left: 50%;
    border: 10px solid transparent;
    border-bottom: 10px solid #000;
  }
  .tooltip .arrow {
    margin-top: 10px;
    margin-left: -15px;
  }
  .tooltip .arrow::before {
    transform: rotate(90deg);
  }
}

.table-comparison .item-img {
  height: 172px;
  background: none !important;
  text-align: center;
  padding: 0;
}

.table-comparison .item-img img {
  height: 132px;
}

.table-comparison .table-list .table-column-item:first-child {
  background: #0076C0 !important;
  color: #fff;
  padding: 12px 5px;
}

@media (max-width: 920px) {
  .table-comparison .table-list .table-column-item:first-child {
    font-size: 16px;
  }
}

.table-comparison .table-list .table-column-item:nth-child(2n + 1) {
  background-color: #F6F8F8;
}

.table-comparison_top {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .table-comparison_top .first {
    display: none;
  }
}

.reset .card-line .inner-content.small .card-title h4 {
  min-height: 0;
  font-size: 24px;
}

a.green {
  color: #4CAF50 !important;
}

a.green h5 {
  color: #4CAF50 !important;
}

.section-pro .card-title h4 {
  min-height: 0 !important;
}

.section-pro .card-content p {
  font-size: 14px !important;
}

.min-h-auto {
  min-height: 0 !important;
}

section.product-exp {
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F6F8F8;
  background-position: top right;
}

section.product-exp p {
  color: #231F20;
}

section.product ul.list-characteristics li {
  color: #231F20;
  font-weight: 600;
}

section.product ul.list-characteristics li span {
  margin: 0 5px 0 0 !important;
  font-weight: normal !important;
}

.co-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.co-between p {
  color: #231F20;
}

.white-space {
  white-space: nowrap;
}

.logo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.logo-list .logo-item {
  width: 20%;
}

@media (max-width: 1140px) {
  .logo-list .logo-item {
    width: auto;
  }
}

.description-text p {
  color: #596369 !important;
  font-size: 14px !important;
  line-height: 150% !important;
}

section.platform {
  display: flex;
  align-items: center;
  background-repeat: no-repeat !important;
  min-height: 900px;
}

@media (max-width: 1200px) {
  section.platform {
    min-height: 0;
    background: none !important;
  }
}

.container.small-2 {
  max-width: 604px;
}

.reset.skew-style .product-details-item p {
  font-size: 14px;
}

.demonstration-view {
  background: #DCDCDC;
  align-items: center;
  padding: 12px;
  border-radius: 0 110px 110px 0;
  position: relative;
}

.demonstration-view::before {
  background: #DCDCDC;
  position: absolute;
  display: block;
  content: "";
  right: 100%;
  top: 0;
  width: 99999%;
  height: 100%;
}

.demonstration-view.before-right {
  border-radius: 110px 0 0 110px;
}

.demonstration-view.before-right::before {
  right: auto;
  left: 100%;
}

.demonstration-view .demonstration-img img {
  min-height: 190px;
  min-width: 190px;
}

.reset.new-skew-style #main .tabs-filter.top {
  top: 40px;
}

.h-full.home-video.bg-video-holder.pt-0 {
  padding-top: 0 !important;
}

.section-izotope .card-line.p-0 .title {
  font-weight: 500;
}

.reset section,
.reset .section-default-page {
  padding: 64px 0 80px 0;
  color: #596369;
}

@media screen and (max-width: 376px) {
  .reset section,
  .reset .section-default-page {
    padding: 32px 0 40px 0;
  }
}

.reset section.section-white,
.reset .section-default-page.section-white {
  background: #fff;
}

.reset section.section-gray,
.reset .section-default-page.section-gray {
  background: #F6F8F8;
}

.reset section.section-blue,
.reset .section-default-page.section-blue {
  background: #0076C0;
}

.reset section.section-angle,
.reset .section-default-page.section-angle {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 16px 0 80px 0;
}

@media (max-width: 575px) {
  .reset section.section-angle,
  .reset .section-default-page.section-angle {
    padding: 64px 0 40px 0;
  }
}

.reset section.section-angle:before,
.reset .section-default-page.section-angle:before {
  bottom: 0;
  transform-origin: 0;
  transform: skewY(-4.5deg);
}

.reset section.section-angle-before,
.reset .section-default-page.section-angle-before {
  padding-bottom: 249px;
}

@media screen and (max-width: 1201px) {
  .reset section.section-angle-before,
  .reset .section-default-page.section-angle-before {
    padding-bottom: 155px;
  }
}

@media (max-width: 575px) {
  .reset section.section-angle-before,
  .reset .section-default-page.section-angle-before {
    padding-bottom: 100px;
  }
}

.reset section.section-angle-before.angle-small,
.reset .section-default-page.section-angle-before.angle-small {
  padding-bottom: 184px;
}

@media screen and (max-width: 1201px) {
  .reset section.section-angle-before.angle-small,
  .reset .section-default-page.section-angle-before.angle-small {
    padding-bottom: 110px;
  }
}

@media (max-width: 575px) {
  .reset section.section-angle-before.angle-small,
  .reset .section-default-page.section-angle-before.angle-small {
    padding-bottom: 80px;
  }
}

.reset section .section-heading,
.reset .section-default-page .section-heading {
  margin-bottom: 40px;
}

@media screen and (max-width: 376px) {
  .reset section .section-heading,
  .reset .section-default-page .section-heading {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 769px) {
  .reset section .section-heading h2,
  .reset section .section-heading .h2,
  .reset .section-default-page .section-heading h2,
  .reset .section-default-page .section-heading .h2 {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 376px) {
  .reset section .section-heading h2,
  .reset section .section-heading .h2,
  .reset .section-default-page .section-heading h2,
  .reset .section-default-page .section-heading .h2 {
    font-size: 24px !important;
    font-weight: 500;
  }
}

.reset.skew-style main,
.reset.skew-style p {
  font-size: 16px;
}

@media screen and (max-width: 376px) {
  .reset.skew-style main,
  .reset.skew-style p {
    font-size: 12px;
  }
}

.reset p {
  margin-bottom: 8px;
}

.reset p:last-child {
  margin: 0;
}

.reset h1, .reset .h1, .reset h2, .reset .h2, .reset h3, .reset .h3, .reset h4, .reset .h4, .reset h5, .reset .h5, .reset h6, .reset .h6,
.reset .title {
  color: #231F20;
}

.reset h1,
.reset .h1 {
  font-size: 52px;
  line-height: 1.3333333;
  margin: 0 0 0.5rem 0;
  font-weight: 500;
}

@media (max-width: 767px) {
  .reset h1,
  .reset .h1 {
    margin: 0 0 0.75rem 0;
    font-size: 32px;
  }
}

@media (max-width: 319px) {
  .reset h1,
  .reset .h1 {
    font-size: 24px;
  }
}

.reset h2,
.reset .h2 {
  font-size: 44px;
  line-height: 1.333334;
  margin: 0 0 0.75rem 0;
}

@media screen and (max-width: 769px) {
  .reset h2,
  .reset .h2 {
    margin: 0 0 0.75rem 0;
    font-size: 28px !important;
  }
}

@media screen and (max-width: 376px) {
  .reset h2,
  .reset .h2 {
    font-size: 24px !important;
    font-weight: 500;
  }
}

.reset h3,
.reset .h3 {
  font-size: 28px;
  margin: 0 0 0.75rem 0;
  letter-spacing: 0.2px;
  line-height: 1.5;
  font-weight: 500;
}

@media screen and (max-width: 769px) {
  .reset h3,
  .reset .h3 {
    margin: 0 0 0.75rem 0;
    font-size: 24px;
  }
}

@media screen and (max-width: 376px) {
  .reset h3,
  .reset .h3 {
    font-size: 20px !important;
  }
}

.reset h4,
.reset .h4 {
  font-size: 24px;
  line-height: 1.333334;
  margin: 0 0 16px 0;
  font-weight: 500;
}

@media (max-width: 767px) {
  .reset h4,
  .reset .h4 {
    margin: 0 0 0.75rem 0;
    font-size: 20px;
  }
}

@media (max-width: 319px) {
  .reset h4,
  .reset .h4 {
    font-size: 16px;
  }
}

.reset h5,
.reset .h5,
.reset h6,
.reset .h6 {
  font-size: 16px;
  line-height: 1.333334;
  margin: 0 0 0.75rem 0;
  font-weight: 500;
}

.reset main ul {
  padding-left: 0;
  list-style: none;
}

.reset main ul li {
  padding-left: 18px;
  margin-bottom: 12px;
  position: relative;
}

.reset main ul li:last-child {
  margin-bottom: 0;
}

.reset main p a {
  color: #008ecd;
}

.reset main p a:hover {
  color: #008ecd;
  text-decoration: underline;
}

.reset main .list-check {
  margin: 0;
}

.reset main .list-check li {
  padding-left: 28px;
}

.reset main .list-check li::before {
  display: none;
}

.reset main .list-check li i {
  position: absolute;
  left: 0;
  top: 5px;
}

.reset main .list-check li:last-child {
  margin-bottom: 0;
}

.reset main .list-check li a {
  color: #0076C0;
}

.reset main .list-check li a:hover {
  text-decoration: underline;
}

.reset main .list-check.green i:before {
  color: #4CAF50;
}

.reset main ol li {
  margin-bottom: 12px;
}

.reset main ul.list-dots li::before {
  background: #0076C0;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: 8px;
  left: 0;
  content: "";
}

.reset main ul.list-dots li:last-child {
  margin-right: 0;
}

.reset main ul.list-dots li a {
  color: #0076C0;
}

.reset main ul.list-dots li a:hover {
  text-decoration: underline;
}

.reset main ul.list-dots.two li::before {
  background: #EAEDEE;
  top: 8px;
  transform: translate(0, 0);
}

.reset main ul.list-items {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.reset main ul.list-items li {
  padding: 0 0.25rem 0.5rem;
}

.reset main ul.list-items li span {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  background-color: #F6F8F8;
  padding: 8px 12px;
  height: 40px;
}

.reset main ul.list-options {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.reset main ul.list-options li {
  font-size: 14px;
  padding: 8px 12px;
  margin: 0 8px 8px 0;
  padding: 5px 10px;
  background: #F6F8F8;
  color: #231F20;
  font-weight: 600;
}

.reset main .card-text.green p {
  color: #219653;
}

.reset main ul.list-characteristics {
  margin: 0 0 20px 0;
  padding: 0;
}

.reset main ul.list-characteristics li {
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  color: #596369;
}

.reset main ul.list-characteristics li:last-child {
  margin: 0;
}

.reset main ul.list-characteristics li span {
  color: #231F20;
  font-weight: 600;
  margin-right: 5px;
}

.reset main ul.list-characteristics li span.green {
  color: #219653;
}

.reset main ul.collection.white {
  background: #fff;
  box-shadow: 0 0.5rem 0.75rem #ececec;
}

.reset main ul.collection.white li {
  padding: 10px 16px 10px 50px;
}

.reset main ul.collection.white li i {
  left: 10px;
  top: 10px;
}

.reset main ul.collection {
  border: 1px solid #EAEDEE;
  padding: 0;
}

.reset main ul.collection li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #EAEDEE;
}

.reset main ul.collection li:last-child {
  border-bottom: none;
}

.reset main ul.collection li a {
  padding: 8px 16px;
  display: block;
  transition: .5s;
}

.reset main ul.collection li a:hover {
  transition: .5s;
  background: #EAEDEE;
}

.reset main ul.collection li a h5 {
  color: #0076C0;
}

.reset main ul.number li {
  padding-left: 40px;
}

.reset main ul.number i {
  background: #0076C0;
  padding: 0 10px;
  border-radius: 15px;
  display: inline-block;
  color: #fff;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-style: normal;
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
}

.reset main ul.number.green i {
  background: #4CAF50 !important;
}

.reset main ul.number li.blue-color i {
  background: #0076C0 !important;
}

.reset main ul.number li.orange-color i {
  background: #f26631 !important;
}

.reset .table-responsive {
  width: 100%;
  overflow: auto;
}

@media screen and (max-width: 1280px) {
  .reset .table-responsive {
    box-shadow: -6px 0px 10px #ccc inset;
  }
}

.reset .table-responsive table {
  width: 1240px !important;
}

.reset table:not(.table-second) {
  width: 100%;
}

.reset table:not(.table-second) td:first-child {
  font-weight: bold;
}

.reset table:not(.table-second) tr:nth-child(2n+ 1) {
  background-color: #f7f9f9;
}

.reset table:not(.table-second) td, .reset table:not(.table-second) th {
  padding: 16px 8px;
  color: #231F20;
}

.reset table:not(.table-second) th {
  background-color: #0076c0;
  color: #fff;
}

.reset table.table-second {
  border: solid 1px #F6F8F8;
  width: 100%;
}

.reset table.table-second tr td {
  padding: 16px 40px;
}

.reset table.table-second tr td .text {
  display: flex;
  justify-content: space-between;
}

.reset table.table-second tr:nth-child(2n+1) td:nth-child(2n+1) {
  background: #F6F8F8;
}

.reset table.table-second tr:nth-child(2n) td:nth-child(2n) {
  background: #F6F8F8;
}

.reset table.table-third {
  border: solid 1px #F6F8F8;
}

.reset table.table-third thead th {
  background: #F6F8F8;
  color: #596369;
  font-weight: normal;
  padding: 16px 12px;
  font-size: 14px;
  vertical-align: top;
}

.reset table.table-third thead th b {
  color: #231F20;
  margin-bottom: 8px;
  font-size: 16px;
}

.reset table.table-third thead th .icon {
  display: block;
  margin-bottom: 16px;
}

.reset table.table-third tr {
  border-bottom: solid 1px #EAEDEE;
}

.reset table.table-third tr td {
  padding: 16px 12px;
  font-size: 14px;
  vertical-align: top;
  width: 229px;
}

.reset table.table-third tr td b {
  font-size: 16px;
}

.reset table.table-third tr td.first-icon {
  border-right: solid 1px #EAEDEE;
  vertical-align: middle;
  padding: 16px 32px;
  width: auto;
  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: 16px;
}

.reset table.table-third tr td i.icon {
  width: 32px;
  display: inline-block;
}

.reset table.table-third tr:last-child {
  border-bottom: none;
}

.reset table.table-third tr.group1 {
  background: rgba(33, 150, 83, 0.1);
}

.reset table.table-third tr.group2 {
  background: rgba(0, 118, 192, 0.1);
}

.reset select.error + label {
  display: none !important;
}

.reset label.error + .jcf-select {
  border-color: #ff0000;
}

.reset .jcf-select,
.reset select,
.reset .dropdown button {
  position: relative;
  cursor: pointer;
  border: 2px solid #F2F2F2;
  display: flex;
  align-items: center;
  background: #fff;
}

.reset .selected + .jcf-select .jcf-select-text span {
  color: #000;
}

.reset .jcf-select.jcf-drop-active .jcf-select-text,
.reset select.jcf-drop-active .jcf-select-text {
  background-color: #ffffff;
}

.reset .jcf-select.jcf-drop-active .jcf-select-opener,
.reset select.jcf-drop-active .jcf-select-opener {
  background-color: #ffffff;
}

.reset .jcf-select.jcf-drop-active .jcf-select-opener:after,
.reset select.jcf-drop-active .jcf-select-opener:after {
  margin-top: 12px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.reset select.jcf-disabled,
.reset select[disabled] + .jcf-select {
  background: #F3F3F3;
  border: 1px solid #D7D7D7;
  cursor: default;
}

.reset select.jcf-disabled .jcf-select-text span,
.reset select[disabled] + .jcf-select .jcf-select-text span {
  color: #B5B5B5 !important;
}

.reset select.jcf-disabled .jcf-select-opener:after,
.reset select[disabled] + .jcf-select .jcf-select-opener:after {
  border-right: 2px solid #7D7D7D !important;
  border-top: 2px solid #7D7D7D !important;
}

.reset .jcf-select .jcf-select-opener,
.reset select .jcf-select-opener {
  width: 46px;
  height: 40px;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.reset .jcf-select .jcf-select-opener:after,
.reset select .jcf-select-opener:after {
  display: block;
  border-right: 1px solid #231F20;
  border-top: 1px solid #231F20;
  content: "";
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.reset .jcf-select .jcf-select-text,
.reset select .jcf-select-text {
  color: #828282;
  font-size: 16px;
  line-height: 40px;
  width: 100%;
  padding: 0 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: transparent;
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.reset .jcf-select-drop {
  position: absolute;
  top: calc(100% + 2px);
  left: -1px;
  z-index: 3;
  border: 2px solid #F2F2F2;
  border-top: none;
  border-radius: 2px;
  max-height: 300px;
  overflow: hidden;
}

.reset .jcf-select-drop-content {
  background-color: #ffffff;
  -webkit-box-shadow: 0 8px 12px rgba(2, 21, 51, 0.1);
  box-shadow: 0 8px 12px rgba(2, 21, 51, 0.1);
}

.reset .jcf-select-drop-content .jcf-list-content {
  vertical-align: top;
  display: inline-block;
  overflow: auto;
  width: 100%;
  max-height: 300px !important;
}

.reset .jcf-select-drop-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.reset .jcf-select-drop-content li {
  margin: 0 !important;
  padding: 0 !important;
}

.reset .jcf-select-drop-content li::before {
  display: none !important;
}

.reset .jcf-select-drop-content .jcf-option {
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #231F20;
  background-color: #ffffff;
  display: block;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.reset .jcf-select-drop-content .jcf-option:hover {
  background-color: #F4F6F9;
}

.reset .jcf-select-drop-content .jcf-option.jcf-selected {
  background-color: #E9EBEE;
}

.reset .jcf-select-drop-content .jcf-option.jcf-selected:hover {
  background-color: #F6F8F8;
}

.reset .card-line {
  padding: 0;
}

.reset .card-line.no-bg {
  box-shadow: none;
  background: #F6F8F8;
  border: none;
}

.reset .card-line.no-bg .inner-content {
  padding: 0;
}

.reset .card-line.no-style {
  background: none;
  box-shadow: none;
  border: none;
}

.reset .card-line.no-style .inner-content {
  padding: 0;
}

.reset .card-line .inner-img {
  height: 112px;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.reset .card-line .inner-img.big {
  height: 200px;
  width: 100%;
  background: none;
  background-size: contain;
}

.reset .card-line .inner-img.big img {
  max-height: 200px;
  /* width: 100%; */
  margin: 0 auto;
  display: block;
}

.reset .card-line .inner-img.cover {
  background-size: cover;
}

.reset .card-line .inner-img + .inner-content {
  padding-top: 24px;
  height: auto;
}

.reset .card-line .inner-img + .inner-content h3 {
  font-size: 24px;
  line-height: 36px;
}

.reset .card-line .inner-img.cover {
  background-size: cover;
}

.reset .card-line .quantity {
  position: absolute;
  width: 128px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  right: 0;
}

.reset .card-line .quantity .quantity-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reset .card-line .quantity span.number {
  display: block;
  text-align: center;
  font-size: 28px;
  margin-bottom: 10px;
}

.reset .card-line .quantity.bottom {
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
}

.reset .card-line .quantity.bottom svg {
  margin-right: 5px;
}

.reset .card-line.height {
  height: auto;
}

.reset .card-line .inner-content.grey-light {
  background: #F6F8F8;
}

.reset .card-line .inner-content.grey-light .inner-img {
  height: 290px;
}

@media screen and (max-width: 920px) {
  .reset .card-line .inner-content.grey-light .inner-img {
    height: 120px;
  }
}

.reset .card-line .inner-content {
  padding: 40px;
}

.reset .card-line .inner-content.small {
  padding: 40px 24px;
}

.reset .card-line .inner-content.small-24 {
  padding: 24px;
}

.reset .card-line .inner-content .card-title,
.reset .card-line .inner-content .card-images,
.reset .card-line .inner-content .footer-card {
  width: 100%;
}

@media screen and (max-width: 376px) {
  .reset .card-line .inner-content {
    padding: 24px;
  }
}

.reset .card-line .inner-content .card-title {
  margin-bottom: 8px;
}

.reset .card-line .inner-content .card-title h3, .reset .card-line .inner-content .card-title h4 {
  margin: 0;
}

.reset .card-line .inner-content .card-title h4 {
  min-height: 65px;
}

.reset .card-line .inner-content .card-text p {
  font-size: 14px;
}

@media screen and (max-width: 376px) {
  .reset .card-line .inner-content .card-text p {
    font-size: 12px !important;
  }
}

.reset .card-line .inner-content .card-text.big20 p {
  font-size: 20px;
}

.reset .card-line .inner-content .card-text.blue p {
  color: #0076C0;
}

.reset .card-line .inner-content .footer-card {
  margin-top: 24px;
}

.reset .card-line .inner-content p:last-child {
  margin: 0;
}

.reset .row > .small.col-lg-3 {
  padding: 0 8px;
}

.reset .row > .small .inner-content {
  padding: 32px 24px 40px 24px;
}

.reset .row > .small .inner-content .title {
  font-size: 20px;
  line-height: 30px;
}

.reset .card-line.horizontale .inner-img {
  min-width: 310px;
  height: auto;
}

.reset .card-line.horizontale.small .inner-img {
  min-width: 254px;
}

.reset .card-line.horizontale.small-2 .inner-img {
  width: 180px;
  min-width: 180px;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .reset .card-line.horizontale.small-2 .inner-img {
    width: 100%;
    min-width: 0;
    height: 200px;
  }
}

.reset .card-line.horizontale.small-2 .inner-content {
  padding: 16px 16px 16px 32px;
}

.reset .card-line.horizontale .inner-content {
  padding: 40px;
}

@media screen and (max-width: 376px) {
  .reset .card-line.horizontale .inner-content {
    padding: 24px;
  }
}

.reset .card-line.horizontale .inner-content .card-title {
  margin-bottom: 8px;
}

@media screen and (max-width: 720px) {
  .reset .card-line.horizontale {
    display: block !important;
  }
  .reset .card-line.horizontale .inner-img {
    min-width: 0;
    height: 200px;
  }
}

.reset .text-img-content .row {
  margin-bottom: 64px;
  align-items: center;
}

.reset .text-img-content .row .img-content {
  height: 304px;
}

.reset .slick-dots .slick-active button {
  background-color: #0076c0;
  border-color: #0076c0;
}

.reset .slick-dots button {
  border: 1px solid #fff;
}

.reset .btn-default.secondary {
  color: #0076C0;
  border: solid 1px #0076C0;
  background: none;
  transition: .5s;
}

.reset .btn-default.secondary:hover {
  background: #0076C0;
  transition: .5s;
  color: #fff;
}

.reset .page-text.justify-center.big {
  flex-direction: column;
}

.reset .page-text.justify-center.big p {
  font-size: 24px;
  color: #231F20;
}

@media screen and (max-width: 768px) {
  .reset .row.tabs-area {
    width: 100%;
  }
}

.reset .slider-block {
  margin-bottom: 40px;
}

.reset .slider-block .slider-block-content .slider-item-img {
  margin-bottom: 32px;
}

.reset .slider-block .slick-arrow {
  width: 48px;
  height: 48px;
  border: solid 1px #0076C0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  z-index: 99;
  transition: .5s;
}

.reset .slider-block .slick-arrow:hover {
  transition: .5s;
  background: #0076C0;
}

.reset .slider-block .slick-arrow:hover path {
  stroke: #fff;
}

.reset .slider-block .slider-block-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -35px;
}

@media screen and (max-width: 640px) {
  .reset .slider-block .slider-block-action {
    justify-content: center;
    margin-top: 16px;
  }
}

.reset .slider-block .slider-block-info {
  margin: 0 16px;
  color: #231F20;
  font-size: 16px;
}

.reset .card-bottom span.video {
  color: #596369;
  padding-left: 8px;
  margin-left: 8px;
  border-left: solid 1px #596369;
}

.reset .section-video .inner-img {
  border-bottom: solid 1px #EAEDEE;
}

.reset .section-video h3 {
  font-size: 24px;
}

.reset .section-video .tabs-video h3 {
  font-size: 20px;
}

.reset .section-video .tabs-content.playlists .row > div {
  display: none;
}

.reset .section-video .tabs-content.playlists .row > div.active {
  display: block;
}

.reset .section-video .tabs-content.playlists .show-more {
  cursor: pointer;
}

.reset .video-list h3 {
  font-size: 20px !important;
}

.reset .section-probes .card-line {
  background: none;
  box-shadow: none;
  border: none;
}

.reset .section-probes .card-line .inner-content .card-title h4 {
  min-height: 0;
}

.reset .section-probes .card-line .inner-img {
  min-width: 160px;
  height: 160px;
  background-color: #f7f9f9;
}

.reset .section-probes .card-line .card-title h4 {
  font-size: 20px;
  line-height: 30px;
  color: #0076C0;
}

.reset .section-probes .card-line .card-title h4 a:hover {
  text-decoration: underline;
}

.reset .section-probes .card-line .card-link ul {
  display: block !important;
}

.reset .section-probes .card-line .card-link ul li {
  margin: 0 0 8px 0 !important;
}

.reset .section-probes .card-line .card-link ul a {
  text-decoration: none !important;
}

.reset .section-probes .card-line .card-link ul a:hover {
  text-decoration: underline !important;
}

@media (max-width: 768px) {
  .reset .section-probes .card-line {
    justify-content: space-between;
    display: flex !important;
  }
  .reset .section-probes .card-line .inner-content {
    padding: 5px !important;
  }
  .reset .section-probes .card-line .inner-img {
    min-width: 100px;
    height: 100px;
  }
}

.reset .modal {
  padding: 0 16px !important;
}

.reset .modal .card-line .inner-img {
  height: 200px !important;
  width: 100% !important;
}

.reset .close {
  position: absolute;
  right: -45px;
}

@media (max-width: 1100px) {
  .reset .close {
    right: 0;
    top: -45px;
  }
}

.reset .modal-backdrop {
  opacity: .87;
  background: #1e1e1e;
}

.reset .modal-dialog {
  margin-top: 64px;
  max-width: 1000px;
}

.reset .modal-dialog iframe {
  width: 100%;
  height: 540px;
}

.reset .modal-body {
  padding: 24px 40px 40px 40px;
}

@media (max-width: 1100px) {
  .reset .modal-body {
    padding: 24px 16px 16px 16px;
  }
}

.reset .modal-body-title {
  padding-bottom: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #EAEDEE;
}

.reset .modal-body-title h3 {
  margin: 0;
}

.reset .modal-body-title .share-block {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  color: #596369;
  font-size: 16px;
  padding: 8px 10px;
  border-radius: 3px;
  transition: .5s;
}

.reset .modal-body-title .share-block:hover {
  transition: .5s;
  background: #F6F8F8;
}

.reset .modal-body-title .share-block.open {
  background: #F6F8F8;
  transition: .5s;
}

.reset .modal-body-title .share-block.open .share-block-left {
  border-right: solid 1px #596369;
  padding-right: 16px;
}

.reset .modal-body-title .share-block.open .share-block-right {
  padding-left: 16px;
  width: auto;
  overflow: inherit;
  visibility: visible;
  transition: .5s;
}

.reset .modal-body-title .share-block.open .share-block-right ul {
  flex-wrap: wrap;
}

.reset .modal-body-title .share-block-left {
  cursor: pointer;
  border-right: none;
}

.reset .modal-body-title .share-block-left i {
  margin-left: 10px;
}

.reset .modal-body-title .share-block-right {
  width: 0;
  overflow: hidden;
  visibility: hidden;
  transition: .5s;
}

.reset .modal-body-title .share-block-right ul {
  display: flex;
  margin: 0;
}

.reset .modal-body-title .share-block-right ul li {
  padding: 0;
  margin: 0 16px 0 0;
}

.reset .modal-body-title .share-block-right ul li:last-child {
  margin: 0;
}

.reset .modal-body-title .share-block-right ul li:last-child svg {
  stroke: #596369;
}

.reset .modal-body-title .share-block-right ul li:last-child:hover svg path {
  stroke: #231F20;
}

.reset .modal-body-title .share-block-right ul li:not(:last-child) svg path {
  fill: #596369;
}

.reset .modal-body-title .share-block-right ul li:not(:last-child):hover svg path {
  fill: #231F20;
}

.reset main ul.technical-docs-ul {
  border: none;
}

.reset main ul.technical-docs-ul li {
  border: none;
  margin-bottom: 24px;
}

.reset main ul.technical-docs-ul li a {
  padding: 0;
  color: #596369;
}

.reset main ul.technical-docs-ul li a:hover {
  background: none;
}

.reset main ul.technical-docs-ul li a:hover h5 {
  text-decoration: underline;
}

.reset main ul.technical-docs-ul li h5 {
  margin-bottom: 8px;
  font-weight: 500;
}

.reset .card-line {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reset .card-line .inner-visible {
  margin-top: auto;
}

.reset .card-line.horizontale {
  flex-direction: row;
}

.reset .inner-visible.blue {
  font-weight: 400;
  font-size: 14px;
}

.reset .section-serial-data .inner-img img {
  width: 100%;
}

.reset .section-serial-data .inner-content p {
  font-size: 14px;
  color: #596369;
}

.reset .section-serial-data .card-title h3 {
  letter-spacing: 0;
}

.reset .section-serial-data [class^="col"] {
  padding: 0 0.5rem;
}

.reset span.prompt,
.reset a.prompt {
  color: #BDBDBD;
  font-size: 12px;
  margin-top: 5px;
  display: inline-block;
}

.reset a.prompt {
  color: #0076C0;
}

.reset a.prompt:hover {
  text-decoration: underline;
}

.reset .explore-content.small {
  max-width: 392px;
}
