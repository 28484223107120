main {
	@extend %body;
	@extend %common;
}

.slick-track {
	display: flex;

	&:before,
	&:after {
		content: none;
	}
}

.slick-slide {
	vertical-align: top;
	float: none;
	outline: none;
}

.slick-slider {
	user-select: text;

	* {
		user-select: text;
	}
}

.slick-list.draggable {
	user-select: none;
}

/* Tabs styles */

.tab-hidden-md {
	@include media('≥tablet') {
		position: absolute !important;
		left: -9999px !important;
		top: -9999px !important;
		display: block !important;
		width: 100% !important;
	}
}

.bg-retina {
	position: relative;
	background-size: cover;
	background-position: 50% 50%;
}

.large-link {
	display: inline-block;
	vertical-align: top;
	padding: 0.5rem 0.5rem 0.5rem 0;
}

.font-weight-medium {
	font-weight: $font-weight-medium !important;
}

.font-weight-bold {
	font-weight: $font-weight-bold !important;
}

.section-heading {
	font-size: $font-size-sm;
	line-height: 1.5;
	margin-bottom: 2rem;
	color: #222;

	@include media('≥phone') {
		font-size: $font-size-base;
		line-height: 1.5;
	}

	@include media('≥widescreen') {
		font-size: $font-size-lg;
		line-height: 1.555555;
		margin-bottom: 2.5rem;
	}

	img {
		width: auto;
	}

	p {
		margin-bottom: 0;
	}

	span.blue {
		margin-right: 10px;
		font-weight: inherit;
		font-size: inherit;
	}
}

.card-body {
	padding: 1.5rem 1rem 2rem;

	@include media('≥phone') {
		padding: 2rem 2.5rem 2.5rem 2rem;
	}

	@include media('≥tablet') {
		padding: 2rem 1.5rem 2.5rem;
	}

	@include media('≥desktop') {
		padding: 2rem 3rem 3rem;
	}

	@include media('≥widescreen') {
		padding: 2rem 4rem 3.5rem;
	}

	>a+a {
		padding-top: 0;
	}
}

.breadcrumb-item {
	color: $blue;
	font-weight: 500;

	@include media('≥tablet') {
		font-size: 1rem;
	}

	+.breadcrumb-item:before {
		@extend %icomoon;
		transform: rotate(-90deg);
		font-size: 0.25rem;
		display: inline-block;
		vertical-align: middle;
	}
}

.jumbotron {
	padding: $jumbotron-padding * 2 0;
	margin-bottom: 0;
	background-color: $jumbotron-bg;
	border-radius: 0;
	color: $white;
	background-image: linear-gradient(to bottom, #056eb0 0%, #056dac 15%, #046aa8 21%, #0969a6 31%, #07649d 54%, #0c629b 63%, #0b6298 65%, #0c5f95 80%, #0c5f91 82%, #0e5c8d 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	background-size: cover;
	background-repeat: no-repeat;

	@include media('≥tablet') {
		background-image: linear-gradient(to left, #056eb0 0%, #056dac 15%, #046aa8 21%, #0969a6 31%, #07649d 54%, #0c629b 63%, #0b6298 65%, #0c5f95 80%, #0c5f91 82%, #0e5c8d 100%);
	}

	@include media('≥desktop') {
		padding: $jumbotron-padding *2.5 0;
		background-image: linear-gradient(to right, #0c5f94 0%, #0076C0 76%, #0076C0 100%);
	}

	.title {
		@include media('≥phone') {
			line-height: 2.75rem;
		}

		@include media('≥tablet') {
			margin-bottom: 1.25rem;
		}

		@include media('≥widescreen') {
			line-height: 3.375rem;
			margin-bottom: 1.75rem;
		}
	}
}

// section-analysis
.box-item {
	display: block;
	padding-top: 44.14%;
	position: relative;
	overflow: hidden;

	.text {
		padding: 1.5rem 1rem;
		background-image: linear-gradient(to top, #f000 75%, #0064a233 100%, #007096);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		opacity: 1;
		display: flex;
		flex-direction: column;
		transition: background-color 0.2s ease-in-out;

		@include media('≥phone') {
			padding: 1.25rem 1rem;
		}

		@include media('≥tablet') {
			padding: 2rem 1.5rem;
		}

		.title {
			@include media('≥desktop') {
				margin-bottom: 1rem;
			}

			+span {
				@include media('≥desktop') {
					display: block;
					font-weight: 500;
				}
			}
		}
	}

	.title {
		display: inline-block;
		vertical-align: top;
		font-size: 1rem;
		line-height: 1.5;
		text-shadow: 2px 2px 4px $black;
		margin-bottom: 0;
		font-weight: 500;
		position: relative;
		z-index: 2;

		@include media('≥tablet') {
			font-size: 1.5rem;
			line-height: 1.3333333;
		}

		@include media('≥desktop') {
			margin-bottom: 0.75rem;
		}

		+span {
			display: none;

			@include media('≥desktop') {
				display: block;
			}
		}
	}
}

.justify-space {
	width: 100%;
	justify-content: space-between;
}

.section-analysis {
	.section-heading {
		text-align: center;

		@include media('≥widescreen') {
			text-align: left;
			font-size: 18px;
			line-height: 28px;
		}
	}

	[class^="col"]:not(:first-child):not(:last-child) {
		padding-bottom: 1rem;

		@include media('≥phone') {
			padding-bottom: 30px;
		}

		&:nth-last-child(2) {
			@include media('≥phone') {
				padding-bottom: 0;
			}
		}

		&:nth-last-child(3) {
			@include media('≥desktop') {
				padding-bottom: 0;
			}
		}

		&:nth-last-child(4) {
			@include media('≥widescreen') {
				padding-bottom: 0;
			}
		}
	}

	.box-item {
		min-height: 128px;
		color: $white;

		@include media('≥phone') {
			min-height: 160px;
		}

		@include media('≥tablet') {
			min-height: 220px;
		}

		@include media('≥desktop') {
			min-height: 340px;
		}

		&:hover {
			.text {
				@include media('≥desktop') {
					background-color: rgba($black, 0.6);
				}
			}

			.block-hover {
				@include media('≥desktop') {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		.block-hover {
			display: none;

			@include media('≥desktop') {
				display: block;
				padding: 2rem 1.5rem;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 100%;
				top: 0;
				z-index: 1;
				opacity: 0;
				transform: translateY(30%);
				line-height: 1.375;
				transition: opacity 0.12s ease-in-out 0.08s, transform 0.12s ease-in-out 0.08s;
			}

			.title {
				visibility: hidden;
			}
		}
	}
}

// section-events
.section-events {
	.link-all {
		display: inline-block;
		vertical-align: top;
	}

	.event-item {
		display: block;
		padding: 1.5rem 1rem 1rem;
		line-height: 1.5;
		position: static;
		background-color: $white;
		border: 1px solid #d6d6d6;

		@include media('≥phone') {
			padding: 1.5rem 1rem;
		}

		@include media('≥tablet') {
			padding: 1.5rem;
		}

		@include media('≥widescreen') {
			padding: 1.5rem 2rem;
		}

		.title {
			color: $blue;
			font-weight: 500;
			line-height: inherit;

			@include media('≥phone') {
				display: block;
				width: 100%;
			}


			@include media('≥tablet') {
				font-size: 1.25rem;
				line-height: 1.6;
				margin-bottom: 0;
			}
		}

		&:hover {
			h5 {
				text-decoration: underline;
			}
		}
	}

	.type,
	time {
		display: inline-block;
		vertical-align: top;
	}

	.type,
	.title {
		margin-bottom: 0.5rem;
	}

	.type {
		position: relative;
		padding-left: 1.5rem;

		@include media('≥phone') {
			margin-right: auto;
			margin-bottom: 0.75rem;
		}

		@include media('≥tablet') {
			margin-bottom: 0.5rem;
		}

		&-trade-show:before {
			background-color: $pink;
		}

		&-webinar:before {
			background-color: $blue-light;
		}

		&-training:before {
			background-color: $green;
		}

		&-seminar:before {
			background-color: $orange;
		}

		&-presentation:before {
			background-color: $purple;
		}

		&:before {
			@include size(1rem);
			content: '';
			position: absolute;
			left: 0;
			top: calc(50% - 1px);
			transform: translateY(-50%);
		}
	}

	.about {
		@include media('≥tablet') {
			width: 200px;
			text-align: right;
		}
	}

	.location {
		margin-bottom: 0.5rem;
		margin-left: auto;
	}
}

.content-events {
	h5 {
		margin-bottom: 0;

		@include media('≥tablet') {
			display: block;
			width: 100%;
		}
	}

	>.row {
		>[class^="col"]+[class^="col"] {
			padding-top: 1rem;
		}
	}
}

.flex-shrink-0 {
	flex-shrink: 0;
}

.flex-grow-0 {
	flex-grow: 0;
}

.block-card {
	&:last-child {
		position: relative;
	}

	.btn-arrow {
		text-transform: uppercase;
		background-color: $blue;
		color: $white;
	}

	.hold-form {
		background-color: #546e7a;
		color: $white;
		position: relative;

		p {
			margin-bottom: 0.5rem;

			&.required {
				label:after {
					content: '*';
				}
			}
		}
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		min-height: 100%;
	}
}

.box-form {
	input:not([type="submit"]) {
		width: 100%;
		height: 30px;
		line-height: 30px;
		padding: 0 0.25rem;
	}
}

.section-padding {
	padding: 2rem 0;

	@include media('≥phone') {
		padding: 3rem 0;
	}

	@include media('≥widescreen') {
		padding: 5rem 0;
	}

}

.section-margin-bottom {
	margin-bottom: 2rem;

	@include media('≥phone') {
		margin-bottom: 3rem;
	}

	@include media('≥widescreen') {
		margin-bottom: 5rem;
	}
}

#testing {
	.col-content-item {
		.visual {
			overflow: hidden;
			height: auto;

			@include media('≥tablet') {
				height: 135px;
			}

			@include media('≥desktop') {
				height: 119px;
			}

			@include media('≥widescreen') {
				height: 143px;
			}
		}
	}
}

.col-content-item {
	position: relative;
	padding-bottom: 1rem;

	@include media('≥desktop') {
		padding-bottom: 0;
	}

	.visual {
		//text-align: center;
		overflow: hidden;

		@include media('≥tablet') {
			height: 207px;
		}

		@include media('≥desktop') {
			height: 182px;
		}

		@include media('≥widescreen') {
			height: 219px;
		}
	}

	img {
		//width: 100%;
		max-height: 100%;
	}

	.title {
		font-size: 1.5rem;
		line-height: 2rem;
		font-weight: 500;
		margin-bottom: 0.5rem;
	}

	p {
		margin-bottom: 0.5rem;
	}

	strong {
		margin-bottom: 0.75rem;
	}
}

.section-gray {
	background-color: $gray-100;
}

.list-dots {
	@extend %listreset;

	@include media('≥tablet') {
		margin-bottom: 0;
	}

	@include media('≥desktop') {
		margin-bottom: 0.5rem;
	}

	li {
		position: relative;
		line-height: 1.5;
		padding-left: 1rem;
		margin-bottom: 0.25rem;

		&:before {
			@include size(0.375rem);
			content: '';
			display: block;
			color: inherit;
			position: absolute;
			left: 0;
			top: 0.5rem;
			background-color: $blue;
		}
	}

	a {
		color: $blue;
	}

	&.green {
		li:before {
			background-color: #4CAF50 !important;
		}
	}
}

#videos {
	.content-wrap {
		margin-bottom: 2rem;

		@include media('≥desktop') {
			margin-bottom: 2.5rem;
		}

		.title {
			font-size: $font-size-base;
			line-height: 1.5rem;
			font-weight: 500;

			@include media('≥tablet') {
				font-size: $font-size-base;
			}

			@include media('≥desktop') {
				font-size: $font-size-lg;
				line-height: 1.5;
			}

			@include media('≥widescreen') {
				font-size: 1.25rem;
			}
		}
	}

	.visual {
		background-color: $gray-dark;
		padding-top: 0;
		margin-bottom: 0.625rem;
		position: relative;

		@include media('≥tablet') {
			margin-bottom: 0.875rem;
		}

		@include media('≥desktop') {
			margin-bottom: 0.75rem;
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 0;
			height: 0;
			mix-blend-mode: multiply;
			transition: all 80ms ease-out 0s;
			background-color: rgba(0, 118, 192, 0.5);
		}

		>span {
			height: 100% !important;
			padding: 0 !important;
			position: absolute !important;
			top: 0 !important;
			left: 0 !important;
		}
	}

	a {
		display: block;

		&:hover {
			//height: 132.594px;
			//position: absolute;
			//width: 255px;
			//z-index: 1;

			//left: 0px; top: 0px;
			//mix-blend-mode: multiply;

			.visual {
				position: relative;

				&:before {
					width: 100%;
					height: 100%;
				}
			}

			.title {
				text-decoration: underline;
			}
		}
	}

	.count {
		font-size: $font-size-sm;

		@include media('≥desktop') {
			font-size: $font-size-base;
		}
	}
}

[id^='content'] {
	.inner-top {
		margin-bottom: 2rem;

		@include media('≥tablet') {
			margin-bottom: 2.75rem;
		}

		@include media('≥desktop') {
			margin-bottom: 4.5rem;
		}

		@include media('≥widescreen') {
			margin-bottom: 5.5rem;
		}
	}

	.image-holder {
		margin-bottom: 1.5rem;

		@include media('≥phone') {
			margin-bottom: 2rem;
		}

		@include media('≥tablet') {
			margin-bottom: 0;
		}

	}

	.title {
		font-size: 1.5rem;
		line-height: 1.38;
		margin-bottom: 1rem;

		@include media('≥tablet') {
			font-size: 2rem;
			margin-bottom: 18px;
		}

		@include media('≥desktop') {
			margin-bottom: 1.25rem;
		}

		@include media('≥widescreen') {
			font-size: 3rem;
			line-height: 1.25;
			margin-bottom: 1.5rem;
		}
	}

	.text-link {
		font-weight: 500;
		cursor: pointer;
	}

	.card-body {
		border-top: 1px solid $gray-400;

		@include media('≥desktop') {
			border-top: 0;
		}
	}

	.title-card {
		font-weight: 500;
		font-size: 1.25rem;
		line-height: 1.33;
		margin-bottom: 1rem;

		@include media('≥tablet') {
			font-size: 1.5rem;
		}

		@include media('≥widescreen') {
			font-size: 2rem;
		}
	}
}

.section-anchors {
	@include animate(background-color);
	background-color: $white;
	z-index: 1;
	border-top: 1px solid #d6d6d6;
	border-bottom: 1px solid #d6d6d6;
	position: relative;
	color: $blue;

	@include media('≥desktop') {
		border-top: 0;
	}

	.fixed-position & {
		@include media('≥desktop') {
			background-color: $white;
			border-bottom: 1px solid #d6d6d6;
			color: $blue;
		}

		.slide {
			@include media('≥desktop') {
				border-bottom: 0;
			}
		}

		.anchor-active {
			color: $gray-dark;
			opacity: 1;
		}

		a:hover {
			color: $gray-dark;
		}
	}

	&.open {
		.opener i {
			transform: rotate3d(1, 0, 0, 180deg);
		}

		.slide {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background-color: $white;
			border-bottom: 1px solid #d6d6d6;

			@include media('≥desktop') {
				background-color: transparent;
			}
		}
	}

	a.opener {
		display: flex;
		align-items: center;
		font-size: 1.25rem;
		line-height: 1.5rem;
		font-weight: 500;
		padding-top: 12px;
		padding-bottom: 12px;
		cursor: pointer;
		background-color: $white;
		color: $gray-dark;

		&:hover {
			color: $gray-dark;
		}

		@include media('≥phone') {
			font-size: 24px;
		}

		i {
			display: inline-block;
			vertical-align: top;
			margin-left: auto;
			font-size: 0.5rem;
		}
	}

	.slide {
		transform: translateY(1px);
		opacity: 1;
		visibility: visible;
		transition: opacity 250ms ease 250ms, visibility 0s linear 500ms, transform 0s linear 500ms;

		&.js-slide-hidden {
			display: block !important;
			left: auto !important;
			right: auto !important;
			transform: translateY(-100%);
			opacity: 0;
			visibility: hidden;
		}
	}

	ul {
		@extend %listreset;
		color: inherit;
		font-size: 1rem;
		line-height: 1.5;
		padding: 8px 0;

		@include media('≥desktop') {
			display: flex;
			font-size: 1rem;
			line-height: 1;
			margin: 0 -2rem;
		}
	}

	li {
		@include media('≥desktop') {
			padding: 0 1vw;
		}

		&:not(:last-child) {
			margin-bottom: 1rem;

			@include media('≥desktop') {
				margin-bottom: 0;
			}
		}

		&:first-child {
			@include media('≥desktop') {
				padding-left: 1rem;
			}
		}
	}

	.anchor-active {
		color: $gray-dark;
	}

	a {
		@include animate(color);
		display: inline-block;
		vertical-align: top;
		padding: 8px 0;

		&:hover {
			@include media('≥desktop') {
				color: $gray-dark;
			}
		}
	}
}

.section-overview {
	padding-top: 32px;
	padding-bottom: 64px;

	@include media('≥phone') {
		padding-bottom: 80px;
	}

	@include media('≥tablet') {
		padding-top: 56px;
	}

	@include media('≥widescreen') {
		padding-bottom: 104px;
	}
}

.section-streams {
	.content-wrap {
		h4 {
			font-weight: 500;
		}
	}

	.item-content {

		@include media('≥tablet') {
			display: flex;
			margin: 0 -15px;
		}

		@include media('≥desktop') {
			display: block;
			margin: 0;
		}

		.visual,
		.text {
			@include media('≥tablet') {
				width: 50%;
				padding: 0 15px;
			}

			@include media('≥desktop') {
				width: auto;
				padding: 0;
			}
		}
	}
}

h2 {
	.visual {
		display: flex;
		width: 60px;
		align-items: center;
		margin-left: 0.5rem;

		@include media('≥desktop') {
			margin-left: 1rem;
		}

		@include media('≥widescreen') {
			width: 100px;
		}

		* {
			display: flex;
			align-items: center;
		}
	}
}

.slide-gen {
	padding: 1.5rem 0 0.5rem;

	@include media('≥phone') {
		padding: 1rem 0;
	}

	@include media('≥tablet') {
		display: none;
	}
}

.list-gen {
	@extend %listreset;
	padding-top: 16px;

	@include media('≥tablet') {
		display: flex;
		margin: 0 -9px 40px;
	}

	@include media('≥desktop') {
		margin: 0 -15px 44px;
	}
}

.gen-item {
	margin-bottom: 1rem;

	@include media('≥tablet') {
		padding: 0 9px;
		width: 25%;
		margin-bottom: 0;
	}

	@include media('≥desktop') {
		padding: 0 15px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.active {
		.opener-gen {
			@extend %active-tab;

			@include media('≥tablet') {
				background-color: #e3eaee;
				color: $blue;
				font-weight: 400;
			}

			&:hover {
				@include media('≥tablet') {
					background-color: $blue;
					color: $white;
					font-weight: 500;
				}
			}

			&:after {
				transform: translateY(-50%) rotate(180deg);

				@include media('≥tablet') {
					transform: translateY(-50%);
				}
			}
		}
	}

	&.active-tab {
		.opener-gen {
			@include media('≥tablet') {
				background-color: $blue;
				color: $white;
				font-weight: 500;

				&:after {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
	}

	>a {
		@include animate(color background-color);
		display: block;
		padding: 10px;
		color: $blue;
		background-color: #e3eaee;
		position: relative;
		text-align: center;

		@include media('≥tablet') {
			padding: 0.5rem;
		}

		&:hover {
			@extend %active-tab;
		}

		&:after {
			@extend %icomoon;
			content: "\e907";
			font-size: 7px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 1rem;

			@include media('≥tablet') {
				content: none;
			}
		}

		.speed {
			font-size: 0.875rem;
			line-height: 1.25rem;
			font-weight: 400;
		}
	}
}

.box-pci-generations {
	.tab-holder {
		display: none;

		@include media('≥tablet') {
			display: block;
		}

		>* {
			@include animate(opacity, 0.6s);
			opacity: 1;
		}

		.product-card {
			@include media('≥widescreen') {
				flex-direction: row;
			}
		}
	}

	.tab-hidden-md {
		opacity: 0;
	}
}

.product-card {
	border: solid 1px #d6d6d6;
	background-color: $white;
	min-height: 124px;

	@include media('≥widescreen') {
		display: flex;
		min-height: 144px;
	}

	&:hover {
		.title {
			text-decoration: underline;
		}
	}

	.main {
		width: 100%;

		p {
			margin-bottom: 0;
		}
	}

	ul {
		@extend %listreset;

		li {
			position: relative;
			padding-left: 8px;

			&:before {
				@include size(4px);
				content: '';
				display: block;
				border-radius: 100%;
				background-color: $body-color;
				position: absolute;
				left: 0;
				top: 9px;
			}
		}
	}

	.content {
		padding: 1.5rem 1rem;
		background-color: $white;
		line-height: 1.25;

		@include media('≥phone') {
			padding: 1.5rem;
		}

		@include media('≥widescreen') {
			display: flex;
			align-items: center;
			width: 61.94%;
			padding: 1.5rem 2rem;
			line-height: 1.5;
		}
	}

	.visual {
		@include media('≥widescreen') {
			flex-basis: 144px;
		}
	}
}

.list-product-cards {
	@extend %listreset;
	padding-top: 0.5rem;
	display: flex;
	flex-direction: column;

	@include media('≥desktop') {
		padding-top: 0;
	}

	>li:not(:last-child) {
		margin-bottom: 1rem;

		@include media('≥tablet') {
			margin-bottom: 1.5rem;
		}

		@include media('≥desktop') {
			margin-bottom: 1rem;
		}
	}
}

.header-product {
	background-color: $pale-gray;
	display: flex;
	align-items: center;
	color: $gray;

	@include media('≥widescreen') {
		width: 38.06%;
	}

	.visual {
		width: 90px;
		flex-shrink: 0;
		margin: 0;
		padding-top: 90px;
		background-position: 50% 50%;
		background-size: contain;
		background-repeat: no-repeat;


		@include media('≥phone') {
			width: 106px;
			padding-left: 1rem;
		}

		@include media('≥widescreen') {
			width: 144px;
			padding-top: 30%;
			padding-left: 0;
		}

		img {
			max-height: 100%;
		}
	}

	.text {
		color: #666;
		padding: 19px 0.5rem;
		flex-grow: 1;

		@include media('≥phone') {
			padding: 0.9375rem 1.5rem;
		}

		@include media('≥tablet') {
			padding: 0.9375rem 0 0.9375rem 1.5rem;
		}


		@include media('≥widescreen') {
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: #fff;
			height: 100%;
			flex-grow: 1;
			padding: 0.9375rem 0 0.9375rem 2rem;
		}
	}

	.title {
		color: $blue;
		font-size: 18px;
		line-height: 1.5rem;
		font-weight: 500;
		margin-bottom: 0.25rem;

		@include media('≥phone') {
			font-size: 1.25rem;
			line-height: 2rem;
		}
	}
}

#overview {
	.jumbotron {
		padding-top: 5rem;

		@include media('≥widescreen') {
			padding-top: 6.5rem;
		}
	}
}

.section-overview-top {
	padding: 2rem 0;

	@include media('≥phone') {
		background-position: 80% 50%;
	}

	@include media('≥tablet') {
		padding: 3rem 0;
	}

	@include media('≥desktop') {
		background-position: 70% 50%;
	}

	@include media('≥widescreen') {
		background-position: 50% 50%;
	}

	.section-heading {
		font-size: 18px;
		line-height: 1.3333;

		@include media('≥phone') {
			font-size: 1.25rem;
			line-height: 1.6;
			padding: 0 0 3.5rem;
		}

		@include media('≥tablet') {
			padding: 0 0 44px;
		}
	}

	.title {
		font-weight: 500;
	}

	.add-text {
		opacity: 0.7;
	}

	.visual {
		height: 43px;
		margin-bottom: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;

		@include media('≥phone') {
			height: 50px;
		}

		@include media('≥tablet') {
			margin-bottom: 1.5rem;
		}

		@include media('≥desktop') {
			margin-bottom: 1rem;
		}

		img {
			max-height: 100%;
			max-width: 80%;

			@include media('≥phone') {
				max-width: 100%;
			}
		}
	}
}

.text-sm-left {
	@include media('≥phone') {
		text-align: left !important;
	}
}

.js-tab-hidden {
	display: block !important;
	left: -9999px !important;
	position: absolute !important;
	top: -9999px !important;
}

.list-banner {
	@extend %listreset;
	text-align: center;

	@include media('≥widescreen') {
		display: flex;
	}

	[class^="col"]:not(:last-child) {
		margin-bottom: 1rem;

		@include media('≥tablet') {
			margin-bottom: 0;
		}
	}

	strong {
		font-weight: 500;

		+span {
			@include media('≥widescreen') {
				opacity: 0.8;
			}
		}
	}
}

.list-banner-item {
	position: relative;
	padding: 1.25rem;
	height: 100%;

	@include media('≥tablet') {
		padding: 1.25rem 0.5rem;
	}

	@include media('≥desktop') {
		padding: 1.25rem 1rem;
	}

	@include media('≥widescreen') {
		padding: 0 0.75rem 3rem;
	}

	&:before {
		@extend %icomoon;
		content: '\e91a';
		font-size: 36px;
		color: $blue;
	}
}

.banner-top {
	color: $white;
	// background-image: linear-gradient(90deg, #146194 0%, #1178BE 100%);
	background-image: linear-gradient(to left, #056eb0 0%, #056dac 15%, #046aa8 21%, #0969a6 31%, #07649d 54%, #0c629b 63%, #0b6298 65%, #0c5f95 80%, #0c5f91 82%, #0e5c8d 100%);
	/*Andrii Changed 8/11/20*/
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	overflow: hidden;
	position: relative;

	@include media('≥phone') {
		font-size: 1.5rem;
		line-height: 1.3333333;
	}

	&:before {
		display: block;
		position: absolute;
		bottom: 0;
		right: -10rem;
		width: 420px;
		height: 100%;
		background-image: linear-gradient(to right, #0000 27%, rgba($white, 0.05) 27%, rgba($white, 0.05) 100%);
		// background-image: linear-gradient(to right, rgba($white, 0.05) 0%, rgba($white, 0.05) 20%, #0000 20%,#0000 27%, rgba($white, 0.05) 27%, rgba($white, 0.05) 100%);
		transform: skew(42deg);

		@include media('≥tablet') {
			content: '';
		}

		@include media('≥desktop') {
			right: 0;
		}

		@include media('≥widescreen') {
			right: 13%;
		}
	}

	.jumbotron {
		background: none;
	}

	.inner-footer {
		background-color: transparent;
		padding: 0;
		margin-top: 1.25rem;

		@include media('≥desktop') {
			margin-top: 2rem;
		}
	}
}

.inner-footer {
	font-size: $font-size-base;
	background-color: #024877;
	color: $white;
	padding: 1.5rem 0;

	p {
		margin-bottom: 0;
	}

	span {
		@include media('≥desktop') {
			display: block;
		}
	}
}

.list-bullets {
	@include media('≥desktop') {
		display: flex;
		margin: 0 -15px;
		align-items: flex-start;
	}

	@include media('≥widescreen') {
		margin: 0 -2rem;
	}
}

.item-bullet {
	position: relative;
	padding-left: 45px;

	@include media('≥desktop') {
		padding: 0 15px;
		padding-left: 3.75rem;
	}

	@include media('≥widescreen') {
		padding: 0 2rem;
		padding-left: 4.5rem;
	}

	&:before {
		@extend %icomoon;
		content: "\e920";
		position: absolute;
		left: 0;
		font-size: 1.5rem;
		top: 50%;
		transform: translateY(-50%);

		@include media('≥desktop') {
			left: 15px;
		}

		@include media('≥widescreen') {
			left: 2rem;
		}
	}

	+.item-bullet {
		margin-top: 0.5rem;

		@include media('≥tablet') {
			margin-top: 1rem;
		}

		@include media('≥desktop') {
			margin-top: 0;
		}
	}
}

.section-services {
	.row+.row {
		padding-top: 2rem;
	}

	.text {
		background-color: $gray-100;
	}
}

.skew-style p {
	.link-arrow {
		line-height: 1.5;
		
		i {
			top: 8px;
		}
	}
}

.banner-home {
	padding: 2rem 0;
	font-size: 16px;
	color: #375d7d;

	@include media('≥desktop') {
		padding: 2.5rem 0;
	}

	@include media('≥widescreen') {
		padding: 5rem 0;
		font-size: 24px;
	}

	.title {
		margin-bottom: 0.5rem;
	}
}

.section-documents {
	.title {
		margin-bottom: 0.5rem;
		/*Andrii Added 8/11/20*/

		@include media('≥desktop') {
			margin-bottom: 0.75rem;
			/*Andrii Added 8/11/20*/
		}
	}
}

@import 'skew-style';

@import 'new-style';

//@import 'section-testtools';
