// section
.reset {
    section,
    .section-default-page {
        padding: 64px 0 80px 0;
        color: #596369;

        @media screen and (max-width: 376px) {
            padding: 32px 0 40px 0;
        }
        
    
        &.section-white {
            background: $white;
        }
    
        &.section-gray {
            background: #F6F8F8;
        }

        &.section-blue {
            background: #0076C0;
        }
    
        &.section-angle {
            background-color: $white;
            position: relative;
            z-index: 1;
            padding: 16px 0 80px 0;
    
            @include media("<phone") {
                padding: 64px 0 40px 0;
            }
    
            &:before {
                @extend %skew-shape;
                bottom: 0;
                transform-origin: 0;
                transform: skewY(-4.5deg); 
            }
        }

        &.section-angle-before { 
            padding-bottom: 249px;

            @media screen and (max-width: 1201px) {
                padding-bottom: 155px;
            }

            @include media("<phone") {
                padding-bottom: 100px;
            }

            &.angle-small {
                padding-bottom: 184px;

                @media screen and (max-width: 1201px) {
                    padding-bottom: 110px;
                }
    
                @include media("<phone") {
                    padding-bottom: 80px;
                }
            }
        }

        .section-heading {
            margin-bottom: 40px;    

            @media screen and (max-width: 376px) {
                margin-bottom: 24px; 
            }
            
            h2,
            .h2 { 
                @media screen and (max-width: 769px) {
                    font-size: 28px !important; 
                }
                
                @media screen and (max-width: 376px) {
                    font-size: 24px !important;
                    font-weight: 500;
                }
            }
        }
    }
    
    // typography

    &.skew-style main, 
    &.skew-style p {
        font-size: 16px;

        @media screen and (max-width: 376px) {
            font-size: 12px;
        }
    }

    p {
        margin-bottom: 8px;
    }

    p:last-child {
        margin: 0;
    }

    h1,.h1, h2,.h2, h3,.h3, h4,.h4, h5,.h5, h6,.h6,
    .title {
        color: $gray-900;
    }
    
    h1,
    .h1 {
      font-size: 52px;
      line-height: 1.3333333;
      margin: 0 0 0.5rem 0;
      font-weight: 500;
    
      @include media('<tablet') {
        margin: 0 0 0.75rem 0;
        font-size: 32px;
      }
    
      @include media('<xs-phone') {
        font-size: 24px;
      }
    }
    
    h2,
    .h2 {
        font-size: 44px;
        line-height: 1.333334;
        margin: 0 0 0.75rem 0;
    
        @media screen and (max-width: 769px) {
            margin: 0 0 0.75rem 0;
            font-size: 28px !important; 
        }
        
        @media screen and (max-width: 376px) {
            font-size: 24px !important;
            font-weight: 500;
        }
    }
    
    h3,
    .h3 {
        font-size: 28px;
        margin: 0 0 0.75rem 0;
        letter-spacing: 0.2px;
        line-height: 1.5;
        font-weight: 500;
    
        @media screen and (max-width: 769px) {
            margin: 0 0 0.75rem 0;
            font-size: 24px;
        }
        
        @media screen and (max-width: 376px) {
            font-size: 20px !important;
        }
    }
    
    h4,
    .h4 {
        font-size: 24px;
        line-height: 1.333334;
        margin: 0 0 16px 0;
        font-weight: 500;
    
        @include media('<tablet') {
            margin: 0 0 0.75rem 0;
            font-size: 20px;
        }
        
        @include media('<xs-phone') {
            font-size: 16px;
        }
    }
    
    h5,
    .h5,
    h6,
    .h6 {
        font-size: 16px;
        line-height: 1.333334;
        margin: 0 0 0.75rem 0;
        font-weight: 500;
    }

    main { 

        ul {
            padding-left: 0;
            list-style: none;

            li {
                padding-left: 18px;
                margin-bottom: 12px;
                position: relative;

                &:last-child {
                    margin-bottom: 0;    
                }
            } 
        }

        p a {
            color: #008ecd;

            &:hover {
                color: #008ecd;
                text-decoration: underline;
            }
        }

        .list-check {
            margin: 0;

            li {
                padding-left: 28px;
                &::before {
                    display: none;
                }

                i {
                    position: absolute;
                    left: 0;
                    top: 5px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: #0076C0;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.green {
                i:before {
                    color: #4CAF50;
                }
            }
        }

        ol {
            li {
                margin-bottom: 12px;
            }   
        }

        ul.list-dots {

            li {

                &::before {
                    background: $blue;
                    width: 8px;
                    height: 8px;
                    display: block;
                    position: absolute; 
                    top: 8px;
                    left: 0;
                    content: "";
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color: #0076C0;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.two {

                li {

                    &::before {
                        background: #EAEDEE;
                        top: 8px;
                        transform: translate(0, 0);
                    }
                }        
            }
        } 

        ul.list-items {
            display: flex;
            flex-wrap: wrap;
            list-style: none;

            li {
                padding: 0 0.25rem 0.5rem;

                span {
                    display: flex;
                    align-items: center;
                    color: rgba(0, 0, 0, 0.8);
                    background-color: #F6F8F8;
                    padding: 8px 12px;
                    height: 40px;
                }
            }
        }
        
        ul.list-options {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;

            li {
                font-size: 14px;
                padding: 8px 12px;
                margin: 0 8px 8px 0;
                padding: 5px 10px;
                background: #F6F8F8;
                color: #231F20;
                font-weight: 600;
            }
        }

        .card-text.green p {
            color: #219653;    
        }

        ul.list-characteristics {
            margin: 0 0 20px 0;
            padding: 0; 
            
            li {
                font-size: 16px;
                margin: 0 0 8px 0;
                padding: 0;
                color: #596369;

                &:last-child {
                    margin: 0;
                }
                
                span {
                    color: #231F20;
                    font-weight: 600;
                    margin-right: 5px;

                    &.green {
                        color: #219653;
                    }
                }
            }
        }

        ul.collection.white {
            background: #fff;
            box-shadow: 0 0.5rem 0.75rem #ececec;

            li {
                padding: 10px 16px 10px 50px;

                i {
                    left: 10px;
                    top: 10px;
                }
            }
        }

        ul.collection {
            border: 1px solid #EAEDEE;
            padding: 0;

            li {
               margin: 0;
               padding: 0;
               border-bottom: 1px solid #EAEDEE;

                &:last-child {
                    border-bottom: none;
                }
               
                a {
                   padding: 8px 16px;
                   display: block;
                   transition: .5s;

                    &:hover {
                        transition: .5s;
                        background: #EAEDEE;
                    }

                    h5 {
                        color: #0076C0;
                    }
                   
                }
            }
        }

        ul.number {

            li {
                padding-left: 40px;
            }

            i {
                background: #0076C0;
                padding: 0 10px;
                border-radius: 15px;
                display: inline-block;
                color: #fff;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-style: normal;
                position: absolute;
                left: 0;
                width: 32px;
                height: 32px;
            }

            &.green i {
                background: #4CAF50 !important;   
            }

            li.blue-color i {
                background: #0076C0 !important;   
            }

            li.orange-color i {
                background: #f26631 !important;   
            }
        }
    }

    // table
    .table-responsive {
        width: 100%;
        overflow: auto;  

        @media screen and (max-width: 1280px) {
            box-shadow: -6px 0px 10px #ccc inset;
        }
        
        table {
            width: 1240px !important;
        }
    }


    table:not(.table-second) {
        width: 100%;

        td:first-child {
            font-weight: bold;
        }

        tr:nth-child(2n+ 1) {
            background-color: #f7f9f9;
        }

        td, th {
            padding: 16px 8px;
            color: #231F20;
        }

        th {
            background-color: #0076c0;
            color: #fff;
        }
    }

    table.table-second {
        border: solid 1px #F6F8F8;
        width: 100%;

        tr {
            td {
                padding: 16px 40px; 

                .text {
                    display: flex;
                    justify-content: space-between;
                }
            }

            &:nth-child(2n+1) td:nth-child(2n+1) {
                background: #F6F8F8;
            }

            &:nth-child(2n) td:nth-child(2n) {
                background: #F6F8F8;
            }
        }
    }

    table.table-third {
        border: solid 1px #F6F8F8;

        thead th {
            background: #F6F8F8;
            color: #596369;
            font-weight: normal;
            padding: 16px 12px; 
            font-size: 14px;
            vertical-align: top;

            b {
                color: #231F20;
                margin-bottom: 8px;
                font-size: 16px;     
            }

            .icon {
                display: block;
                margin-bottom: 16px;
            }
        }

        tr {
            border-bottom: solid 1px #EAEDEE;

            td {
                padding: 16px 12px; 
                font-size: 14px;
                vertical-align: top;
                width: 229px;

                b {
                    font-size: 16px;      
                }

                &.first-icon { 
                    border-right: solid 1px #EAEDEE; 
                    vertical-align: middle;
                    padding: 16px 32px; 
                    width: auto;
                    transform: rotate(-90deg);
                    white-space: nowrap;
                    font-size: 16px;
                }

                i.icon {
                    width: 32px;
                    display: inline-block;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            &.group1 {
               background:rgba(33, 150, 83, 0.1);
            }

            &.group2 {
                background:rgba(0, 118, 192, 0.1);
             }
        }

    }

    

    // select
    select.error + label {
        display: none !important;
    }

    label.error + .jcf-select {
        border-color: #ff0000;
    }

    .jcf-select,
    select,
    .dropdown button {
        position: relative;
        cursor: pointer;
        border: 2px solid #F2F2F2;
        display: flex;
        align-items: center;
        background: #fff; 
    }

    .selected + .jcf-select {
        .jcf-select-text span {
            color: #000;
        }
    }

    .jcf-select.jcf-drop-active .jcf-select-text,
    select.jcf-drop-active .jcf-select-text {
        background-color: #ffffff;
    }

    .jcf-select.jcf-drop-active .jcf-select-opener,
    select.jcf-drop-active .jcf-select-opener {
        background-color: #ffffff;
    }

    .jcf-select.jcf-drop-active .jcf-select-opener:after,
    select.jcf-drop-active .jcf-select-opener:after {
        margin-top: 12px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    // .jcf-select.jcf-disabled,
    select.jcf-disabled,
    select[disabled] + .jcf-select {
        background: #F3F3F3;
        border: 1px solid #D7D7D7;
        cursor: default;

        .jcf-select-text span {
            color: #B5B5B5 !important;
        }

        .jcf-select-opener:after {
            border-right: 2px solid #7D7D7D !important;
            border-top: 2px solid #7D7D7D !important;
        }
    }

    .jcf-select .jcf-select-opener,
    select .jcf-select-opener {
        width: 46px;
        height: 40px;
        background-color: transparent;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .jcf-select .jcf-select-opener:after,
    select .jcf-select-opener:after {
        display: block;
        border-right: 1px solid #231F20;
        border-top: 1px solid #231F20;
        content: "";
        width: 8px;
        height: 8px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .jcf-select .jcf-select-text,
    select .jcf-select-text {
        color: #828282;
        font-size: 16px;
        line-height: 40px;
        width: 100%;
        padding: 0 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        background-color: transparent;
        display: inline-block;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .jcf-select-drop {
        position: absolute;
        top: calc(100% + 2px);
        left: -1px;
        z-index: 3;
        border: 2px solid #F2F2F2;
        border-top: none;
        border-radius: 2px;
        max-height: 300px;
        overflow: hidden;
    }

    .jcf-select-drop-content {
        background-color: #ffffff;
        -webkit-box-shadow: 0 8px 12px rgba(2, 21, 51, 0.1);
        box-shadow: 0 8px 12px rgba(2, 21, 51, 0.1);
    }

    .jcf-select-drop-content .jcf-list-content {
        vertical-align: top;
        display: inline-block;
        overflow: auto;
        width: 100%;
        max-height: 300px !important;
    }

    .jcf-select-drop-content ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .jcf-select-drop-content li {
        margin: 0 !important;
        padding: 0 !important;

        &::before {
            display: none !important;
        }
    }

    .jcf-select-drop-content .jcf-option {
        padding: 8px;
        font-size: 16px;
        line-height: 24px;
        color: #231F20;
        background-color: #ffffff;
        display: block;
        cursor: pointer;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .jcf-select-drop-content .jcf-option:hover {
        background-color: #F4F6F9;
    }

    .jcf-select-drop-content .jcf-option.jcf-selected {
        background-color: #E9EBEE;

        &:hover {
            background-color: #F6F8F8;
        }
    }

    // card
    .card-line {
        padding: 0;

        &.no-bg {
            box-shadow: none;
            background: #F6F8F8;
            border: none;

            .inner-content {
                padding: 0;
            }
        }

        &.no-style {
            background: none;
            box-shadow: none;
            border: none;

            .inner-content {
                padding: 0;
            }
        }

        .inner-img {
            height: 112px;
            background-repeat: no-repeat !important;
            background-position: center !important;

            &.big {
                height: 200px;
                width: 100%;
                background: none;
                background-size: contain;

                img {
                    max-height: 200px;
                    /* width: 100%; */
                    margin: 0 auto;
                    display: block;
                }
            }

            &.cover {
                background-size: cover;
            }
            
            & + .inner-content {
                padding-top: 24px;
                height: auto;

                h3 {
                    font-size: 24px;
                    line-height: 36px;
                }
            }  
            
            &.cover {
                background-size: cover;
            }
        } 

        .quantity {
            position: absolute;
            width: 128px;
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
            top: 0;
            right: 0;

            .quantity-wrap {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            span.number {
                display: block;
                text-align: center;
                font-size: 28px;
                margin-bottom: 10px;
            }

            &.bottom {
                top: auto;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50px;

                svg {
                    margin-right: 5px;
                }
            }
        }
        
        

        &.height {
            height: auto;
        }

        .inner-content.grey-light {
            background: #F6F8F8;

            .inner-img {
                height: 290px;

                @media screen and (max-width: 920px) {
                    height: 120px;
                }
            }
        }

        .inner-content {
            padding: 40px;

            &.small {
                padding: 40px 24px;    
            }

            &.small-24 {
                padding: 24px;    
            }

            .card-title,
            .card-images,
            .footer-card {
                width: 100%;
            }

            @media screen and (max-width: 376px) {
                padding: 24px;
            }

            .card-title {
                margin-bottom: 8px;

                h3, h4 {
                    margin: 0;
                }

                h4 {
                    min-height: 65px;
                }
            }

            .card-text {
                p {
                    font-size: 14px;

                    @media screen and (max-width: 376px) {
                        font-size: 12px !important; 
                    }
                }    

                &.big p {
                    // font-size: 16px; 
                }

                &.big20 p {
                    font-size: 20px; 
                }

                &.blue p {
                    color: #0076C0;
                }
            }

            .footer-card {
                margin-top: 24px;
            }


            p:last-child {
                margin: 0;
            }
        }     
    }

    .row > .small {
        &.col-lg-3 {
            padding: 0 8px;
        }    

        .inner-content {
            padding: 32px 24px 40px 24px;

            .title {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    
    .card-line.horizontale {
        .inner-img {
            min-width: 310px;
            height: auto;
        }
        
        &.small .inner-img {
            min-width: 254px;
        }

        &.small-2 {
            .inner-img {
                width: 180px;
                min-width: 180px;
                height: 100%;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    min-width: 0;
                    height: 200px;
                }
            }

            .inner-content {
                padding: 16px 16px 16px 32px;
            }
        }
    
        .inner-content {
            padding: 40px;

            @media screen and (max-width: 376px) {
                padding: 24px;
            }
    
            .card-title {
                margin-bottom: 8px;
            }
        }

        @media screen and (max-width: 720px) {
            display: block !important;  
            
            .inner-img {
                min-width: 0;
                height: 200px;
            }
        }
    }

    .text-img-content  {
        .row {
            margin-bottom: 64px;
            align-items: center;

            .img-content {
                height: 304px;
            }
        }
    }       
    
    // slider

    .slick-dots {
        .slick-active button {
            background-color: #0076c0;
            border-color: #0076c0;
        }   
        
        button {
            border: 1px solid #fff;
        }
    }

    .btn-default.secondary {
        color: #0076C0;
        border: solid 1px #0076C0;
        background: none;
        transition: .5s;

        &:hover {
            background:  #0076C0;
            transition: .5s;
            color: #fff;
        }
    }

    .page-text.justify-center.big {
        flex-direction: column;

        p {
            font-size: 24px;
            color: #231F20;
        }
    }

    @media screen and (max-width: 768px) {
        .row.tabs-area {
            width: 100%;
        }
    }
    

    .slider-block {
        margin-bottom: 40px;
    
        .slider-block-content {
    
            .slider-item-img {
                margin-bottom: 32px;
            }
        } 
    
        .slick-arrow {
            width: 48px;
            height: 48px;   
            border: solid 1px #0076C0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #fff;
            z-index: 99;
            transition: .5s;
    
            &:hover {
                transition: .5s;
                background: #0076C0;
    
                path {
                    stroke: #fff;
                }
            }
    
        }  
        
        .slider-block-action {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: -35px;   
            
            @media screen and (max-width: 640px) {
                justify-content: center;
                margin-top: 16px;
            }
        }
    
        .slider-block-info {
            margin: 0 16px;    
            color: #231F20;
            font-size: 16px;
        }
    }

    .card-bottom {
        span.video {
            color: #596369;
            padding-left: 8px;
            margin-left: 8px;
            border-left: solid 1px #596369;
        }
    }

    .section-video {
        .inner-img {
            border-bottom: solid 1px #EAEDEE;
        } 
     
        h3 {
         font-size: 24px;
        }
     
        .tabs-video h3 {
             font-size: 20px;     
        }
     
         .tabs-content.playlists  {
             .row > div {
                display: none;
     
                &.active {
                    display: block;    
                }
            }
     
            .show-more {
                cursor: pointer;
            }
        }
    }
     
    .video-list h3 {
        font-size: 20px !important;
    }

    .section-probes {
        .card-line {
            background: none;
            box-shadow: none;
            border: none;
    
            .inner-content .card-title h4 {
                min-height: 0;
            }	
            
            .inner-img {
                min-width: 160px;
                height: 160px;
                background-color: #f7f9f9;
            }
            
            .card-title h4 {
                font-size: 20px;
                line-height: 30px;
                color: #0076C0;
    
                a:hover {
                    text-decoration: underline;
                }
            }
    
            .card-link ul {
                display: block !important;
    
                li {
                    margin: 0 0 8px 0 !important;
                }
    
                a {
                    text-decoration: none !important;
    
                    &:hover {
                        text-decoration: underline !important; 
                    }
                }
            }
    
            @media (max-width: 768px) {
                justify-content: space-between;
                display: flex !important;
                
                .inner-content {
                    padding: 5px !important; 
                }
    
                .inner-img {
                    min-width: 100px;
                    height: 100px;
                }    
            }
        }
    }


    
    .modal {
        padding: 0 16px !important;

        .card-line .inner-img {
            height: 200px !important;
            width: 100% !important;
        }
    }

    .close {
        position: absolute;
        right: -45px;

        @media (max-width: 1100px) {
            right: 0;
            top: -45px;
        }
    }

    .modal-backdrop {
        opacity: .87;
        background: #1e1e1e;
    } 
    
    .modal-dialog {
        margin-top: 64px;   
        max-width: 1000px;
        
        iframe {
            width: 100%;
            height: 540px;
        }
    }

    .modal-body {
        padding: 24px 40px 40px 40px;

        @media (max-width: 1100px) {
            padding: 24px 16px 16px 16px;    
        }
    }

    .modal-body-title {
        padding-bottom: 16px;
        margin-bottom: 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #EAEDEE;

        h3 {
            margin: 0;
        }

        .share-block {
            display: flex;
            flex-wrap: wrap;
            margin-left: 10px;
            color: #596369;
            font-size: 16px;
            padding: 8px 10px;
            border-radius: 3px;
            transition: .5s;

            &:hover {
                transition: .5s;
                background: #F6F8F8;
            }

            &.open {
                background: #F6F8F8;
                transition: .5s;

                .share-block-left {
                    border-right: solid 1px #596369;
                    padding-right: 16px;
                }

                .share-block-right {
                    padding-left: 16px;
                    width: auto;
                    overflow: inherit;
                    visibility: visible;
                    transition: .5s;

                    ul {
                        flex-wrap: wrap;
                    }
                }
            }
        }

        .share-block-left {
            cursor: pointer;
            border-right: none;

            i {
                margin-left: 10px;
            }    
        }

        .share-block-right {
            width: 0;
            overflow: hidden;
            visibility: hidden;
            transition: .5s;

            ul {
                display: flex;
                margin: 0;
                
                li {
                    padding: 0;
                    margin: 0 16px 0 0;

                    &:last-child {
                        margin: 0;

                        svg {
                            stroke: #596369;
                        }

                        &:hover {
                            svg path {
                                stroke:#231F20;
                            }
                        }
                    }

                    &:not(:last-child) {
                        svg path {
                            fill:#596369;
                        }

                        &:hover {
                            svg path {
                                fill:#231F20;
                            }
                        }
                    }
                } 
            }    
        }
    }


    main ul.technical-docs-ul {
        border: none;
    
        li {
            border: none;
            margin-bottom: 24px;
    
            a {
                padding: 0;
                color: #596369;
    
                &:hover {
                    background: none;
    
                    h5 {
                        text-decoration: underline;
                    }
                }
            }
    
            h5 {
                margin-bottom: 8px;
                font-weight: 500;
            }
        }
    }

    
    .card-line {
        display: flex;
        flex-direction: column;  
        height: 100%;
        
        .inner-visible {
            margin-top: auto;    
        }

        &.horizontale {
            flex-direction: row;     
        }
    }

    .inner-visible.blue {
        font-weight: 400;
        font-size: 14px;
    }
    
    .section-serial-data {
        .inner-img img {
            width: 100%; 
        }

        .inner-content p {
            font-size: 14px;
            color: #596369;
        }

        .card-title h3 {
            letter-spacing: 0;
        }  
        
        [class^="col"] {
            padding: 0 0.5rem;
        }
    } 

    span.prompt,
    a.prompt {
        color: #BDBDBD;
        font-size: 12px;
        margin-top: 5px;
        display: inline-block;
    }

    a.prompt {
        color: #0076C0;

        &:hover {
            text-decoration: underline;
        }
    }

    .explore-content.small {
        max-width: 392px;
    }
}






