.reset {
	.section-banner-slide {
		padding: 0;
		height: 420px;
	
		.banner-slide-item {
			height: 420px;
			position: relative;
			background-position: center;
    		background-size: cover;

			&:before {
				background-color: rgba(11, 27, 37, 0.5);
				position: absolute;
				pointer-events: none;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: block;
				content: "";
			}

			&.default {
				&:before {
					background-color: rgba(0, 118, 192, 0.5);
				}	
			}

			&.blue {
				&:before {
					background: rgba(20, 97, 148, 0.5);
				}		
			}
		}

		.banner-slide-content {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			width: 100%;
    		// padding: 0 40px;
			
			h2, h1 {
				color: #fff;
			}
			
			span.page {
				text-transform: uppercase;
				margin-bottom: 8px;
				color: #fff;
			}

			.banner-slide-text {
				margin-bottom: 24px;

				p {
					color: #fff;
				}
			}
		}

		&.small {
			height: 320px;
			padding: 0 !important;

			.banner-slide-item {
				height: 320px;
			}
		}
	}

	.logos-list-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		
		.logos-item {
			margin: 0 50px 20px 0; 

			&:last-child {
				margin-right: 0;
			}
		}

		@media screen and (max-width: 920px) {

			max-width: 650px;
			margin: 0 auto;

			.logos-item {
				margin: 0 5px 20px 5px;
				width: calc(25% - 10px);
				text-align: center;
			}
		}
	}

	.section-approach {
		.approach-img {
			text-align: center;

			img {
				margin-bottom: 16px;
			}

			a {
				text-transform: uppercase;
			}
		}
	}

	.section-automotive-list {
		padding: 40px 0;

		.card-line.horizontale .inner-img {
			min-width: 410px;
			background-size: cover;
			background-color: inherit;

			@include media("<540px") {
				min-width: 0;
			}
		}
	}

	.also-interest {
		padding-top: 20px;

		.container  {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			.label {
				font-weight: 600;
				margin-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
			}
				
			a {
				color: #0076C0;

				&:hover {
					color: #003f66;
				}
			}			
		}	
	}
	
	// product

	#categoryTop {
		background:  #EAEDEE;
		height: 100%;

		.subNav {
			background: none;
			height: 100%;
			margin: 0;
			width: auto;

			a {
				display: block;
			}
		}
	}

	.product-banner-title {
		min-height: 120px;
		color: #0076C0;

		h2 {
			color: #0076C0;
		}
	}

	.section-product.product-banner-title {

		& + .product-line {
			.product-content {
				@include media('<720px') {
					padding-top: 0;
				}
			}	
		}
	}

	.section-product.product-line {
		.container {
			border-bottom: 1px solid #EAEDEE;
		}

		&:nth-child(3) {
			.container {
				border-bottom: none;
			}
		}

		.product-content {
			padding: 20px 0;

			p a {
				color: #0076C0;

				&:hover {
					color: #003f66;
				}
			}	

			.img-text {
				text-align: center;
				font-style: italic;
				font-size: 12px;
			}
		}

		.product-content-subtitle {
			margin-bottom: 24px;
			font-size: 20px;
			color: #231F20;
		}
	}

	.product-bottom-block {
		background: #EAEDEE;
		border-radius: 10px;
		margin-top: 85px;

		.row {
			margin: 0;
		}

		.product-bottom-img {
			margin-top: -85px;	
		}

		.product-bottom-content {
			padding: 20px 0;
			text-align: center;

			.product-bottom-text {
				margin-bottom: 20px;
			}

			p a {
				color: #008ecd;

				&:hover {
					color: #003f66;
				}
			}
		}

	}

	.slide-content.hidden {
		opacity: 0;
	}

	.section-default-page {
		padding: 30px 0;

		img {

			@media screen and (max-width: 840px) {
				max-width: 400px;
				width: 100%;

				&.big-img {
					max-width: 100%;
				}
			}		
		}

		.col-xl-4, 
		.col-xl-3 {
			@media screen and (max-width: 1140px) {
				margin-bottom: 24px;
			}
			
			@media screen and (max-width: 768px) {
				&:last-child {
					margin-bottom: 0;	
				}
			}
		}


		.container-gray {
			box-shadow: 0 0.5rem 0.75rem #ececec;
			background-color: #F6F8F8;
			border: 1px solid #EAEDEE; 
			padding: 24px;
		}

		p a {	
			color:#0076C0;

			&:hover {
				text-decoration: underline;
			}

			&.green {
				color: #4CAF50;
			}
		}

		h2.green {
			color: #4CAF50;
		}

		h2.blue {
			color: #0076C0;
		}

		.page-button {
			margin-top: 24px;

			a, span {
				margin: 0 16px 16px 0;
				position: relative;

				&:last-child {
					margin-right: 0;
				}
			}

			&.right {
				margin: 0;

				a {
					margin: 0 0 16px 0;
					width: 100%;
				}
			}

			@media screen and (max-width: 576px) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				justify-content: center;

				span, a {
					margin: 0 4px 8px 4px;	
					width: calc(50% - 10px);
				}
			}
		}

		.card-line.number {
			.card-title  h3 {
				color: $gray-900;
				font-size: 24px;
				font-weight: normal;

				.icon {
					display: inline-block;
					color: #fff;
					width: 37px;
					height: 37px;
					line-height: 37px;
					padding: 0;
					background-color: #4CAF50;
					border-radius: 50%;
					vertical-align: middle; 
					text-align: center;
					margin-right: 15px;
					font-style: normal;
				}
			}    
		}

		.tabs-area {

			.tab-hidden{
				position: absolute !important;
				left: -9999px !important;
				top: -9999px !important;
				display: block !important;
				width: 100% !important;
			}

			.tabs-pci {
				display: flex;

				@media screen and (max-width: 940px) {
					display: block;	

					.lc-item {
						margin-bottom: 8px;
					}
				}
			}

			.lc-item a,
			.lc-item span {
				padding: 8px;
				font-size: 13px;
				align-items: center;
				display: flex;
				justify-content: center;
				min-height: 60px;

				&:after {
					display: none;
				}
			}

			.tab-holder p {
				font-size: 13px;
			}
		}
	} 

	
	.upcoming-row {
		margin-top: 8px;
		padding-top: 8px;
		border-top: solid 1px #EAEDEE;
		flex-wrap: wrap;

		&:first-child {
			margin-top: 0;
			padding-top: 0;
			border-top: none;
		}
	}

	// quotations 
	.height-auto {
		height: auto !important;
		min-height: 0 !important;
	}

	.flex-betwen {
		justify-content: space-between;
	}

	.uppercase {
		text-transform: uppercase !important;
	}

	.h-100 {
		height: 100% !important;
	}

	.w-100 {
		width: 100% !important;
	}

	.mb-24 {
		margin-bottom: 24px !important;
	}

	.mb-32 {
		margin-bottom: 32px !important;
	}

	.mt-32 {
		margin-top: 32px !important;
	}

	.w-100 {
		width: 100% !important;
	}

	.mb-40 {
		margin-bottom: 40px !important;
	}

	.mb-64 {
		margin-bottom: 64px !important;
	}

	.mb-80 {
		margin-bottom: 80px !important;
	}

	.mb-120 {
		margin-bottom: 120px !important;
	}

	.p-40 {
		padding: 40px !important;

		@media screen and (max-width: 376px) {
			padding: 24px !important;	
		}
	}

	.p-24 {
		padding: 24px !important;
	}

	.pt-40 {
		padding-top: 40px !important;
	}

	.pt-0 {
		padding-top: 0 !important;
	}

	.pb-0 {
		padding-bottom: 0 !important;
	}

	.pb-40 {
		padding-bottom: 40px !important;
	}

	.pb-64 {
		padding-bottom: 64px !important;
	}

	.d-f-start {
		align-items: flex-start;
	}

	.d-f-center {
		display: flex;
		align-items: center;
	}

	.justify-content {
		justify-content: center !important;	
	}

	@media (min-width: 1200px) {
		.mb-lg-0, .my-lg-0 {
			margin-bottom: 0 !important;
		}
	}
	
	@media screen and (min-width: 992px) {
		.mb-md-0, 
		.my-md-0 {
			margin-bottom: 0 !important;
		}
	}

	@media screen and (min-width: 768px) {
		.mb-md-0, 
		.my-md-0 {
			margin-bottom: 0 !important;
		}
	}
	
	@media screen and (min-width: 376px) {
		.mb-sm-40 {
			margin-bottom: 40px !important;
		}
	}

	.section-quotations {
		.card-line {
			label {
				color:  #231F20;
				font-weight: 500;
			}
		}

		p {
			margin-bottom: 8px;

			a {
				color: #0076C0;
			}
		}

	}
		
	.quotations-try-top {
		margin-bottom: 80px;

		@media screen and (max-width: 993px) {
			margin-bottom: 23px;
		}
	}

	.quotations-try-bottom {
		.row.mb-40:last-child {
			margin: 0;
		}
	}

	.section-links {
		border-bottom: solid 1px #EAEDEE; 
		padding: 25px 0;

		&.section-links-izotope {
			border-bottom: none; 	
			padding: 0;

			&.open {
				.izotope-filters {
					display: block;
				}

				.slide {
					width: 200px;
					transform: translate(-50%, 0);
					left: 50%;

					.button {
						width: 100%;
						margin-bottom: 8px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		ul {
			display: flex;
			justify-content: space-between;
			margin: 0;

			li {
				margin: 0 10px 0 0;

				&:last-child {
					margin-right: 10px; 
				}

				a {
					color: #231F20;
					font-weight: 600;

					&:hover {
						color: #0076C0;
					}
				}
			}
		}

		&.open {
			ul {
				display: block;

				li {
					margin: 0;
					padding: 0;

					&:last-child {
						margin-bottom: 0;	
					}
				}	
				
				a {
					display: block;
				}
			}
		}
			
	}

	// bootstrap
	.show-list .multi-level {
		display: block;
	}

	.holder-form-items {
		display: -ms-flexbox;
		display: flex;
	}
	
	.holder-form-items .dropdown-menu {
		min-width: 160px;
	}
	
	.holder-form-items .dropdown-menu a {
		display: block;
		padding: 8px 6px;
	}
	
	.holder-form-items .dropdown-menu a:hover {
		color: #fff;
		background-color: #0076c0;
	}
	
	.holder-form-items .dropdown-submenu {
		position: relative;
		font-size: inherit;
	}
	
	.holder-form-items .dropdown-submenu:hover:after {
		content: "\e921";
		position: absolute;
		background-color: transparent;
		font-size: 8px;
		right: 10px;
		top: 50%;
		display: block;
		margin: 0;
		border: 0;
		transform: translateY(-50%);
		color: #fff;
	}
	
	.holder-form-items .dropdown-submenu:hover > a {
		color: #fff;
		background-color: #0076c0;
	}
	
	.holder-form-items .dropdown-item {
		width: 100%;
	}
	
	ul.dropdown-menu.multi-level {
		list-style: none !important;
		border: 1px solid;
		font-size: inherit;
		margin-top: 2px;
		font-size: 10px;
	}
	
	ul.dropdown-menu.multi-level li {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	
	ul.dropdown-menu.multi-level a {
		color: #000;
	}
	
	ul.dropdown-menu.multi-level a:focus {
		outline: none;
	}
	
	ul.dropdown-menu.multi-level a:after {
		content: none;
	}
	
	input[type="search"],
	.dropdown-toggle {
		background-color: transparent;
		box-shadow: none;
		border: 1px solid;
		position: relative;
		padding: 6px 2rem 6px .5rem;
	}
	
	input[type="search"]:after,
	.dropdown-toggle:after {
		content: "\e921";
		position: absolute;
		background-color: transparent;
		font-size: 8px;
		right: 10px;
		top: 50%;
		display: block;
		margin: 0;
		border: 0;
		transform: rotate(90deg) translateY(-50%);
		transform-origin: top;
	}
	
	input[type="search"]:focus,
	.dropdown-toggle:focus {
		outline: none;
	}
	
	.dropdown-submenu > ul.dropdown-menu {
		top: 0;
		padding: 0;
		font-size: inherit;
		left: calc(100% - .25rem);
	}
	
	.dropdown-menu {
		z-index: 1;
		border: 1px solid;
		border-radius: 0;
	}
	
	.dropdown-submenu:hover > .dropdown-menu {
		display: block;
	}
	
	.dropdown-submenu.pull-left {
		float: none;
	}
	
	.dropdown-submenu.pull-left > .dropdown-menu {
		left: -100%;
		margin-left: 10px;
		border-radius: 0;
	}
	
	#wrapper .content-container,
	#wrapper .column-center {
		overflow: visible;
	}

	.open-form.open + .contact-form {
		max-height: 9999px;	
		// transition: .5s;
		margin-top: 24px;
		visibility: visible;
	}

	.contact-form {
		max-height: 0;
		// transition: .5s;
		margin-top: 0;
		visibility: hidden;

		@media screen and (max-width: 768px) {
			.form-row .col-md-6:last-child {
				margin-top: 16px;
			}
		}
	}

	.open .datasheet-dropdown {
		max-height: 999px;
		transition: .5s;
		visibility: visible;
	}

	.datasheet-dropdown {
		position: absolute;
		z-index: 9;
		top: 100%;
		left: 0;
		background: #008ecd;
		padding: 0;
		margin: 0;
		max-height: 0; 
		overflow: hidden;
		transition: .5s;

		box-shadow: 0 0.5rem 0.75rem #ececec;
		background-color: #fff;
		border: 1px solid #EAEDEE;
		visibility: hidden;

		li {
			margin: 0 !important;
			border-bottom: 1px solid #EAEDEE;
			text-align: left;
			padding: 0 !important;

			&:last-child {
				border-bottom: none;
			}

			a {
				font-size: 12px;
				color: #0076C0;
				padding: 5px;
				display: inline-block; 
				margin: 0 !important; 

				&:hover {
					color: #0067a7;
				}
			}
		}
	}

	.justify-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.align-center {
		display: flex;
		align-items: center;
	}

	.preference-content {
		padding: 32px 0;
	
		.preference-content-header {
			margin-bottom: 24px;
	
			h1,h2 {
				color: #0076C0;
			}
		}
	
		.preference-text {
			a {
				color: #0076C0;
				text-decoration: underline;
	
				&:hover {
					text-decoration: none;	
				}
			}
	
			.preference-text-top {
				margin-bottom: 24px;
			}
	
			table {
				margin-bottom: 24px;
			}
		}
	}	

	span.img-title {
		font-weight: 500;
		color: #fff;
		margin-top: 5px;
		display: block;
		position: absolute;
		bottom: 20px;
		left: 30px;
		width: calc(100% - 80px);
		text-align: left;
	}
	
	.preference-footer {
		padding-bottom: 50px;
	}
	
	#videos {
		h3 {
			color: #fff;
			font-size: 16px;
		}
	}
	
	section#docs .description,
	section#briefs .description {
		white-space: nowrap; 
		overflow: hidden; 
		text-overflow: ellipsis;
	}

	.bottom-block {
		align-items: center;
	}


	.green-text {
		h2, .link-arrow {
			color: #4CAF50 !important;
		}
	
		h2 {
			font-size: 44px !important;
		}
	
		ul.green span {
			font-weight: 400;
		}
	}
	
	.green-text,
	.blue-text {
		.title {
			font-weight: 400; 
		}
	}
	
	.blue-text {
	
		h2, .link-arrow {
			color: #0076C0 !important;
		}
	
		ul.green span {
			font-weight: 500;
		}
	
		.inner-img {
			background-size: auto !important;
		}
	}
	
	.bottom-block-text {
		font-weight: 500;
		font-size: 26px;
		line-height: 28.6px;
		color: #000;
	}
	
	.slide-event {
		justify-content: flex-start;
		height: 100%;
		flex-direction: column;
		
		.top-card {
			margin-bottom: 24px;
		}
	
		.bottom-card {
			margin-top: auto;
		}
	
		.title-slide {
			min-height: 0;
			margin-bottom: 8px;
			font-size: 22px;
		}
	}
	
	.section-heading.line {
		h3 {
			padding-bottom: 8px;
			border-bottom: solid 1px #EAEDEE;
		}
	}
	
	.section-training-filters {
		padding: 24px 0 40px 0;
		
		.row > div {
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
		 }
	}
	
	.search-wrap {
		position: relative;
	
		input {
			padding-right: 30px;
		}
	
		.icon-search {
			position: absolute;
			right: 25px;
			bottom: 14px;
		}
	}
	
	.block-view {
		position: relative;
	
		.tabs-pci {
			display: flex; 
			flex-wrap: wrap;
		}
	
		.lc-item {
			padding: 0;
			flex: inherit;
			width: auto;
	
			a, span {
				cursor: pointer;
				padding: 20px 24px;
				background: none !important;
				color: #231F20;
				font-size: 20px;
				font-weight: 500 !important;
				position: relative;
				display: inline-block;

				@media (max-width: 768px) {
					font-size: 16px;

					@media (max-width: 321px) {
						font-size: 14px;
					}
				}
	
				&::before,
				&::after {
					display: none;
				}
	
				&:hover {
					color: #0076C0;
				}
	
				&::before {
					display: block;
					width: 0;
					height: 2px;
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					background: #0076C0;
					transition: .5s;
				}

				// @media (max-width: 480px) {
				// 	padding: 20px 12px;
				// }
			}
	
			&.active a  {
				color: #0076C0;
	
				&:before {
					transition: .5s;
					width: 100%; 
				}	
			}
		}
	
		.view-select {
			position: absolute;
			top: 25px;
			right: 0;
	
			span {
				display: inline-block;
				margin-left: 8px;
				cursor: pointer;
			}
	
			@media (max-width: 740px) {
				position: static;	
				margin: 20px 0; 
				text-align: center;
			}
		}
	
		&.view-cards {
			span.view-cards {
				rect {
					fill: #0076C0;
				}
	
				path {
					fill: #fff;
				}
			}
	
			.title-slide a {
				pointer-events: none;
			}
		}
	
		&.view-list {
	
			.title-slide a {
				color: #0076C0;
				pointer-events: inherit;
			}
	
			span.view-cards {
				rect {
					fill:#F6F8F8;
				}
	
				path {
					fill:#596369;
				}
			}
	
			span.view-list {
				rect {
					fill: #0076C0;
				}
	
				path {
					fill: #fff;
				}
			}
		}
	
		.tabs-content {
	
			.slide-event {
				min-height: 0;
				padding: 24px;
				position: relative;
				box-shadow: none;
				justify-content: space-between;
				flex-direction: row;
				align-items: center;
				flex-wrap: wrap;
				
				.top-card {
					margin-bottom: 0;
					width: calc(100% - 200px);
	
					@media (max-width: 740px) {
						width: 100%;
					}
				}
	
				.footer-slide {
					margin: 0;
				}
	
				.bottom-card {
					margin-top: 0;
					min-width: 150px;
					text-align: right;
	
					@media (max-width: 740px) {
						text-align: left;
						margin-top: 20px;
					}
				}
			} 
	
			&#tab-pci-1 {
				.local {
					color: #231F20;
					margin-right: 8px;
				}
	
				.date {
					color: #596369;
					border-left: solid 1px #596369;
					padding-left: 8px;
				}

				.calendar {
					padding: 0 10px;
					border-left: solid 1px #596369;

					a {
						color: #0076C0;
						font-weight: 500;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	
		.pagination-block {
			margin-top: 38px;
		}
	}
	
	main {
		.demand .line-none {
			display: none; 
		}
	}
	
	.border-top-green {
		padding-top: 40px !important;
		border-top: solid 1px #4CAF50 !important;
	}
	
	thead.green th{
	 background-color: #4CAF50 !important;
	}
	
	.white-space {
		white-space: nowrap;
	}
	
	.big-text p {
		font-size: 20px !important;
		font-weight: 400;
	}

	.demand-webinars {
		.card-line.horizontale .inner-content {
			padding: 16px;
		}

		.card-title h3 {
			font-size: 20px !important;
			line-height: 30px !important;
		}

		.card-line.horizontale .inner-img {
			width: 210px;
			min-width: 210px;
			background-size: contain;
			background-color: #fff;
			background-position: left top !important;
		}

		.card-part {
			margin-bottom: 8px;
			font-size: 16px;
			line-height: 24px;
			color: #231F20;
		}

		.card-time {
			font-size: 14px;
			line-height: 21px;
			color: #231F20;
		}
	}
	
}


body  {
	.show-register {
		display: none !important;
	}
}

body.register  {
	.show-register {
		display: flex !important;
	}

	.no-show-register {
		display: none !important;
	}
}	

.reset.skew-style .section-login { 
	.section-heading {
		margin-bottom: 32px;
	}

	[class^="row"] {
		margin: 0 -0.5rem;
	}
	
	[class^="col"] {
		padding: 0 0.5rem;
	}

	.sub-title p {
    	color: #231F20;
		font-size: 16px;
	}
	
	h2 { 
		margin-bottom: 24px !important;
	}

	.link-back {
		font-weight: 500;
	}

	label.error + .jcf-select {
		border-color: inherit;
	}

	// input[type="password"] + label + .prompt {
	// 	display: none;
	// }
}


.table-list {
    border: solid 1px #EAEDEE;
    display: flex;
    flex-wrap: wrap;
}

    .table-list .table-column-item {
        border-bottom: 1px solid #EAEDEE;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        padding: 12px 24px;
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #231F20;
    }

@media (max-width: 920px) {
    .table-list .table-column-item {
        font-size: 12px;
    }
}

.table-list-column.first .table-column-item {
    justify-content: start;
}

.table-list-column.active {
    box-shadow: 0px 4px 8px #eaedee;
}

    .table-list-column.active .table-column-item:nth-child(2n+1) {
        background: #F6F8F8;
    }

    .table-list-column.active .table-column-item:first-child {
        background: #0076C0;
        color: #fff;
    }

.table-list .table-column-item:first-child {
    background: #F6F8F8;
    font-weight: 500;
    font-size: 20px;
}

.table-list .table-column-item:last-child {
    border-bottom: none;
}

.table-list-description {
    font-size: 14px;
    color: #596369
}

#videos iframe {
    width: 100%;
    height: 200px;
}

.login-page .column-left-container {
    border-right: none;
    float: none;
    margin: 0;
    position: static;
}

.login-page .column-center-container {
    float: none;
    position: static;
    width: 100%;
}

.login-page .column-container {
    float: none;
    margin-left: 0;
    position: static;
    width: 100%;
}

.login-page .column-center {
    margin: 0;
    position: static;
}

.reset .section-login .sub-title p {
    color: #231F20;
    font-size: 16px;
}

.reset .section-login .sub-title b {
    font-size: 20px;
    font-weight: 500;
    display: block;
}

.reset .explore-content.small {
    max-width: 392px;
}

.reset span.prompt,
.reset a.prompt {
    color: #BDBDBD;
    font-size: 12px;
    margin-top: 5px;
    display: inline-block;
}

.reset a.prompt {
    color: #0076C0;
}

.tooltip {
    opacity: 1 !important;

}

a.tooltip {
    border-radius: 50%;
    position: relative !important;
    border: solid 1px #000;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transition: .5s;
    z-index: 0;
}

.tooltip:hover {
    background-color: #000;
    color: #fff;
    transition: .5s;
}

.tooltip-inner {
    box-shadow: 0px 4px 8px #eaedee;
    background: #fff;
    color: #000;
    position: absolute;
    z-index: 99999;
    top: 0;
    transform: translate(20px, -50%);
    width: 400px;
    max-width: 999px;
    border: solid 1px #ccc;
}

.tooltip .tooltip-arrow {
    top: 50%;
    right: 100%;
    width: 10px;
    height: 10px;
    position: absolute;
    display: block;
    border: 10px solid transparent;
    border-right: 10px solid #000;
    margin-right: -20px;
    transform: translate(0, -50%);
}

.tooltip .arrow {
	margin-left: 20px;
}

@media (max-width: 720px) {
	a.tooltip {
		position: absolute !important;
    	right: 10px;
	}

	.tooltip-inner {
		box-shadow: 0px 4px 8px #eaedee;
		background: #fff;
		color: #000;
		position: absolute;
		z-index: 99999;
		top: 100%;
		transform: translate(0, 20px);
		width: 270px;
		right: 100%;
		border: solid 1px #ccc;
		max-width: 270px;
		font-size: 12px;
	}
 
	.tooltip .tooltip-arrow {
		top: 100%;
		left: 50%;
		border: 10px solid transparent;
		border-bottom: 10px solid #000;
	}

	.tooltip .arrow {
		margin-top: 10px;
    	margin-left: -15px;
	}

	.tooltip .arrow::before {
		transform: rotate(90deg);
	}
 }

.table-comparison {

	.item-img {
		height: 172px;
		background: none !important;
		text-align: center;
		padding: 0;

		img {
			height: 132px;
		}
	}

	.table-list .table-column-item:first-child {
		background: #0076C0 !important;
		color: #fff;
		padding: 12px 5px;

		@media (max-width: 920px) {
			font-size: 16px;
		}
	}

	.table-list .table-column-item:nth-child(2n + 1) {
		background-color: #F6F8F8;
	}
}

.table-comparison_top {
	display: flex;
	flex-wrap: wrap;

	@media (max-width: 768px) {
		.first {
			display: none;
		}
	}
}

.reset .card-line .inner-content.small .card-title h4 {
    min-height: 0;
	font-size: 24px;
}

a.green {
    color: #4CAF50 !important;	

	h5 {
		color: #4CAF50 !important;	
	}
}

.section-pro {
	.card-title h4 {
		min-height: 0 !important;	
	}

	.card-content p {
		font-size: 14px !important;	
	}
}


.min-h-auto {
	min-height: 0 !important;
}

section.product-exp {
	background-repeat: no-repeat;
    background-size: contain;
    background-color: #F6F8F8;
    background-position: top right;

	p {
		color: #231F20;
	}
}

section.product {
	ul.list-characteristics li {
		color: #231F20;
		font-weight: 600;

		span {
			margin: 0 5px 0 0 !important;
			font-weight: normal !important;
		}	
	}
}

.co-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	height: 100%;

	p {
		color: #231F20;
	}
}

.white-space {
	white-space: nowrap;
}

.logo-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .logo-item {
        width: 20%;

        @media (max-width: 1140px) {
            width: auto;
        }
    }
}

.description-text p {
	color: #596369 !important;
	font-size: 14px !important;
	line-height: 150% !important;
}

section.platform {
	display: flex;
    align-items: center; 

	background-repeat: no-repeat !important;
	min-height:900px;

	@media (max-width: 1200px) {
		min-height: 0;
		background: none !important;
	}
}

.container.small-2 {
	max-width: 604px;
}

.reset.skew-style .product-details-item p {
	font-size: 14px;
}

.demonstration-view {
	background: #DCDCDC;
	align-items: center;
	padding: 12px;
	border-radius: 0 110px 110px 0;
	position: relative;

	&::before {
		background: #DCDCDC;
		position: absolute;
		display: block;
		content: "";
		right: 100%;
		top: 0;
		width: 99999%;
		height: 100%;
	}

	&.before-right {
		border-radius: 110px 0 0 110px;
		&::before {
			right: auto;
			left: 100%;
		}	
	}

	.demonstration-img img {
		min-height: 190px;
    	min-width: 190px;
	}
}

.reset.new-skew-style #main .tabs-filter.top {
    top: 40px;
}

.h-full.home-video.bg-video-holder.pt-0 {
    padding-top: 0 !important;
}

.section-izotope .card-line.p-0 .title {
    font-weight: 500;
}