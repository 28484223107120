// Button sizes
@mixin button-color($btn-background, $btn-color, $btn-border-color, $btn-hover-background, $btn-hover-color, $btn-hover-border-color) {
  background: $btn-background;
  color: $btn-color;
  border-color: $btn-border-color;  

  &:hover {
    background: $btn-hover-background;
    color: $btn-hover-color;
    border-color: $btn-hover-border-color;
  }

  &:focus {
  	box-shadow: none;
  }
}

.btn {
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  border: 2px solid;
  transition: none;

  @include media('≥phone') {
    padding: 0.5rem 2.25rem;
  }

  .focus,
  &:focus {
    box-shadow: none !important;
  }

  &:link,
  &:active,
  &:visited {
    color: $body-color;
  }
}

.btn-default {
  $color: $gray-100;
  @include button-color($blue, $color, $blue, #0067a7, $color, #0067a7);

  &:link,
  &:active,
  &:visited {
    color: $color;
  }
}

.btn-white {
  $color: $blue;
  @include button-color($white, $color, $white, $pale-gray, $color, $pale-gray);

  &:link,
  &:active,
  &:visited {
    color: $color;
  }
}

.btn-green {
  background-color: #4CAF50 !important;
  border-color: #4CAF50 !important; 
  color: #fff !important;

  &:hover {
    background-color: #fff !important;
    border-color: #4CAF50 !important;
    color: #4CAF50 !important;
  }
}

.btn-green-outline {
  background-color: transparent !important;
  border-color: #4CAF50 !important;
  color: #4CAF50 !important;

  &:hover {
    background-color: #4CAF50 !important;
    color: #fff !important;
    border-color: #4CAF50 !important;
  }
}

.btn-blue-outline {
  background-color: transparent !important;
  border-color: #0076C0 !important;
  color: #0076C0 !important;


  &:hover {
    background-color: #0076C0 !important;
    color: #fff !important;
    border-color: #0076C0 !important;
  }
}

.btn-default-outline {
  $color: $blue;
  @include button-color(transparent, $blue, $color, $blue, $white, $blue);
  border: 2px solid;

  &:link,
  &:active,
  &:visited {
    color: $color;
  }
}
