.section-lifecycle {
  .tabs-area {
    > * {
      flex: 1 1 auto;
    }
  }

  .tabs-pci {
    @include media('≥widescreen') {
      display: flex;
      margin-bottom: 2.5rem;
      padding: 0 5px;
    }

  }

  .tab-holder {
    display: none;
    padding: 0 15px;

    @include media('≥tablet') {
      display: flex;
      flex-direction: column;
    }

    @include media('≥widescreen') {
      flex: 1 1 auto;
    }

    [id^="tab"] {
      padding: 0;

      &:before {
        content: none;
      }
    }

    .slide-content {
      @include animate(opacity, 0.6s);
      opacity: 1;

      @include media('≥widescreen') {
        display: flex;
        min-height: 0;
      }
    }

    .tab-hidden {
      opacity: 0;
    }

    p {
      @include media('≥tablet') {
        margin-bottom: 1.5rem;
      }
    }
  }

  .visual {
    margin-bottom: 1rem;

    @include media('≥tablet') {
      margin-bottom: 1.5rem;
    }
    
    @include media('≥widescreen') {
      flex-basis: calc(50% - 15px);
      margin-bottom: 0;
    }
  }

  .text {
    @include media('≥widescreen') {
      flex-basis: calc(50% - 15px);
    }
  }

  .title {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 0.5rem;

    @include media('≥phone') {
      font-size: 1.5rem;
      line-height: 1.333333;
    }

    @include media('≥desktop') {
      margin-bottom: 1rem;
    }
  }

  .wrap-btn {

    @include media('≥desktop') {
      padding-top: 8px;
      display: flex;
      align-items: flex-start;
    }

    .btn {
      //min-width: 264px;
      margin-bottom: 0;

      @include media('≥desktop') {
        //min-width: 260px;
      }

      & + .btn {
        margin-bottom: 0;

        @include media('≥desktop') {
          margin-left: 1rem;
          margin-bottom: 0;
        }
      }
    }

    p {
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  .wrap-links {
    p {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
  }
}

.lc-item {
  margin-bottom: 1rem;

  @include media('≥widescreen') {
    display: flex;
    margin-bottom: 0;
    padding: 0 10px;
    width: 100%;
    flex: 1 1 auto;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.show {
    .opener {
      @extend %active-tab;

      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &.active {

    .opener {
      @include media('≥tablet') {
        background-color: $blue;
        color: $white;
        font-weight: 500;
      }
    }
  }

  .opener {
    @include animate(color background-color);
    display: block;
    background-color:#F6F8F8;
    color: #000;
    padding: 0.625rem 2rem 0.625rem 1rem;
    position: relative;

    @include media('≥tablet') {
      padding-right: 1rem;
    }

    @include media('≥widescreen') {
      width: 100%;
      height: 100%;
      padding: 1.5rem 0.5rem 0.75rem;
      text-align: center;
    }

    &:hover {
      @extend %active-tab;
    }

    > * {
      display: inline-block;
      vertical-align: middle;

      @include media('≥widescreen') {
        display: block;
      }
    }

    &:after {
      @extend %icomoon;
      content: "\e907";
      font-size: 7px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;

      @include media('≥tablet') {
        content: none;
      }
    }
  }

  .slide-content {
    padding: 1rem 0 0.5rem;

    @include media('≥phone') {
      padding: 1.5rem 0 0.5rem;
    }

    @include media('≥tablet') {
      display: none;
    }

    p {
      @include media('≥phone') {
        margin-bottom: 1.5rem;
      }
    }
  }

  i {
    margin-right: 0.75rem;
    font-size: 1.5rem;

    @include media('≥widescreen') {
      display: block;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }

    &:before {
      color: inherit;
    }
  }
}