@import '../base/mixins';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
// @include font-face("HelveticaNeue", "helveticaneueregular-webfont", 400, normal);
@include font-face("HelveticaNeue", "helveticaneuemedium-webfont", 500, normal);

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?pjx8xk');
  src:  url('../fonts/icomoon.eot?pjx8xk#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?pjx8xk') format('truetype'),
    url('../fonts/icomoon.woff?pjx8xk') format('woff'),
    url('../fonts/icomoon.svg?pjx8xk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chek:before {
    content: "\e92c";
    color: #0076c0;
}
.icon-scroll:before {
    content: "\e92b";
    color: #fff;
}
.icon-calendar-1:before {
    content: "\e928";
}
.icon-check-2:before {
    content: "\e929";
    color: #fff;
}
.icon-local-1:before {
    content: "\e92a";
}
.icon-arr-right:before {
    content: "\e926";
}
.icon-arr-left:before {
    content: "\e927";
}
.icon-clock:before {
    content: "\e924";
}
.icon-calendar:before {
    content: "\e925";
}
.icon-target-link:before {
    content: "\e923";
    color: #0076bf;
}
.icon-btn-right-01:before {
    content: "\e921";
    color: #5e686e;
}
.icon-btn-left-01:before {
    content: "\e922";
    color: #5e686e;
}
.icon-check-1:before {
    content: "\e920";
    color: #fff;
}
.icon-menu2:before {
    content: "\e91b";
}
.icon-menu3:before {
    content: "\e91c";
}
.icon-menu1:before {
    content: "\e91d";
}
.icon-menu .path1:before {
    content: "\e91e";
    color: rgb(255, 255, 255);
}
.icon-menu .path2:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(34, 34, 34);
}
.icon-check:before {
    content: "\e91a";
    color: #fff;
}
.icon-world:before {
    content: "\e918";
}
.icon-profile:before {
    content: "\e919";
}
.icon-prototype:before {
    content: "\e90f";
}
.icon-resolution:before {
    content: "\e910";
}
.icon-testing:before {
    content: "\e911";
}
.icon-training:before {
    content: "\e912";
    color: #fff;
}
.icon-validation:before {
    content: "\e913";
}
.icon-bring-up:before {
    content: "\e914";
}
.icon-compliance:before {
    content: "\e915";
}
.icon-debug:before {
    content: "\e916";
}
.icon-power-on:before {
    content: "\e917";
}
.icon-icon-check:before {
    content: "\e90e";
    color: #0076bf;
}
.icon-location:before {
    content: "\e90d";
}
.icon-btn-right:before {
    content: "\e90b";
}
.icon-btn-left:before {
    content: "\e90c";
}
.icon-arrow-right:before {
    content: "\e90a";
    color: #0076bf;
}
.icon-close:before {
    content: "\e900";
}
.icon-fb:before {
    content: "\e901";
    color: #0076bf;
}
.icon-in:before {
    content: "\e902";
    color: #0076bf;
}
.icon-search:before {
    content: "\e903";
}
.icon-tw:before {
    content: "\e904";
    color: #0076bf;
}
.icon-yt:before {
    content: "\e905";
    color: #0076bf;
}
.icon-arrow:before {
    content: "\e906";
    color: #fff;
}
.icon-arrow-down:before {
    content: "\e907";
    color: #fff;
}
.icon-arrow-left:before {
    content: "\e908";
    color: #0076bf;
}
.icon-blogger:before {
    content: "\e909";
    color: #0076bf;
}
